import { useOutletContext } from 'react-router-dom';
import type { ContextType } from '~/common';
import { ModelMenu, PresetsMenu, HeaderNewChat } from './Menus';
import HeaderOptions from './Input/HeaderOptions';
import RagMenu from './Menus/RagMenu';
import ControlsMenu from './Menus/ControlsMenu';
import { useChatSettings, useUserModels } from '~/services/queries/models';
import { SKU, VERA_RED } from '~/utils/constants';
import { useState } from 'react';
import NoModelsInfo from './Menus/NoModelsInfo';
import { useUser } from '~/services/queries/user';
import { useMediaQuery } from '~/hooks';
import { useOrg } from '~/services/queries/orgs';
import { useAuth } from '~/Providers/useAuth';
import PoliciesMenu from './Menus/SandboxPoliciesMenu';

export default function Header() {
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [showNoModelsWarning, setShowNoModelsWarning] = useState(true);
  const { navVisible } = useOutletContext<ContextType>();
  const orgQuery = useOrg();
  const modelsQuery = useUserModels();
  const { user } = useAuth();
  const userQuery = useUser(user?.user_id!);
  const chatSettingsQuery = useChatSettings();
  const routingIsActive = chatSettingsQuery.data?.routing_enabled ?? false;
  const showRagControls = chatSettingsQuery.data?.rag_enabled !== null;
  const showRagMenu = !!chatSettingsQuery.data?.rag_enabled;
  const models = modelsQuery.data ?? [];
  const selected =
    models.find((model) => model.id_ === chatSettingsQuery.data?.default_model)?.id_ ?? '';
  const isSandbox = orgQuery.data?.sku === SKU.SANDBOX;
  const userSupportsImageUpload =
    userQuery?.data?.images_allowed && userQuery?.data?.upload_allowed && !isSandbox;

  const getContent = () => {
    if (!modelsQuery.isLoading && models.length === 0)
      return (
        <>
          <p
            className="mx-4 cursor-pointer underline"
            style={{ color: VERA_RED }}
            onClick={() => setShowNoModelsWarning(true)}
          >
            No models configured
          </p>
          <NoModelsInfo open={showNoModelsWarning} onOpenChange={setShowNoModelsWarning} />
        </>
      );

    return (
      <>
        <div className="flex">
          {!navVisible && <HeaderNewChat />}
          <ControlsMenu showRag={showRagControls} />
          {!routingIsActive && (
            <ModelMenu
              userSupportsImageUpload={!!userSupportsImageUpload}
              isSandbox={isSandbox}
              models={models}
              routingIsActive={chatSettingsQuery.data?.routing_enabled ?? false}
              selected={selected}
            />
          )}
          {isSandbox && <PoliciesMenu />}
          <HeaderOptions />
          {/* <PresetsMenu /> */}
          {showRagMenu && <RagMenu />}
        </div>
        <label
          className={`${
            isSmallScreen ? 'mr-0 text-xs' : 'mr-2 text-sm'
          } justify-self-end font-normal text-gray-500 dark:text-[#8A81B8]`}
        >
          {isSmallScreen ? 'Limit' : 'Usage limit'}:{' '}
          <span
            className={`${
              (userQuery.data?.curr_requests ?? 0) >= (userQuery.data?.max_requests ?? 10)
                ? 'text-vred'
                : 'text-gray-500'
            }`}
          >
            {(userQuery.data?.max_requests ?? 0) - (userQuery.data?.curr_requests ?? 0)} requests
            left
          </span>
          {/* /{userQuery.data?.max_requests} requests left */}
        </label>
      </>
    );
  };

  return (
    <div className="sticky top-0 z-10 flex h-14 w-full items-center justify-between bg-vwhite/95 p-2 font-semibold shadow-sm dark:bg-vnavy-dark-mode dark:text-vwhite">
      <div className="hide-scrollbar flex w-full items-center justify-between gap-2 overflow-x-auto">
        {getContent()}
      </div>
      {/* Empty div for spacing */}
      <div />
    </div>
  );
}
