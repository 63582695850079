import { Trigger } from '@radix-ui/react-popover';

export default function TitleButton({
  primaryText = '',
  secondaryText = <></>,
  isDisabled = false,
}) {
  const content = (
    <div
      className={`text-md group flex items-center gap-1  rounded-xl px-3 py-2 font-medium hover:bg-gray-50 radix-state-open:bg-gray-50 dark:hover:bg-vnavy-hover dark:radix-state-open:bg-[#3B3465] ${
        isDisabled ? 'cursor-default text-gray-400 hover:bg-transparent' : 'cursor-pointer'
      } `}
      // type="button"
    >
      <div className="flex items-center truncate">
        {primaryText}
        {!!secondaryText && secondaryText}
      </div>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        className={`text-token-text-tertiary ${isDisabled ? 'hidden' : ''}`}
      >
        <path
          d="M11.3346 7.83203L8.00131 11.1654L4.66797 7.83203"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );

  if (isDisabled) {
    return <span>{content}</span>;
  }

  return <Trigger asChild>{content}</Trigger>;
}
