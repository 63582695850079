import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(timezone);

export const onClickCellCopy = (value: string, toast: any) => {
  toast({
    title: 'Value Copied',
    description: 'Value successfully copied to your clipboard',
    status: 'info',
    duration: 5000,
    isClosable: true,
  });
  navigator.clipboard.writeText(value);
};

export const formatDate = (date: Date | string | null, formatOverride?: string) => {
  if (!date) return 'None';

  return dayjs
    .utc(date)
    .local()
    .format(formatOverride || 'L LT');
};

export const compareUtcDates = (
  dateString1: string | Date | null,
  dateString2: string | Date | null,
) => {
  const date1 = dateString1 ? dayjs(dateString1) : null;
  const date2 = dateString2 ? dayjs(dateString2) : null;

  if (!date1 && !date2) {
    return 0; // Both dates are null, consider them equal
  }

  if (!date1) {
    return 1; // date1 is null, place it at the end
  }

  if (!date2) {
    return -1; // date2 is null, place it at the end
  }

  return date1.isBefore(date2) ? -1 : date1.isAfter(date2) ? 1 : 0;
};
