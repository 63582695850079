import { cn } from '~/utils';
export default function BingAIMinimalIcon({ className = '' }) {
  return (
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth="1"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={cn('h-4 w-4', className)}
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m12.648 7.5685c-0.50544 0.051397-0.89091 0.41065-0.92674 0.8637-0.01545 0.19521-0.0106 0.20845 0.49497 1.3471 1.1502 2.5905 1.4289 3.214 1.4758 3.3018 0.11355 0.21251 0.27321 0.41243 0.47269 0.59187 0.15307 0.1377 0.25403 0.21173 0.42478 0.31156 0.3001 0.17541 0.44902 0.2239 1.6168 0.5265 1.1375 0.29477 1.759 0.49069 2.2945 0.7233 0.69376 0.30136 1.1778 0.64416 1.484 1.0509 0.21968 0.29184 0.41423 0.81518 0.49888 1.342 0.0331 0.20592 0.03332 0.66112 4.01e-4 0.84729-0.07142 0.40404-0.21408 0.74262-0.43231 1.026-0.11606 0.15068-0.07568 0.12546 0.09306-0.05817 0.47754-0.51963 0.96399-1.4077 1.2122-2.2131 0.30039-0.9747 0.34126-2.0213 0.11759-3.012-0.43558-1.9291-1.8271-3.594-3.7869-4.5307-0.12313-0.058878-0.59216-0.27403-1.228-0.56337-0.096488-0.043922-0.22806-0.10404-0.29238-0.13359-0.064327-0.029581-0.1959-0.089695-0.29238-0.13359-0.096489-0.043895-0.37425-0.17055-0.61725-0.28144-0.243-0.11091-0.5149-0.23498-0.60423-0.27574-0.27232-0.12427-0.45504-0.20775-0.59127-0.27016-0.63008-0.28868-0.89678-0.40538-0.9728-0.42563-0.07975-0.021233-0.28234-0.048493-0.33317-0.044835-0.01071 7.938e-4 -0.05937 0.00549-0.10814 0.010432z" />
      <path d="m14.021 17.239c-0.03492 0.01809-0.08393 0.0443-0.10894 0.0582-0.02504 0.01392-0.08058 0.04413-0.12346 0.06717-0.15738 0.08454-0.57595 0.31026-0.93564 0.50458-0.23638 0.12771-0.27147 0.14674-0.57175 0.30984-0.10723 0.05822-0.22126 0.11958-0.25342 0.13636-0.03216 0.01678-0.16957 0.09084-0.30537 0.16458-0.1358 0.07371-0.37261 0.20168-0.52628 0.28434-0.15366 0.08264-0.4285 0.23102-0.61074 0.32972-0.18226 0.09867-0.42202 0.22807-0.53278 0.28753-0.11079 0.05944-0.21311 0.11686-0.22741 0.12759-0.021237 0.01594-1.0065 0.5501-1.5009 0.8137-0.37548 0.20018-0.80985 0.33408-1.2545 0.38667-0.20701 0.02447-0.59874 0.02456-0.80516 1.4e-4 -0.55972-0.066143-1.0754-0.24884-1.517-0.5374-0.17322-0.1132-0.49933-0.39839-0.62401-0.54572-0.2938-0.34713-0.48386-0.71948-0.58229-1.1408-0.02265-0.09696-0.044078-0.17883-0.047599-0.18197-0.0092-0.0081 0.00742 0.13833 0.037398 0.32981 0.031175 0.19915 0.097605 0.4872 0.16915 0.73355 0.55368 1.9065 2.1292 3.4572 4.2621 4.195 0.61417 0.21234 1.2339 0.34617 1.9083 0.4118 0.25339 0.02484 0.97064 0.03469 1.2351 0.01711 1.2128-0.08039 2.2688-0.39281 3.3521-0.99199 0.09649-0.05337 0.27776-0.1534 0.40282-0.22233 0.12509-0.06891 0.28296-0.15652 0.35087-0.19465 0.06789-0.03815 0.14975-0.08323 0.18192-0.10015 0.03216-0.01695 0.09649-0.05231 0.14295-0.07863 0.04646-0.02629 0.24528-0.13636 0.44181-0.24457l1.0558-0.58364 0.0097-0.0054 0.02973-0.01643 0.01414-0.0078 0.88532-0.4894c0.8749-0.48115 1.1358-0.65006 1.5423-0.99853 0.16946-0.14524 0.42494-0.39321 0.43764-0.42469 0.0026-0.0064 0.04799-0.06771 0.10091-0.13624 0.2151-0.27856 0.35853-0.61978 0.42966-1.0222 0.03291-0.18616 0.0327-0.64137-4.02e-4 -0.84729-0.06398-0.39813-0.20934-0.84973-0.36605-1.1373-0.25701-0.47152-0.80449-0.89995-1.591-1.245-0.21716-0.09527-0.44141-0.18246-0.46645-0.18131-0.01187 5.39e-4 -0.74376 0.39206-1.6264 0.87009-0.88266 0.47803-1.6487 0.89294-1.7023 0.92205-0.05362 0.02913-0.14571 0.07838-0.20468 0.10941z" />
      <path d="m3.481 14.946 0.00378 3.3294 0.049238 0.19334c0.15396 0.60448 0.42072 1.0403 0.88446 1.4451 0.21813 0.19041 0.38493 0.3052 0.62131 0.42762 0.50018 0.25906 1.0385 0.38686 1.6281 0.38663 0.61757-2.82e-4 1.1518-0.1351 1.7023-0.42959 0.092899-0.04969 0.45692-0.24584 0.80891-0.43589l0.63999-0.34554v-7.9005l-1.874e-4 -3.6137c-1.339e-4 -2.3055-0.0049543-3.6747-0.013283-3.7821-0.052488-0.67502-0.37505-1.2956-0.91745-1.765-0.16646-0.14405-0.30869-0.24027-0.7324-0.49545-0.21084-0.12699-0.59679-0.35962-0.85765-0.51695-0.26086-0.15734-0.69065-0.41654-0.9551-0.57601-0.26445-0.15946-0.64162-0.38697-0.83816-0.50557-0.40945-0.24708-0.4415-0.26426-0.56527-0.30302-0.16099-0.050404-0.3316-0.068919-0.4938-0.053589-0.47275 0.044684-0.85119 0.34342-0.94956 0.74955-0.015308 0.063187-0.018128 0.90411-0.018323 5.4682l-2.205e-4 5.3936h-5.06e-4z" />
    </svg>
  );
}
