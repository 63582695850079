import React from 'react';
import { Spinner } from '../svg';
import { useNavigate } from 'react-router-dom';
import { useUser } from '~/services/queries/user';
import { ACCOUNT_STATE } from '~/utils/constants';
import { useAuth } from '~/Providers/useAuth';

const LockedAccountPage = () => {
  // todo: instead of depending on some random query add state to user_metadata
  const navigate = useNavigate();
  const { user } = useAuth();

  // using to see if user is locked
  const userQuery = useUser(user?.user_id!);

  if (userQuery.isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner color="black" />
      </div>
    );
  }

  if (userQuery.data?.account_state === ACCOUNT_STATE.LOCKED) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-gray-100">
        <div className="w-full max-w-md rounded-lg bg-vwhite p-8 shadow-lg">
          <h2 className="mb-4 text-center text-2xl font-bold text-vnavy">Account Locked</h2>
          <p className="mb-8 text-center text-vnavy">
            Your account is locked. Contact your administrator for details.
          </p>
        </div>
      </div>
    );
  }

  navigate('/c/new', { replace: true });
};

export default LockedAccountPage;
