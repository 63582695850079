import React from 'react';

function MediaIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.55991 17.2733L9.05703 11.7761C9.38704 11.4461 9.55205 11.2811 9.74232 11.2193C9.90969 11.1649 10.09 11.1649 10.2574 11.2193C10.4476 11.2811 10.6126 11.4461 10.9426 11.7761L16.4031 17.2366M11.6665 12.5L14.057 10.1095C14.387 9.77946 14.552 9.61445 14.7423 9.55263C14.9097 9.49825 15.09 9.49825 15.2574 9.55263C15.4476 9.61445 15.6126 9.77946 15.9426 10.1095L18.3332 12.5M8.33317 7.5C8.33317 8.42047 7.58698 9.16667 6.6665 9.16667C5.74603 9.16667 4.99984 8.42047 4.99984 7.5C4.99984 6.57953 5.74603 5.83333 6.6665 5.83333C7.58698 5.83333 8.33317 6.57953 8.33317 7.5ZM5.6665 17.5H14.3332C15.7333 17.5 16.4334 17.5 16.9681 17.2275C17.4386 16.9878 17.821 16.6054 18.0607 16.135C18.3332 15.6002 18.3332 14.9001 18.3332 13.5V6.5C18.3332 5.09987 18.3332 4.3998 18.0607 3.86502C17.821 3.39462 17.4386 3.01217 16.9681 2.77248C16.4334 2.5 15.7333 2.5 14.3332 2.5H5.6665C4.26637 2.5 3.56631 2.5 3.03153 2.77248C2.56112 3.01217 2.17867 3.39462 1.93899 3.86502C1.6665 4.3998 1.6665 5.09987 1.6665 6.5V13.5C1.6665 14.9001 1.6665 15.6002 1.93899 16.135C2.17867 16.6054 2.56112 16.9878 3.03153 17.2275C3.56631 17.5 4.26637 17.5 5.6665 17.5Z"
        stroke="#A6A9B3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MediaIcon;
