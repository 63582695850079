import React, { useContext } from 'react';
import InfoIcon from '~/components/svg/InfoIcon';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui';
import { ThemeContext } from '~/hooks';

function MessageInfoChip({ children, text, bg, color, darkBg = '', darkColor = '' }) {
  const { theme } = useContext(ThemeContext);

  let isDark = theme === 'dark';

  if (theme === 'system') {
    isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  return (
    <TooltipProvider delayDuration={50}>
      <Tooltip>
        <div
          className="max-w-max-content flex items-center rounded-xl px-3 py-1"
          style={{
            background: isDark ? darkBg || bg : bg,
            color: isDark ? darkColor || color : color,
          }}
        >
          <span className="mr-2">{text}</span>{' '}
          <TooltipTrigger>
            <InfoIcon color={isDark ? darkColor || color : color} />
          </TooltipTrigger>
        </div>
        <TooltipContent className="bg-[#4A417A]" side="top" sideOffset={-5}>
          {children}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default MessageInfoChip;
