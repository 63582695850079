import { useRecoilValue } from 'recoil';
import React, { useState, useEffect } from 'react';
import store from '~/store';
import type { TMessage } from 'librechat-data-provider';
import rehypeHighlight from 'rehype-highlight';
import type { PluggableList } from 'unified';
import ReactMarkdown from 'react-markdown';
import supersub from 'remark-supersub';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import CodeBlock from '~/components/Messages/Content/CodeBlock';
import { langSubset, validateIframe, processLaTeX } from '~/utils';
import { useChatContext } from '~/Providers';
import { BlockReplace, REDACT_REGEX, RedactReplace } from './RedactReplace';

type TCodeProps = {
  inline: boolean;
  className: string;
  children: React.ReactNode;
};

type TContentProps = {
  content: string;
  message: TMessage;
  showCursor?: boolean;
};

const Markdown = React.memo(({ content, message, showCursor }: TContentProps) => {
  const [cursor, setCursor] = useState('');
  const { isSubmitting, latestMessage } = useChatContext();
  const LaTeXParsing = useRecoilValue<boolean>(store.LaTeXParsing);

  const isInitializing = content === '<span className="result-streaming">█</span>';

  const { isEdited, messageId } = message ?? {};
  const isLatestMessage = messageId === latestMessage?.messageId;

  const _content = content?.replace('z-index: 1;', '') ?? '';
  const currentContent = LaTeXParsing ? processLaTeX(_content) : _content;

  const code = React.memo(({ inline, className, children }: TCodeProps) => {
    const match = /language-(\w+)/.exec(className || '');
    const lang = match && match[1];

    const renderableContent = children?.map((child) => {
      if (REDACT_REGEX.test(child)) {
        return <RedactReplace content={child} policyMessage={message.policyMessage} />;
      }
      return child;
    });

    if (inline) {
      return <code className={className}>{renderableContent}</code>;
    } else {
      return <CodeBlock lang={lang || 'text'} codeChildren={renderableContent} />;
    }
  });

  const li = React.memo((props) => {
    const { children } = props;

    const renderableContent = children.map((child) => {
      if (REDACT_REGEX.test(child)) {
        // console.log('li', child);

        return <RedactReplace content={child} policyMessage={message.policyMessage} />;
      }
      return child;
    });

    return <li>{renderableContent}</li>;
  });

  const strong = React.memo((props) => {
    const { children } = props;

    const renderableContent = children.map((child) => {
      if (REDACT_REGEX.test(child)) {
        // console.log('li', child);

        return <RedactReplace content={child} policyMessage={message.policyMessage} />;
      }
      return child;
    });

    return <strong>{renderableContent}</strong>;
  });

  const p = React.memo((props) => {
    const { children } = props;
    let renderableContent = children;
    if (message.isBlocked) {
      renderableContent = [
        <BlockReplace content={children} policyMessage={message.policyMessage} />,
      ];
    } else if (message.isRedacted) {
      // the passed in item used to be children, so if code styling becomes an issue revisit this
      renderableContent = children.map((child) => {
        if (REDACT_REGEX.test(child)) {
          // console.log('p', child);

          return <RedactReplace content={child} policyMessage={message.policyMessage} />;
        }
        return child;
      });

      // renderableContent = [children];

      return renderableContent;
    }

    return <p className="mb-2 whitespace-pre-wrap">{renderableContent}</p>;
  });

  useEffect(() => {
    let timer1: NodeJS.Timeout, timer2: NodeJS.Timeout;

    if (!showCursor) {
      setCursor('');
      return;
    }

    if (isSubmitting && isLatestMessage) {
      timer1 = setInterval(() => {
        setCursor('');
        timer2 = setTimeout(() => {
          setCursor('');
        }, 200);
      }, 1000);
    } else {
      setCursor('');
    }

    // This is the cleanup function that React will run when the component unmounts
    return () => {
      clearInterval(timer1);
      clearTimeout(timer2);
    };
  }, [isSubmitting, isLatestMessage, showCursor]);

  const rehypePlugins: PluggableList = [
    [rehypeKatex, { output: 'mathml' }],
    [
      rehypeHighlight,
      {
        detect: true,
        ignoreMissing: true,
        subset: langSubset,
      },
    ],
    [rehypeRaw],
  ];

  let isValidIframe: string | boolean | null = false;
  if (!isEdited) {
    isValidIframe = validateIframe(currentContent);
  }

  if (isEdited || ((!isInitializing || !isLatestMessage) && !isValidIframe)) {
    rehypePlugins.pop();
  }

  return (
    <ReactMarkdown
      remarkPlugins={[supersub, remarkGfm, [remarkMath, { singleDollarTextMath: true }]]}
      rehypePlugins={rehypePlugins}
      linkTarget="_new"
      components={
        {
          code,
          p,
          li,
          strong,
        } as {
          [nodeType: string]: React.ElementType;
        }
      }
    >
      {isLatestMessage && isSubmitting && !isInitializing
        ? currentContent + cursor
        : currentContent}
    </ReactMarkdown>
  );
});

export default Markdown;
