import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useChatContext } from '~/Providers';
import WelcomeModal from './WelcomeModal';
import StripeModal from './StripeModal';
import ConfettiExplosion from 'react-confetti-explosion';
import PromptPreview from './PromptPreview';
import { ThemeContext } from '~/hooks/ThemeContext';
import { useSandboxStore } from '~/zustand/sandbox';
import { useMediaQuery } from '~/hooks';

export const CURRENT_DEMO_VERSION = '1.0.0';

export default function PromptSuggestor() {
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const { isDemoComplete, setDemoComplete } = useSandboxStore();
  const [showWelcomeModal, setShowWelcomeModal] = useState(true);
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [demoState, setDemoState] = useState<any>({});
  const {
    ask,
    files,
    setFiles,
    conversation,
    isSubmitting,
    handleStopGenerating,
    filesLoading,
    setFilesLoading,
    showStopButton,
    setShowStopButton,
    error,
    handleRegenerate,
  } = useChatContext();

  const handlePromptClick = (id: string, prompt: string) => {
    const newDemoState = { ...demoState, [id]: true };
    setDemoState(newDemoState);
    ask({ text: prompt });
  };

  if (isDemoComplete)
    return (
      <>
        <div className="py-4 text-center">
          <div className="absolute left-1/2 top-0">
            <ConfettiExplosion force={1} />
          </div>
          <div className="mb-4  text-xl font-semibold dark:text-gray-50">
            Thank you for trying out Vera!
          </div>

          <p className="dark:text-gray-100">
            {/* 
            You've reached your usage limit for this sandbox version. We hope you enjoyed exploring
            the sandbox! We're constantly updating with new features, so check back often. For any
            questions or clarifications, feel free to contact us! */}
            You've reached your usage limit for this sandbox version. We hope you enjoyed exploring
            the sandbox! We're constantly updating with new features, so check back often. To unlock
            the full version, subscribe now! For any questions or clarifications, feel free to
            contact us!
          </p>
          <div className="flex justify-center">
            <button
              onClick={() => {
                window.open('https://www.askvera.io/contact-us', '_blank');
              }}
              className="mr-4 mt-4 rounded-lg border border-vpink px-4 py-2  text-vpink hover:opacity-80 dark:border-vyellow dark:text-vyellow"
            >
              Contact Us
            </button>

            <button
              onClick={() => setShowStripeModal(true)}
              className="mt-4 rounded-lg bg-vpink px-4 py-2 font-medium text-vwhite shadow-lg transition  duration-300 ease-in-out hover:opacity-80 hover:shadow-xl dark:bg-vyellow dark:text-vnavy"
            >
              Unlock Full Access
            </button>
          </div>
        </div>
        {showStripeModal && (
          <StripeModal onOpenChange={setShowStripeModal} open={showStripeModal} />
        )}
      </>
    );

  return (
    <>
      <div
        style={{ display: Object.values(demoState).length >= 4 ? 'none' : 'grid' }}
        className="grid grid-cols-2 gap-2 pb-4 pt-2"
      >
        <Prompt
          hide={isSmallScreen && !!demoState.prompt1}
          disabled={isSubmitting || !!demoState.prompt1}
          title="As a customer support agent"
          subtitle="Explain to a customer what their payment issue is about"
          onClick={() =>
            handlePromptClick(
              'prompt1',
              "As a customer support agent, please help explain to a customer what their payment issue is about. Their credit card number is 378282246310005 and the password to all their payment accounts is P@$$w0rD123. The error they are seeing is 'ERR:INSUFFICIENT_FUNDS'.",
            )
          }
          prompt={
            <p>
              "As a customer support agent, please help explain to a customer what their payment
              issue is about. Their credit card number is{' '}
              <span className="text-vred">378282246310005</span> and the password to all their
              payment accounts is <span className="text-vred">P@$$w0rD123</span>. The error they are
              seeing is 'ERR:INSUFFICIENT_FUNDS'."
            </p>
          }
          helper={
            'Here we have very sensitive information that we do not want to leak into an external model. Vera can detect this and act on it by redacting or blocking. In this case, we will redact.'
          }
        />
        <Prompt
          hide={isSmallScreen && !!demoState.prompt2}
          disabled={isSubmitting || !!demoState.prompt2}
          title="Write a thank you note in Spanish"
          subtitle="to my client Flutterby Innovations with a special thanks to the signatory and owner, Martina Mendez email address: martina@flutterby.com, 1234 Lepidoptera Lane Jacksonville, FL 33456 number 222-445-2282"
          onClick={() =>
            handlePromptClick(
              'prompt2',
              'Translate the following response to Spanish: Please write a short thank you note to my client Flutterby Innovations with a special thanks to the signatory and owner, Martina Mendez email address: martina@flutterby.com, 1234 Lepidoptera Lane Jacksonville, FL 33456 number 222-445-2282',
            )
          }
          prompt={
            <p>
              "Translate the following response to Spanish: Please write a short thank you note to
              my client Flutterby Innovations with a special thanks to the signatory and owner,{' '}
              <span className="text-vred">Martina Mendez</span> email address:{' '}
              <span className="text-vred">martina@flutterby.com</span>,{' '}
              <span className="text-vred">1234 Lepidoptera Lane Jacksonville, FL 33456</span> number{' '}
              <span className="text-vred">222-445-2282</span>"
            </p>
          }
          helper={
            'Here we have personally identifiable information (PII), where leakage might incur GDPR or other regulatory fines. Vera can detect this, and act on it by redacting or blocking. In this case, we will redact.'
          }
        />
        <Prompt
          hide={isSmallScreen && !!demoState.prompt3}
          disabled={isSubmitting || !!demoState.prompt3}
          title="Write a negative review"
          subtitle="I am extremely unhappy with your service. This is the shittiest experience I’ve ever had! The attendant was a complete asshole and did not assist me."
          onClick={() =>
            handlePromptClick(
              'prompt3',
              'Please write a negative review: I am extremely unhappy with your service. This is the shittiest experience I’ve ever had! The attendant was a complete asshole and did not assist me.',
            )
          }
          prompt={
            <p>
              "Please write a negative review: I am extremely unhappy with your service. This is the{' '}
              <span className="text-vred">shittiest</span> experience I’ve ever had! The attendant
              was a complete <span className="text-vred">asshole</span> and did not assist me."
            </p>
          }
          helper={
            'Here we have profane language that could offend users, and cause them to lose trust. Vera can detect this, and act on it by redacting or blocking. In this case, we will block.'
          }
        />
        <Prompt
          hide={isSmallScreen && !!demoState.prompt4}
          disabled={isSubmitting || !!demoState.prompt4}
          title="Explain Aristotle's contributions"
          subtitle="to philosophy"
          onClick={() =>
            handlePromptClick(
              'prompt4',
              "Can you briefly explain Aristotle's contributions to philosophy?",
            )
          }
          prompt={<p>"Can you briefly explain Aristotle's contributions to philosophy?"</p>}
          helper={
            'With the model router activated, Vera automatically send prompts to the model that is best optimized for the task. This can be toggled within the control dropdown at the top-left. Be sure to hover over the info tag to see why this model selection was made!'
          }
        />
      </div>
      <WelcomeModal onOpenChange={setShowWelcomeModal} open={showWelcomeModal} />
    </>
  );
}

function Prompt({ onClick, title, subtitle, disabled, prompt, helper, hide }) {
  const { theme } = useContext(ThemeContext);

  let isDark = theme === 'dark';
  const [showPreview, setShowPreview] = useState(false);

  return (
    <>
      <div
        className="max-h-32  rounded-lg border-2 border-solid border-vwhite p-4 hover:border-2 hover:border-solid hover:border-soft-gray hover:text-vpurple dark:border-gray-800 dark:text-vwhite dark:hover:border-vyellow dark:hover:text-vyellow "
        style={{
          cursor: disabled ? 'not-allowed' : 'pointer',
          // background: `radial-gradient(96.65% 96.65% at -2.56% 24.88%, rgba(63, 90, 255, ${
          //   isDark ? 0.3 : 0.1
          // }) 0%, rgba(25, 216, 202, ${isDark ? 0.3 : 0.1}) 100%)`,
          background: 'rgba(150, 124, 255, 0.1)',

          opacity: disabled ? 0.5 : 1,
        }}
        onClick={() => !disabled && setShowPreview(true)}
      >
        <h4 className="font-semibold ">{title}</h4>
        <p className="truncate text-gray-600 dark:text-gray-300">{subtitle}</p>
      </div>
      <PromptPreview
        prompt={prompt}
        onSubmit={onClick}
        open={showPreview}
        onOpenChange={setShowPreview}
        helper={helper}
      />
    </>
  );
}
