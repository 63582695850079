import React, { useState } from 'react';
import InfoForm from './InfoForm';
import UserRegistrationForm from './UserRegistrationForm';
import { useNavigate } from 'react-router-dom';
import { useToastContext } from '~/Providers';
import { useAuth } from '~/Providers/useAuth';

const InfoFormStepsPage = () => {
  const [key, setKey] = useState('form');
  const [email, setEmail] = useState('');
  const { signInNatively } = useAuth();
  const navigate = useNavigate();
  const { showToast } = useToastContext();

  // if (formDetailsCollected && key === 'form') setKey('password');

  const getContent = (key: string) => {
    switch (key) {
      case 'form':
        return (
          <InfoForm
            onFormSubmitted={(data) => {
              setEmail(data.email);
              setKey('password');
              // localStorage.setItem('form-details-collected', 'true');
            }}
          />
        );
      case 'password':
        return (
          <UserRegistrationForm
            title={'Try it Now!'}
            subtitle={
              "Fill in your login details to create your demo account. Once submitted, you'll be redirected to the Vera Sandbox."
            }
            userEmail={email}
            onFormSubmitted={({ response }) => {
              signInNatively(response);
              navigate('/login', { replace: true });
              // showToast({
              //   message: 'Sandbox Account Successfully Created! Signing in...',
              //   duration: 2900,
              // });
              // setTimeout(() => navigate('login', { replace: true }), 3000);
              //localStorage.setItem('sandbox-password-created', 'true');
            }}
          />
        );
      default:
        return (
          <InfoForm
            onFormSubmitted={() => {
              // localStorage.setItem('form-details-collected', 'true');
            }}
          />
        );
    }
  };

  return <div className=" max-h-screen overflow-auto">{getContent(key)}</div>;
};

export default InfoFormStepsPage;
