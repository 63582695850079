import React, { useState } from 'react';
import UserRegistrationForm from '../Sandbox/UserRegistrationForm';
import StripeCheckout from './StripeCheckout';
import { Box, Heading, VStack, Text, Link } from '@chakra-ui/react';
import { HARD_GRAY, VERA_TEAL } from '~/utils/constants';
import { useAuth } from '~/Providers/useAuth';
import { useNavigate } from 'react-router-dom';

const RegistrationStepsPage = ({ startKey }: { startKey?: 'upgrade' }) => {
  const navigate = useNavigate();
  const { signInNatively } = useAuth();
  const [key, setKey] = useState(startKey || 'register');
  const [email, setEmail] = useState('');
  const [orgId, setOrgId] = useState('');
  const [accessToken, setAccessToken] = useState('');

  const getContent = (key: string) => {
    switch (key) {
      // case 'register':
      //   return (
      //     <UserRegistrationForm
      //       isRegister
      //       title={'Create an account'}
      //       userEmail={email}
      //       onFormSubmitted={({ response }) => {

      //         signInNatively(response, true);
      //         setAccessToken(response.access_token);
      //         setOrgId(response.user.org_id);
      //         setKey('upgrade');
      //       }}
      //     />
      //   );
      case 'upgrade':
        return (
          <Box maxW="md" mx="auto" my={8} p={8} background={'white'} borderRadius={'lg'}>
            <VStack spacing={4} textAlign={'center'}>
              <Heading as="h2" size="lg" mb={4}>
                Your account has been successfully created 🎉
              </Heading>

              <Text fontSize="lg" mt={2}>
                You're just one step away from unlocking everything Vera has to offer!
              </Text>

              <StripeCheckout orgId={orgId} accessToken={accessToken} />

              <Link
                onClick={() =>
                  (window.location.href = import.meta.env.VITE_AUTH0_LOGOUT_CALLBACK_URL)
                }
                fontSize={'smaller'}
                color={HARD_GRAY}
              >
                Or continue to your sandbox here
              </Link>
            </VStack>
          </Box>
        );
      default:
        return (
          <UserRegistrationForm
            isRegister
            title={'Create an account'}
            userEmail={email}
            onFormSubmitted={({ response }) => {
              signInNatively(response);
              setAccessToken(response.access_token);
              setOrgId(response.user.org_id);
              // setKey('upgrade');
              navigate('/upgrade', { replace: true });
            }}
          />
        );
    }
  };

  return <div className="max-h-screen overflow-auto">{getContent(key)}</div>;
};

export default RegistrationStepsPage;
