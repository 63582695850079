// Russian phrases

export default {
  com_ui_examples: 'Примеры',
  com_ui_new_chat: 'Создать чат',
  com_ui_example_quantum_computing: 'Объясни квантовые вычисления простыми словами',
  com_ui_example_10_year_old_b_day:
    'У тебя есть креативные идеи для дня рождения 10-летнего ребенка?',
  com_ui_example_http_in_js: 'Как мне сделать HTTP-запрос в JavaScript?',
  com_ui_capabilities: 'Возможности',
  com_ui_capability_remember: 'Запоминает, что пользователь говорил ранее в разговоре',
  com_ui_capability_correction: 'Позволяет пользователю вносить корректировки после ответа',
  com_ui_capability_decline_requests: 'Обучен отклонять неподходящие запросы',
  com_ui_limitations: 'Ограничения',
  com_ui_limitation_incorrect_info: 'Иногда может генерировать некорректную информацию',
  com_ui_limitation_harmful_biased:
    'Иногда может создавать вредные инструкции или предвзятое содержимое',
  com_ui_limitation_limited_2021: 'Ограниченные знания о мире и событиях после 2021 года',
  com_ui_experimental: 'Экспериментальный',
  com_ui_input: 'Ввод',
  com_ui_close: 'Закрыть',
  com_ui_model: 'Модель',
  com_ui_select_model: 'Выберите модель',
  com_ui_use_prompt: 'Использовать промт',
  com_ui_prev: 'Предыдущий',
  com_ui_next: 'Следующий',
  com_ui_stop: 'Остановить генерацию',
  com_ui_prompt_templates: 'Шаблоны промтов',
  com_ui_hide_prompt_templates: 'Скрыть шаблоны промтов',
  com_ui_showing: 'Показано',
  com_ui_of: 'из',
  com_ui_entries: 'записей',
  com_ui_pay_per_call: 'Все AI-разговоры в одном месте. Оплачивайте за вызовы, а не за месяц',
  com_ui_new_footer: 'Все AI-разговоры в одном месте.',
  com_ui_enter: 'Ввести',
  com_ui_submit: 'Отправить',
  com_ui_upload_success: 'Файл успешно загружен',
  com_ui_upload_invalid: 'Недопустимый файл для загрузки',
  com_ui_cancel: 'Отмена',
  com_ui_save: 'Сохранить',
  com_ui_save_submit: 'Сохранить и отправить',
  com_ui_copy_to_clipboard: 'Копировать в буфер обмена',
  com_ui_copied_to_clipboard: 'Скопировано в буфер обмена',
  com_ui_regenerate: 'Повторная генерация',
  com_ui_continue: 'Продолжить',
  com_ui_edit: 'Редактировать',
  com_ui_success: 'Успешно',
  com_ui_all: 'все',
  com_ui_clear: 'Удалить',
  com_ui_revoke: 'Отозвать',
  com_ui_revoke_info: 'Отозвать все предоставленные пользователем учетные данные',
  com_ui_confirm_action: 'Подтвердить действие',
  com_ui_chats: 'чаты',
  com_ui_delete: 'Удалить',
  com_ui_preview: 'Предпросмотр',
  com_ui_upload: 'Загрузить',
  com_ui_connect: 'Подключить',
  com_ui_delete_conversation: 'Удалить чат?',
  com_ui_delete_conversation_confirm: 'Будет удален следующий чат: ',
  com_auth_error_login:
    'Не удалось войти с предоставленной информацией. Пожалуйста, проверьте ваши учетные данные и попробуйте снова.',
  com_auth_error_login_rl:
    'Слишком много попыток входа в систему за короткий промежуток времени. Пожалуйста, повторите попытку позже.',
  com_auth_error_login_ban:
    'Ваша учетная запись была временно заблокирована в связи с нарушениями нашего сервиса.',
  com_auth_error_login_server:
    'Произошла внутренняя ошибка сервера. Пожалуйста, подождите несколько минут и повторите попытку.',
  com_auth_no_account: 'Еще не зарегистрированы?',
  com_auth_sign_up: 'Зарегистрироваться',
  com_auth_sign_in: 'Войти',
  com_auth_google_login: 'Войти с помощью Google',
  com_auth_facebook_login: 'Войти с помощью Facebook',
  com_auth_github_login: 'Войти с помощью Github',
  com_auth_discord_login: 'Войти с помощью Discord',
  com_auth_email: 'Email',
  com_auth_email_required: 'Email обязателен',
  com_auth_email_min_length: 'Email должен содержать не менее 6 символов',
  com_auth_email_max_length: 'Email не может быть длиннее 120 символов',
  com_auth_email_pattern: 'Вы должны ввести действительный адрес электронной почты',
  com_auth_email_address: 'Адрес электронной почты',
  com_auth_password: 'Пароль',
  com_auth_password_required: 'Пароль обязателен',
  com_auth_password_min_length: 'Пароль должен содержать не менее 8 символов',
  com_auth_password_max_length: 'Пароль должен быть не более 128 символов',
  com_auth_password_forgot: 'Забыли пароль?',
  com_auth_password_confirm: 'Подтвердите пароль',
  com_auth_password_not_match: 'Пароли не совпадают',
  com_auth_continue: 'Продолжить',
  com_auth_create_account: 'Создать аккаунт',
  com_auth_error_create:
    'Возникла ошибка при попытке зарегистрировать ваш аккаунт. Пожалуйста, попробуйте еще раз.',
  com_auth_full_name: 'Полное имя',
  com_auth_name_required: 'Имя обязательно',
  com_auth_name_min_length: 'Имя должно содержать не менее 3 символов',
  com_auth_name_max_length: 'Имя должно быть короче 80 символов',
  com_auth_username: 'Имя пользователя (необязательно)',
  com_auth_username_required: 'Имя пользователя обязательно',
  com_auth_username_min_length: 'Имя пользователя должно содержать не менее 2 символов',
  com_auth_username_max_length: 'Имя пользователя должно быть не более 20 символов',
  com_auth_already_have_account: 'Уже зарегистрированы?',
  com_auth_login: 'Войти',
  com_auth_reset_password: 'Сбросить пароль',
  com_auth_click: 'Нажмите',
  com_auth_here: 'ЗДЕСЬ',
  com_auth_to_reset_your_password: 'чтобы сбросить ваш пароль.',
  com_auth_reset_password_link_sent: 'Письмо отправлено',
  com_auth_reset_password_email_sent:
    'На вашу почту было отправлено письмо с дальнейшими инструкциями по сбросу пароля.',
  com_auth_error_reset_password:
    'При сбросе пароля возникла проблема. Пользователь с указанным адресом электронной почты не найден. Пожалуйста, попробуйте еще раз.',
  com_auth_reset_password_success: 'Сброс пароля успешно выполнен',
  com_auth_login_with_new_password: 'Теперь вы можете войти с новым паролем.',
  com_auth_error_invalid_reset_token: 'Этот токен сброса пароля больше не действителен.',
  com_auth_click_here: 'Нажмите здесь',
  com_auth_to_try_again: 'чтобы попробовать снова.',
  com_auth_submit_registration: 'Отправить регистрацию',
  com_auth_welcome_back: 'Добро пожаловать',
  com_endpoint_open_menu: 'Открыть меню',
  com_endpoint_bing_enable_sydney: 'Включить Sydney',
  com_endpoint_bing_to_enable_sydney: 'Чтобы включить Sydney',
  com_endpoint_bing_jailbreak: 'Jailbreak',
  com_endpoint_bing_context_placeholder:
    'Bing может использовать до 7 тысяч токенов для "контекста", на который он может ссылаться в разговоре. Точный предел неизвестен, но превышение 7 тысяч токенов может вызвать ошибки.',
  com_endpoint_bing_system_message_placeholder:
    'ПРЕДУПРЕЖДЕНИЕ: Неправильное использование этой функции может привести к БАНУ на использование Bing! Нажмите на "Системное сообщение" для получения полных инструкций и значения по умолчанию, которое является предустановкой "Sydney", считающейся безопасной.',
  com_endpoint_system_message: 'Системное сообщение',
  com_endpoint_message: 'Сообщение',
  com_endpoint_message_not_appendable: 'Отредактируйте свое сообщение или перегенерируйте.',
  com_endpoint_default_blank: 'по умолчанию: пусто',
  com_endpoint_default_false: 'по умолчанию: false',
  com_endpoint_default_creative: 'по умолчанию: креативный',
  com_endpoint_default_empty: 'по умолчанию: пусто',
  com_endpoint_default_with_num: 'по умолчанию: {0}',
  com_endpoint_context: 'Контекст',
  com_endpoint_tone_style: 'Стиль тона',
  com_endpoint_token_count: 'Количество токенов',
  com_endpoint_output: 'Вывод',
  com_endpoint_google_temp:
    'Более высокие значения = более случайные результаты, более низкие значения = более фокусированные и детерминированные результаты. Мы рекомендуем изменять это или Top P, но не оба значения одновременно.',
  com_endpoint_google_topp:
    'Top-p изменяет то, как модель выбирает токены для вывода. Токены выбираются из наиболее вероятных K (см. параметр topK) до наименее вероятных, пока сумма их вероятностей не достигнет значения top-p.',
  com_endpoint_google_topk:
    'Top-k изменяет то, как модель выбирает токены для вывода. Top-k равное 1 означает, что выбирается наиболее вероятный токен из всего словаря модели (так называемое жадное декодирование), а Top-k равное 3 означает, что следующий токен выбирается из трех наиболее вероятных токенов (с использованием температуры).',
  com_endpoint_google_maxoutputtokens:
    ' 	Максимальное количество токенов, которые могут быть сгенерированы в ответе. Укажите меньшее значение для более коротких ответов и большее значение для более длинных ответов.',
  com_endpoint_google_custom_name_placeholder: 'Задайте кастомное имя для Google',
  com_endpoint_prompt_prefix_placeholder:
    'Задайте пользовательские инструкции или контекст. Игнорируется, если пусто.',
  com_endpoint_custom_name: 'Кастомное имя',
  com_endpoint_prompt_prefix: 'Префикс промта',
  com_endpoint_temperature: 'Температура',
  com_endpoint_default: 'по умолчанию',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top K',
  com_endpoint_max_output_tokens: 'Максимальное количество выводимых токенов',
  com_endpoint_openai_temp:
    'Более высокие значения = более случайные результаты, более низкие значения = более фокусированные и детерминированные результаты. Мы рекомендуем изменять это или Top P, но не оба значения одновременно.',
  com_endpoint_openai_max:
    'Максимальное количество генерируемых токенов. Общая длина входных токенов и сгенерированных токенов ограничена длиной контекста модели.',
  com_endpoint_openai_topp:
    'Альтернатива выбору с использованием температуры, называемая выбором по ядру, при которой модель учитывает результаты токенов с наибольшей вероятностью top_p. Таким образом, значение 0,1 означает, что рассматриваются только токены, составляющие верхние 10% вероятностной массы. Мы рекомендуем изменять это или температуру, но не оба значения одновременно.',
  com_endpoint_openai_freq:
    'Число от -2.0 до 2.0. Положительные значения штрафуют новые токены на основе их частоты в тексте до сих пор, уменьшая вероятность модели повторить ту же строку дословно.',
  com_endpoint_openai_pres:
    'Число от -2.0 до 2.0. Положительные значения штрафуют новые токены на основе того, появляются ли они в тексте до сих пор, увеличивая вероятность модели говорить о новых темах.',
  com_endpoint_openai_custom_name_placeholder: 'Задайте кастомное имя для ChatGPT',
  com_endpoint_openai_prompt_prefix_placeholder:
    'Задайте кастомные промты для включения в системное сообщение. По умолчанию: нет',
  com_endpoint_anthropic_temp:
    'Диапазон значений от 0 до 1. Используйте значение temp ближе к 0 для аналитических / множественного выбора и ближе к 1 для креативных и генеративных задач. Мы рекомендуем изменять это или Top P, но не оба значения одновременно.',
  com_endpoint_anthropic_topp:
    'Top P изменяет то, как модель выбирает токены для вывода. Токены выбираются из наиболее вероятных (см. параметр topK) до наименее вероятных, пока сумма их вероятностей не достигнет значения top-p.',
  com_endpoint_anthropic_topk:
    'Top K изменяет то, как модель выбирает токены для вывода. Top K равное 1 означает, что выбирается наиболее вероятный токен из всего словаря модели (так называемое жадное декодирование), а Top K равное 3 означает, что следующий токен выбирается из трех наиболее вероятных токенов (с использованием температуры).',
  com_endpoint_anthropic_maxoutputtokens:
    'Максимальное количество токенов, которые могут быть сгенерированы в ответе. Укажите меньшее значение для более коротких ответов и большее значение для более длинных ответов.',
  com_endpoint_anthropic_custom_name_placeholder: 'Задайте кастомное имя для Anthropic',
  com_endpoint_frequency_penalty: 'Штраф за частоту',
  com_endpoint_presence_penalty: 'Штраф за присутствие',
  com_endpoint_plug_use_functions: 'Использовать функции',
  com_endpoint_plug_skip_completion: 'Пропустить завершение',
  com_endpoint_disabled_with_tools: 'отключено с плагинами',
  com_endpoint_disabled_with_tools_placeholder: 'Отключено при включённых плагинах',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    'Задайте кастомные инструкции для включения в системное сообщение. По умолчанию: нет',
  com_endpoint_import: 'Импортировать',
  com_endpoint_set_custom_name:
    'Задайте кастомное имя на случай, если вы сможете найти эту предустановку :)',
  com_endpoint_preset_delete_confirm: 'Вы уверены, что хотите удалить этот пресет?',
  com_endpoint_preset_clear_all_confirm: 'Вы уверены, что хотите удалить все ваши пресеты?',
  com_endpoint_preset_import: 'Пресет Импортирован!',
  com_endpoint_preset_import_error:
    'Произошла ошибка при импорте вашего пресета. Пожалуйста, попробуйте еще раз.',
  com_endpoint_preset_save_error:
    'Произошла ошибка при сохранении вашего пресета. Пожалуйста, попробуйте еще раз.',
  com_endpoint_preset_delete_error:
    'Произошла ошибка при удалении вашего пресета. Пожалуйста, попробуйте еще раз.',
  com_endpoint_preset_default_removed: 'больше не пресет по умолчанию.',
  com_endpoint_preset_default_item: 'По умолчанию:',
  com_endpoint_preset_default_none: 'Нет активных пресетов По умолчанию.',
  com_endpoint_preset_title: 'Пресет',
  com_endpoint_preset_saved: 'Сохранено!',
  com_endpoint_preset_default: 'теперь пресет По умолчаанию.',
  com_endpoint_preset: 'пресет',
  com_endpoint_presets: 'пресеты',
  com_endpoint_preset_selected: 'Пресет Активирован!',
  com_endpoint_preset_selected_title: 'Активирован!',
  com_endpoint_preset_name: 'Имя пресета',
  com_endpoint_new_topic: 'Новая тема',
  com_endpoint: 'Эндпоинт',
  com_endpoint_hide: 'Скрыть',
  com_endpoint_show: 'Показать',
  com_endpoint_examples: 'Примеры',
  com_endpoint_completion: 'Завершение',
  com_endpoint_agent: 'Агент',
  com_endpoint_show_what_settings: 'Показать настройки {0}',
  com_endpoint_save: 'Сохранить',
  com_endpoint_export: 'Экспортировать',
  com_endpoint_save_as_preset: 'Сохранить как Пресет',
  com_endpoint_presets_clear_warning:
    'Вы уверены, что хотите удалить все пресеты? Это действие необратимо и восстановление невозможно.',
  com_endpoint_not_implemented: 'Не реализовано',
  com_endpoint_no_presets: 'Пока нет пресетов, используйте кнопку настроек чтобы создать его',
  com_endpoint_not_available: 'Нет доступных эндпоинтов',
  com_endpoint_view_options: 'Просмотреть Настройки',
  com_endpoint_save_convo_as_preset: 'Сохранить текущий разговор как Пресет',
  com_endpoint_my_preset: 'Мой Пресет',
  com_endpoint_agent_model: 'Модель агента (Рекомендуется: GPT-3.5)',
  com_endpoint_completion_model: 'Модель завершения (Рекомендуется: GPT-4)',
  com_endpoint_func_hover: 'Включить использование плагинов как функции OpenAI',
  com_endpoint_skip_hover:
    'Пропустить этап завершения, который проверяет окончательный ответ и сгенерированные шаги',
  com_endpoint_config_token: 'Токен конфигурации',
  com_endpoint_config_key: 'Указать ключ к API',
  com_endpoint_config_placeholder: 'Укажите ваш ключ к API в меню сверху для начала разговора.',
  com_endpoint_config_key_for: 'Установить ключ к API для',
  com_endpoint_config_key_name: 'Ключ',
  com_endpoint_config_value: 'Введите значение для',
  com_endpoint_config_key_name_placeholder: 'Сначала укажите ключ к API',
  com_endpoint_config_key_encryption: 'Ваш ключ зашифрован и будет удалён',
  com_endpoint_config_key_expiry: 'срок действия',
  com_endpoint_config_click_here: 'Нажми Здесь',
  com_endpoint_config_google_service_key: 'Google Service Account Key',
  com_endpoint_config_google_cloud_platform: '(из Google Cloud Platform)',
  com_endpoint_config_google_api_key: 'Google API Key',
  com_endpoint_config_google_gemini_api: '(Gemini API)',
  com_endpoint_config_google_api_info:
    'Чтобы получить ключ к API Generative Language (для Gemini),',
  com_endpoint_config_key_import_json_key: 'Импортировать Service Account JSON Key.',
  com_endpoint_config_key_import_json_key_success: 'Успешно Импортирован Service Account JSON Key',
  com_endpoint_config_key_import_json_key_invalid:
    'Некорректный Service Account JSON Key, Вы импортировали верный файл?',
  com_endpoint_config_key_get_edge_key: 'Чтобы получить ваш токен доступа к Bing, войдите в',
  com_endpoint_config_key_get_edge_key_dev_tool:
    'Пока вы на сайте, используйте dev tools или расширение чтобы скопировать содержимое куки _U. Если не получается, следуйте этим',
  com_endpoint_config_key_edge_instructions: 'инструкциям',
  com_endpoint_config_key_edge_full_key_string: 'чтобы получить все строки cookie.',
  com_endpoint_config_key_chatgpt:
    'Чтобы получить токен доступа к "Бесплатной Версии" ChatGPT, войдите в',
  com_endpoint_config_key_chatgpt_then_visit: 'затем посетите',
  com_endpoint_config_key_chatgpt_copy_token: 'Скопируйте токен доступа.',
  com_endpoint_config_key_google_need_to: 'Вам нужно',
  com_endpoint_config_key_google_vertex_ai: 'Активировать Vertex AI',
  com_endpoint_config_key_google_vertex_api: 'API в Google Cloud, после',
  com_endpoint_config_key_google_service_account: 'Создать Service Account',
  com_endpoint_config_key_google_vertex_api_role:
    'Убедитесь что нажали на \'Create and Continue\' чтобы получить как минимум \'Vertex AI User\'. Наконец, создайте JSON-ключ чтобы импортировать его сюда.',
  com_nav_welcome_message: 'Чем я могу помочь вам сегодня?',
  com_nav_auto_scroll: 'Автоматически проматывать к самым новым сообщениям при открытии',
  com_nav_modular_chat: 'Разрешить менять точки подключения в середине разговора',
  com_nav_latex_parsing: 'Обработка LaTeX в сообщениях (может повлиять на производительность)',
  com_nav_plugin_store: 'Магазин плагинов',
  com_nav_plugin_search: 'Поиск плагинов',
  com_nav_plugin_auth_error:
    'При попытке аутентификации этого плагина произошла ошибка. Пожалуйста, попробуйте еще раз.',
  com_nav_export_filename: 'Имя файла',
  com_nav_export_filename_placeholder: 'Задайте имя файла',
  com_nav_export_type: 'Тип',
  com_nav_export_include_endpoint_options: 'Включить параметры эндпоинта',
  com_nav_enabled: 'Включено',
  com_nav_not_supported: 'Не поддерживается',
  com_nav_export_all_message_branches: 'Экспортировать все ветки сообщений',
  com_nav_export_recursive_or_sequential: 'Рекурсивно или последовательно?',
  com_nav_export_recursive: 'Рекурсивно',
  com_nav_export_conversation: 'Экспортировать разговор',
  com_nav_theme: 'Тема',
  com_nav_theme_system: 'Системная',
  com_nav_theme_dark: 'Темная',
  com_nav_theme_light: 'Светлая',
  com_nav_user_name_display: 'Отображать имя пользователя в сообщениях',
  com_nav_language: 'Локализация',
  com_nav_setting_account: 'Аккаунт',
  com_nav_profile_picture: 'Изображение профиля',
  com_nav_change_picture: 'Изменить изображение',
  com_nav_lang_auto: 'Автоопределение',
  com_nav_clear: 'Очистить',
  com_nav_clear_all_chats: 'Удалить все чаты',
  com_nav_confirm_clear: 'Подтвердить удаление',
  com_nav_close_sidebar: 'Закрыть боковую панель',
  com_nav_open_sidebar: 'Открыть боковую панель',
  com_nav_send_message: 'Отправить сообщение',
  com_nav_log_out: 'Выйти',
  com_nav_user: 'ПОЛЬЗОВАТЕЛЬ',
  com_nav_clear_conversation: 'Удалить разговоры',
  com_nav_clear_conversation_confirm_message:
    'Вы уверены, что хотите удалить все разговоры? Это действие нельзя отменить.',
  com_nav_help_faq: 'Помощь и Вопросы',
  com_nav_settings: 'Настройки',
  com_nav_search_placeholder: 'Поиск сообщений',
  com_nav_setting_general: 'Общие',
  com_nav_setting_beta: 'Бета-функции',
  com_nav_setting_data: 'Управление данными',
};
