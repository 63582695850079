import { PresignedResponse } from '~/types/chat';
import { axios } from './setup';

export const uploadSingleFile = async (file: File): Promise<PresignedResponse> => {
  const presignedResponse = await generatePresignedUpload(file);
  await uploadWithPresign(file, presignedResponse.upload_url);
  return presignedResponse;
};

const generatePresignedUpload = async (file: File) => {
  return (
    await axios.get<PresignedResponse>(`chat/images/upload-url`, {
      params: { filename: file.name },
    })
  ).data;
};

const uploadWithPresign = async (file: File, uploadUrl: string) => {
  await axios.put(uploadUrl, file, {
    headers: {
      'Content-Type': file.type,
    },
  });
};
