import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getAllUserConversations, getConversationEvents } from '../api/conversations';
// import { useAuthStore } from '~/zustand';
import { useLocation } from 'react-router-dom';
import { buildMessagesFromEvents } from '~/utils/buildTree';
import { useAuth } from '~/Providers/useAuth';

export function useConversationEvents(conversationId: string | null) {
  return useQuery({
    queryKey: ['events', conversationId],
    queryFn: () => getConversationEvents(conversationId!),
    enabled: !!conversationId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
}

export function useConversationMessages(conversationId: string | null) {
  const location = useLocation();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  //! DO NOT REMOVE EVENTS QUERY, it may appear unused but it is used to get eventsData
  const eventsQuery = useConversationEvents(conversationId);

  return useQuery({
    queryKey: ['messages', conversationId],
    queryFn: async () => {
      // for handling new conversations
      if (location.state?.shallow) {
        return location.state.messages;
      }
      const eventsData = await queryClient.fetchQuery(['events', conversationId]);
      const messages = buildMessagesFromEvents({ events: eventsData, user });

      return messages;
    },
    enabled: !!conversationId && !!user,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    onSuccess: () => {
      window.history.replaceState({}, `/c/${conversationId}`);
    },
  });
}

export function useConversations() {
  const { user } = useAuth();

  return useQuery({
    queryKey: ['conversations'],
    queryFn: () => getAllUserConversations(user?.user_id!),
    enabled: !!user?.user_id,
    onSuccess(data) {
      //console.log('[USECONVERSATIONS]: ', data);
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
}
