export default function Footer() {
  return (
    <div className="relative px-2 py-2 text-center text-xs text-gray-600 dark:text-gray-300 md:px-[60px]">
      <span>
        <>
          Chatbots often make mistakes, so be sure to verify the accuracy of their responses first.
        </>
      </span>
    </div>
  );
}
