// Portuguese phrases
// file deepcode ignore NoHardcodedPasswords: No hardcoded values present in this file
// file deepcode ignore HardcodedNonCryptoSecret: No hardcoded secrets present in this file

export default {
  com_ui_examples: 'Exemplos',
  com_ui_new_chat: 'Novo Chat',
  com_ui_example_quantum_computing: 'Explique a computação quântica em termos simples',
  com_ui_example_10_year_old_b_day:
    'Tem alguma ideia criativa para o aniversário de uma criança de 10 anos?',
  com_ui_example_http_in_js: 'Como faço uma solicitação HTTP em Javascript?',
  com_ui_capabilities: 'Capacidades',
  com_ui_capability_remember: 'Lembra o que o usuário disse anteriormente na conversa',
  com_ui_capability_correction: 'Permite que o usuário forneça correções de acompanhamento',
  com_ui_capability_decline_requests: 'Treinado para recusar pedidos inadequados',
  com_ui_limitations: 'Limitações',
  com_ui_limitation_incorrect_info: 'Pode ocasionalmente gerar informações incorretas',
  com_ui_limitation_harmful_biased:
    'Pode ocasionalmente produzir instruções prejudiciais ou conteúdo tendencioso',
  com_ui_limitation_limited_2021: 'Conhecimento limitado do mundo e eventos após 2021',
  com_ui_experimental: 'Experimental',
  com_ui_input: 'Entrada',
  com_ui_close: 'Fechar',
  com_ui_model: 'Modelo',
  com_ui_select_model: 'Selecione um modelo',
  com_ui_use_prompt: 'Use o prompt',
  com_ui_prev: 'Anterior',
  com_ui_next: 'Próximo',
  com_ui_stop: 'Parar',
  com_ui_prompt_templates: 'Modelos de Prompt',
  com_ui_hide_prompt_templates: 'Ocultar Modelos de Prompt',
  com_ui_showing: 'Mostrando',
  com_ui_of: 'de',
  com_ui_entries: 'Entradas',
  com_ui_pay_per_call: 'Todas as conversas de IA em um só lugar. Pague por chamada e não por mês',
  com_ui_new_footer: 'Todas as conversas de IA em um só lugar.',
  com_ui_enter: 'Entrar',
  com_ui_submit: 'Enviar',
  com_ui_upload_success: 'Arquivo carregado com sucesso',
  com_ui_upload_error: 'Houve um erro ao carregar seu arquivo',
  com_ui_upload_invalid: 'Arquivo inválido para upload. Deve ser uma imagem que não exceda 2 MB',
  com_ui_cancel: 'Cancelar',
  com_ui_save: 'Salvar',
  com_ui_save_submit: 'Salvar e Enviar',
  com_user_message: 'Você',
  com_ui_copy_to_clipboard: 'Copiar para a área de transferência',
  com_ui_copied_to_clipboard: 'Copiado para a área de transferência',
  com_ui_regenerate: 'Regenerar',
  com_ui_continue: 'Continuar',
  com_ui_edit: 'Editar',
  com_ui_success: 'Sucesso',
  com_ui_all: 'todos',
  com_ui_clear: 'Excluir tudo',
  com_ui_revoke: 'Revogar',
  com_ui_revoke_info: 'Revogar todas as credenciais fornecidas pelo usuário',
  com_ui_confirm_action: 'Confirmar Ação',
  com_ui_chats: 'chats',
  com_ui_delete: 'Excluir',
  com_ui_delete_conversation: 'Excluir chat?',
  com_ui_delete_conversation_confirm: 'Isso irá excluir',
  com_ui_preview: 'Visualizar',
  com_ui_upload: 'Carregar',
  com_ui_connect: 'Conectar',
  com_auth_error_login:
    'Não foi possível fazer login com as informações fornecidas. Por favor, verifique suas credenciais e tente novamente.',
  com_auth_error_login_rl:
    'Muitas tentativas de login em um curto período de tempo. Por favor, tente novamente mais tarde.',
  com_auth_error_login_ban:
    'Sua conta foi temporariamente banida devido a violações de nosso serviço.',
  com_auth_error_login_server:
    'Houve um erro interno do servidor. Por favor, aguarde alguns momentos e tente novamente.',
  com_auth_no_account: 'Não tem uma conta?',
  com_auth_sign_up: 'Inscrever-se',
  com_auth_sign_in: 'Entrar',
  com_auth_google_login: 'Login com Google',
  com_auth_facebook_login: 'Login com Facebook',
  com_auth_github_login: 'Login com Github',
  com_auth_discord_login: 'Login com Discord',
  com_auth_email: 'Email',
  com_auth_email_required: 'Email é obrigatório',
  com_auth_email_min_length: 'O email deve ter pelo menos 6 caracteres',
  com_auth_email_max_length: 'O email não deve ter mais de 120 caracteres',
  com_auth_email_pattern: 'Você deve inserir um endereço de email válido',
  com_auth_email_address: 'Endereço de email',
  com_auth_password: 'Senha',
  com_auth_password_required: 'Senha é obrigatória',
  com_auth_password_min_length: 'A senha deve ter pelo menos 8 caracteres',
  com_auth_password_max_length: 'A senha deve ter menos de 128 caracteres',
  com_auth_password_forgot: 'Esqueceu a senha?',
  com_auth_password_confirm: 'Confirme a senha',
  com_auth_password_not_match: 'As senhas não coincidem',
  com_auth_continue: 'Continuar',
  com_auth_create_account: 'Crie sua conta',
  com_auth_error_create: 'Houve um erro ao tentar registrar sua conta. Por favor, tente novamente.',
  com_auth_full_name: 'Nome completo',
  com_auth_name_required: 'Nome é obrigatório',
  com_auth_name_min_length: 'O nome deve ter pelo menos 3 caracteres',
  com_auth_name_max_length: 'O nome deve ter menos de 80 caracteres',
  com_auth_username: 'Nome de usuário (opcional)',
  com_auth_username_required: 'Nome de usuário é obrigatório',
  com_auth_username_min_length: 'O nome de usuário deve ter pelo menos 2 caracteres',
  com_auth_username_max_length: 'O nome de usuário deve ter menos de 20 caracteres',
  com_auth_already_have_account: 'Já tem uma conta?',
  com_auth_login: 'Login',
  com_auth_reset_password: 'Redefina sua senha',
  com_auth_click: 'Clique',
  com_auth_here: 'AQUI',
  com_auth_to_reset_your_password: 'para redefinir sua senha.',
  com_auth_reset_password_link_sent: 'Email Enviado',
  com_auth_reset_password_email_sent:
    'Um email foi enviado para você com mais instruções para redefinir sua senha.',
  com_auth_error_reset_password:
    'Houve um problema para redefinir sua senha. Não foi encontrado nenhum usuário com o endereço de email fornecido. Por favor, tente novamente.',
  com_auth_reset_password_success: 'Redefinição de Senha Bem-sucedida',
  com_auth_login_with_new_password: 'Agora você pode fazer login com sua nova senha.',
  com_auth_error_invalid_reset_token: 'Este token de redefinição de senha não é mais válido.',
  com_auth_click_here: 'Clique aqui',
  com_auth_to_try_again: 'para tentar novamente.',
  com_auth_submit_registration: 'Enviar registro',
  com_auth_welcome_back: 'Bem-vindo de volta',
  com_endpoint_open_menu: 'Abrir Menu',
  com_endpoint_bing_enable_sydney: 'Habilitar Sydney',
  com_endpoint_bing_to_enable_sydney: 'Para habilitar Sydney',
  com_endpoint_bing_jailbreak: 'Jailbreak',
  com_endpoint_bing_context_placeholder:
    'O Bing pode usar até 7k tokens para \'contexto\', que ele pode referenciar para a conversa. O limite específico não é conhecido, mas pode ocorrer erros ao exceder 7k tokens',
  com_endpoint_bing_system_message_placeholder:
    'AVISO: O uso indevido deste recurso pode fazer com que você seja BANIDO de usar o Bing! Clique em \'System Message\' para obter instruções completas e a mensagem padrão se omitida, que é o preset \'Sydney\' que é considerado seguro.',
  com_endpoint_system_message: 'Mensagem do Sistema',
  com_endpoint_message: 'Conversar com',
  com_endpoint_message_not_appendable: 'Edite sua mensagem ou Regenere.',
  com_endpoint_default_blank: 'padrão: em branco',
  com_endpoint_default_false: 'padrão: falso',
  com_endpoint_default_creative: 'padrão: criativo',
  com_endpoint_default_empty: 'padrão: vazio',
  com_endpoint_default_with_num: 'padrão: {0}',
  com_endpoint_context: 'Contexto',
  com_endpoint_tone_style: 'Estilo de Tom',
  com_endpoint_token_count: 'Contagem de Tokens',
  com_endpoint_output: 'Saída',
  com_endpoint_google_temp:
    'Valores mais altos = mais aleatório, enquanto valores mais baixos = mais focado e determinístico. Recomendamos alterar isso ou Top P, mas não ambos.',
  com_endpoint_google_topp:
    'Top-p muda como o modelo seleciona tokens para saída. Os tokens são selecionados do mais K (veja o parâmetro topK) provável ao menos até que a soma de suas probabilidades seja igual ao valor de top-p.',
  com_endpoint_google_topk:
    'Top-k muda como o modelo seleciona tokens para saída. Um top-k de 1 significa que o token selecionado é o mais provável entre todos os tokens no vocabulário do modelo (também chamado de decodificação gananciosa), enquanto um top-k de 3 significa que o próximo token é selecionado entre os 3 tokens mais prováveis (usando temperatura).',
  com_endpoint_google_maxoutputtokens:
    'Número máximo de tokens que podem ser gerados na resposta. Especifique um valor menor para respostas mais curtas e um valor maior para respostas mais longas.',
  com_endpoint_google_custom_name_placeholder: 'Defina um nome personalizado para o Google',
  com_endpoint_prompt_prefix_placeholder:
    'Defina instruções personalizadas ou contexto. Ignorado se vazio.',
  com_endpoint_custom_name: 'Nome Personalizado',
  com_endpoint_prompt_prefix: 'Prefixo do Prompt',
  com_endpoint_temperature: 'Temperatura',
  com_endpoint_default: 'padrão',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top K',
  com_endpoint_max_output_tokens: 'Max Output Tokens',
  com_endpoint_openai_temp:
    'Valores mais altos = mais aleatório, enquanto valores mais baixos = mais focado e determinístico. Recomendamos alterar isso ou Top P, mas não ambos.',
  com_endpoint_openai_max:
    'Os tokens máximos para gerar. O comprimento total dos tokens de entrada e dos tokens gerados é limitado pelo comprimento do contexto do modelo.',
  com_endpoint_openai_topp:
    'Uma alternativa para amostragem com temperatura, chamada amostragem de núcleo, onde o modelo considera os resultados dos tokens com massa de probabilidade top_p. Então 0.1 significa que apenas os tokens que compõem a massa de probabilidade dos 10% principais são considerados. Recomendamos alterar isso ou a temperatura, mas não ambos.',
  com_endpoint_openai_freq:
    'Número entre -2.0 e 2.0. Valores positivos penalizam novos tokens com base em sua frequência existente no texto até agora, diminuindo a probabilidade do modelo de repetir a mesma linha literalmente.',
  com_endpoint_openai_pres:
    'Número entre -2.0 e 2.0. Valores positivos penalizam novos tokens com base em se eles aparecem no texto até agora, aumentando a probabilidade do modelo de falar sobre novos tópicos.',
  com_endpoint_openai_resend:
    'Reenviar todas as imagens anteriormente anexadas. Observação: isso pode aumentar significativamente o custo em tokens e você pode enfrentar erros com muitos anexos de imagem.',
  com_endpoint_openai_detail:
    'A resolução para solicitações do Vision. "Low" é mais barata e rápida, "High" é mais detalhada e cara, e "Auto" escolherá automaticamente entre as duas com base na resolução da imagem.',
  com_endpoint_openai_custom_name_placeholder: 'Defina um nome personalizado para ChatGPT',
  com_endpoint_openai_prompt_prefix_placeholder:
    'Defina instruções personalizadas para incluir na Mensagem do Sistema. Padrão: nenhum',
  com_endpoint_anthropic_temp:
    'Varia de 0 a 1. Use temp mais próximo de 0 para analítico / múltipla escolha, e mais próximo de 1 para tarefas criativas e gerativas. Recomendamos alterar isso ou Top P, mas não ambos.',
  com_endpoint_anthropic_topp:
    'Top-p muda como o modelo seleciona tokens para saída. Os tokens são selecionados do mais K (veja o parâmetro topK) provável ao menos até que a soma de suas probabilidades seja igual ao valor de top-p.',
  com_endpoint_anthropic_topk:
    'Top-k muda como o modelo seleciona tokens para saída. Um top-k de 1 significa que o token selecionado é o mais provável entre todos os tokens no vocabulário do modelo (também chamado de decodificação gananciosa), enquanto um top-k de 3 significa que o próximo token é selecionado entre os 3 tokens mais prováveis (usando temperatura).',
  com_endpoint_anthropic_maxoutputtokens:
    'Número máximo de tokens que podem ser gerados na resposta. Especifique um valor menor para respostas mais curtas e um valor maior para respostas mais longas.',
  com_endpoint_anthropic_custom_name_placeholder: 'Defina um nome personalizado para Anthropic',
  com_endpoint_frequency_penalty: 'Penalidade de Frequência',
  com_endpoint_presence_penalty: 'Penalidade de Presença',
  com_endpoint_plug_use_functions: 'Use Funções',
  com_endpoint_plug_resend_images: 'Reenviar Imagens',
  com_endpoint_plug_image_detail: 'Detalhes da Imagem',
  com_endpoint_plug_skip_completion: 'Pular Conclusão',
  com_endpoint_disabled_with_tools: 'desativado com ferramentas',
  com_endpoint_disabled_with_tools_placeholder: 'Desativado com Ferramentas Selecionadas',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    'Defina instruções personalizadas para incluir na Mensagem do Sistema. Padrão: nenhum',
  com_endpoint_import: 'Importar',
  com_endpoint_set_custom_name:
    'Defina um nome personalizado, caso você possa encontrar este preset',
  com_endpoint_preset_delete_confirm: 'Você tem certeza de que deseja excluir este preset?',
  com_endpoint_preset_clear_all_confirm:
    'Você tem certeza de que deseja excluir todos os seus presets?',
  com_endpoint_preset_import: 'Preset Importado!',
  com_endpoint_preset_import_error:
    'Houve um erro ao importar seu preset. Por favor, tente novamente.',
  com_endpoint_preset_save_error: 'Houve um erro ao salvar seu preset. Por favor, tente novamente.',
  com_endpoint_preset_delete_error:
    'Houve um erro ao excluir seu preset. Por favor, tente novamente.',
  com_endpoint_preset_default_removed: 'não é mais o preset padrão.',
  com_endpoint_preset_default_item: 'Padrão:',
  com_endpoint_preset_default_none: 'Nenhum preset padrão ativo.',
  com_endpoint_preset_title: 'Preset',
  com_endpoint_preset_saved: 'Salvo!',
  com_endpoint_preset_default: 'é agora o preset padrão.',
  com_endpoint_preset: 'preset',
  com_endpoint_presets: 'presets',
  com_endpoint_preset_selected: 'Preset Ativo!',
  com_endpoint_preset_selected_title: 'Ativo!',
  com_endpoint_preset_name: 'Nome do Preset',
  com_endpoint_new_topic: 'Novo Tópico',
  com_endpoint: 'Endpoint',
  com_endpoint_hide: 'Esconder',
  com_endpoint_show: 'Mostrar',
  com_endpoint_examples: ' Presets',
  com_endpoint_completion: 'Conclusão',
  com_endpoint_agent: 'Agente',
  com_endpoint_show_what_settings: 'Mostrar {0} Configurações',
  com_endpoint_save: 'Salvar',
  com_endpoint_export: 'Exportar',
  com_endpoint_save_as_preset: 'Salvar como Preset',
  com_endpoint_presets_clear_warning:
    'Você tem certeza de que deseja limpar todos os presets? Isso é irreversível.',
  com_endpoint_not_implemented: 'Não implementado',
  com_endpoint_no_presets: 'Ainda não há presets, use o botão de configurações para criar um',
  com_endpoint_not_available: 'Nenhum endpoint disponível',
  com_endpoint_view_options: 'Ver Opções',
  com_endpoint_save_convo_as_preset: 'Salvar Conversa como Preset',
  com_endpoint_my_preset: 'Meu Preset',
  com_endpoint_agent_model: 'Modelo do Agente (Recomendado: GPT-3.5)',
  com_endpoint_completion_model: 'Modelo de Conclusão (Recomendado: GPT-4)',
  com_endpoint_func_hover: 'Habilitar uso de Plugins como Funções OpenAI',
  com_endpoint_skip_hover:
    'Habilitar a etapa de conclusão de pulo, que revisa a resposta final e as etapas geradas',
  com_endpoint_config_key: 'Definir Chave API',
  com_endpoint_config_placeholder: 'Defina sua Chave no menu Cabeçalho para conversar.',
  com_endpoint_config_key_for: 'Definir Chave API para',
  com_endpoint_config_key_name: 'Chave',
  com_endpoint_config_value: 'Insira valor para',
  com_endpoint_config_key_name_placeholder: 'Defina a chave API primeiro',
  com_endpoint_config_key_encryption: 'Sua chave será criptografada e excluída em',
  com_endpoint_config_key_expiry: 'o tempo de expiração',
  com_endpoint_config_click_here: 'Clique Aqui',
  com_endpoint_config_google_service_key: 'Chave da Conta de Serviço do Google',
  com_endpoint_config_google_cloud_platform: '(do Google Cloud Platform)',
  com_endpoint_config_google_api_key: 'Chave API do Google',
  com_endpoint_config_google_gemini_api: '(API Gemini)',
  com_endpoint_config_google_api_info:
    'Para obter sua chave API de Linguagem Generativa (para Gemini),',
  com_endpoint_config_key_import_json_key: 'Importar Chave JSON da Conta de Serviço.',
  com_endpoint_config_key_import_json_key_success:
    'Chave JSON da Conta de Serviço importada com sucesso',
  com_endpoint_config_key_import_json_key_invalid:
    'Chave JSON da Conta de Serviço inválida, você importou o arquivo correto?',
  com_endpoint_config_key_get_edge_key: 'Para obter seu token de acesso para o Bing, faça login em',
  com_endpoint_config_key_get_edge_key_dev_tool:
    'Use as ferramentas de desenvolvimento ou uma extensão enquanto estiver logado no site para copiar o conteúdo do cookie _U. Se isso falhar, siga estas',
  com_endpoint_config_key_edge_instructions: 'instruções',
  com_endpoint_config_key_edge_full_key_string: 'para fornecer as strings completas do cookie.',
  com_endpoint_config_key_chatgpt:
    'Para obter seu token de acesso para o ChatGPT \'Versão Gratuita\', faça login em',
  com_endpoint_config_key_chatgpt_then_visit: 'então visite',
  com_endpoint_config_key_chatgpt_copy_token: 'Copie o token de acesso.',
  com_endpoint_config_key_google_need_to: 'Você precisa',
  com_endpoint_config_key_google_vertex_ai: 'Habilitar Vertex AI',
  com_endpoint_config_key_google_vertex_api: 'API no Google Cloud, então',
  com_endpoint_config_key_google_service_account: 'Crie uma Conta de Serviço',
  com_endpoint_config_key_google_vertex_api_role:
    'Certifique-se de clicar em \'Criar e Continuar\' para dar pelo menos a função \'Usuário do Vertex AI\'. Por último, crie uma chave JSON para importar aqui.',
  com_nav_welcome_message: 'Como posso ajudá-lo hoje?',
  com_nav_auto_scroll: 'Auto-rolagem para o Mais Novo ao Abrir',
  com_nav_modular_chat: 'Ativar a troca de Endpoints no meio da conversa',
  com_nav_latex_parsing:
    'Ativar análise de LaTeX nas mensagens. Ativado por padrão, mas pode afetar o desempenho em conversas mais longas ou em dispositivos móveis',
  com_nav_profile_picture: 'Foto de Perfil',
  com_nav_change_picture: 'Mudar foto',
  com_nav_plugin_store: 'Loja de plugins',
  com_show_agent_settings: 'Mostrar Definições do Agente',
  com_show_completion_settings: 'Mostrar Definições de Complemento',
  com_hide_examples: 'Esconder Exemplos',
  com_show_examples: 'Mostrar Exemplos',
  com_nav_plugin_search: 'Pesquisar plugins',
  com_nav_plugin_auth_error:
    'Houve um erro ao tentar autenticar este plugin. Por favor, tente novamente.',
  com_nav_export_filename: 'Nome do arquivo',
  com_nav_export_filename_placeholder: 'Defina o nome do arquivo',
  com_nav_export_type: 'Tipo',
  com_nav_export_include_endpoint_options: 'Incluir opções de endpoint',
  com_nav_enabled: 'Habilitado',
  com_nav_not_supported: 'Não suportado',
  com_nav_export_all_message_branches: 'Exportar todos os ramos de mensagens',
  com_nav_export_recursive_or_sequential: 'Recursivo ou sequencial?',
  com_nav_export_recursive: 'Recursivo',
  com_nav_export_conversation: 'Exportar conversa',
  com_nav_theme: 'Tema',
  com_nav_theme_system: 'Sistema',
  com_nav_theme_dark: 'Escuro',
  com_nav_theme_light: 'Claro',
  com_nav_user_name_display: 'Mostrar nome de usuário nas mensagens',
  com_nav_clear_all_chats: 'Excluir todas as conversas?',
  com_nav_confirm_clear: 'Confirmar Exclusão',
  com_nav_close_sidebar: 'Fechar barra lateral',
  com_nav_open_sidebar: 'Abrir barra lateral',
  com_nav_send_message: 'Enviar mensagem',
  com_nav_log_out: 'Sair',
  com_nav_user: 'USUÁRIO',
  com_nav_clear_conversation: 'Limpar conversas',
  com_nav_clear_conversation_confirm_message: 'Limpar seu histórico de conversas- tem certeza?',
  com_nav_help_faq: 'Ajuda & FAQ',
  com_nav_settings: 'Configurações',
  com_nav_search_placeholder: 'Pesquisar mensagens',
  com_nav_setting_general: 'Geral',
  com_nav_setting_beta: 'Recursos beta',
  com_nav_setting_data: 'Controles de dados',
  com_nav_setting_account: 'Conta',
  com_nav_language: 'Idioma',
  com_nav_lang_auto: 'Detecção automática',
  com_nav_lang_brazilian_portuguese: 'Português Brasileiro',
};
