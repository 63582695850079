// Traditional Chinese phrases

export default {
  com_ui_examples: '範例',
  com_ui_new_chat: '新對話',
  com_ui_example_quantum_computing: '用簡單的方式解釋量子計算',
  com_ui_example_10_year_old_b_day: '有沒有創意十足的十歲生日派對點子？',
  com_ui_example_http_in_js: '如何在 JavaScript 中發起 HTTP 請求？',
  com_ui_capabilities: '功能',
  com_ui_capability_remember: '能記得先前在對話中提到的內容',
  com_ui_capability_correction: '允許使用者提供後續的修正',
  com_ui_capability_decline_requests: '訓練有素以拒絕不適當的請求',
  com_ui_limitations: '限制',
  com_ui_limitation_incorrect_info: '有時可能會產生不正確的資訊',
  com_ui_limitation_harmful_biased: '有時可能會產生有害的指示或帶有偏見的內容',
  com_ui_limitation_limited_2021: '對於 2021 年後的世界和事件的知識有限',
  com_ui_input: '輸入',
  com_ui_close: '關閉',
  com_ui_model: '模型',
  com_ui_select_model: '選擇模型',
  com_ui_use_prompt: '使用提示',
  com_ui_prev: '上一個',
  com_ui_next: '下一個',
  com_ui_prompt_templates: '提示範本',
  com_ui_hide_prompt_templates: '隱藏提示範本',
  com_ui_showing: '顯示',
  com_ui_of: '的',
  com_ui_entries: '條目',
  com_ui_pay_per_call: '將所有 AI 對話都在集中在一處，按用量付費而非固定月費',
  com_ui_enter: '輸入',
  com_ui_submit: '送出',
  com_ui_upload_success: '檔案上傳成功',
  com_ui_upload_invalid: '上傳檔案無效',
  com_ui_cancel: '取消',
  com_ui_save: '儲存',
  com_ui_copy_to_clipboard: '複製到剪貼簿',
  com_ui_copied_to_clipboard: '已複製到剪貼簿',
  com_ui_regenerate: '重新生成',
  com_ui_continue: '繼續',
  com_ui_edit: '編輯',
  com_ui_success: '成功',
  com_ui_all: '全部',
  com_ui_clear: '清除',
  com_ui_revoke: '撤銷',
  com_ui_revoke_info: '撤銷所有使用者提供的憑證。',
  com_ui_confirm_action: '確認操作',
  com_ui_chats: '對話',
  com_ui_delete: '刪除',
  com_ui_delete_conversation: '刪除對話？',
  com_ui_delete_conversation_confirm: '這將刪除',
  com_auth_error_login: '無法使用提供的資訊登入。請檢查您的登入資訊後重試。',
  com_auth_error_login_rl: '短時間內嘗試登入的次數過多。請稍後再試。',
  com_auth_error_login_ban: '由於違反我們的服務條款，您的帳號已被暫時停用。',
  com_auth_error_login_server: '發生內部伺服器錯誤。請稍候片刻，然後重試。',
  com_auth_no_account: '還沒有帳號？',
  com_auth_sign_up: '註冊',
  com_auth_sign_in: '登入',
  com_auth_google_login: '使用 Google 登入',
  com_auth_facebook_login: '使用 Facebook 登入',
  com_auth_github_login: '使用 GitHub 登入',
  com_auth_discord_login: '使用 Discord 登入',
  com_auth_email: '電子郵件',
  com_auth_email_required: '電子郵件必填',
  com_auth_email_min_length: '電子郵件長度必須至少有 6 個字元',
  com_auth_email_max_length: '電子郵件不應超過 120 個字元',
  com_auth_email_pattern: '您必須輸入有效的電子郵件地址',
  com_auth_email_address: '電子郵件地址',
  com_auth_password: '密碼',
  com_auth_password_required: '密碼必填',
  com_auth_password_min_length: '密碼長度必須至少有 8 個字元',
  com_auth_password_max_length: '密碼長度必須少於 128 個字元',
  com_auth_password_forgot: '忘記密碼？',
  com_auth_password_confirm: '確認密碼',
  com_auth_password_not_match: '密碼不符',
  com_auth_continue: '繼續',
  com_auth_create_account: '建立您的帳號',
  com_nav_auto_scroll: '開啟時自動捲動至最新內容',
  com_auth_error_create: '嘗試註冊您的帳號時發生錯誤。請重試。',
  com_auth_full_name: '全名',
  com_auth_name_required: '名稱必填',
  com_auth_name_min_length: '名稱長度必須至少有 3 個字元',
  com_auth_name_max_length: '名稱長度長度必須少於 80 個字元',
  com_auth_username: '使用者名稱（選填）',
  com_auth_username_required: '使用者名稱必填',
  com_auth_username_min_length: '使用者名稱長度必須至少有 2 個字元',
  com_auth_username_max_length: '使用者名稱長度必須少於 20 個字元',
  com_auth_already_have_account: '已經有帳號了？',
  com_auth_login: '登入',
  com_auth_reset_password: '重設密碼',
  com_auth_click: '點選',
  com_auth_here: '這裡',
  com_auth_to_reset_your_password: '重設您的密碼。',
  com_auth_reset_password_link_sent: '電子郵件已傳送',
  com_auth_reset_password_email_sent: '已向您傳送電子郵件，其中包含進一步重設密碼的操作說明。',
  com_auth_error_reset_password:
    '重設密碼時出現問題。找不到使用提供的電子郵件地址的使用者。請重試。',
  com_auth_reset_password_success: '密碼重設成功',
  com_auth_login_with_new_password: '您現在可以使用新密碼登入。',
  com_auth_error_invalid_reset_token: '此密碼重設令牌已無效。',
  com_auth_click_here: '點選這裡',
  com_auth_to_try_again: '重試。',
  com_auth_submit_registration: '送出',
  com_auth_welcome_back: '歡迎回來',
  com_endpoint_open_menu: '開啟選單',
  com_endpoint_bing_enable_sydney: 'Sydney',
  com_endpoint_bing_to_enable_sydney: '啟用 Sydney',
  com_endpoint_bing_jailbreak: '越獄',
  com_endpoint_bing_context_placeholder:
    'Bing 可以使用多達 7k 個 token 作為「前後文」，並在對話中引用。具體限制不詳，但可能會在超過 7k token 時出現錯誤',
  com_endpoint_bing_system_message_placeholder:
    '警告：不當使用此功能可能會導致您被禁止使用 Bing！如需完整指引和預設訊息（若未設定），請點選「系統訊息」，這是被認為安全的「Sydney」預設值。',
  com_endpoint_system_message: '系統訊息',
  com_endpoint_default_blank: '預設：空白',
  com_endpoint_default_false: '預設：否',
  com_endpoint_default_creative: '預設：創意',
  com_endpoint_default_empty: '預設：空',
  com_endpoint_default_with_num: '預設：{0}',
  com_endpoint_context: '前後文',
  com_endpoint_tone_style: '語氣風格',
  com_endpoint_token_count: 'token 數量',
  com_endpoint_output: '輸出',
  com_endpoint_google_temp:
    '較高的值表示更隨機，而較低的值表示更集中和確定。我們建議修改這個或 Top P，但不建議兩者都修改。',
  com_endpoint_google_topp:
    'Top-p 調整模型在輸出 token 時的選擇機制。從最可能的 K（見 topK 參數）開始選擇 token，直到它們的機率之和達到 top-p 值。',
  com_endpoint_google_topk:
    'Top-k 調整模型如何選取輸出的 token。當 Top-k 設為 1 時，模型會選取在其詞彙庫中機率最高的 token 進行輸出（這也被稱為貪婪解碼）。相對地，當 Top-k 設為 3 時，模型會從機率最高的三個 token 中選取下一個輸出 token（這會涉及到所謂的「溫度」調整）',
  com_endpoint_google_maxoutputtokens:
    '設定回應中可生成的最大 token 數。若希望回應簡短，請設定較低的數值；若需較長的回應，則設定較高的數值。',
  com_endpoint_google_custom_name_placeholder: '為 Google 設定自定義名稱',
  com_endpoint_prompt_prefix_placeholder: '設定自定義提示或前後文。如果為空則忽略。',
  com_endpoint_custom_name: '自定義名稱',
  com_endpoint_prompt_prefix: '提示起始字串',
  com_endpoint_temperature: '溫度',
  com_endpoint_default: '預設',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top K',
  com_endpoint_max_output_tokens: '最大輸出 token 數',
  com_endpoint_openai_temp:
    '較高的值表示更隨機，而較低的值表示更集中和確定。我們建議修改這個或 Top P，但不建議兩者都修改。',
  com_endpoint_openai_max:
    '要生成的最大 token 數。輸入 token 和生成 token 的總長度受到模型前後文長度的限制。',
  com_endpoint_openai_topp:
    '與溫度取樣的替代方法，稱為核心取樣，其中模型考慮 top_p 機率質量的 token 結果。所以 0.1 表示只考慮佔 top 10% 機率質量的 token 。我們建議修改這個或溫度，但不建議兩者都修改。',
  com_endpoint_openai_freq:
    '數值範圍介於 -2.0 和 2.0 之間。正值會根據該 token 在目前的文字中出現的頻率進行懲罰，減少模型產生重複內容的可能性。',
  com_endpoint_openai_pres:
    '數值範圍介於 -2.0 和 2.0 之間。正值會根據該 token 是否在目前的文字中出現來進行懲罰，增加模型談及新主題的可能性。',
  com_endpoint_openai_custom_name_placeholder: '為 ChatGPT 設定自定義名稱',
  com_endpoint_openai_prompt_prefix_placeholder: '在系統訊息中設定自定義提示。',
  com_endpoint_anthropic_temp:
    '範圍從 0 到 1。對於分析/多選題，使用接近 0 的溫度，對於創意和生成式任務，使用接近 1 的溫度。我們建議修改這個或 Top P，但不建議兩者都修改。',
  com_endpoint_anthropic_topp:
    'Top-p 改變模型選擇輸出 token 的方式。從最可能的 K（見 topK 參數）開始選擇 token，直到它們的機率之和達到 top-p 值。',
  com_endpoint_anthropic_topk:
    'Top-k 改變模型選擇輸出 token 的方式。Top-k 為 1 表示所選 token 在模型詞彙表中所有 token 中最可能（也稱為貪婪解碼），而 Top-k 為 3 表示下一個 token 從最可能的 3 個 token 中選擇（使用溫度）。',
  com_endpoint_anthropic_maxoutputtokens:
    '設定回應中可生成的最大 token 數。若希望回應簡短，請設定較低的數值；若需較長的回應，則設定較高的數值。',
  com_endpoint_anthropic_custom_name_placeholder: '為 Anthropic 設定自定義名稱',
  com_endpoint_frequency_penalty: '頻率懲罰',
  com_endpoint_presence_penalty: '出現懲罰',
  com_endpoint_plug_use_functions: '使用外掛作為 OpenAI 函式',
  com_endpoint_plug_skip_completion: '跳過完成步驟',
  com_endpoint_disabled_with_tools: '與工具一起停用',
  com_endpoint_disabled_with_tools_placeholder: '選擇工具時停用',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder: '在系統訊息中新增自定義提示。',
  com_endpoint_import: '匯入',
  com_endpoint_set_custom_name: '設定自定義名稱，以便您找到此預設設定',
  com_endpoint_preset: '預設設定',
  com_endpoint_presets: '預設設定',
  com_endpoint_preset_name: '名稱',
  com_endpoint_new_topic: '新主題',
  com_endpoint: '選項',
  com_endpoint_hide: '隱藏',
  com_endpoint_show: '顯示',
  com_endpoint_examples: '預設設定',
  com_endpoint_completion: '完成',
  com_endpoint_agent: '代理',
  com_endpoint_show_what_settings: '顯示 {0} 設定',
  com_endpoint_save: '儲存',
  com_endpoint_export: '匯出',
  com_endpoint_save_as_preset: '另存為預設設定',
  com_endpoint_presets_clear_warning: '您確定要清除所有預設設定嗎？此操作無法復原。',
  com_endpoint_not_implemented: '尚未實做',
  com_endpoint_no_presets: '尚無預設設定',
  com_endpoint_not_available: '無可用選項',
  com_endpoint_view_options: '檢視選項',
  com_endpoint_save_convo_as_preset: '將對話另存為預設設定',
  com_endpoint_my_preset: '我的預設設定',
  com_endpoint_agent_model: '代理模型（建議：GPT-3.5）',
  com_endpoint_completion_model: '完成模型（建議：GPT-4）',
  com_endpoint_func_hover: '啟用將外掛用作 OpenAI 函式',
  com_endpoint_skip_hover: '啟用跳過完成步驟，評估最終答案和生成步驟',
  com_endpoint_config_key: '設定 API 金鑰',
  com_endpoint_config_key_for: '設定 API 金鑰給',
  com_endpoint_config_key_name: '金鑰',
  com_endpoint_config_value: '輸入',
  com_endpoint_config_key_name_placeholder: '請先設定 API 金鑰',
  com_endpoint_config_key_encryption: '您的金鑰將被加密並在此到期時間刪除：',
  com_endpoint_config_key_expiry: '到期時刪除',
  com_endpoint_config_key_import_json_key: '匯入服務帳戶 JSON 金鑰。',
  com_endpoint_config_key_import_json_key_success: '成功匯入服務帳戶 JSON 金鑰',
  com_endpoint_config_key_import_json_key_invalid:
    '無效的服務帳戶 JSON 金鑰，您是否匯入了正確的檔案？',
  com_endpoint_config_key_get_edge_key: '要取得 Bing 的存取權杖，請登入到',
  com_endpoint_config_key_get_edge_key_dev_tool:
    '使用開發工具或擴充套件在登入網站時複製 _U cookie 的內容。如果此方法失敗，請按照這些',
  com_endpoint_config_key_edge_instructions: '說明',
  com_endpoint_config_key_edge_full_key_string: '提供完整的 cookie 字串。',
  com_endpoint_config_key_chatgpt: '要取得 ChatGPT \'免費版\' 的存取權杖，請登入到',
  com_endpoint_config_key_chatgpt_then_visit: '然後造訪',
  com_endpoint_config_key_chatgpt_copy_token: '複製存取權杖。',
  com_endpoint_config_key_google_need_to: '您需要',
  com_endpoint_config_key_google_vertex_ai: '在 Google Cloud 上啟用 Vertex AI',
  com_endpoint_config_key_google_vertex_api: 'API，然後',
  com_endpoint_config_key_google_service_account: '建立一個服務帳戶',
  com_endpoint_config_key_google_vertex_api_role:
    '確保點選「建立並繼續」並至少給予「Vertex AI 使用者」角色。最後，建立一個 JSON 金鑰以在此處匯入。',
  com_nav_plugin_store: '外掛商店',
  com_nav_plugin_search: '搜尋外掛',
  com_nav_plugin_auth_error: '嘗試驗證此外掛時發生錯誤。請重試。',
  com_nav_export_filename: '檔名',
  com_nav_export_filename_placeholder: '設定檔案名稱',
  com_nav_export_type: '類型',
  com_nav_export_include_endpoint_options: '包含 AI 選項',
  com_nav_enabled: '已啟用',
  com_nav_not_supported: '不支援',
  com_nav_export_all_message_branches: '匯出所有訊息分支',
  com_nav_export_recursive_or_sequential: '遞迴還是序列？',
  com_nav_export_recursive: '遞迴',
  com_nav_export_conversation: '匯出對話',
  com_nav_theme: '主題',
  com_nav_theme_system: '跟隨系統設定',
  com_nav_theme_dark: '深色',
  com_nav_theme_light: '淺色',
  com_nav_clear_all_chats: '清除所有對話',
  com_nav_confirm_clear: '確認清除',
  com_nav_close_sidebar: '關閉側邊選單',
  com_nav_open_sidebar: '開啟側邊選單',
  com_nav_send_message: '傳送訊息',
  com_nav_log_out: '登出',
  com_nav_user: '使用者',
  com_nav_clear_conversation: '清除對話',
  com_nav_clear_conversation_confirm_message: '您確定要清除所有對話嗎？此操作無法復原。',
  com_nav_help_faq: '說明與常見問題',
  com_nav_settings: '設定',
  com_nav_search_placeholder: '搜尋訊息',
  com_nav_setting_general: '一般',
  com_nav_setting_data: '資料控制',
};
