import { Conversation } from '~/types/conversation';
import { axios } from './setup';
import { User } from '~/types/user';
import { buildMessagesFromEvents } from '~/utils/buildTree';

export const createConversation = async () => {
  return (await axios.post<{ conversation: Conversation }>(`conversations`)).data.conversation;
};

export const getAllUserConversations = async (userId: string) => {
  return (await axios.get<{ conversations: Conversation[] }>(`conversations/user/${userId}`)).data
    .conversations;
};

export const getConversationEvents = async (conversationId: string) => {
  if (conversationId === 'new') return [];

  return (await axios.get<{ events: Conversation }>(`conversations/${conversationId}/events`)).data
    .events;
};

export const getConversationMessages = async (conversationId: string, user: User) => {
  if (conversationId === 'new') return [];

  const eventsData = (
    await axios.get<{ events: Conversation }>(`conversations/${conversationId}/events`)
  ).data;

  // const filesData = (
  //   await axios.get<{
  //     images: {
  //       org_id: string;
  //       user_id: string;
  //       s3_bucket: string;
  //       s3_key: string;
  //       filename: string;
  //       file_type: string;
  //       file_ext: string;
  //       image_id: string;
  //       image_type: string;
  //       model_id: string;
  //       _conversation_id: string;
  //       _interaction_id: string;
  //     }[];
  //     download_urls: Record<string, string>;
  //   }>(`conversations/${conversationId}/images`)
  // ).data;

  // console.log(filesData);

  return buildMessagesFromEvents({ events: eventsData.events, user });
};
