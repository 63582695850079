import type { TDialogProps } from '~/common';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '~/components/ui';
import { useMediaQuery } from '~/hooks';
import { cn } from '~/utils';
import RagDocsTable from '~/components/Tables/RagDocsTable';
import PrimaryButton from '~/components/Buttons/PrimaryButton';
import SecondaryButton from '~/components/Buttons/SecondaryButton';
import { useRagDocuments } from '~/services/queries/sources';
import { useEffect, useState } from 'react';
import { useUpdateSelectedRagDocuments } from '~/services/mutations/sources';
import { useToastContext } from '~/Providers/ToastContext';

export default function Settings({ open, onOpenChange }: TDialogProps) {
  const ragDocumentsQuery = useRagDocuments();
  const { showToast } = useToastContext();
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const [selectedDocs, setSelectedDocs] = useState<string[]>([]);
  const updateSelectedRagDocumentsMutation = useUpdateSelectedRagDocuments();

  useEffect(() => {
    if (ragDocumentsQuery.isSuccess) {
      setSelectedDocs(ragDocumentsQuery.data.selected);
    }
  }, [ragDocumentsQuery.isSuccess, ragDocumentsQuery.data]);

  const handleRowIsSelected = (row) => {
    return selectedDocs.some((id) => id === row.document_id);
  };

  const handleRowSelection = (row, checked) => {
    if (checked) {
      if (selectedDocs.length < 10) {
        setSelectedDocs((prev: string[]) => [...prev, row.document_id]);
      }
    } else {
      setSelectedDocs((prev: string[]) => prev.filter((id) => id !== row.document_id));
    }
  };

  const handleSubmission = async () => {
    await updateSelectedRagDocumentsMutation.mutateAsync(selectedDocs);
    onOpenChange(false);
    //showToast({ message: 'Selected documents updated successfully' });
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        onOpenChange(open);
        setSelectedDocs(ragDocumentsQuery.data!.selected);
      }}
    >
      <DialogContent
        className={cn(
          'shadow-2xl dark:bg-vnavy-dark-card dark:text-white md:min-h-[373px] md:w-[680px]',
        )}
        style={{
          borderRadius: '12px',
          position: 'fixed',
          margin: 'auto',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <DialogHeader>
          <DialogTitle className=" text-lg font-medium leading-6 text-gray-900 dark:text-gray-200">
            Select Documents
          </DialogTitle>
        </DialogHeader>
        <div className="px-6">
          <RagDocsTable rowIsSelected={handleRowIsSelected} onSelectRow={handleRowSelection} />
          <label
            className={`text-xs font-medium font-normal transition-colors dark:bg-transparent sm:w-72 ${
              selectedDocs?.length >= 10 ? 'text-vred' : 'text-gray-400 dark:text-gray-300'
            }`}
          >
            Selected {selectedDocs.length}/10 documents
          </label>
          <div className="flex justify-end gap-2">
            <SecondaryButton
              className="w-28"
              onClick={() => {
                onOpenChange(false);
                setSelectedDocs(ragDocumentsQuery.data!.selected);
              }}
            >
              Cancel{' '}
            </SecondaryButton>
            <PrimaryButton className="w-28" onClick={handleSubmission}>
              {' '}
              Confirm
            </PrimaryButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
