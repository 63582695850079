import { useLocalize } from '~/hooks';
import { version } from '~/../package.json';

export default function SettingsFooter() {
  const localize = useLocalize();
  return (
    <div className="relative px-2 py-2 text-center text-xs text-gray-600 dark:text-gray-300 md:px-[60px]">
      <span>
        <>
          <a href="https://www.askvera.io/" target="_blank" rel="noreferrer" className="underline">
            Vera Chat 2024.10.1
          </a>
          {' - '} {localize('com_ui_new_footer')}
        </>
      </span>
    </div>
  );
}
