import { NewChatIcon } from '~/components/svg';
import { useChatContext } from '~/Providers';
import { ThemeContext, useMediaQuery } from '~/hooks';
import { SKU, VERA_NAVY, VERA_WHITE } from '~/utils/constants';
import { useOrg } from '~/services/queries/orgs';
import { useContext } from 'react';

export default function HeaderNewChat() {
  const { theme } = useContext(ThemeContext);

  let isDark = theme === 'dark';

  const { newConversation } = useChatContext();
  const orgQuery = useOrg();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const isAtLockedAccountRoute = window.location.pathname.includes('locked-account');

  const isSandboxMode = orgQuery.data?.sku === SKU.SANDBOX;
  if (isSmallScreen || isAtLockedAccountRoute || isSandboxMode) {
    return null;
  }

  return (
    <button
      data-testid="wide-header-new-chat-button"
      type="button"
      className="btn btn-neutral btn-small border-token-border-medium relative ml-2 flex hidden h-9 w-9 items-center justify-center whitespace-nowrap rounded-lg rounded-lg border focus:ring-0 focus:ring-offset-0 md:flex"
      style={{ background: isDark ? VERA_NAVY : VERA_WHITE }}
      onClick={() => newConversation()}
    >
      <div className="flex w-full items-center justify-center gap-2 ">
        <NewChatIcon className="" />
      </div>
    </button>
  );
}
