import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getOrganization } from '../api/orgs';
import { Organization } from '~/types/org';
import { useAuth } from '~/Providers/useAuth';

export function useOrg() {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const orgId = user?.org_id;

  return useQuery({
    queryKey: ['org', { orgId }],
    queryFn: () => getOrganization(orgId!),
    enabled: !!orgId,
    placeholderData: () => {
      const cachedOrgs = queryClient.getQueryData<Organization[]>(['orgs']);

      if (cachedOrgs) {
        return cachedOrgs.find((o: Organization) => o.org_id === orgId);
      }
    },
  });
}
