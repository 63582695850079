import { Content, Portal, Root } from '@radix-ui/react-popover';
import { useChatContext } from '~/Providers';
import TitleButton from './UI/TitleButton';
import MenuSeparator from './UI/MenuSeparator';
import { useGroupPolicies } from '~/services/queries/group';
import { useUserGroups } from '~/services/queries/user';
import { useAuth } from '~/Providers/useAuth';
import { useUpdateSandboxPolicy } from '~/services/mutations/policies';
import { Policy } from '~/types/policy';

const MATCHER_KINDS = {
  POLITICAL_CONTENT: 'political_content',
  BRANDNAME_CONTENT: 'brandname_content',
  DRUG_CONTENT: 'drug_content',
};

const PoliciesMenu = () => {
  const { user } = useAuth();
  const userGroupsQuery = useUserGroups(user?.user_id ?? '');
  const sandboxUserGroup = userGroupsQuery.data?.at(0);
  const policiesQuery = useGroupPolicies(sandboxUserGroup?.group_id ?? '');
  const sandboxPolicy: Policy = policiesQuery.data?.at(0)!;
  const updatePolicyMutation = useUpdateSandboxPolicy();
  const { isSubmitting } = useChatContext();

  const politicalContentVal =
    sandboxPolicy?.statements.find((st) => st.matcher.kind === MATCHER_KINDS.POLITICAL_CONTENT)
      ?.action ?? 'off';
  const brandnameContentVal =
    sandboxPolicy?.statements.find((st) => st.matcher.kind === MATCHER_KINDS.BRANDNAME_CONTENT)
      ?.action ?? 'off';
  const drugContentVal =
    sandboxPolicy?.statements.find((st) => st.matcher.kind === MATCHER_KINDS.DRUG_CONTENT)
      ?.action ?? 'off';

  const handleChangePolicy = (kind, action) => {
    let updatedStatements = sandboxPolicy.statements.filter((st) => st.matcher.kind !== kind);
    if (action !== 'off') updatedStatements.push({ action, target: 'prompt', matcher: { kind } });
    updatePolicyMutation.mutate({
      groupId: sandboxUserGroup?.group_id!,
      policyId: sandboxPolicy.policy_id,
      data: {
        name: sandboxPolicy.name,
        description: sandboxPolicy.description,
        report_destination: '',
        report_enabled: false,
        statements: updatedStatements,
      },
    });
  };

  return (
    <Root>
      <TitleButton primaryText={'Policies'} isDisabled={isSubmitting} />
      <Portal>
        <div
          style={{
            position: 'fixed',
            left: '0px',
            top: '0px',
            transform: 'translate3d(268px, 50px, 0px)',
            minWidth: 'max-content',
            zIndex: 'auto',
          }}
        >
          <Content
            side="bottom"
            align="center"
            className="ml-4 mt-2 max-h-[405px] overflow-x-hidden rounded-lg  bg-vwhite shadow-lg  dark:bg-vnavy-dark-card dark:text-white md:min-w-[200px]"
          >
            <div className="flex h-full  grow flex-col items-center justify-center gap-2">
              <div className={`max-w-[340px] py-4`}>
                <div className="mb-4 px-4">
                  <span className={`text-sm font-medium text-gray-900 dark:text-vwhite`}>
                    Tailor Your Content Safeguards
                  </span>
                  <p className=" text-xs font-normal text-[#6C7080] transition-colors dark:bg-transparent dark:text-gray-300 sm:w-72">
                    Make your adjustments below and continue chatting to observe the impact.
                  </p>
                  <div className="mt-4">
                    <TextMatcherToggle
                      isSubmitting={isSubmitting}
                      title={'Political Content'}
                      value={politicalContentVal}
                      onChange={handleChangePolicy}
                      kind={MATCHER_KINDS.POLITICAL_CONTENT}
                    />
                    <div className="my-2">
                      <TextMatcherToggle
                        isSubmitting={isSubmitting}
                        title={'Drug Content'}
                        value={drugContentVal}
                        onChange={handleChangePolicy}
                        kind={MATCHER_KINDS.DRUG_CONTENT}
                      />
                    </div>
                    <TextMatcherToggle
                      isSubmitting={isSubmitting}
                      title={'Brandname Content'}
                      value={brandnameContentVal}
                      onChange={handleChangePolicy}
                      kind={MATCHER_KINDS.BRANDNAME_CONTENT}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <MenuSeparator />
                </div>
                <div className="px-4 pt-3">
                  <label className={`inline-flex  w-full items-center justify-between`}>
                    <span className={`text-sm font-medium text-gray-900 dark:text-vwhite`}>
                      Vera SafeAI Policy
                    </span>
                  </label>
                  <p className="text-xs font-normal text-[#6C7080] transition-colors dark:bg-transparent dark:text-gray-300 sm:w-72">
                    This sandbox includes mandatory safeguards against the exposure of passwords,
                    sensitive info, PII, profanity, and hate speech, which{' '}
                    <strong>cannot be changed</strong>.
                  </p>

                  <div className="mt-4 flex flex-col gap-2">
                    <TextMatcherToggle
                      isSubmitting={isSubmitting}
                      title={'Profanity'}
                      value={'redact'}
                      viewOnly
                    />
                    <TextMatcherToggle
                      isSubmitting={isSubmitting}
                      title={'PII Content'}
                      value={'redact'}
                      viewOnly
                    />
                    <TextMatcherToggle
                      isSubmitting={isSubmitting}
                      title={'Secrets & Passwords'}
                      value={'redact'}
                      viewOnly
                    />
                    <TextMatcherToggle
                      isSubmitting={isSubmitting}
                      title={'Hatespeech Content'}
                      value={'block'}
                      viewOnly
                    />
                    <TextMatcherToggle
                      isSubmitting={isSubmitting}
                      title={'Adult Content'}
                      value={'block'}
                      viewOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </div>
      </Portal>
      {/* {preset && <EditPresetDialog submitPreset={submitPreset} exportPreset={exportPreset} />} */}
    </Root>
  );
};

const TextMatcherToggle = ({
  isSubmitting,
  title,
  value,
  onChange,
  kind,
  viewOnly,
}: {
  isSubmitting: boolean;
  title: string;
  value: string;
  onChange?: (kind: string, action: string) => void;
  kind?: string;
  viewOnly?: boolean;
}) => {
  const handleClick = (action) => {
    if (value !== action) {
      onChange && !!kind && onChange(kind, action);
    }
  };

  const dotColorMap = {
    redact: 'bg-purple-300',
    block: 'bg-red-500',
    off: 'bg-gray-500',
  };

  if (viewOnly) {
    return (
      <div className="flex w-full items-center justify-between ">
        <span
          className={`mr-2 text-sm font-medium ${
            isSubmitting ? 'text-gray-400' : 'text-gray-900'
          }  dark:text-vwhite`}
        >
          {title}
        </span>
        <div className="flex items-center justify-center ">
          <div className="inline-flex overflow-hidden rounded-lg border border-gray-200 dark:border-gray-500">
            <label>
              <input type="radio" className="peer sr-only" checked={true} disabled={isSubmitting} />
              <span className="relative inline-flex h-full w-20 items-center space-x-2 py-2 pl-7 pr-3 text-sm peer-checked:bg-[#F3EFFF] dark:peer-checked:bg-[#F3EFFF1A] ">
                <span
                  className={`before:absolute before:left-3 before:top-[14px] before:h-2 before:w-2 before:rounded-full before:${dotColorMap[value]}`}
                />
                {value.charAt(0).toUpperCase() + value.slice(1)}
              </span>
            </label>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full items-center justify-between ">
      <span
        className={`mr-2 text-sm font-medium ${
          isSubmitting ? 'text-gray-400' : 'text-gray-900'
        }  dark:text-vwhite`}
      >
        {title}
      </span>
      <div className="flex items-center justify-center ">
        <div className="inline-flex overflow-hidden rounded-lg border border-gray-200 dark:border-gray-500">
          <label className="cursor-pointer">
            <input
              type="radio"
              className="peer sr-only"
              checked={value === 'off'}
              disabled={isSubmitting}
              onClick={(e) => {
                e.preventDefault();
                handleClick('off');
              }}
            />
            <span className="relative inline-flex h-full items-center space-x-2 py-2 pl-7 pr-3 text-sm peer-checked:bg-[#F3EFFF] dark:peer-checked:bg-[#F3EFFF1A] ">
              <span className="before:absolute before:left-3 before:top-[14px] before:h-2 before:w-2 before:rounded-full before:bg-gray-500" />
              Off
            </span>
          </label>
          <label className="cursor-pointer">
            <input
              type="radio"
              className="peer sr-only"
              checked={value === 'redact'}
              disabled={isSubmitting}
              onClick={(e) => {
                e.preventDefault();
                handleClick('redact');
              }}
            />
            <span
              className={`relative inline-flex h-full items-center space-x-2 py-2 pl-7 pr-3 text-sm peer-checked:bg-[#F3EFFF] dark:peer-checked:bg-[#F3EFFF1A]`}
            >
              <span className="before:absolute before:left-3 before:top-[14px] before:h-2 before:w-2 before:rounded-full before:bg-purple-300">
                Redact
              </span>
            </span>
          </label>
          <label className="cursor-pointer">
            <input
              type="radio"
              className="peer sr-only"
              checked={value === 'block'}
              disabled={isSubmitting}
              onClick={(e) => {
                e.preventDefault();
                handleClick('block');
              }}
            />
            <span className="relative inline-flex h-full items-center space-x-2 py-2 pl-7 pr-3 text-sm peer-checked:bg-[#F3EFFF] dark:peer-checked:bg-[#F3EFFF1A]">
              <span className="before:absolute before:left-3 before:top-[14px] before:h-2 before:w-2 before:rounded-full before:bg-red-500">
                Block
              </span>
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default PoliciesMenu;
