// Chinese phrases
// file deepcode ignore NoHardcodedPasswords: No hardcoded values present in this file
// file deepcode ignore HardcodedNonCryptoSecret: No hardcoded secrets present in this file

export default {
  com_ui_examples: '示例',
  com_ui_new_chat: '创建新对话',
  com_ui_example_quantum_computing: '如何给7岁小孩讲解量子计算？',
  com_ui_example_10_year_old_b_day: '如何举办生日宴才能耳目一新？',
  com_ui_example_http_in_js: '如何在Python中实现HTTP请求？',
  com_ui_capabilities: '功能',
  com_ui_capability_remember: '记忆历史对话',
  com_ui_capability_correction: '允许更正内容',
  com_ui_capability_decline_requests: '限制不当信息',
  com_ui_limitations: '局限性',
  com_ui_limitation_incorrect_info: '可能会不时出现错误信息',
  com_ui_limitation_harmful_biased: '可能会提供有害指示或者偏见',
  com_ui_limitation_limited_2021: '基于2021年以前信息训练',
  com_ui_experimental: '实验性',
  com_ui_input: '输入',
  com_ui_close: '关闭',
  com_ui_model: '模型',
  com_ui_select_model: '模型选择',
  com_ui_use_prompt: '使用提示词',
  com_ui_prev: '上一页',
  com_ui_next: '下一页',
  com_ui_stop: '停止',
  com_ui_prompt_templates: '对话模板',
  com_ui_hide_prompt_templates: '隐藏对话模板',
  com_ui_showing: '显示',
  com_ui_of: '/',
  com_ui_entries: '项',
  com_ui_pay_per_call: '所有对话都将集中在一处。按会话次数计费，而不是按月付费',
  com_ui_new_footer: '所有对话都将集中在一处。',
  com_ui_enter: '进入',
  com_ui_submit: '提交',
  com_ui_upload_success: '上传文件成功',
  com_ui_upload_error: '上传文件错误',
  com_ui_upload_invalid: '无效的上传文件，请上传不超过2M大小的图片',
  com_ui_cancel: '取消',
  com_ui_save: '保存',
  com_ui_save_submit: '保存并提交',
  com_ui_copy_to_clipboard: '复制到剪贴板',
  com_ui_copied_to_clipboard: '已复制到剪贴板',
  com_ui_regenerate: '重新生成',
  com_ui_continue: '继续',
  com_ui_edit: '编辑',
  com_ui_success: '成功',
  com_ui_all: '所有',
  com_ui_clear: '清除',
  com_ui_revoke: '撤销',
  com_ui_revoke_info: '撤销所有用户提供的凭据',
  com_ui_confirm_action: '确认执行',
  com_ui_chats: '聊天',
  com_ui_delete: '删除',
  com_ui_delete_conversation: '删除对话？',
  com_ui_delete_conversation_confirm: '这将删除',
  com_ui_preview: '预览',
  com_ui_upload: '上传',
  com_ui_connect: '连接',
  com_auth_error_login: '无法登录，请确认提供的账户密码正确，并重新尝试。',
  com_auth_error_login_rl: '尝试登录次数过多，请稍后再试。',
  com_auth_error_login_ban: '根据我们的服务规则，您的帐号被暂时禁用。',
  com_auth_error_login_server: '内部服务器错误，请稍后再试。',
  com_auth_no_account: '新用户注册',
  com_auth_sign_up: '注册',
  com_auth_sign_in: '登录',
  com_auth_google_login: '使用 Google 登录',
  com_auth_facebook_login: '使用 Facebook 登录',
  com_auth_github_login: '使用 GitHub 登录',
  com_auth_discord_login: '使用 Discord 登录',
  com_auth_email: '电子邮箱',
  com_auth_email_required: '邮箱为必填项',
  com_auth_email_min_length: '邮箱地址至少6个字符',
  com_auth_email_max_length: '邮箱地址最多120个字符',
  com_auth_email_pattern: '请输入正确的电子邮箱格式',
  com_auth_email_address: '电子邮箱地址',
  com_auth_password: '密码',
  com_auth_password_required: '密码为必填项',
  com_auth_password_min_length: '密码至少8个字符',
  com_auth_password_max_length: '密码最多128个字符',
  com_auth_password_forgot: '忘记密码?',
  com_auth_password_confirm: '确认密码',
  com_auth_password_not_match: '密码不一致',
  com_auth_continue: '继续',
  com_auth_create_account: '创建账号',
  com_auth_error_create: '注册账户过程中出现错误，请重试。',
  com_auth_full_name: '姓名',
  com_auth_name_required: '姓名为必填项',
  com_auth_name_min_length: '姓名至少3个字符',
  com_auth_name_max_length: '姓名最多80个字符',
  com_auth_username: '用户名（可选）',
  com_auth_username_required: '用户名为必填项',
  com_auth_username_min_length: '用户名至少3个字符',
  com_auth_username_max_length: '用户名最多20个字符',
  com_auth_already_have_account: '已有账号',
  com_auth_login: '登录',
  com_auth_reset_password: '重置密码',
  com_auth_click: '点击',
  com_auth_here: '这里',
  com_auth_to_reset_your_password: '重置密码。',
  com_auth_reset_password_link_sent: '重置密码链接已发送至邮箱',
  com_auth_reset_password_email_sent: '重置密码邮件已发送至邮箱',
  com_auth_error_reset_password: '重置密码出现错误，未找到对应的邮箱地址，请重新输入。',
  com_auth_reset_password_success: '密码重置成功',
  com_auth_login_with_new_password: '现在你可以使用你的新密码登录。',
  com_auth_error_invalid_reset_token: '重置密码的密钥已失效。',
  com_auth_click_here: '点击这里',
  com_auth_to_try_again: '再试一次。',
  com_auth_submit_registration: '注册提交',
  com_auth_welcome_back: '欢迎',
  com_endpoint_open_menu: '打开菜单',
  com_endpoint_bing_enable_sydney: '启用 Sydney',
  com_endpoint_bing_to_enable_sydney: '启用 Sydney',
  com_endpoint_bing_jailbreak: '越狱',
  com_endpoint_bing_context_placeholder:
    '必应可以使用多达7000个词元作为“上下文（context）”，参照这些内容进行对话。其具体限制并不清楚，但可能会在超过7000个词元时出现错误',
  com_endpoint_bing_system_message_placeholder:
    '警告：滥用此功能可能导致你被禁止使用必应！点击“系统消息”查看完整的使用指南，如果你忽略了默认消息，那么将会使用被视为安全的“Sydney”预设。',
  com_endpoint_system_message: '系统消息',
  com_endpoint_message: '消息',
  com_endpoint_message_not_appendable: '编辑您的消息内容或重新生成',
  com_endpoint_default_blank: '初始值: 空白',
  com_endpoint_default_false: '初始值: 否',
  com_endpoint_default_creative: '初始值: 创意',
  com_endpoint_default_empty: '初始值: 空',
  com_endpoint_default_with_num: '初始值: {0}',
  com_endpoint_context: '上下文',
  com_endpoint_tone_style: '语气',
  com_endpoint_token_count: '词元数',
  com_endpoint_output: '输出',
  com_endpoint_google_temp:
    '值越高表示输出越随机，值越低表示输出越确定。建议不要同时改变此值和Top-p。',
  com_endpoint_google_topp:
    'Top-p（核采样）会改变模型选择输出词的方式。从概率最大的K（参见topK参数）向最小的K选择，直到它们的概率之和等于top-p值',
  com_endpoint_google_topk:
    'Top-k 会改变模型选择输出词的方式。top-k为1意味着所选词是模型词汇中概率最大的（也称为贪心解码），而top-k为3意味着下一个词是从3个概率最大的词中选出的（使用随机性）。',
  com_endpoint_google_maxoutputtokens:
    ' 	响应生成中可以使用的最大词元数。指定较低的值会得到更短的响应，而指定较高的值则会得到更长的响应。',
  com_endpoint_google_custom_name_placeholder: '为Google设置一个名称',
  com_endpoint_prompt_prefix_placeholder: '自定义提示词和上下文，默认为空',
  com_endpoint_custom_name: '自定义名称',
  com_endpoint_prompt_prefix: '对话前缀',
  com_endpoint_temperature: '随机性',
  com_endpoint_default: '初始值',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top K',
  com_endpoint_max_output_tokens: '最大输出词元数',
  com_endpoint_openai_temp:
    '值越高表示输出越随机，值越低表示输出越确定。建议不要同时改变此值和Top P。',
  com_endpoint_openai_max: '最大生成词元数。输入词元长度由模型的上下文长度决定。',
  com_endpoint_openai_topp:
    '相较于随机性的另一个取样方法，称为核采样，模型选取输出词元中大于P值（概率密度在整个概率分布中的比例）的结果。比如 top_p=0.1 表示只有概率占比为前10%的词元才会被考虑作为输出。建议不要同时改变此值和随机性。',
  com_endpoint_openai_freq:
    '值介于-2.0到2.0之间。正值将惩罚当前已频繁使用的词元，从而降低重复用词的可能性。',
  com_endpoint_openai_pres:
    '值介于-2.0到2.0之间。正值将惩罚当前已经使用的词元，从而增加讨论新话题的可能性。',
  com_endpoint_openai_custom_name_placeholder: '为ChatGPT设置一个名称',
  com_endpoint_openai_prompt_prefix_placeholder: '在消息开头添加系统级提示词，默认为空',
  com_endpoint_anthropic_temp:
    '值介于0到1之间。 对于分析性/选择性任务，值应更接近0；对于创造性和生成性任务，值应更接近1。我们建议更改该参数或Top-p，但不要同时更改这两个参数。',
  com_endpoint_anthropic_topp:
    'Top-p（核采样）会改变模型选择输出词元的方式。从概率最大的K（参见topK参数）向最小的K选择，直到它们的概率之和等于top-p值。',
  com_endpoint_anthropic_topk:
    'Top-k 会改变模型选择输出词元的方式。top-k为1意味着所选词是模型词汇中概率最大的（也称为贪心解码），而top-k为3意味着下一个词是从3个概率最大的词中选出的（使用随机性）。',
  com_endpoint_anthropic_maxoutputtokens:
    '响应中可生成的最大词元数。指定较低的值会得到更短的响应，而指定较高的值则会得到更长的响应。',
  com_endpoint_anthropic_custom_name_placeholder: '为Anthropic设置一个名称',
  com_endpoint_frequency_penalty: '重复惩罚度',
  com_endpoint_presence_penalty: '话题新鲜度',
  com_endpoint_plug_use_functions: '使用函数',
  com_endpoint_plug_skip_completion: '跳过补全',
  com_endpoint_disabled_with_tools: '系统禁用',
  com_endpoint_disabled_with_tools_placeholder: '系统禁用',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    '在消息开头添加系统级提示词，默认为空',
  com_endpoint_import: '导入',
  com_endpoint_set_custom_name: '设置一个自定义名，以便您检索此预设',
  com_endpoint_preset_delete_confirm: '确定删除此预设？',
  com_endpoint_preset_clear_all_confirm: '确定删除所有预设？',
  com_endpoint_preset_import: '成功导入预设！',
  com_endpoint_preset_import_error: '导入预设时发生错误，请重试。',
  com_endpoint_preset_save_error: '保存预设时发生错误，请重试。',
  com_endpoint_preset_delete_error: '删除预设时发生错误，请重试。',
  com_endpoint_preset_default_removed: '不再是默认预设。',
  com_endpoint_preset_default_item: '默认：',
  com_endpoint_preset_default_none: '无默认预设可用。',
  com_endpoint_preset_title: '预设',
  com_endpoint_preset_saved: '保存成功！',
  com_endpoint_preset_default: '现在是默认预设。',
  com_endpoint_preset: '预设',
  com_endpoint_presets: '预设',
  com_endpoint_preset_selected: '预设可用！',
  com_endpoint_preset_selected_title: '可用！',
  com_endpoint_preset_name: '预设名',
  com_endpoint_new_topic: '新主题',
  com_endpoint: '渠道',
  com_endpoint_hide: '隐藏',
  com_endpoint_show: '显示',
  com_endpoint_examples: ' 预设',
  com_endpoint_completion: '补全',
  com_endpoint_agent: '代理',
  com_endpoint_show_what_settings: '显示{0}设置',
  com_endpoint_save: '保存',
  com_endpoint_export: '导出',
  com_endpoint_save_as_preset: '保存为预设',
  com_endpoint_presets_clear_warning: '确定要清除所有预设吗？此操作不可逆转的。',
  com_endpoint_not_implemented: '未实现功能',
  com_endpoint_no_presets: '暂无预设，使用设置按钮创建一个。',
  com_endpoint_not_available: '无可用渠道',
  com_endpoint_view_options: '查看选项',
  com_endpoint_save_convo_as_preset: '保存对话为预设',
  com_endpoint_my_preset: '我的预设',
  com_endpoint_agent_model: '代理模型 (推荐: GPT-3.5)',
  com_endpoint_completion_model: '补全模型 (推荐: GPT-4)',
  com_endpoint_func_hover: '将插件作为OpenAI函数使用',
  com_endpoint_skip_hover: '跳过补全步骤， 检查最终答案和生成步骤',
  com_endpoint_config_key: '设置API Key',
  com_endpoint_config_placeholder: '在顶部菜单设置API KEY',
  com_endpoint_config_key_for: '设置API Key：',
  com_endpoint_config_key_name: '密钥',
  com_endpoint_config_value: '输入值：',
  com_endpoint_config_key_name_placeholder: '请先设置API key',
  com_endpoint_config_key_encryption: '您的密钥将被加密并删除于：',
  com_endpoint_config_key_expiry: '过期时间',
  com_endpoint_config_click_here: '点击此处',
  com_endpoint_config_google_service_key: 'Google服务账号密钥',
  com_endpoint_config_google_cloud_platform: '（从谷歌云平台）',
  com_endpoint_config_google_api_key: 'Google API密钥',
  com_endpoint_config_google_gemini_api: '(Gemini API)',
  com_endpoint_config_google_api_info: '获取您的生成式语言API密钥(Gemini),',
  com_endpoint_config_key_import_json_key: '导入服务账号JSON密钥',
  com_endpoint_config_key_import_json_key_success: '成功导入服务账号JSON密钥',
  com_endpoint_config_key_import_json_key_invalid: '无效的服务账号JSON密钥，您是否导入正确的文件？',
  com_endpoint_config_key_get_edge_key: '为获得Bing访问凭证（Access token），请登录：',
  com_endpoint_config_key_get_edge_key_dev_tool:
    '登录网站后，使用开发工具或扩展程序复制 _U cookie 的内容。如果失败，请按照以下步骤操作：',
  com_endpoint_config_key_edge_instructions: '说明',
  com_endpoint_config_key_edge_full_key_string: '提供完整的cookie字符串。',
  com_endpoint_config_key_chatgpt: '为获得ChatGPT的访问凭证（Access token）, 请登录：',
  com_endpoint_config_key_chatgpt_then_visit: '然后访问',
  com_endpoint_config_key_chatgpt_copy_token: '复制access token。',
  com_endpoint_config_key_google_need_to: '您需要',
  com_endpoint_config_key_google_vertex_ai: '在Google Cloud上启用Vertex AI',
  com_endpoint_config_key_google_vertex_api: 'API，然后',
  com_endpoint_config_key_google_service_account: '创建一个服务账号',
  com_endpoint_config_key_google_vertex_api_role:
    '确保单击“创建并继续”以至少授予“Vertex AI 用户”角色。最后，创建一个要在此处导入的JSON密钥。',
  com_nav_welcome_message: '有什么可以帮助到您？',
  com_nav_auto_scroll: '打开时自动滚动到最新内容',
  com_nav_modular_chat: '启用对话中切换渠道',
  com_nav_profile_picture: '个人资料头像',
  com_nav_change_picture: '修改头像',
  com_nav_plugin_store: '插件商店',
  com_nav_plugin_search: '搜索插件',
  com_nav_plugin_auth_error: '尝试验证此插件时出错。请重试。',
  com_nav_export_filename: '文件名',
  com_nav_export_filename_placeholder: '设置文件名',
  com_nav_export_type: '类型',
  com_nav_export_include_endpoint_options: '包含配置信息',
  com_nav_enabled: '启用',
  com_nav_not_supported: '未支持',
  com_nav_export_all_message_branches: '导出所有对话',
  com_nav_export_recursive_or_sequential: '递归或顺序？',
  com_nav_export_recursive: '递归',
  com_nav_export_conversation: '导出对话',
  com_nav_theme: '主题',
  com_nav_theme_system: '跟随系统设置',
  com_nav_theme_dark: '暗色主题',
  com_nav_theme_light: '亮色主题',
  com_nav_clear_all_chats: '清空所有对话',
  com_nav_confirm_clear: '确认清空',
  com_nav_close_sidebar: '关闭侧边栏',
  com_nav_open_sidebar: '打开侧边栏',
  com_nav_send_message: '发送消息',
  com_nav_log_out: '注销',
  com_nav_user: '默认用户',
  com_nav_clear_conversation: '清空对话',
  com_nav_clear_conversation_confirm_message: '请是否清空所有对话？该操作无法撤销',
  com_nav_help_faq: '帮助',
  com_nav_settings: '设置',
  com_nav_search_placeholder: '搜索对话及对话内容',
  com_nav_setting_general: '通用',
  com_nav_setting_data: '数据管理',
  com_nav_setting_account: '账户',
};
