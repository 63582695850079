import { VERA_NAVY } from '~/utils/constants';

function VeraFullLogo({ color = VERA_NAVY }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width={'100%'}
      viewBox="0 0 859.72 241.54"
      fill={color}
    >
      <g>
        <g>
          <path d="m156.67,0l-64.69,172.95c-5.04,13.54-23.39,10.09-22.02-2.52L88.31,0H0v241.54h140.38L239.71,0h-83.04Z" />
          <path d="m271.36,159.19c1.61-8.95,9.63-11.93,19.27-11.93l101.16-.46,24.31-59.18h-184.42l-63.54,153.92h184.65l28.21-68.36h-96.11c-11.01,0-14.68-7.34-13.53-13.99Z" />
          <polygon points="452.11 0 266.54 0 241.54 61.47 426.88 61.47 452.11 0" />
          <path d="m723.93,0l-99.32,241.54h83.03l24.37-65.15h50.01l-7.63,65.15h85.33V0h-135.79Zm70.42,71.11l-6.13,52.3h-36.4l20.51-54.83c5.05-13.53,23.4-10.09,22.02,2.53Z" />
          <path d="m666.81,64.68c0-40.83-26.37-64.68-79.59-64.68h-106.43l-25.24,61.47h128.92c13.07,0,15.14,8.26,14.22,13.54-1.61,8.48-8.26,12.61-21.56,12.61h-132.36l-63.31,153.92h85.79l25.92-87.86c3.21-12.84,21.11-13.3,23.17,0l13.76,87.86h79.37l-25.54-95.5c58.16-3.83,82.88-44.46,82.88-81.36Z" />
        </g>
      </g>
    </svg>
  );
}

export default VeraFullLogo;
