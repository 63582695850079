import React from 'react';
import { Listbox } from '@headlessui/react';
import ThreeDotsMenuIcon from '~/components/svg/ThreeDotsMenuIcon';

export type ActionOption = {
  label: string;
  onSelect: Function;
};

const ThreeDotsMenu = ({ options, row }: { options: ActionOption[]; row?: any }) => {
  return (
    <Listbox as="div" className="relative inline-block text-left">
      <div>
        <Listbox.Button className="w-4 hover:opacity-50 focus:outline-none">
          <ThreeDotsMenuIcon className="fill-gray-700 stroke-gray-700 dark:fill-white dark:stroke-white" />
        </Listbox.Button>
      </div>
      <Listbox.Options className="fixed z-10 mt-2 w-32 origin-top-right divide-y divide-gray-100 rounded-md bg-vwhite shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-vnavy-dark-card ">
        {options.map((option) => (
          <Listbox.Option key={option.label} value={option.label}>
            {({ active, selected }) => (
              <button
                className={`${
                  active ? 'rounded bg-gray-100 dark:bg-[#4A417A]' : ''
                } group flex w-full items-center  px-2 py-2 text-sm text-gray-900 dark:text-gray-100`}
                onClick={() => option.onSelect(row)}
              >
                {option.label}
              </button>
            )}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
};

export default ThreeDotsMenu;
