import { useMutation } from '@tanstack/react-query';
import { createOrg } from '../api/sandbox';
import { RegisterOrgForm } from '~/types/sandbox';

export function useRegisterOrg() {
  return useMutation({
    mutationFn: (data: RegisterOrgForm) => createOrg(data),
    onSettled(data, error, variables, context) {
      if (error) {
        console.log(error);
      }
      return data;
    },
  });
}
