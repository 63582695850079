import { Content, Portal, Root } from '@radix-ui/react-popover';
import { useChatContext } from '~/Providers';
import TitleButton from './UI/TitleButton';
import RouterToggle from './Toggles/RouterToggle';
import RagToggle from './Toggles/RagToggle';
import MenuSeparator from './UI/MenuSeparator';

const ControlsMenu = ({ showRag = false }: { showRag: boolean }) => {
  const { isSubmitting } = useChatContext();

  return (
    <Root>
      <TitleButton primaryText={'Controls'} isDisabled={isSubmitting} />
      <Portal>
        <div
          style={{
            position: 'fixed',
            left: '0px',
            top: '0px',
            transform: 'translate3d(268px, 50px, 0px)',
            minWidth: 'max-content',
            zIndex: 'auto',
          }}
        >
          <Content
            side="bottom"
            align="center"
            className="ml-4 mt-2 max-h-[495px] overflow-x-hidden rounded-lg bg-vwhite shadow-lg  dark:bg-vnavy-dark-card dark:text-white md:min-w-[200px]"
          >
            <div className="flex h-full  grow flex-col items-center justify-center gap-2">
              <div className={`max-w-[320px] px-4 ${showRag ? 'pt-4' : 'py-4'}`}>
                <RouterToggle />
              </div>
              {showRag && (
                <>
                  {' '}
                  <div className="w-full">
                    <MenuSeparator />
                  </div>
                  <div className="max-w-[320px] px-4 pb-4">
                    <RagToggle />
                  </div>
                </>
              )}
            </div>
          </Content>
        </div>
      </Portal>
      {/* {preset && <EditPresetDialog submitPreset={submitPreset} exportPreset={exportPreset} />} */}
    </Root>
  );
};

export default ControlsMenu;
