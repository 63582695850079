import type { TDialogProps } from '~/common';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '~/components/ui';
import { ThemeContext, useMediaQuery } from '~/hooks';
import { cn } from '~/utils';
import PrimaryButton from '~/components/Buttons/PrimaryButton';
import SecondaryButton from '~/components/Buttons/SecondaryButton';
import { useContext, useEffect, useState } from 'react';
import VeraColorLogo from '~/components/svg/VeraColorLogo';
import VeraIconLogo from '~/components/svg/VeraIconLogo';
import { VERA_NAVY, VERA_YELLOW } from '~/utils/constants';
import VeraFullLogo from '~/components/svg/VeraFullLogo';

export default function WelcomeModal({ open, onOpenChange }: TDialogProps) {
  const { theme } = useContext(ThemeContext);

  let isDark = theme === 'dark';
  const isSmallScreen = useMediaQuery('(max-width: 767px)');

  const handleSubmission = async () => {
    onOpenChange(false);
    //showToast({ message: 'Selected documents updated successfully' });
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        onOpenChange(open);
      }}
    >
      <DialogContent
        className={cn(
          'shadow-2xl dark:bg-vnavy-dark-card dark:text-white md:min-h-[373px] md:w-[680px]',
        )}
        style={{
          borderRadius: '12px',
          position: 'fixed',
          margin: 'auto',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <DialogHeader>
          <DialogTitle className=" flex items-center text-lg font-medium leading-6 text-gray-900 dark:text-gray-200 dark:text-gray-50">
            <div className=" h-min-content mr-6 w-24">
              <VeraFullLogo color={isDark ? VERA_YELLOW : VERA_NAVY} />
            </div>
            Welcome to your Vera Sandbox!
          </DialogTitle>
        </DialogHeader>
        <div className="mt-4 px-6">
          <label
            className={`'text-gray-400 dark:text-gray-300'  font-medium font-normal transition-colors  dark:bg-transparent sm:w-72
            `}
          >
            Welcome to Vera's chat application! Here you will find 4 sample prompts that showcase
            the Vera platform. As Vera processes each prompt, you'll learn about its functionality.
            Hover over the info tags to see the selected AI model, the reason for its selection, and
            the policies applied.
            <br /> <br />
            You can start with our sample prompts, or try anything you like. Go ahead and explore!
          </label>
          {/* <label
            className={`text-xs font-medium font-normal transition-colors dark:bg-transparent sm:w-72 ${
              selectedDocs?.length >= 10 ? 'text-vred' : 'text-gray-400 dark:text-gray-300'
            }`}
          >
            Selected {selectedDocs.length}/10 documents
          </label> */}
          <div className="mt-8 flex justify-end gap-2">
            <PrimaryButton
              className="w-28"
              onClick={() => {
                onOpenChange(false);
              }}
            >
              {' '}
              Let's go!
            </PrimaryButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
