import { SendIcon } from '~/components/svg';
import { cn } from '~/utils';

export default function SendButton({ text, disabled, onClick }) {
  return (
    <button
      disabled={!text || disabled}
      className={cn(
        'absolute bottom-1.5 right-2 rounded-lg  bg-vyellow p-0.5 transition-colors hover:opacity-90 disabled:opacity-50  md:bottom-3 md:right-3',
      )}
      // style={{
      //   background: 'radial-gradient(96.65% 96.65% at -2.56% 24.88%, #3F5AFF 0%, #19D8CA 100%)',
      // }}
      data-testid="send-button"
      onClick={onClick && onClick}
      type="submit"
    >
      <span className="" data-state="closed">
        <SendIcon size={24} className="text-vnavy" />
      </span>
    </button>
  );
}
