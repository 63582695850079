import React from 'react';
import { Link } from 'react-router-dom';
import './pp-styling.css';
import VeraFullLogo from '../svg/VeraFullLogo';
import { VERA_YELLOW } from '~/utils/constants';

const PrivacyPolicy = () => {
  return (
    <div>
      <div className=" mb-6 mt-12 h-10 w-auto">
        <VeraFullLogo color={VERA_YELLOW} />
      </div>
      <div className="privacy-policy">
        <h1>Privacy Policy</h1>

        <p className="effective-date">Effective date: September 30, 2024</p>

        <p>
          Thank you for visiting website(s), products, services and applications (the "Services")
          owned and operated by Vera AI, Inc. ("Vera AI"). At Vera AI, we take your privacy
          seriously. Please read this Privacy Policy to learn how we treat your personal data.
        </p>

        <p className="important">
          By using or accessing our Services in any manner, you acknowledge that you accept the
          practices and policies outlined below, and you hereby consent that we will collect, use
          and disclose your information as described in this Privacy Policy.
        </p>

        <p>
          Remember that your use of Vera AI's Services is at all times subject to our{' '}
          <Link to="/terms-of-use">Terms of Use</Link>, which incorporates this Privacy Policy. Any
          terms we use in this Privacy Policy without defining them have the definitions given to
          them in the Terms of Use.
        </p>

        <p>
          You may print a copy of this Privacy Policy by{' '}
          <Link
            onClick={(e) => {
              e.preventDefault();
              window.print();
            }}
          >
            clicking here
          </Link>
          .
        </p>

        <p>
          As we continually work to improve our Services, we may need to change this Privacy Policy
          from time to time. We will alert you of material changes by placing a notice on the Vera
          AI website, by sending you an email and/or by some other means. Please note that if you've
          opted not to receive legal notice emails from us (or you haven't provided us with your
          email address), those legal notices will still govern your use of the Services, and you
          are still responsible for reading and understanding them. If you use the Services after
          any changes to the Privacy Policy have been posted, that means you agree to all of the
          changes.
        </p>

        <h2>Privacy Policy Table of Contents</h2>

        <ul>
          <li>
            <a href="#what-this-covers">What this Privacy Policy Covers</a>
          </li>
          <li>
            <a href="#personal-data">Personal Data</a>
          </li>
          <li>
            <a href="#how-we-disclose">How We Disclose Your Personal Data</a>
          </li>
          <li>
            <a href="#tracking-tools">Tracking Tools and Opt-Out</a>
          </li>
          <li>
            <a href="#data-security">Data Security</a>
          </li>
          <li>
            <a href="#children-data">Personal Data of Children</a>
          </li>
          <li>
            <a href="#state-law-rights">State Law Privacy Rights</a>
          </li>
          <li>
            <a href="#eu-uk-rights">
              European Union, United Kingdom, and Swiss Data Subject Rights
            </a>
          </li>
          <li>
            <a href="#contact-info">Contact Information</a>
          </li>
        </ul>

        <h2 id="what-this-covers">What this Privacy Policy Covers</h2>

        <p>
          This Privacy Policy covers how we treat Personal Data that we gather when you access or
          use our Services. "Personal Data" means any information that identifies or relates to a
          particular individual and also includes information referred to as "personally
          identifiable information" or "personal information" or "sensitive personal information"
          under applicable data privacy laws, rules or regulations. This Privacy Policy does not
          cover the practices of companies we don't own or control or people we don't manage.
        </p>

        <h2 id="personal-data">Personal Data</h2>

        <h3>Categories of Personal Data We Collect</h3>

        <p>
          This chart details the categories of Personal Data that we collect and have collected over
          the past 12 months:
        </p>

        <table>
          <thead>
            <tr>
              <th>Category of Personal Data (and Examples)</th>
              <th>Categories of Third Parties With Whom We Disclose this Personal Data</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>Profile or Contact Data</strong> such as first and last name and email.
              </td>
              <td>
                <ul>
                  <li>Service Providers</li>
                  <li>Business Partners</li>
                  <li>Parties You Authorize, Access or Authenticate</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Payment Data</strong> such as financial account information, payment card
                type, payment card information, and billing address, phone number, and email.
              </td>
              <td>
                <ul>
                  <li>
                    Service Providers (specifically our payment processing partner, currently
                    Stripe, Inc., Square, Inc. and Square Capital, LLC, Braintree (offered by
                    PayPal, Inc.)
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Device/IP Data</strong> such as IP address, device ID, and type of device/
                operating system/ browser used to access the Services.
              </td>
              <td>
                <ul>
                  <li>Service Providers</li>
                  <li>Advertising Partners</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Web Analytics</strong> such as referring webpage/source through which you
                accessed the Services, non-identifiable request IDs, and statistics associated with
                the interaction between device or browser and the Services.
              </td>
              <td>
                <ul>
                  <li>Service Providers</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Geolocation Data</strong> such as IP-address-based location information.
              </td>
              <td>
                <ul>
                  <li>Service Providers</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Our Commercial or Business Purposes for Collecting Personal Data</h3>

        <ul>
          <li>
            <strong>Providing, Customizing and Improving the Services</strong>
            <ul>
              <li>Creating and managing your account or other user profiles.</li>
              <li>Processing orders or other transactions; billing.</li>
              <li>Providing you with the products, services or information you request.</li>
              <li>Meeting or fulfilling the reason you provided the information to us.</li>
              <li>Providing support and assistance for the Services.</li>
              <li>
                Improving the Services, including testing, research, internal analytics and product
                development.
              </li>
              <li>
                Personalizing the Services, website content and communications based on your
                preferences.
              </li>
              <li>Doing fraud protection, security and debugging.</li>
            </ul>
          </li>
          <li>
            <strong>Marketing the Services</strong>
            <ul>
              <li>Marketing and selling the Services.</li>
            </ul>
          </li>
          <li>
            <strong>Corresponding with You</strong>
            <ul>
              <li>
                Responding to correspondence that we receive from you, contacting you when necessary
                or requested, and sending you information about the Company or the Services.
              </li>
              <li>Sending emails and other communications according to your preferences.</li>
            </ul>
          </li>
        </ul>

        <h3>Other Permitted Purposes for Processing Personal Data</h3>

        <p>
          In addition, each of the above referenced categories of Personal Data may be collected,
          used, and disclosed with the government, including law enforcement, or other parties to
          meet certain legal requirements and enforcing legal terms including: fulfilling our legal
          obligations under applicable law, regulation, court order or other legal process, such as
          preventing, detecting and investigating security incidents and potentially illegal or
          prohibited activities; protecting the rights, property or safety of you, Vera AI or
          another party; enforcing any agreements with you; responding to claims that any posting or
          other content violates third-party rights; and resolving disputes.
        </p>

        <p>
          We will not collect additional categories of Personal Data or use the Personal Data we
          collected for materially different, unrelated or incompatible purposes without providing
          you notice or obtaining your consent.
        </p>

        <h3>Categories of Sources of Personal Data</h3>

        <p>We collect Personal Data about you from the following categories of sources:</p>

        <ul>
          <li>
            <strong>You</strong>
            <ul>
              <li>
                When you provide such information directly to us.
                <ul>
                  <li>When you create an account or use our interactive tools and Services.</li>
                  <li>
                    When you voluntarily provide information in free-form text boxes through the
                    Services or through responses to surveys or questionnaires.
                  </li>
                  <li>When you send us an email or otherwise contact us.</li>
                </ul>
              </li>
              <li>
                When you use the Services and such information is collected automatically.
                <ul>
                  <li>
                    Through Cookies (defined in the "Tracking Tools and Opt-Out" section below).
                  </li>
                  <li>
                    If you download our mobile application or use a location-enabled browser, we may
                    receive information about your location and mobile device, as applicable.
                  </li>
                  <li>
                    If you download and install certain applications and software we make available,
                    we may receive and collect information transmitted from your computing device
                    for the purpose of providing you the relevant Services, such as information
                    regarding when you are logged on and available to receive updates or alert
                    notices.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <strong>Third Parties</strong>
            <ul>
              <li>
                Vendors
                <ul>
                  <li>
                    We may use analytics providers to analyze how you interact and engage with the
                    Services, or third parties may help us provide you with customer support.
                  </li>
                  <li>
                    We may use vendors to obtain information to generate leads and create user
                    profiles.
                  </li>
                </ul>
              </li>
              <li>
                Advertising Partners
                <ul>
                  <li>
                    We receive information about you from some of our vendors who assist us with
                    marketing or promotional services related to how you interact with our websites,
                    applications, products, Services, advertisements or communications.
                  </li>
                </ul>
              </li>
              <li>
                Third-Party Credentials
                <ul>
                  <li>
                    If you provide your third-party account credentials, such as your social network
                    account credentials, to us or otherwise sign in to the Services through a
                    third-party site or service, some content and/or information in those accounts
                    may be transmitted into your account with us.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>

        <h2 id="how-we-disclose">How We Disclose Your Personal Data</h2>

        <p>
          We disclose your Personal Data to the categories of service providers and other parties
          listed in this section. Depending on state laws that may be applicable to you, some of
          these disclosures may constitute a "sale" of your Personal Data. For more information,
          please refer to the state-specific sections below.
        </p>

        <ul>
          <li>
            <strong>Service Providers.</strong> These parties help us provide the Services or
            perform business functions on our behalf. They include:
            <ul>
              <li>Hosting, technology and communication providers.</li>
              <li>Security and fraud prevention consultants.</li>
              <li>Support and customer service vendors.</li>
              <li>Analytics providers regarding web traffic or usage of the Services.</li>
              <li>
                Payment processors.
                <ul>
                  <li>
                    Our payment processing partner Stripe, Inc. ("Stripe") collects your
                    voluntarily-provided payment card information necessary to process your payment.
                  </li>
                  <li>
                    Please see Stripe's terms of service and privacy policy for information on its
                    use and storage of your Personal Data.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <strong>Advertising Partners.</strong> These parties help us market our services and
            provide you with other offers that may be of interest to you. They include:
            <ul>
              <li>Ad networks.</li>
              <li>Marketing providers.</li>
            </ul>
          </li>
          <li>
            <strong>Business Partners.</strong> These parties partner with us in offering various
            services. They include:
            <ul>
              <li>Businesses that you have a relationship with.</li>
            </ul>
          </li>
          <li>
            <strong>Parties You Authorize, Access or Authenticate</strong>
            <ul>
              <li>Third parties you access through the services.</li>
            </ul>
          </li>
        </ul>

        <h3>Legal Obligations</h3>
        <p>
          We may disclose any Personal Data that we collect with third parties in conjunction with
          any of the activities set forth under "Other Permitted Purposes for Processing Personal
          Data" section above.
        </p>

        <h3>Business Transfers</h3>
        <p>
          All of your Personal Data that we collect may be transferred to a third party if we
          undergo a merger, acquisition, bankruptcy or other transaction in which that third party
          assumes control of our business (in whole or in part).
        </p>

        <h3>Data that is Not Personal Data</h3>
        <p>
          We may create aggregated, de-identified or anonymized data from the Personal Data we
          collect, including by removing information that makes the data personally identifiable to
          a particular user. We may use such aggregated, de-identified or anonymized data and
          disclose it with third parties for our lawful business purposes, including to analyze,
          build and improve the Services and promote our business, provided that we will not
          disclose such data in a manner that could identify you.
        </p>

        <h2 id="tracking-tools">Tracking Tools and Opt-Out</h2>

        <p>
          The Services use cookies and similar technologies such as pixel tags, web beacons, clear
          GIFs and JavaScript (collectively, "Cookies") to enable our servers to recognize your web
          browser, tell us how and when you visit and use our Services, analyze trends, learn about
          our user base and operate and improve our Services. Cookies are small pieces of data--
          usually text files -- placed on your computer, tablet, phone or similar device when you
          use that device to access our Services. We may also supplement the information we collect
          from you with information received from third parties, including third parties that have
          placed their own Cookies on your device(s).
        </p>

        <p>
          Please note that because of our use of Cookies, the Services do not support "Do Not Track"
          requests sent from a browser at this time.
        </p>

        <p>We use the following types of Cookies:</p>

        <ul>
          <li>
            <strong>Essential Cookies.</strong> Essential Cookies are required for providing you
            with features or services that you have requested. For example, certain Cookies enable
            you to log into secure areas of our Services. Disabling these Cookies may make certain
            features and services unavailable.
          </li>
          <li>
            <strong>Performance/Analytical Cookies.</strong> Performance/Analytical Cookies allow us
            to understand how visitors use our Services. They do this by collecting information
            about the number of visitors to the Services, what pages visitors view on our Services
            and how long visitors are viewing pages on the Services. Performance/Analytical Cookies
            also help us measure the performance of our advertising campaigns in order to help us
            improve our campaigns and the Services’ content for those who engage with our
            advertising. For example, Google LLC (“Google”) uses cookies in connection with its
            Google Analytics services. Google’s ability to use and disclose information collected by
            Google Analytics about your visits to the Services is subject to the Google Analytics
            Terms of Use and the Google Privacy Policy. You have the option to opt-out of Google’s
            use of Cookies by visiting the Google advertising opt-out page at
            www.google.com/privacy_ads.html or the Google Analytics Opt-out Browser Add-on at
            https://tools.google.com/dlpage/gaoptout/.
          </li>
        </ul>

        <p>
          You can decide whether or not to accept Cookies through your internet browser's settings.
          Most browsers have an option for turning off the Cookie feature, which will prevent your
          browser from accepting new Cookies, as well as (depending on the sophistication of your
          browser software) allow you to decide on acceptance of each new Cookie in a variety of
          ways. You can also delete all Cookies that are already on your device. If you do this,
          however, you may have to manually adjust some preferences every time you visit our website
          and some of the Services and functionalities may not work.
        </p>

        <h3>Session Replay Technology</h3>
        <p>
          We may use session replay technology in order to identify and resolve customer issues, to
          monitor and analyze how you use our Services, to better understand user behavior, and to
          improve our Services. By continuing to use the Services, you consent to the use of session
          replay technology.
        </p>

        <h2 id="data-security">Data Security</h2>
        <p>
          We seek to protect your Personal Data from unauthorized access, use and disclosure using
          appropriate physical, technical, organizational and administrative security measures based
          on the type of Personal Data and how we are processing that data. You should also help
          protect your data by appropriately selecting and protecting your password and/or other
          sign-on mechanism; limiting access to your computer or device and browser; and signing off
          after you have finished accessing your account. Although we work to protect the security
          of your account and other data that we hold in our records, please be aware that no method
          of transmitting data over the internet or storing data is completely secure.
        </p>

        <h2>Data Retention</h2>
        <p>
          We retain Personal Data about you for as long as necessary to provide you with our
          Services or to perform our business or commercial purposes for collecting your Personal
          Data. When establishing a retention period for specific categories of data, we consider
          who we collected the data from, our need for the Personal Data, why we collected the
          Personal Data, and the sensitivity of the Personal Data. In some cases we retain Personal
          Data for longer, if doing so is necessary to comply with our legal obligations, resolve
          disputes or collect fees owed, or is otherwise permitted or required by applicable law,
          rule or regulation. We may further retain information in an anonymous or aggregated form
          where that information would not identify you personally.
        </p>

        <p>For example:</p>
        <ul>
          <li>
            We retain your profile information and credentials for as long as you have an account
            with us.
          </li>
          <li>
            We retain your device/IP data for as long as we need it to ensure that our systems are
            working appropriately, effectively and efficiently.
          </li>
        </ul>

        <h2 id="children-data">Personal Data of Children</h2>
        <p>
          As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data from
          children under 13 years of age; if you are a child under the age of 13, please do not
          attempt to register for or otherwise use the Services or send us any Personal Data. If we
          learn we have collected Personal Data from a child under 13 years of age, we will delete
          that information as quickly as possible. If you believe that a child under 13 years of age
          may have provided Personal Data to us, please contact us at info@askvera.io.
        </p>

        <h2 id="state-law-rights">State Law Privacy Rights</h2>

        <h3>California Resident Rights</h3>
        <p>
          Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to
          contact us to prevent disclosure of Personal Data to third parties for such third parties'
          direct marketing purposes; in order to submit such a request, please contact us at
          info@askvera.io.
        </p>

        <p>
          Your browser may offer you a "Do Not Track" option, which allows you to signal to
          operators of websites and web applications and services that you do not wish such
          operators to track certain of your online activities over time and across different
          websites. Our Services do not support Do Not Track requests at this time. To find out more
          about "Do Not Track," you can visit{' '}
          <a href="http://www.allaboutdnt.com" target="_blank" rel="noopener noreferrer">
            www.allaboutdnt.com
          </a>
          .
        </p>

        <h3>Nevada Resident Rights</h3>
        <p>
          Please note that we do not currently sell your Personal Data as sales are defined in
          Nevada Revised Statutes Chapter 603A.
        </p>

        <h2 id="eu-uk-rights">European Union and United Kingdom Data Subject Rights</h2>

        <h3>EU and UK Residents</h3>
        <p>
          If you are a resident of the European Union ("EU"), United Kingdom ("UK"), Lichtenstein,
          Norway or Iceland, you may have additional rights under the EU or UK General Data
          Protection Regulation (the "GDPR") with respect to your Personal Data, as outlined below.
        </p>

        <p>
          For this section, we use the terms "Personal Data" and "processing" as they are defined in
          the GDPR, but "Personal Data" generally means information that can be used to individually
          identify a person, and "processing" generally covers actions that can be performed in
          connection with data such as collection, use, storage and disclosure. Vera AI will be the
          controller of your Personal Data processed in connection with the Services.
        </p>

        <p>
          If there are any conflicts between this this section and any other provision of this
          Privacy Policy, the policy or portion that is more protective of Personal Data shall
          control to the extent of such conflict. If you have any questions about this section or
          whether any of the following applies to you, please contact us at info@askvera.io. Note
          that we may also process Personal Data of our customers' end users or employees in
          connection with our provision of certain services to customers, in which case we are the
          processor of Personal Data. If we are the processor of your Personal Data (i.e., not the
          controller), please contact the controller party in the first instance to address your
          rights with respect to such data.
        </p>

        <h3>Personal Data We Collect</h3>
        <p>
          The "Categories of Personal Data We Collect" section above details the Personal Data that
          we collect from you.
        </p>

        <h3>Personal Data Use and Processing Grounds</h3>
        <p>
          The "Our Commercial or Business Purposes for Collecting Personal Data" section above
          explains how we use your Personal Data.
        </p>

        <p>
          We will only process your Personal Data if we have a lawful basis for doing so. Lawful
          bases for processing include consent, contractual necessity and our "legitimate interests"
          or the legitimate interest of others, as further described below.
        </p>

        <ul>
          <li>
            <strong>Contractual Necessity:</strong> We process the following categories of Personal
            Data as a matter of "contractual necessity", meaning that we need to process the data to
            perform under our Terms of Use with you, which enables us to provide you with the
            Services. When we process data due to contractual necessity, failure to provide such
            Personal Data will result in your inability to use some or all portions of the Services
            that require such data.
            <ul>
              <li>Profile/Contact Data</li>
              <li>Payment Data</li>
            </ul>
          </li>
          <li>
            <strong>Legitimate Interest:</strong> We process the following categories of Personal
            Data when we believe it furthers the legitimate interest of us or third parties:
            <ul>
              <li>Profile/Contact Data</li>
              <li>Device/IP Data</li>
              <li>Web Analytics</li>
              <li>Geolocation Data</li>
              <li>
                We may also de-identify or anonymize Personal Data to further our legitimate
                interests.
              </li>
            </ul>
          </li>
        </ul>

        <p>Examples of these legitimate interests include (as described in more detail above):</p>
        <ul>
          <li>Providing, customizing and improving the Services.</li>
          <li>Marketing the Services.</li>
          <li>Corresponding with you.</li>
          <li>Meeting legal requirements and enforcing legal terms.</li>
          <li>Completing corporate transactions.</li>
        </ul>

        <ul>
          <li>
            <strong>Consent:</strong> In some cases, we process Personal Data based on the consent
            you expressly grant to us at the time we collect such data. When we process Personal
            Data based on your consent, it will be expressly indicated to you at the point and time
            of collection.
          </li>
          <li>
            <strong>Other Processing Grounds:</strong> From time to time we may also need to process
            Personal Data to comply with a legal obligation, if it is necessary to protect the vital
            interests of you or other data subjects, or if it is necessary for a task carried out in
            the public interest.
          </li>
        </ul>

        <h3>EU, UK and Swiss Data Subject Rights</h3>
        <p>
          You have certain rights with respect to your Personal Data, including those set forth
          below. For more information about these rights, or to submit a request, please email us at
          info@askvera.io. Please note that in some circumstances, we may not be able to fully
          comply with your request, such as if it is frivolous or extremely impractical, if it
          jeopardizes the rights of others, or if it is not required by law, but in those
          circumstances, we will still respond to notify you of such a decision. In some cases, we
          may also need you to provide us with additional information, which may include Personal
          Data, if necessary to verify your identity and the nature of your request.
        </p>

        <ul>
          <li>
            <strong>Access:</strong> You can request more information about the Personal Data we
            hold about you and request a copy of such Personal Data. You can also access certain of
            your Personal Data by logging on to your account.
          </li>
          <li>
            <strong>Rectification:</strong> If you believe that any Personal Data we are holding
            about you is incorrect or incomplete, you can request that we correct or supplement such
            data. You can also correct some of this information directly by logging on to your
            account.
          </li>
          <li>
            <strong>Erasure:</strong> You can request that we erase some or all of your Personal
            Data from our systems.
          </li>
          <li>
            <strong>Withdrawal of Consent:</strong> If we are processing your Personal Data based on
            your consent (as indicated at the time of collection of such data), you have the right
            to withdraw your consent at any time. Please note, however, that if you exercise this
            right, you may have to then provide express consent on a case-by-case basis for the use
            or disclosure of certain of your Personal Data, if such use or disclosure is necessary
            to enable you to utilize some or all of our Services.
          </li>
          <li>
            <strong>Portability:</strong> You can ask for a copy of your Personal Data in a
            machine-readable format. You can also request that we transmit the data to another
            controller where technically feasible.
          </li>
          <li>
            <strong>Objection:</strong> You can contact us to let us know that you object to the
            further use or disclosure of your Personal Data for certain purposes, such as for direct
            marketing purposes.
          </li>
          <li>
            <strong>Restriction of Processing:</strong> You can ask us to restrict further
            processing of your Personal Data.
          </li>
          <li>
            <strong>Right to File Complaint:</strong> You have the right to lodge a complaint about
            Vera AI's practices with respect to your Personal Data with the supervisory authority of
            your country or EU Member State. A list of Supervisory Authorities is available here:{' '}
            <a
              href="https://edpb.europa.eu/about-edpb/board/members_en"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://edpb.europa.eu/about-edpb/board/members_en
            </a>
            .
          </li>
        </ul>

        <h3>Transfers of Personal Data</h3>
        <p>
          The Services are hosted and operated in the United States ("U.S.") through Vera AI and its
          service providers, and if you do not reside in the U.S., laws in the U.S. may differ from
          the laws where you reside. By using the Services, you acknowledge that any Personal Data
          about you, regardless of whether provided by you or obtained from a third party, is being
          provided to Vera AI in the U.S. and will be hosted on U.S. servers, and you authorize Vera
          AI to transfer, store and process your information to and in the U.S., and possibly other
          countries. In some circumstances, your Personal Data may be transferred to the U.S.
        </p>

        <h2 id="contact-info">Contact Information:</h2>
        <p>
          If you have any questions or comments about this Privacy Policy, the ways in which we
          collect and use your Personal Data or your choices and rights regarding such collection
          and use, please do not hesitate to contact us at:
        </p>

        <ul>
          <li>www.askvera.io</li>
          <li>info@askvera.io</li>
          <li>29 Little West 12th St. NY, NY 10014</li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
