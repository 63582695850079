import type { TDialogProps } from '~/common';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '~/components/ui';
import { ThemeContext, useMediaQuery } from '~/hooks';
import { cn } from '~/utils';
import VeraColorLogo from '~/components/svg/VeraColorLogo';
import StripeCheckout from '~/components/Registration/StripeCheckout';
import { useAuth } from '~/Providers/useAuth';
import { useContext, useEffect, useState } from 'react';
import VeraFullLogo from '~/components/svg/VeraFullLogo';
import VeraIconLogo from '~/components/svg/VeraIconLogo';
import { VERA_NAVY, VERA_YELLOW } from '~/utils/constants';

export default function StripeModal({ open, onOpenChange }: TDialogProps) {
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const { theme } = useContext(ThemeContext);

  let isDark = theme === 'dark';
  const { getAccessToken, user, isLoading } = useAuth();
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const token = await getAccessToken();
        setAccessToken(token!);
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };

    if (!!getAccessToken) {
      fetchAccessToken();
    }
  }, [getAccessToken, isLoading]);

  const handleSubmission = async () => {
    onOpenChange(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onOpenChange={(open) => {
          onOpenChange(open);
        }}
      >
        <DialogContent
          className={cn(
            'shadow-2xl dark:bg-vnavy-dark-card dark:text-white md:min-h-[373px] md:w-[680px]',
          )}
          style={{
            borderRadius: '12px',
            position: 'fixed',
            margin: 'auto',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <DialogHeader>
            <DialogTitle className=" flex items-center text-lg font-medium leading-6 text-gray-900 dark:text-gray-200">
              <div className=" h-min-content mr-6 w-24">
                <VeraFullLogo color={isDark ? VERA_YELLOW : VERA_NAVY} />
              </div>
              Unlock Full Access Today!
            </DialogTitle>
          </DialogHeader>
          <div className="my-4 flex flex-col items-center justify-center px-6 text-center">
            <label
              className={`'text-gray-400 dark:text-gray-300' mb-8 text-lg font-medium font-normal  transition-colors dark:bg-transparent
            `}
            >
              Subscribe now to enjoy full access to all our features!
            </label>

            <StripeCheckout orgId={user?.org_id!} accessToken={accessToken!} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
