import { useState, useEffect } from 'react';
import { Checkbox, CheckboxGroup, VStack, Text } from '@chakra-ui/react';
import { VERA_NAVY, VERA_WHITE } from '~/utils/constants';

const PasswordRequirements = ({
  password,
  isSubmitError,
}: {
  password: string;
  isSubmitError: boolean;
}) => {
  const [requirements, setRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    numberOrSpecialChar: false,
  });

  useEffect(() => {
    checkRequirements(password);
  }, [password]);

  const checkRequirements = (value: string) => {
    const hasLength = value.length >= 8;
    const hasUppercase = /[A-Z]/.test(value);
    const hasLowercase = /[a-z]/.test(value);
    const hasNumberOrSpecialChar = /[0-9]/.test(value) || /[!@#$%^&*(),.?":{}|<>]/.test(value);

    setRequirements({
      length: hasLength,
      uppercase: hasUppercase,
      lowercase: hasLowercase,
      numberOrSpecialChar: hasNumberOrSpecialChar,
    });
  };

  return (
    <VStack spacing={4} color={VERA_WHITE}>
      <CheckboxGroup colorScheme="green" defaultValue={[]} size={'sm'}>
        <VStack
          align="start"
          spacing={2}
          border="2px solid"
          borderColor={'#3B3465'}
          p={3}
          borderRadius={8}
        >
          <Text fontSize={'sm'} fontWeight={'bold'}>
            Password Requirements:
          </Text>
          <Checkbox
            iconColor={'#197F37'}
            tabIndex={-1}
            cursor={'default'}
            isChecked={requirements.length}
            isInvalid={isSubmitError && !requirements.length}
            sx={{
              '& .chakra-checkbox__control': { borderRadius: '48%' },
            }}
          >
            Minimum 8 characters
          </Checkbox>
          <Checkbox
            iconColor={'#197F37'}
            tabIndex={-1}
            cursor={'default'}
            isChecked={requirements.uppercase}
            isInvalid={isSubmitError && !requirements.uppercase}
            sx={{
              '& .chakra-checkbox__control': { borderRadius: '48%' },
            }}
          >
            At least one uppercase letter
          </Checkbox>
          <Checkbox
            iconColor={'#197F37'}
            tabIndex={-1}
            cursor={'default'}
            isChecked={requirements.lowercase}
            isInvalid={isSubmitError && !requirements.lowercase}
            sx={{
              '& .chakra-checkbox__control': { borderRadius: '48%' },
            }}
          >
            At least one lowercase letter
          </Checkbox>
          <Checkbox
            iconColor={'#197F37'}
            tabIndex={-1}
            cursor={'default'}
            isChecked={requirements.numberOrSpecialChar}
            isInvalid={isSubmitError && !requirements.numberOrSpecialChar}
            sx={{
              '& .chakra-checkbox__control': { borderRadius: '48%' },
            }}
          >
            At least one number or special character
          </Checkbox>
        </VStack>
      </CheckboxGroup>
    </VStack>
  );
};

export default PasswordRequirements;
