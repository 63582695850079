// Arabic phrases

export default {
  com_ui_balance: 'الرصيد',
  com_ui_examples: 'أمثلة',
  com_ui_new_chat: 'دردشة جديدة',
  com_ui_example_quantum_computing: 'شرح الحوسبة الكمومية ببساطة',
  com_ui_example_10_year_old_b_day: 'هل لديك أفكار إبداعية لعيد ميلاد طفل عمره 10 سنوات؟',
  com_ui_example_http_in_js: 'كيف أقوم بإجراء طلب HTTP في Javascript؟',
  com_ui_capabilities: 'القدرات',
  com_ui_capability_remember: 'يتذكر ما قاله المستخدم في وقت سابق من المحادثة',
  com_ui_capability_correction: 'يسمح للمستخدم بتقديم تصحيحات لاحقة',
  com_ui_capability_decline_requests: 'تم تدريبه على رفض الطلبات غير المناسبة',
  com_ui_limitations: 'القيود',
  com_ui_limitation_incorrect_info: 'قد يولد معلومات غير صحيحة أحيانًا',
  com_ui_limitation_harmful_biased: 'قد ينتج تعليمات ضارة أو محتوى متحيز أحيانًا',
  com_ui_limitation_limited_2021: 'معرفة محدودة بالعالم والأحداث بعد عام 2021',
  com_ui_input: 'إدخال',
  com_ui_close: 'إغلاق',
  com_ui_model: 'النموذج',
  com_ui_select_model: 'اختر نموذجًا',
  com_ui_use_prompt: 'استخدم الأمر',
  com_ui_prev: 'السابق',
  com_ui_next: 'التالي',
  com_ui_prompt_templates: 'قوالب الأوامر',
  com_ui_hide_prompt_templates: 'إخفاء قوالب الأوامر',
  com_ui_showing: 'عرض',
  com_ui_of: 'من',
  com_ui_entries: 'الإدخالات',
  com_ui_pay_per_call:
    'الذكاء الاصطناعي يمكن ان ينتج اخطاء الرجاء التاكد بنفسك من المعلومات المهمه.',
  com_ui_enter: 'أدخل',
  com_ui_submit: 'إرسال',
  com_ui_upload_success: 'تم تحميل الملف بنجاح',
  com_ui_upload_invalid: 'ملف غير صالح للتحميل',
  com_ui_cancel: 'إلغاء',
  com_ui_save: 'حفظ',
  com_ui_copy_to_clipboard: 'نسخ إلى الحافظة',
  com_ui_copied_to_clipboard: 'تم النسخ إلى الحافظة',
  com_ui_regenerate: 'إعادة توليد',
  com_ui_continue: 'استمر',
  com_ui_edit: 'تعديل',
  com_ui_success: 'نجاح',
  com_ui_all: 'الكل',
  com_ui_clear: 'مسح',
  com_ui_revoke: 'إلغاء',
  com_ui_revoke_info: 'إلغاء جميع بيانات الاعتماد المقدمة من المستخدم.',
  com_ui_confirm_action: 'تأكيد الإجراء',
  com_ui_chats: 'الدردشات',
  com_ui_delete: 'حذف',
  com_ui_delete_conversation: 'حذف الدردشة؟',
  com_ui_delete_conversation_confirm: 'سيتم حذف هذا',
  com_auth_error_login:
    'تعذر تسجيل الدخول باستخدام المعلومات المقدمة. يرجى التحقق من بيانات الاعتماد الخاصة بك والمحاولة مرة أخرى.',
  com_auth_error_login_rl:
    'محاولات تسجيل الدخول الكثيرة في فترة زمنية قصيرة. يرجى المحاولة مرة أخرى لاحقًا.',
  com_auth_error_login_ban: 'تم حظر حسابك مؤقتًا بسبب انتهاكات لخدمتنا.',
  com_auth_error_login_server:
    'كان هناك خطأ في الخادم الداخلي. يرجى الانتظار بضع لحظات وحاول مرة أخرى.',
  com_auth_no_account: 'ليس لديك حساب؟',
  com_auth_sign_up: 'سجل الان',
  com_auth_sign_in: 'تسجيل الدخول',
  com_auth_google_login: 'تسجيل الدخول بواسطة Google',
  com_auth_facebook_login: 'تسجيل الدخول بواسطة Facebook',
  com_auth_github_login: 'تسجيل الدخول بواسطة Github',
  com_auth_discord_login: 'تسجيل الدخول بواسطة Discord',
  com_auth_email: 'البريد الإلكتروني',
  com_auth_email_required: 'البريد الإلكتروني مطلوب',
  com_auth_email_min_length: 'يجب أن يكون البريد الإلكتروني على الأقل 6 أحرف',
  com_auth_email_max_length: 'يجب ألا يزيد البريد الإلكتروني عن 120 حرفًا',
  com_auth_email_pattern: 'يجب أن تدخل عنوان بريد إلكتروني صالح',
  com_auth_email_address: 'عنوان البريد الإلكتروني',
  com_auth_password: 'كلمة المرور',
  com_auth_password_required: 'كلمة المرور مطلوبة',
  com_auth_password_min_length: 'يجب أن تكون كلمة المرور على الأقل 8 أحرف',
  com_auth_password_max_length: 'يجب أن تكون كلمة المرور أقل من 128 حرفًا',
  com_auth_password_forgot: 'نسيت كلمة المرور؟',
  com_auth_password_confirm: 'تأكيد كلمة المرور',
  com_auth_password_not_match: 'كلمات المرور لا تتطابق',
  com_auth_continue: 'استمر',
  com_auth_create_account: 'أنشئ حسابك',
  com_auth_error_create: 'كان هناك خطأ في محاولة تسجيل حسابك. يرجى المحاولة مرة أخرى.',
  com_auth_full_name: 'الاسم الكامل',
  com_auth_name_required: 'الاسم مطلوب',
  com_auth_name_min_length: 'يجب أن يكون الاسم على الأقل 3 أحرف',
  com_auth_name_max_length: 'يجب أن يكون الاسم أقل من 80 حرفًا',
  com_auth_username: 'اسم المستخدم (اختياري)',
  com_auth_username_required: 'اسم المستخدم مطلوب',
  com_auth_username_min_length: 'يجب أن يكون اسم المستخدم على الأقل 2 أحرف',
  com_auth_username_max_length: 'يجب أن يكون اسم المستخدم أقل من 20 حرفًا',
  com_auth_already_have_account: 'هل لديك حساب بالفعل؟',
  com_auth_login: 'تسجيل الدخول',
  com_auth_reset_password: 'إعادة تعيين كلمة المرور',
  com_auth_click: 'انقر',
  com_auth_here: 'هنا',
  com_auth_to_reset_your_password: 'لإعادة تعيين كلمة المرور الخاصة بك.',
  com_auth_reset_password_link_sent: 'تم إرسال البريد الإلكتروني',
  com_auth_reset_password_email_sent:
    'تم إرسال بريد إلكتروني إليك مع مزيد من التعليمات لإعادة تعيين كلمة المرور الخاصة بك.',
  com_auth_error_reset_password:
    'كانت هناك مشكلة في إعادة تعيين كلمة المرور الخاصة بك. لم يتم العثور على مستخدم بالعنوان البريدي الذي تم تقديمه. يرجى المحاولة مرة أخرى.',
  com_auth_reset_password_success: 'نجاح إعادة تعيين كلمة المرور',
  com_auth_login_with_new_password:
    'يمكنك الآن تسجيل الدخول باستخدام كلمة المرور الجديدة الخاصة بك.',
  com_auth_error_invalid_reset_token: 'رمز إعادة تعيين كلمة المرور هذا لم يعد صالحًا.',
  com_auth_click_here: 'انقر هنا',
  com_auth_to_try_again: 'للمحاولة مرة أخرى.',
  com_auth_submit_registration: 'إرسال التسجيل',
  com_auth_welcome_back: 'مرحبا بك مرة أخرى',
  com_endpoint_open_menu: 'افتح القائمة',
  com_endpoint_bing_enable_sydney: 'تمكين سيدني',
  com_endpoint_bing_to_enable_sydney: 'لتمكين سيدني',
  com_endpoint_bing_jailbreak: 'الهروب من السجن',
  com_endpoint_bing_context_placeholder:
    'يمكن أن يستخدم بينغ ما يصل إلى 7 آلاف رمز لـ \'السياق\'، والذي يمكنه الرجوع إليه للمحادثة. الحد الخاص ليس معروفًا ولكن قد يواجه أخطاء تجاوز 7 آلاف رمز',
  com_endpoint_bing_system_message_placeholder:
    'تحذير: يمكن أن يؤدي سوء استخدام هذه الميزة إلى حظرك من استخدام Bing! انقر على \'رسالة النظام\' للحصول على التعليمات الكاملة والرسالة الافتراضية إذا تم حذفها، والتي هي الإعداد المسبق \'سيدني\' الذي يعتبر آمنًا.',
  com_endpoint_system_message: 'رسالة النظام',
  com_endpoint_default_blank: 'الافتراضي: فارغ',
  com_endpoint_default_false: 'الافتراضي: خاطئ',
  com_endpoint_default_creative: 'الافتراضي: إبداعي',
  com_endpoint_default_empty: 'الافتراضي: فارغ',
  com_endpoint_default_with_num: 'الافتراضي: {0}',
  com_endpoint_context: 'السياق',
  com_endpoint_tone_style: 'نمط النبرة',
  com_endpoint_token_count: 'عدد الرموز',
  com_endpoint_output: 'الإخراج',
  com_endpoint_google_temp:
    'القيم الأعلى = أكثر عشوائية، بينما القيم الأقل = أكثر تركيزًا وحتمية. نوصي بتغيير هذا أو Top P ولكن ليس كلاهما.',
  com_endpoint_google_topp:
    'Top-p يغير كيفية اختيار النموذج للرموز للإخراج. يتم اختيار الرموز من الأكثر K (انظر معلمة topK) احتمالًا إلى   الأقل حتى يصبح مجموع احتمالاتهم يساوي قيمة top-p.',
  com_endpoint_google_topk:
    'Top-k يغير كيفية اختيار النموذج للرموز للإخراج. top-k من 1 يعني أن الرمز المحدد هو الأكثر احتمالية بين جميع   الرموز في مفردات النموذج (يسمى أيضًا الترميز الجشعي)، بينما top-k من 3 يعني أن الرمز التالي يتم اختياره من بين الرموز الثلاثة الأكثر احتمالية (باستخدام الحرارة).',
  com_endpoint_google_maxoutputtokens:
    'الحد الأقصى لعدد الرموز التي يمكن إنشاؤها في الرد. حدد قيمة أقل للردود الأقصر وقيمة أعلى للردود الأطول.',
  com_endpoint_google_custom_name_placeholder: 'قم بتعيين اسم مخصص لـ Google',
  com_endpoint_prompt_prefix_placeholder:
    'قم بتعيين تعليمات مخصصة أو سياق. يتم تجاهله إذا كان فارغًا.',
  com_endpoint_custom_name: 'اسم مخصص',
  com_endpoint_prompt_prefix: 'بادئة الأمر',
  com_endpoint_temperature: 'درجة الحرارة',
  com_endpoint_default: 'الافتراضي',
  com_endpoint_top_p: 'أعلى P',
  com_endpoint_top_k: 'أعلى K',
  com_endpoint_max_output_tokens: 'الحد الأقصى لعدد الرموز المنتجة',
  com_endpoint_openai_temp:
    'القيم الأعلى = أكثر عشوائية ، بينما القيم الأقل = أكثر تركيزًا وتحديدًا. نوصي بتغيير هذا أو Top P ولكن ليس كلاهما.',
  com_endpoint_openai_max:
    'الحد الأقصى للرموز لتوليد. إجمالي طول الرموز المدخلة والرموز المولدة محدود بطول سياق النموذج.',
  com_endpoint_openai_topp:
    'بديل للعينة مع درجة الحرارة، يسمى العينة النووية، حيث ينظر النموذج في نتائج الرموز مع كتلة احتمال top_p. لذا 0.1 يعني أن الرموز التي تشكل فقط 10% من كتلة الاحتمال تعتبر. نوصي بتغيير هذا أو درجة الحرارة ولكن ليس كلاهما.',
  com_endpoint_openai_freq:
    'رقم بين -2.0 و 2.0. القيم الموجبة تعاقب الرموز الجديدة بناءً على تكرارها الحالي في النص حتى الآن، مما يقلل من احتمالية تكرار النموذج لنفس السطر حرفيًا.',
  com_endpoint_openai_pres:
    'رقم بين -2.0 و 2.0. القيم الموجبة تعاقب الرموز الجديدة بناءً على ما إذا كانت تظهر في النص حتى الآن، مما يزيد احتمالية النموذج للحديث عن مواضيع جديدة.',
  com_endpoint_openai_custom_name_placeholder: 'قم بتعيين اسم مخصص لـ ChatGPT',
  com_endpoint_openai_prompt_prefix_placeholder:
    'قم بتعيين تعليمات مخصصة لتضمينها في رسالة النظام. الافتراضي: لا شيء',
  com_endpoint_anthropic_temp:
    'المدى من 0 إلى 1. استخدم درجة الحرارة الأقرب إلى 0 للمهام التحليلية / اختيارية متعددة، وأقرب إلى 1 للمهام الإبداعية والإنشائية. نوصي بتغيير هذا أو Top P ولكن ليس كلاهما.',
  com_endpoint_anthropic_topp:
    'Top-p يغير الطريقة التي يختار فيها النموذج الرموز للإخراج. يتم اختيار الرموز من الأكثر احتمالية k (انظر المعلمة topK) إلى الأقل حتى يساوي مجموع احتمالاتهم قيمة top-p.',
  com_endpoint_anthropic_topk:
    'يغير top-k الطريقة التي يختار فيها النموذج الرموز للإخراج. يعني top-k 1 أن الرمز المحدد هو الأكثر احتمالية من بين جميع الرموز في المفردات النموذجية (يسمى أيضا الترميز الطمع)، بينما يعني top-k من 3 أن الرمز القادم محدد من بين الرموز الثلاثة الأكثر احتمالية (باستخدام درجة الحرارة).',
  com_endpoint_anthropic_maxoutputtokens:
    'العدد الأقصى من الرموز التي يمكن إنشاؤها في الرد. حدد قيمة أقل للحصول على ردود أقصر وقيمة أعلى للحصول على ردود أطول.',
  com_endpoint_anthropic_custom_name_placeholder: 'قم بتعيين اسم مخصص لـ Anthropic',
  com_endpoint_frequency_penalty: 'عقوبة التكرار',
  com_endpoint_presence_penalty: 'عقوبة الوجود',
  com_endpoint_plug_use_functions: 'استخدام الوظائف',
  com_endpoint_plug_skip_completion: 'تجاوز الإكمال',
  com_endpoint_disabled_with_tools: 'معطل بواسطة الأدوات',
  com_endpoint_disabled_with_tools_placeholder: 'تم التعطيل باختيار الأدوات',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    'قم بتعيين تعليمات مخصصة لتضمينها في رسالة النظام. الافتراضي: لا شيء',
  com_endpoint_import: 'استيراد',
  com_endpoint_set_custom_name: 'قم بتعيين اسم مخصص، في حالة إمكانية العثور على هذا الإعداد المسبق',
  com_endpoint_preset: 'إعداد مسبق',
  com_endpoint_presets: 'إعدادات مسبقة',
  com_endpoint_preset_name: 'اسم الإعداد المسبق',
  com_endpoint_new_topic: 'موضوع جديد',
  com_endpoint: 'نقطة النهاية',
  com_endpoint_hide: 'إخفاء',
  com_endpoint_show: 'إظهار',
  com_endpoint_examples: 'الإعدادات المسبقة ',
  com_endpoint_completion: 'إكمال',
  com_endpoint_agent: 'الوكيل',
  com_endpoint_show_what_settings: 'إظهار {0} الإعدادات',
  com_endpoint_save: 'حفظ',
  com_endpoint_export: 'تصدير',
  com_endpoint_save_as_preset: 'حفظ كإعداد مسبق',
  com_endpoint_presets_clear_warning:
    'هل أنت متأكد أنك تريد مسح جميع الإعدادات المسبقة؟ هذا لا يمكن التراجع عنه.',
  com_endpoint_not_implemented: 'لم يتم تنفيذه',
  com_endpoint_no_presets: 'لا يوجد إعداد مسبق بعد',
  com_endpoint_not_available: 'لا توجد نقطة نهاية متاحة',
  com_endpoint_view_options: 'عرض الخيارات',
  com_endpoint_save_convo_as_preset: 'حفظ المحادثة كإعداد مسبق',
  com_endpoint_my_preset: 'الإعداد المسبق الخاص بي',
  com_endpoint_agent_model: 'نموذج الوكيل (موصى به: GPT-3.5)',
  com_endpoint_completion_model: 'نموذج الإكمال (موصى به: GPT-4)',
  com_endpoint_func_hover: 'تمكين استخدام الإضافات كوظائف OpenAI',
  com_endpoint_skip_hover:
    'تمكين تجاوز خطوة الإكمال التي تقوم بمراجعة الإجابة النهائية والخطوات المولدة',
  com_endpoint_config_key: 'تعيين مفتاح API',
  com_endpoint_config_key_for: 'ضع API Key لـ',
  com_endpoint_config_key_name: 'مفتـاح',
  com_endpoint_config_value: 'أدخل القيمة لـ',
  com_endpoint_config_key_name_placeholder: 'قم بتعيين مفتاح API أولاً',
  com_endpoint_config_key_encryption: 'سيتم تشفير مفتاحك وحذفه في',
  com_endpoint_config_key_expiry: 'وقت الانتهاء',
  com_endpoint_config_key_import_json_key: 'استيراد مفتاح حساب الخدمة JSON.',
  com_endpoint_config_key_import_json_key_success: 'تم استيراد مفتاح حساب الخدمة JSON بنجاح',
  com_endpoint_config_key_import_json_key_invalid:
    'مفتاح حساب الخدمة JSON غير صالح، هل قمت باستيراد الملف الصحيح؟',
  com_endpoint_config_key_get_edge_key:
    'للحصول على رمز الوصول الخاص بك لـ Bing، قم بتسجيل الدخول إلى',
  com_endpoint_config_key_get_edge_key_dev_tool:
    'استخدم أدوات المطور أو ملحق أثناء تسجيل الدخول إلى الموقع لنسخ محتوى ألـ_U cookie. إذا فشل هذا، اتبع هذه',
  com_endpoint_config_key_edge_instructions: 'التعليمات',
  com_endpoint_config_key_edge_full_key_string: ' لتقديم سلاسل كاملة للكوكيز.',
  com_endpoint_config_key_chatgpt:
    'للحصول على رمز الوصول الخاص بـ ChatGPT إصدار مجاني، قم بتسجيل الدخول إلى',
  com_endpoint_config_key_chatgpt_then_visit: 'ثم قم بزيارة',
  com_endpoint_config_key_chatgpt_copy_token: 'نسخ رمز الوصول.',
  com_endpoint_config_key_google_need_to: 'أنت بحاجة إلى',
  com_endpoint_config_key_google_vertex_ai: 'تمكين Vertex AI',
  com_endpoint_config_key_google_vertex_api: 'API على Google Cloud، ثم',
  com_endpoint_config_key_google_service_account: 'أنشئ حساب خدمة',
  com_endpoint_config_key_google_vertex_api_role:
    'تأكد من النقر على إنشاء ومتابعة" لمنح الدور "Vertex AI User" على الأقل. أخيرًا، قم بإنشاء مفتاح JSON للعمل على استيراده هنا.',
  com_nav_auto_scroll: 'التمرير التلقائي إلى أحدث عند الفتح',
  com_nav_plugin_store: 'متجر الإضافات',
  com_nav_plugin_search: 'ابحث عن الإضافات',
  com_nav_plugin_auth_error:
    'حدث خطأ أثناء محاولة المصادقة على هذا البرنامج المساعد. يرجى المحاولة مرة أخرى.',
  com_nav_export_filename: 'اسم الملف',
  com_nav_export_filename_placeholder: 'قم بتعيين اسم الملف',
  com_nav_export_type: 'النوع',
  com_nav_export_include_endpoint_options: 'تضمين خيارات النقاط النهائية',
  com_nav_enabled: 'تم التمكين',
  com_nav_not_supported: 'غير مدعوم',
  com_nav_export_all_message_branches: 'تصدير كل فروع الرسائل',
  com_nav_export_recursive_or_sequential: 'التراجع أو التسلسل؟',
  com_nav_export_recursive: 'تكراري',
  com_nav_export_conversation: 'تصدير المحادثة',
  com_nav_theme: 'المظهر',
  com_nav_theme_system: 'النظام',
  com_nav_theme_dark: 'داكن',
  com_nav_theme_light: 'فاتح',
  com_nav_clear_all_chats: 'مسح كل الدردشات',
  com_nav_confirm_clear: 'تأكيد المسح',
  com_nav_close_sidebar: 'إغلاق القائمة الجانبية',
  com_nav_open_sidebar: 'افتح القائمة الجانبية',
  com_nav_log_out: 'تسجيل الخروج',
  com_nav_user: 'المستخدم',
  com_nav_clear_conversation: 'مسح المحادثات',
  com_nav_clear_conversation_confirm_message:
    'هل أنت متأكد أنك تريد مسح جميع المحادثات؟ هذا لا يمكن التراجع عنه.',
  com_nav_help_faq: 'مساعدة & الأسئلة الشائعة',
  com_nav_settings: 'الإعدادات',
  com_nav_search_placeholder: 'بحث في الرسائل',
  com_nav_setting_general: 'عام',
  com_nav_setting_data: 'تحكم في البيانات',
};
