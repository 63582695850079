import React from 'react';

function VeraFavIcon({ bgColor = '#1c1444', color = '#fce61a' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 16 16">
      <g>
        <g id="Artwork">
          <rect style={{ fill: bgColor }} width="16" height="16" />
          <path
            style={{ fill: color }}
            d="M12.18,4l-3.29,8h-4.65V4h2.92l-.61,5.64c-.05.42.56.53.73.08l2.14-5.73h2.75Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default VeraFavIcon;
