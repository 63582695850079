import { Flex, Image, Box } from '@chakra-ui/react';
import React from 'react';
import { useMediaQuery } from '~/hooks';
import { VERA_NAVY } from '~/utils/constants';

function Background({ children }: any) {
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const svgBackground = {
    background: VERA_NAVY,
    backgroundImage: `url(assets/Background.svg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100vh',
  };

  return (
    <Flex
      flexDir={'column'}
      justifyContent={'center'}
      minH={'100vh'}
      position={'relative'}
      style={svgBackground}
      w={'100%'}
    >
      <>
        <Box zIndex={2}>{children}</Box>
        {/* <Image
          position={'absolute'}
          alignSelf={'flex-end'}
          src={'assets/vera-logo-color.svg'}
          width="auto"
          height={10}
          bottom={5}
          right={5}
          zIndex={1}
        /> */}
      </>
    </Flex>
  );
}

export default Background;
