import { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Box,
  Button,
  HStack,
  Textarea,
  CheckboxGroup,
  Checkbox,
  VStack,
  Heading,
  Text,
  Flex,
} from '@chakra-ui/react';
import ReCAPTCHA from 'react-google-recaptcha';
import { VERA_NAVY, VERA_PINK, VERA_TEAL, VERA_WHITE, VERA_YELLOW } from '~/utils/constants';
import { css } from '@emotion/react';
import VeraFullLogo from '../svg/VeraFullLogo';

function getCookieValue(cookieName: string) {
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
}

export default function InfoForm({ onFormSubmitted }: { onFormSubmitted?: Function }) {
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    orgDetails: '',
    launchDate: '',
    orgUse: [],
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (orgUse: any) => {
    setFormData((prevData) => ({
      ...prevData,
      orgUse,
    }));
  };

  const handleCaptchaChange = (value: any) => {
    // This callback will be triggered when the user successfully completes the reCAPTCHA
    // timeout necessary to complete captcha animation
    // setTimeout(() => setIsCaptchaVerified(true), 1100);
    setIsCaptchaVerified(true);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const devMode = localStorage.getItem('devMode');
    if (import.meta.env.VITE_MODE !== 'PROD' || devMode) {
      onFormSubmitted && onFormSubmitted(formData);
    } else {
      const hubspotutkValue = getCookieValue('hubspotutk');
      const response = await fetch('https://ipinfo.io/json');
      const data = await response.json();
      const ip = data.ip;

      const portalId = '20967612';
      const formGuid = '1fb20f99-c564-4eb2-bf6a-eb66a834081c';
      const formBody: any = {
        fields: [
          {
            objectTypeId: '0-1',
            name: 'firstname',
            value: formData.firstName,
          },
          {
            objectTypeId: '0-1',
            name: 'lastname',
            value: formData.lastName,
          },
          {
            objectTypeId: '0-1',
            name: 'email',
            value: formData.email,
          },
          {
            objectTypeId: '0-1',
            name: 'orguse', // Checkbox field
            value: formData.orgUse.join(';'), // Selected checkbox values
          },
          {
            objectTypeId: '0-1',
            name: 'orgdetails', // Text area field
            value: formData.orgDetails, // Text area value
          },
          {
            objectTypeId: '0-1',
            name: 'launchdate', // Date field
            value: formData.launchDate
              ? new Date(formData.launchDate).toISOString().split('T')[0]
              : '', // Date value (YYYY-MM-DD format)
          },
        ],
        context: {
          pageUri: 'www.askvera.io/try-vera',
          pageName: 'Try it Now -- Vera',
          ipAddress: ip,
        },
      };
      if (hubspotutkValue) {
        formBody.context.hutk = hubspotutkValue;
      }
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formBody),
      };

      fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
        requestOptions,
      )
        .then((response) => response.json())
        .then((data) => {
          onFormSubmitted && onFormSubmitted(formData);
        });
    }
  };

  return (
    <>
      <Box
        maxW="md"
        mx="auto"
        my={8}
        p={8}
        borderRadius="lg"
        background={'#2C2552'}
        color={VERA_WHITE}
      >
        <Box justifyContent={'flex-start'} h={8} w="auto" mb={6}>
          <VeraFullLogo color={VERA_YELLOW} />
        </Box>
        <Heading size="lg" textAlign={'center'} mb={4}>
          Try it Now!
        </Heading>
        <Text className="text-center" mb={6} color="#E9EAF6">
          Thank you for your interest in our application! <br /> Access your sandbox by submitting
          the form below.
        </Text>
        <form onSubmit={handleSubmit}>
          <HStack gap={4}>
            <FormControl id="firstName" mb={4} isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                border="unset"
                focusBorderColor={VERA_PINK}
                background={'#3B3465'}
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl id="lastName" mb={4} isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input
                border="unset"
                focusBorderColor={VERA_PINK}
                background={'#3B3465'}
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </FormControl>
          </HStack>

          <FormControl id="email" mb={8} isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              border="unset"
              placeholder="email@example.com"
              focusBorderColor={VERA_PINK}
              background={'#3B3465'}
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="orgUse" mb={4}>
            <FormLabel>How does your organization use Generative AI?</FormLabel>
            <CheckboxGroup
              colorScheme="green"
              onChange={handleCheckboxChange}
              value={formData.orgUse}
            >
              <VStack align={'flex-start'}>
                <Checkbox name="orgUse" value="In our product" iconColor={'#197F37'}>
                  In our product
                </Checkbox>
                <Checkbox name="orgUse" value="For internal productivity" iconColor={'#197F37'}>
                  For internal productivity
                </Checkbox>
                <Checkbox name="orgUse" value="Other" iconColor={'#197F37'}>
                  Other
                </Checkbox>
              </VStack>
            </CheckboxGroup>
          </FormControl>
          <FormControl isRequired id="orgDetails" mb={4}>
            <FormLabel>Tell us about your organization</FormLabel>
            <Textarea
              border="unset"
              focusBorderColor={VERA_PINK}
              background={'#3B3465'}
              name="orgDetails"
              onChange={handleInputChange}
              size="sm"
            />
          </FormControl>
          <FormControl id="launchDate" mb={6} w="50%">
            <FormLabel>Desired launch date</FormLabel>
            <Input
              sx={{ colorScheme: 'dark' }}
              border="unset"
              focusBorderColor={VERA_PINK}
              background={'#3B3465'}
              type="date"
              name="launchDate"
              value={formData.launchDate}
              onChange={handleInputChange}
              // css={css`
              //   ::-webkit-calendar-picker-indicator {
              //     background: url(https://cdn3.iconfinder.com/data/icons/linecons-free-vector-icons-pack/32/calendar-16.png)
              //       center/80% no-repeat;
              //     color: red;
              //   }
              // `}
            />
          </FormControl>
          <Box mb={6}>
            <ReCAPTCHA
              sitekey={import.meta.env.VITE_CAPTCHA_SITE_KEY as string}
              onChange={handleCaptchaChange}
              theme="dark"
            />
          </Box>
          <Flex justifyContent="flex-end">
            <Button
              type="submit"
              color={VERA_NAVY}
              w="full"
              _hover={{ background: VERA_YELLOW, opacity: 0.4 }}
              background={VERA_YELLOW}
              borderRadius={'0.4rem'}
              cursor={'pointer'}
              isDisabled={
                !isCaptchaVerified ||
                !formData.firstName ||
                !formData.lastName ||
                !formData.email ||
                !formData.orgDetails
              }
            >
              Submit
            </Button>
          </Flex>
        </form>
      </Box>
    </>
  );
}
