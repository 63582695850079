import React from 'react';
import { VERA_PURPLE } from '~/utils/constants';

function RagDocIcon({ className = '' }) {
  return (
    <svg
      className={className}
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0192 6.62059L11.4992 1.10059C11.4092 1.01059 11.2873 0.959961 11.1598 0.959961H2.51609C1.59172 0.959961 0.839844 1.70996 0.839844 2.63059V21.0731C0.839844 22.1568 1.72484 23.04 2.81422 23.04H15.1855C16.2748 23.04 17.1598 22.1568 17.1598 21.0731V6.95996C17.1598 6.83246 17.1092 6.71059 17.0192 6.62059ZM5.15984 17.76C4.89547 17.76 4.67984 17.5443 4.67984 17.28C4.67984 17.0156 4.89547 16.8 5.15984 16.8C5.42422 16.8 5.63984 17.0156 5.63984 17.28C5.63984 17.5443 5.42422 17.76 5.15984 17.76ZM5.15984 15.36C4.89547 15.36 4.67984 15.1443 4.67984 14.88C4.67984 14.6156 4.89547 14.4 5.15984 14.4C5.42422 14.4 5.63984 14.6156 5.63984 14.88C5.63984 15.1443 5.42422 15.36 5.15984 15.36ZM5.15984 12.96C4.89547 12.96 4.67984 12.7443 4.67984 12.48C4.67984 12.2156 4.89547 12 5.15984 12C5.42422 12 5.63984 12.2156 5.63984 12.48C5.63984 12.7443 5.42422 12.96 5.15984 12.96ZM12.8398 17.76H7.07984V16.8H12.8398V17.76ZM12.8398 15.36H7.07984V14.4H12.8398V15.36ZM12.8398 12.96H7.07984V12H12.8398V12.96ZM12.2005 6.71996C11.758 6.71996 11.3998 6.36184 11.3998 5.91934V2.35871L15.7611 6.71996H12.2005Z"
        fill={VERA_PURPLE}
      />
    </svg>
  );
}

export default RagDocIcon;
