// Dutch phrases

export default {
  com_ui_examples: 'Voorbeelden',
  com_ui_new_chat: 'Nieuwe chat',
  com_ui_example_quantum_computing: 'Leg quantumcomputing in eenvoudige termen uit',
  com_ui_example_10_year_old_b_day: 'Heb je creatieve ideeën voor de verjaardag van een 10-jarige?',
  com_ui_example_http_in_js: 'Hoe maak ik een HTTP-aanvraag in Javascript?',
  com_ui_capabilities: 'Mogelijkheden',
  com_ui_capability_remember: 'Onthoudt wat de gebruiker eerder in het gesprek heeft gezegd',
  com_ui_capability_correction: 'Staat de gebruiker toe om vervolgcorrigeringen te geven',
  com_ui_capability_decline_requests: 'Getraind om ongepaste verzoeken te weigeren',
  com_ui_limitations: 'Beperkingen',
  com_ui_limitation_incorrect_info: 'Kan af en toe onjuiste informatie genereren',
  com_ui_limitation_harmful_biased:
    'Kan af en toe schadelijke instructies of bevooroordeelde inhoud produceren',
  com_ui_limitation_limited_2021: 'Beperkte kennis van de wereld en gebeurtenissen na 2021',
  com_ui_input: 'Invoer',
  com_ui_close: 'Sluiten',
  com_ui_model: 'Model',
  com_ui_select_model: 'Selecteer een model',
  com_ui_use_prompt: 'Gebruik prompt',
  com_ui_prev: 'Vorige',
  com_ui_next: 'Volgende',
  com_ui_stop: 'Stop',
  com_ui_prompt_templates: 'Prompt-sjablonen',
  com_ui_hide_prompt_templates: 'Prompt-sjablonen verbergen',
  com_ui_showing: 'Tonen',
  com_ui_of: 'van',
  com_ui_entries: 'Items',
  com_ui_pay_per_call: 'Alle AI-gesprekken op één plek. Betaal per gesprek en niet per maand',
  com_ui_new_footer: 'Alle AI-gesprekken op één plek.',
  com_ui_enter: 'Invoeren',
  com_ui_submit: 'Indienen',
  com_ui_upload_success: 'Bestand succesvol geüpload',
  com_ui_upload_invalid: 'Ongeldig bestand voor uploaden',
  com_ui_cancel: 'Annuleren',
  com_ui_save: 'Opslaan',
  com_ui_copy_to_clipboard: 'Kopiëren naar klembord',
  com_ui_copied_to_clipboard: 'Gekopieerd naar klembord',
  com_ui_regenerate: 'Opnieuw genereren',
  com_ui_continue: 'Doorgaan',
  com_ui_edit: 'Bewerken',
  com_ui_success: 'Succes',
  com_ui_all: 'alle',
  com_ui_clear: 'Wissen',
  com_ui_revoke: 'Intrekken',
  com_ui_revoke_info: 'Trek alle door de gebruiker verstrekte referenties in',
  com_ui_confirm_action: 'Bevestig actie',
  com_ui_chats: 'chats',
  com_ui_delete: 'Verwijderen',
  com_ui_delete_conversation: 'Chat verwijderen?',
  com_ui_delete_conversation_confirm: 'Hiermee wordt',
  com_auth_error_login:
    'Kan niet inloggen met de verstrekte informatie. Controleer uw referenties en probeer het opnieuw.',
  com_auth_error_login_rl: 'Te veel inlogpogingen in een korte tijd. Probeer het later nog eens.',
  com_auth_error_login_ban:
    'Uw account is tijdelijk verbannen vanwege schendingen van onze service.',
  com_auth_error_login_server:
    'Er was een interne serverfout. Wacht een paar momenten en probeer het opnieuw.',
  com_auth_no_account: 'Heb je geen account?',
  com_auth_sign_up: 'Aanmelden',
  com_auth_sign_in: 'Inloggen',
  com_auth_google_login: 'Inloggen met Google',
  com_auth_facebook_login: 'Inloggen met Facebook',
  com_auth_github_login: 'Inloggen met Github',
  com_auth_discord_login: 'Inloggen met Discord',
  com_auth_email: 'E-mailadres',
  com_auth_email_required: 'E-mailadres is verplicht',
  com_auth_email_min_length: 'E-mailadres moet minstens 6 tekens bevatten',
  com_auth_email_max_length: 'E-mailadres mag niet langer zijn dan 120 tekens',
  com_auth_email_pattern: 'Je moet een geldig e-mailadres invoeren',
  com_auth_email_address: 'E-mailadres',
  com_auth_password: 'Wachtwoord',
  com_auth_password_required: 'Wachtwoord is verplicht',
  com_auth_password_min_length: 'Wachtwoord moet minstens 8 tekens bevatten',
  com_auth_password_max_length: 'Wachtwoord moet minder dan 128 tekens bevatten',
  com_auth_password_forgot: 'Wachtwoord vergeten?',
  com_auth_password_confirm: 'Bevestig wachtwoord',
  com_auth_password_not_match: 'Wachtwoorden komen niet overeen',
  com_auth_continue: 'Doorgaan',
  com_auth_create_account: 'Maak uw account aan',
  com_auth_error_create:
    'Er is een fout opgetreden bij het registreren van uw account. Probeer het opnieuw.',
  com_auth_full_name: 'Volledige naam',
  com_auth_name_required: 'Naam is verplicht',
  com_auth_name_min_length: 'Naam moet minstens 3 tekens bevatten',
  com_auth_name_max_length: 'Naam mag niet langer zijn dan 80 tekens',
  com_auth_username: 'Gebruikersnaam (optioneel)',
  com_auth_username_required: 'Gebruikersnaam is verplicht',
  com_auth_username_min_length: 'Gebruikersnaam moet minstens 2 tekens bevatten',
  com_auth_username_max_length: 'Gebruikersnaam mag niet langer zijn dan 20 tekens',
  com_auth_already_have_account: 'Heb je al een account?',
  com_auth_login: 'Inloggen',
  com_auth_reset_password: 'Stel uw wachtwoord opnieuw in',
  com_auth_click: 'Klik',
  com_auth_here: 'HIER',
  com_auth_to_reset_your_password: 'om uw wachtwoord opnieuw in te stellen.',
  com_auth_reset_password_link_sent: 'E-mail verzonden',
  com_auth_reset_password_email_sent:
    'Er is een e-mail naar je verzonden met verdere instructies om je wachtwoord opnieuw in te stellen.',
  com_auth_error_reset_password:
    'Er was een probleem met het opnieuw instellen van uw wachtwoord. Er is geen gebruiker gevonden met het opgegeven e-mailadres. Probeer het opnieuw.',
  com_auth_reset_password_success: 'Wachtwoord opnieuw ingesteld',
  com_auth_login_with_new_password: 'Je kunt nu inloggen met je nieuwe wachtwoord.',
  com_auth_error_invalid_reset_token: 'Dit wachtwoord resettoken is niet langer geldig.',
  com_auth_click_here: 'Klik hier',
  com_auth_to_try_again: 'om het opnieuw te proberen.',
  com_auth_submit_registration: 'Registratie indienen',
  com_auth_welcome_back: 'Welkom terug',
  com_endpoint_open_menu: 'Open menu',
  com_endpoint_bing_enable_sydney: 'Schakel Sydney in',
  com_endpoint_bing_to_enable_sydney: 'Om Sydney in te schakelen',
  com_endpoint_bing_jailbreak: 'Jailbreak',
  com_endpoint_bing_context_placeholder:
    'Bing kan maximaal 7k tokens voor \'context\' gebruiken, waarnaar het kan verwijzen voor het gesprek. De specifieke limiet is niet bekend, maar kan fouten opleveren bij meer dan 7k tokens',
  com_endpoint_bing_system_message_placeholder:
    'WAARSCHUWING: Misbruik van deze functie kan ertoe leiden dat je VERBANNEN wordt van het gebruik van Bing! Klik op \'Systeembericht\' voor volledige instructies en het standaardbericht indien weggelaten, wat de \'Sydney\'-voorinstelling is die veilig wordt geacht.',
  com_endpoint_system_message: 'Systeembericht',
  com_endpoint_default_blank: 'standaard: leeg',
  com_endpoint_default_false: 'standaard: onwaar',
  com_endpoint_default_creative: 'standaard: creatief',
  com_endpoint_default_empty: 'standaard: leeg',
  com_endpoint_default_with_num: 'standaard: {0}',
  com_endpoint_context: 'Context',
  com_endpoint_tone_style: 'Toonstijl',
  com_endpoint_token_count: 'Aantal tokens',
  com_endpoint_output: 'Uitvoer',
  com_endpoint_google_temp:
    'Hogere waarden = meer willekeurig, terwijl lagere waarden = meer gericht en deterministisch. We raden aan dit of Top P te wijzigen, maar niet beide.',
  com_endpoint_google_topp:
    'Top-p verandert hoe het model tokens selecteert voor uitvoer. Tokens worden geselecteerd van meest K (zie topK-parameter) waarschijnlijk tot minst waarschijnlijk totdat de som van hun kansen gelijk is aan de top-p-waarde.',
  com_endpoint_google_topk:
    'Top-k verandert hoe het model tokens selecteert voor uitvoer. Een top-k van 1 betekent dat het geselecteerde token het meest waarschijnlijk is van alle tokens in de vocabulaire van het model (ook wel \'greedy decoding\' genoemd), terwijl een top-k van 3 betekent dat het volgende token wordt geselecteerd uit de 3 meest waarschijnlijke tokens (met behulp van temperatuur).',
  com_endpoint_google_maxoutputtokens:
    '	Maximum aantal tokens dat kan worden gegenereerd in de reactie. Geef een lagere waarde op voor kortere reacties en een hogere waarde voor langere reacties.',
  com_endpoint_google_custom_name_placeholder: 'Stel een aangepaste naam in voor Google',
  com_endpoint_prompt_prefix_placeholder:
    'Stel aangepaste instructies of context in. Wordt genegeerd indien leeg.',
  com_endpoint_custom_name: 'Aangepaste naam',
  com_endpoint_prompt_prefix: 'Prompt-voorvoegsel',
  com_endpoint_temperature: 'Temperatuur',
  com_endpoint_default: 'standaard',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top K',
  com_endpoint_max_output_tokens: 'Max. uitvoertokens',
  com_endpoint_openai_temp:
    'Hogere waarden = meer willekeurig, terwijl lagere waarden = meer gericht en deterministisch. We raden aan dit of Top P te wijzigen, maar niet beide.',
  com_endpoint_openai_max:
    'Het max. aantal tokens dat kan worden gegenereerd. De totale lengte van invoer-tokens en gegenereerde tokens is beperkt door de contextlengte van het model.',
  com_endpoint_openai_topp:
    'Een alternatief voor sampling met temperatuur, genaamd nucleus sampling, waarbij het model de resultaten van de tokens met de top_p waarschijnlijkheidsmassa in overweging neemt. Dus 0,1 betekent dat alleen de tokens die de bovenste 10% waarschijnlijkheidsmassa omvatten, in overweging worden genomen. We raden aan dit of temperatuur te wijzigen, maar niet beide.',
  com_endpoint_openai_freq:
    'Getal tussen -2,0 en 2,0. Positieve waarden straffen nieuwe tokens op basis van hun bestaande frequentie in de tekst tot nu toe, waardoor de kans dat het model dezelfde regel letterlijk herhaalt, afneemt.',
  com_endpoint_openai_pres:
    'Getal tussen -2,0 en 2,0. Positieve waarden straffen nieuwe tokens op basis van of ze al voorkomen in de tekst tot nu toe, waardoor de kans dat het model over nieuwe onderwerpen praat toeneemt.',
  com_endpoint_openai_custom_name_placeholder: 'Stel een aangepaste naam in voor ChatGPT',
  com_endpoint_openai_prompt_prefix_placeholder:
    'Stel aangepaste instructies in om op te nemen in Systeembericht. Standaard: geen',
  com_endpoint_anthropic_temp:
    'Varieert van 0 tot 1. Gebruik een lagere temp voor analytische / meerkeuze taken en een hogere temp voor creatieve en generatieve taken. We raden aan dit of Top P te wijzigen, maar niet beide.',
  com_endpoint_anthropic_topp:
    'Top-p verandert hoe het model tokens selecteert voor uitvoer. Tokens worden geselecteerd van meest K (zie topK-parameter) waarschijnlijk tot minst waarschijnlijk totdat de som van hun kansen gelijk is aan de top-p-waarde.',
  com_endpoint_anthropic_topk:
    'Top-k verandert hoe het model tokens selecteert voor uitvoer. Een top-k van 1 betekent dat het geselecteerde token het meest waarschijnlijk is van alle tokens in de vocabulaire van het model (ook wel \'greedy decoding\' genoemd), terwijl een top-k van 3 betekent dat het volgende token wordt geselecteerd uit de 3 meest waarschijnlijke tokens (met behulp van temperatuur).',
  com_endpoint_anthropic_maxoutputtokens:
    'Maximum aantal tokens dat kan worden gegenereerd in de reactie. Geef een lagere waarde op voor kortere reacties en een hogere waarde voor langere reacties.',
  com_endpoint_anthropic_custom_name_placeholder: 'Stel een aangepaste naam in voor Anthropic',
  com_endpoint_frequency_penalty: 'Frequentiestraf',
  com_endpoint_presence_penalty: 'Aanwezigheidsstraf',
  com_endpoint_plug_use_functions: 'Gebruik functies inschakelen',
  com_endpoint_plug_skip_completion: 'Voltooiing overslaan',
  com_endpoint_disabled_with_tools: 'uitgeschakeld met tools',
  com_endpoint_disabled_with_tools_placeholder: 'Uitgeschakeld met geselecteerde tools',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    'Stel aangepaste instructies in om op te nemen in Systeembericht. Standaard: geen',
  com_endpoint_import: 'Importeren',
  com_endpoint_set_custom_name:
    'Stel een aangepaste naam in, voor het geval je deze voorinstelling kunt vinden',
  com_endpoint_preset: 'voorinstelling',
  com_endpoint_presets: 'voorinstellingen',
  com_endpoint_preset_selected: 'Voorinstelling actief!',
  com_endpoint_preset_name: 'Naam voorinstelling',
  com_endpoint_new_topic: 'Nieuw onderwerp',
  com_endpoint: 'Eindpunt',
  com_endpoint_hide: 'Verbergen',
  com_endpoint_show: 'Weergeven',
  com_endpoint_examples: ' Voorinstellingen',
  com_endpoint_completion: 'Voltooiing',
  com_endpoint_agent: 'Agent',
  com_endpoint_show_what_settings: '{0} instellingen weergeven',
  com_endpoint_save: 'Opslaan',
  com_endpoint_export: 'Exporteren',
  com_endpoint_save_as_preset: 'Opslaan als voorinstelling',
  com_endpoint_presets_clear_warning:
    'Weet u zeker dat u alle voorinstellingen wilt wissen? Dit is onomkeerbaar.',
  com_endpoint_not_implemented: 'Niet geïmplementeerd',
  com_endpoint_no_presets:
    'Nog geen voorinstellingen, gebruik de instellingenknop om er een te maken',
  com_endpoint_not_available: 'Geen eindpunt beschikbaar',
  com_endpoint_view_options: 'Weergaveopties',
  com_endpoint_save_convo_as_preset: 'Conversatie opslaan als voorinstelling',
  com_endpoint_my_preset: 'Mijn voorinstelling',
  com_endpoint_agent_model: 'Agentmodel (Aanbevolen: GPT-3,5)',
  com_endpoint_completion_model: 'Voltooiingsmodel (Aanbevolen: GPT-4)',
  com_endpoint_func_hover: 'Schakel het gebruik van plug-ins als OpenAI-functies in',
  com_endpoint_skip_hover:
    'Schakel het overslaan van de voltooiingsstap in, die het definitieve antwoord en gegenereerde stappen beoordeelt',
  com_endpoint_config_key: 'API-sleutel instellen',
  com_endpoint_config_key_for: 'API-sleutel instellen voor',
  com_endpoint_config_key_name: 'Sleutel',
  com_endpoint_config_value: 'Voer waarde in voor',
  com_endpoint_config_key_name_placeholder: 'Stel eerst API-sleutel in',
  com_endpoint_config_key_encryption: 'Uw sleutel wordt versleuteld en verwijderd op',
  com_endpoint_config_key_expiry: 'het vervaltijdstip',
  com_endpoint_config_key_import_json_key: 'Serviceaccount-JSON-sleutel importeren.',
  com_endpoint_config_key_import_json_key_success:
    'Serviceaccount-JSON-sleutel succesvol geïmporteerd',
  com_endpoint_config_key_import_json_key_invalid:
    'Ongeldige Serviceaccount-JSON-sleutel, heb je het juiste bestand geïmporteerd?',
  com_endpoint_config_key_get_edge_key: 'Om uw toegangstoken voor Bing te krijgen, logt u in op',
  com_endpoint_config_key_get_edge_key_dev_tool:
    'Gebruik ontwikkelhulpmiddelen of een extensie terwijl u bent ingelogd op de site om de inhoud van de _U-cookie te kopiëren. Als dit mislukt, volg dan deze',
  com_endpoint_config_key_edge_instructions: 'instructies',
  com_endpoint_config_key_edge_full_key_string:
    'om de volledige cookie-tekenreeksen te verstrekken.',
  com_endpoint_config_key_chatgpt:
    'Om uw toegangstoken voor ChatGPT \'Gratis versie\' te krijgen, logt u in op',
  com_endpoint_config_key_chatgpt_then_visit: 'ga vervolgens naar',
  com_endpoint_config_key_chatgpt_copy_token: 'Kopieer toegangstoken.',
  com_endpoint_config_key_google_need_to: 'U moet',
  com_endpoint_config_key_google_vertex_ai: 'Vertex AI inschakelen',
  com_endpoint_config_key_google_vertex_api: 'API op Google Cloud, dan',
  com_endpoint_config_key_google_service_account: 'Maak een serviceaccount',
  com_endpoint_config_key_google_vertex_api_role:
    'Zorg ervoor dat u op \'Maken en doorgaan\' klikt om ten minste de \'Vertex AI-gebruiker\'-rol te geven. Maak ten slotte een JSON-sleutel aan om hier te importeren.',
  com_nav_auto_scroll: 'Automatisch scrollen naar Nieuwste bij openen',
  com_nav_plugin_store: 'Plugin-opslag',
  com_nav_plugin_search: 'Plugins zoeken',
  com_nav_plugin_auth_error:
    'Er trad een fout op bij het authenticeren van deze plugin. Probeer het opnieuw.',
  com_nav_export_filename: 'Bestandsnaam',
  com_nav_export_filename_placeholder: 'Stel de bestandsnaam in',
  com_nav_export_type: 'Type',
  com_nav_export_include_endpoint_options: 'Eindpuntopties opnemen',
  com_nav_enabled: 'Ingeschakeld',
  com_nav_not_supported: 'Niet ondersteund',
  com_nav_export_all_message_branches: 'Alle berichtvertakkingen exporteren',
  com_nav_export_recursive_or_sequential: 'Recursief of sequentieel?',
  com_nav_export_recursive: 'Recursief',
  com_nav_export_conversation: 'Conversatie exporteren',
  com_nav_theme: 'Thema',
  com_nav_theme_system: 'Systeem',
  com_nav_theme_dark: 'Donker',
  com_nav_theme_light: 'Licht',
  com_nav_clear_all_chats: 'Alle chats wissen',
  com_nav_confirm_clear: 'Wissen bevestigen',
  com_nav_close_sidebar: 'Zijbalk sluiten',
  com_nav_open_sidebar: 'Zijbalk openen',
  com_nav_send_message: 'Bericht verzenden',
  com_nav_log_out: 'Uitloggen',
  com_nav_user: 'GEBRUIKER',
  com_nav_clear_conversation: 'Conversaties wissen',
  com_nav_clear_conversation_confirm_message:
    'Weet u zeker dat u alle conversaties wilt wissen? Dit is onomkeerbaar.',
  com_nav_help_faq: 'Help & FAQ',
  com_nav_settings: 'Instellingen',
  com_nav_search_placeholder: 'Berichten doorzoeken',
  com_nav_setting_general: 'Algemeen',
  com_nav_setting_data: 'Gegevensbesturing',
};
