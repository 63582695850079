import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateSelectedRagDocuments } from '../api/sources';
import { Source } from '~/types/source';
import { NotificationSeverity } from '~/common/types';
import { useToastContext } from '~/Providers/ToastContext';

export function useUpdateSelectedRagDocuments() {
  const queryClient = useQueryClient();
  const { showToast } = useToastContext();

  type RagDocumentsFetchObject = {
    count: number;
    documents: Source[];
    selected: string[];
  };

  return useMutation({
    mutationFn: (data: string[]) => updateSelectedRagDocuments(data),
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: ['ragDocuments'] });
      const previousSettings: RagDocumentsFetchObject | undefined = queryClient.getQueryData([
        'ragDocuments',
      ]);
      if (previousSettings) {
        const documents = previousSettings.documents.map((doc) => ({
          ...doc,
          active: data.some((id) => id === doc.document_id),
        }));
        const newSettings = {
          ...previousSettings,
          selected: data,
          documents,
        };
        queryClient.setQueryData(['ragDocuments'], newSettings);
      }
      return { previousSettings };
    },
    onError: (err, data, context) => {
      showToast({
        message:
          'There was an error while updating your documents. Reverted to the previous state.',
        severity: NotificationSeverity.ERROR,
      });
      queryClient.setQueryData(['ragDocuments'], context?.previousSettings);
    },
    onSettled: async (data, error) => {
      queryClient.invalidateQueries({ queryKey: ['ragDocuments'] });
    },
  });
}
