import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getChatSettings, getUserModels } from '../api/models';
// import { useAuthStore } from '~/zustand';
import { ChatSettings } from '~/types/models';
import { useAuth } from '~/Providers/useAuth';

export function useUserModels() {
  const { user } = useAuth();

  return useQuery({
    queryKey: ['userModels'],
    queryFn: () => getUserModels(user?.user_id!),
    enabled: !!user?.user_id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
}

export function useChatSettings() {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['chatSettings'],
    queryFn: () => getChatSettings(user?.user_id!),
    enabled: !!user?.user_id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    placeholderData: () => {
      const cachedSettings = queryClient.getQueryData<ChatSettings>(['chatSettings']);

      if (cachedSettings) {
        return cachedSettings;
      }
    },
  });
}
