import { useRecoilState } from 'recoil';
import { useEffect, type ChangeEvent } from 'react';
import { useChatContext } from '~/Providers';
import AttachFile from './Files/AttachFile';
import StopButton from './StopButton';
import SendButton from './SendButton';
import Images from './Files/Images';
import Textarea from './Textarea';
import store from '~/store';
import { useParams } from 'react-router-dom';
import { useChatSettings, useUserModels } from '~/services/queries/models';
import PromptSuggestor from './Sandbox/PromptSuggestor';
import { useUser } from '~/services/queries/user';
import { ACCOUNT_STATE, SKU } from '~/utils/constants';
import { useSandboxStore } from '~/zustand/sandbox';
import { Model } from '~/types/models';
import { useOrg } from '~/services/queries/orgs';
import { useAuth } from '~/Providers/useAuth';

export default function ChatForm({ index = '' }) {
  const { isDemoComplete, setDemoComplete, setIsSandbox } = useSandboxStore();
  const [text, setText] = useRecoilState(store.textByIndex(index));
  const {
    ask,
    files,
    setFiles,
    conversation,
    isSubmitting,
    handleStopGenerating,
    filesLoading,
    setFilesLoading,
    showStopButton,
    setShowStopButton,
    error,
    handleRegenerate,
  } = useChatContext();

  const orgQuery = useOrg();
  const modelsQuery = useUserModels();
  const models = modelsQuery.data ?? [];
  const hasNoModels = !modelsQuery.isLoading && models.length === 0;
  const atLeastOneModelHasMedia = models.some((model) => model.capabilities?.image_input);

  const chatSettingsQuery = useChatSettings();
  const routingIsActive = chatSettingsQuery.data?.routing_enabled ?? false;
  const selectedModel: Model | '' =
    models.find((model) => model.id_ === chatSettingsQuery.data?.default_model) ?? '';

  const { user } = useAuth();
  const userQuery = useUser(user?.user_id!);
  const isSandboxMode = orgQuery.data?.sku === SKU.SANDBOX;
  const supportsImageUpload =
    userQuery?.data?.images_allowed &&
    userQuery?.data?.upload_allowed &&
    !isSandboxMode &&
    !hasNoModels &&
    (routingIsActive
      ? atLeastOneModelHasMedia
      : selectedModel
      ? selectedModel.capabilities.image_input
      : false);

  useEffect(() => {
    if (userQuery.isSuccess && userQuery.data) {
      if (isSandboxMode) {
        setIsSandbox(true);

        if ((userQuery.data?.curr_requests ?? 0) >= (userQuery.data?.max_requests ?? 10)) {
          setDemoComplete();
        }
      }
    }
  }, [userQuery.isSuccess, userQuery.data]);

  const sendMessage = () => {
    if (!text) return;
    ask({ text, supportsImageUpload });
    setText('');
    if (supportsImageUpload) setFiles(new Map());
  };

  // TODO: change back to null after proto
  const { endpoint: _endpoint, endpointType } = conversation ?? { endpoint: 'used to be null' };
  const endpoint = endpointType ?? _endpoint;
  const params = useParams();

  const getChat = () => {
    if (isSandboxMode && isDemoComplete) {
      return null;
    }

    return (
      <div className="flex w-full items-center">
        <div className="[&:has(textarea:focus)]:border-token-border-xheavy border-token-border-heavy shadow-xs dark:shadow-xs relative flex w-full flex-grow flex-col overflow-hidden rounded-2xl border border-black/10 bg-vwhite shadow-[0_0_0_2px_rgba(255,255,255,0.95)] dark:border-none  dark:bg-vnavy-hover dark:text-white dark:shadow-[0_0_0_2px_rgba(52,53,65,0)] [&:has(textarea:focus)]:shadow-[0_2px_6px_rgba(0,0,0,.05)]">
          <div style={{ display: supportsImageUpload ? 'block' : 'none' }}>
            <Images files={files} setFiles={setFiles} setFilesLoading={setFilesLoading} />
          </div>

          <Textarea
            disabled={hasNoModels || (isSubmitting && params.conversationId === 'new')}
            value={text}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setText(e.target.value)}
            setText={setText}
            submitMessage={sendMessage}
            supportsFiles={supportsImageUpload}
          />

          {supportsImageUpload && <AttachFile endpoint={endpoint ?? ''} disabled={isSubmitting} />}
          {isSubmitting && showStopButton ? (
            <StopButton stop={handleStopGenerating} setShowStopButton={setShowStopButton} />
          ) : (
            // <></>
            endpoint && (
              <SendButton
                onClick={async (e) => {
                  e.preventDefault();
                  sendMessage();
                }}
                text={text}
                disabled={filesLoading || isSubmitting || !text.trim()}
              />
            )
          )}
        </div>
      </div>
    );
  };

  // if (!!error) {
  //   return (
  //     <div className="text-center mb-8">
  //       <p className="font-bold">There was an error processing your message. </p>{' '}
  //       <p>Please try again.</p> <button onClick={handleRegenerate}>Regenerate Response</button>
  //     </div>
  //   );
  // }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        sendMessage();
      }}
      className="stretch mx-2 flex flex-row gap-3 last:mb-2 md:mx-4 md:last:mb-6 lg:mx-auto lg:max-w-2xl xl:max-w-3xl"
    >
      <div className="relative flex h-full flex-1 flex-col items-stretch">
        {isSandboxMode && <PromptSuggestor />}

        {getChat()}
      </div>
    </form>
  );
}

// useEffect(() => {
//     const events = new SSE(apiUrl, {
//       payload: JSON.stringify(payload),
//       headers,
//     });

//     events.onmessage = (e: MessageEvent) => {
//       console.log("PROTO EVENTS message: ", e)
//       console.log("PROTO EVENTS data: ", JSON.parse(e.data))
//     };

//     events.onopen = () => console.log('PROTO connection is opened');

//     events.oncancel = () => {
//       console.log('PROTO EVENTS connection is cancelled')
//     };

//     events.onerror = function (e: MessageEvent) {
//       console.log('PROTO EVENTS error in server stream', e);
//     };

//     events.stream();

//     return () => {
//       events.close();
//     };
// }, [])
