import { Dialog, DialogContent, DialogHeader, DialogTitle } from '~/components/ui';
import { ThemeContext, useMediaQuery } from '~/hooks';
import { cn } from '~/utils';
import PrimaryButton from '~/components/Buttons/PrimaryButton';
import SecondaryButton from '~/components/Buttons/SecondaryButton';
import { useContext, useEffect, useState } from 'react';
import VeraColorLogo from '~/components/svg/VeraColorLogo';
import VeraIconLogo from '~/components/svg/VeraIconLogo';
import { VERA_NAVY, VERA_YELLOW } from '~/utils/constants';
import VeraFullLogo from '~/components/svg/VeraFullLogo';

export default function PromptPreview({ open, onOpenChange, prompt, onSubmit, helper }) {
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const { theme } = useContext(ThemeContext);

  let isDark = theme === 'dark';
  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        onOpenChange(open);
      }}
    >
      <DialogContent
        className={cn(
          'shadow-2xl dark:bg-vnavy-dark-card dark:text-white md:min-h-[353px] md:w-[680px]',
        )}
        style={{
          borderRadius: '12px',
          position: 'fixed',
          margin: 'auto',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <DialogHeader>
          <DialogTitle className="flex items-center text-lg font-medium leading-6 text-gray-900 dark:text-gray-200 dark:text-gray-50">
            <div className=" h-min-content mr-6 w-24">
              <VeraFullLogo color={isDark ? VERA_YELLOW : VERA_NAVY} />
            </div>
            Prompt Preview
          </DialogTitle>
        </DialogHeader>
        <div className="mt-4 px-6">
          <label
            className={`text-sm font-medium font-normal text-gray-500 transition-colors dark:bg-transparent dark:text-[#E9EAF6] sm:w-72
            `}
          >
            {prompt}
          </label>
          <br />

          <label
            className={` font-medium font-normal text-gray-900 transition-colors dark:bg-transparent dark:text-vwhite
            sm:w-72`}
          >
            {helper}
          </label>

          <div className="mt-8 flex justify-end gap-2">
            <PrimaryButton
              className="w-28"
              onClick={() => {
                onSubmit();
                onOpenChange(false);
              }}
            >
              {' '}
              Submit
            </PrimaryButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
