import { useQuery } from '@tanstack/react-query';
import { getGroupPolicies } from '../api/groups';

export function useGroupPolicies(groupId: string) {
  return useQuery({
    queryKey: ['groupPolicies', { groupId }],
    queryFn: () => getGroupPolicies(groupId!),
    enabled: !!groupId,
  });
}
