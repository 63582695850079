import React from 'react';
import { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Auth } from '../types/auth';
import { User } from '../types/user';
import { useUser } from '../services/queries/user';
import Cookies from 'js-cookie';
import { VERA_HEADER } from '~/utils/constants';

type AbstractAuth = {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: User | undefined;
  signOutUser: Function;
  signInNatively: Function;
  loginWithRedirect: Function;
  getAccessToken: () => Promise<string | undefined>;
  setCrossSubdomainCookies: (accessToken: string, userId?: string) => void;
};

const AuthContext = createContext<AbstractAuth | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState('');
  const [authType, setAuthType] = useState<null | 'auth0' | 'cookie'>(null);
  const userQuery = useUser(userId);
  const auth0 = useAuth0();

  useEffect(() => {
    if (auth0 && !auth0.isLoading && auth0.isAuthenticated && !userId) {
      clearCookies(); // clear native cookie just in case there is one
      setUserId(auth0?.user?.user_metadata?.user_id);
      setAuthType('auth0');
      setIsAuthenticated(true);
    } else if (!auth0.isLoading && Cookies.get(VERA_HEADER) && !userId) {
      setUserId(Cookies.get('vera_user_id')!);
      setAuthType('cookie');
      setIsAuthenticated(true);
    }

    if (!auth0.isLoading) {
      setIsLoading(false);
    }
  }, [auth0.isLoading, auth0.isAuthenticated]);

  const getCookieDomain = () => {
    let domain = window.location.hostname; // Get the current window's domain

    if (window.location.hostname.includes('localhost')) {
      domain = 'localhost';
    } else if (authType === 'cookie') {
      domain = '.askvera.io'; // Accessible to all subdomains
    }

    return domain;
  };

  const signInNatively = (auth: Auth) => {
    setCrossSubdomainCookies(auth.access_token, auth.user.user_id);
    setIsAuthenticated(true);
    setUserId(auth.user.user_id);
    setAuthType('cookie');
  };

  const signOutUser = () => {
    clearCookies();
    auth0.logout({
      logoutParams: {
        returnTo: import.meta.env.VITE_AUTH0_LOGOUT_CALLBACK_URL,
      },
    });
    setAuthType(null);
    setUserId('');
    setIsAuthenticated(false);
  };

  const setCrossSubdomainCookies = (accessToken_: string, userId_?: string) => {
    // Set a cookie that will be shared across subdomains
    const cookieDomain = getCookieDomain();

    Cookies.set(VERA_HEADER, accessToken_, {
      domain: cookieDomain,
      path: '/', // Accessible site-wide
      expires: 1, // Expires in 1 day (can be adjusted)
      sameSite: 'None', // Allows the cookie to be sent across different origins
      secure: true, // Ensures the cookie is sent over HTTPS
    });

    Cookies.set('vera_user_id', userId_ ?? userId, {
      domain: cookieDomain,
      path: '/', // Accessible site-wide
      expires: 1, // Expires in 1 day (can be adjusted)
      sameSite: 'None', // Allows the cookie to be sent across different origins
      secure: true, // Ensures the cookie is sent over HTTPS
    });
  };

  const clearCookies = () => {
    const cookieDomain = getCookieDomain();

    Cookies.remove(VERA_HEADER, {
      domain: cookieDomain, // Same domain as used when setting the cookie
      path: '/', // Same path as used when setting the cookie
      sameSite: 'None',
      secure: true,
    });

    Cookies.remove('vera_user_id', {
      domain: cookieDomain, // Same domain as used when setting the cookie
      path: '/', // Same path as used when setting the cookie
      sameSite: 'None',
      secure: true,
    });

    // todo: hacky, just in case
    Cookies.remove(VERA_HEADER, {
      domain: '.askvera.io', // Same domain as used when setting the cookie
      path: '/', // Same path as used when setting the cookie
      sameSite: 'None',
      secure: true,
    });
    Cookies.remove('vera_user_id', {
      domain: '.askvera.io', // Same domain as used when setting the cookie
      path: '/', // Same path as used when setting the cookie
      sameSite: 'None',
      secure: true,
    });
    Cookies.remove(VERA_HEADER, {
      domain: `.${window.location.hostname}`, // Same domain as used when setting the cookie
      path: '/', // Same path as used when setting the cookie
      sameSite: 'None',
      secure: true,
    });
    Cookies.remove('vera_user_id', {
      domain: `.${window.location.hostname}`, // Same domain as used when setting the cookie
      path: '/', // Same path as used when setting the cookie
      sameSite: 'None',
      secure: true,
    });
  };

  const getAccessToken = async () => {
    let accessToken_: string | undefined = undefined;

    if (authType === 'auth0') {
      accessToken_ = await auth0.getAccessTokenSilently();
      if (!accessToken_) signOutUser();
    } else if (authType === 'cookie') {
      accessToken_ = Cookies.get(VERA_HEADER);
      if (!accessToken_) signOutUser();
    }

    if (accessToken_) return accessToken_;
  };

  // const signOutNatively = () => {
  //   clearCookies();
  //   auth0.loginWithRedirect();
  //   setIsAuthenticated(false);
  //   setAuthType(null);
  // };

  // const signOutAuth0 = () => {
  //   clearCookies();
  //   auth0.logout({
  //     logoutParams: {
  //       returnTo: import.meta.env.VITE_AUTH0_LOGOUT_CALLBACK_URL,
  //     },
  //   });
  //   setIsAuthenticated(false);
  //   setAuthType(null);
  // };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading: isLoading || (!!userId && !userQuery.isFetched),
        getAccessToken,
        user: userQuery.data,
        signOutUser: signOutUser,
        signInNatively,
        loginWithRedirect: auth0.loginWithRedirect,
        setCrossSubdomainCookies,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
