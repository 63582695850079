import { useMutation, useQueryClient } from '@tanstack/react-query';
//import { useAuthStore } from '~/zustand';
import { updateChatSettings } from '../api/models';
import { ChatSettings } from '~/types/models';
import { useToastContext } from '~/Providers/ToastContext';
import { NotificationSeverity } from '~/common/types';
import { useAuth } from '~/Providers/useAuth';

export function useUpdateChatSettings() {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { showToast } = useToastContext();

  return useMutation({
    mutationFn: (data: ChatSettings) => updateChatSettings(user?.user_id!, data),
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: ['chatSettings'] });
      const previousSettings = queryClient.getQueryData(['chatSettings']);
      if (previousSettings) {
        const newSettings = { ...previousSettings, ...data };
        queryClient.setQueryData(['chatSettings'], newSettings);
      }
      return { previousSettings };
    },
    onError: (err, data, context) => {
      showToast({
        message: 'There was an error while updating your controls. Reverted to the previous state.',
        severity: NotificationSeverity.ERROR,
      });
      queryClient.setQueryData(['chatSettings'], context?.previousSettings);
    },
    onSettled: async (data, error) => {
      queryClient.invalidateQueries({ queryKey: ['chatSettings'] });
    },
  });
}
