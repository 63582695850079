import { FC, useState } from 'react';
import { useChatContext } from '~/Providers';
import { useChatSettings, useUserModels } from '~/services/queries/models';
import { useUpdateChatSettings } from '~/services/mutations/models';
import { ChatSettings } from '~/types/models';

const RouterToggle: FC = () => {
  const { isSubmitting } = useChatContext();
  const updateChatSettingsMutation = useUpdateChatSettings();
  const modelsQuery = useUserModels();
  const chatSettingsQuery = useChatSettings();
  const routingIsActive = chatSettingsQuery.data?.routing_enabled ?? false;
  const models = modelsQuery.data ?? [];
  const selected =
    models.find((model) => model.id_ === chatSettingsQuery.data?.default_model)?.id_ ??
    models[0]?.id_ ??
    '';

  const toggleHandler = () => {
    const updateSettings: ChatSettings = {
      ...chatSettingsQuery.data!,
      routing_enabled: !routingIsActive,
      default_model: selected,
    };

    updateChatSettingsMutation.mutate(updateSettings);
  };

  return (
    <>
      <label
        className={`mb-2 inline-flex ${
          isSubmitting ? '' : 'cursor-pointer'
        } w-full items-center justify-between`}
      >
        <span
          className={`text-sm font-medium ${
            isSubmitting ? 'text-gray-400' : 'text-gray-900'
          }  dark:text-vwhite`}
        >
          Model Router
        </span>
        <input
          disabled={isSubmitting}
          readOnly={isSubmitting}
          type="checkbox"
          checked={routingIsActive}
          onChange={toggleHandler}
          className="peer sr-only"
        />
        <div className=" peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-vwhite after:transition-all after:content-[''] peer-checked:bg-vpurple peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none  dark:border-gray-600 dark:bg-[#8A81B8] dark:bg-gray-700  rtl:peer-checked:after:-translate-x-full"></div>
      </label>
      <p className="text-xs font-normal text-[#6C7080] transition-colors dark:bg-transparent dark:text-gray-300 sm:w-72">
        Automatically direct your prompts to the best-suited model.
      </p>
    </>
  );
};

export default RouterToggle;
