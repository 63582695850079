import { User } from '~/types/user';
import { axios } from './setup';
import { Group } from '~/types/group';

export const getUser = async (userId: string) => {
  return (await axios.get<User>(`users/${userId}`)).data;
};

// currently used for sandbox
export const getUserGroups = async (userId: string) => {
  return (await axios.get<{ groups: Group[] }>(`users/id/${userId}/groups`)).data.groups;
};
