import React from 'react';
import VeraMessage from './VeraMessage';

function VeraErrorMessage({ error }) {
  return (
    <VeraMessage isError>
      {' '}
      <div className="flex max-w-max items-center justify-center rounded bg-red-500/10 px-3 py-2">
        <em>
          {(error && error.toString()) ||
            `Error: An unexpected error has occurred. Ensure you're connected to the internet. If the problem persists, please notify an administrator.
            }`}
        </em>
      </div>
    </VeraMessage>
  );
}

export default VeraErrorMessage;
