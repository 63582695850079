import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import Root from './Root';
import Chat from './Chat';
import ChatRoute from './ChatRoute';
import AssistantsRoute from './AssistantsRoute';
import Search from './Search';
import {
  Login,
  Registration,
  RequestPasswordReset,
  ResetPassword,
  ApiErrorWatcher,
} from '~/components/Auth';
import RedirectLoginPage from '~/components/Auth/RedirectLoginPage';
import useAuthRedirect from './useAuthRedirect';
import LockedAccountPage from '~/components/Auth/LockedAccountPage';
import InfoFormStepsPage from '~/components/Sandbox/InfoFormStepsPage';
import { ChakraProvider } from '@chakra-ui/react';
import Background from '~/components/Sandbox/Background';
import RegistrationStepsPage from '~/components/Registration/RegistrationStepsPage';
import UpgradePage from '~/components/Sandbox/UpgradePage';
import { theme } from './ChakraTheme';
import PrivacyPolicyPage from '~/components/Registration/PrivacyPolicyPage';
import TermsOfServicePage from '~/components/Registration/TermsOfServicePage';

const AuthLayout = () => {
  useAuthRedirect();

  return (
    <>
      <Outlet />
      <ApiErrorWatcher />
    </>
  );
};

export const router = createBrowserRouter([
  // {
  //   path: 'register',
  //   element: <Registration />,
  // },
  // {
  //   path: 'forgot-password',
  //   element: <RequestPasswordReset />,
  // },
  // {
  //   path: 'reset-password',
  //   element: <ResetPassword />,
  // },
  {
    element: <AuthLayout />,
    errorElement: <RedirectLoginPage />,
    children: [
      {
        path: 'login',
        element: <RedirectLoginPage />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicyPage />,
      },
      {
        path: 'terms-of-use',
        element: <TermsOfServicePage />,
      },
      {
        path: 'try-vera',
        element: (
          <ChakraProvider theme={theme}>
            <Background>
              <InfoFormStepsPage />
            </Background>
          </ChakraProvider>
        ),
      },
      {
        path: 'register',
        element: (
          <ChakraProvider theme={theme}>
            <Background>
              <RegistrationStepsPage />
            </Background>
          </ChakraProvider>
        ),
      },
      {
        path: 'upgrade',
        element: (
          <ChakraProvider theme={theme}>
            <UpgradePage />
          </ChakraProvider>
        ),
      },
      {
        path: '/',
        element: <Root />,
        children: [
          {
            index: true,
            element: <Navigate to="/c/new" replace={true} />,
          },
          {
            path: 'c/:conversationId?',
            element: <ChatRoute />,
          },
          {
            path: '/locked-account',
            element: <LockedAccountPage />,
          },
          // {
          //   path: 'chat/:conversationId?',
          //   element: <Chat />,
          // },
          // {
          //   path: 'a/:conversationId?',
          //   element: <AssistantsRoute />,
          // },
          // {
          //   path: 'search/:query?',
          //   element: <Search />,
          // },
        ],
      },
    ],
  },
]);
