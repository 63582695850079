import { create } from 'zustand';
import { appLocalStorage as localStorage } from '../services/api/setup';

const CURRENT_SANDBOX_VERSION = '1.0.0';

type SandboxStoreState = {
  isSandbox: boolean;
  setIsSandbox: (val: boolean) => void;
  isDemoComplete: boolean;
  setDemoComplete: () => void;
};

export const useSandboxStore = create<SandboxStoreState>((set) => ({
  isSandbox: false,
  setIsSandbox: (val: boolean) =>
    set(() => {
      return { isSandbox: val };
    }),
  isDemoComplete: false,
  setDemoComplete: () =>
    set(() => {
      localStorage.setItem('lastDemoVCompleted', CURRENT_SANDBOX_VERSION);
      return { isDemoComplete: true };
    }),
}));

// https://docs.pmnd.rs/zustand/guides/slices-pattern
