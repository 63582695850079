import { TableColumn } from 'react-data-table-component';
import { Source } from '../../types/source';
import { DefaultDataTable } from './DataTables';
import RagDocIcon from '../svg/RagDocIcon';
import { useRagDocuments } from '~/services/queries/sources';
import { compareUtcDates, formatDate } from './cellHelpers';
import { exportDocumentFromStore } from '~/services/api/exports';
import useMediaQuery from '~/hooks/useMediaQuery';
//import { compareUtcDates, formatDate } from './cellHelpers';

interface SourcesTableProps {
  onSelectRow?: (row: Source, checked: boolean) => void;
  rowIsSelected?: (row: Source) => boolean;
  sources?: Source[];
}

export default function SourcesTable({ rowIsSelected, onSelectRow, sources }: SourcesTableProps) {
  const ragDocumentsQuery = useRagDocuments();
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const sourceData: Source[] = sources ?? ragDocumentsQuery.data?.documents ?? [];
  const isPending = !sources && ragDocumentsQuery.isLoading;

  return (
    <DefaultDataTable
      actions={[
        {
          label: 'Download',
          onSelect: async (row: Source) => {
            if (row && row.document_name) {
              await exportDocumentFromStore(row.document_name);
            }
          },
        },
        // { label: 'Preview in Tab', onSelect: () => console.log('selected') },
      ]}
      omitColumns={isSmallScreen ? ['Last Update'] : []}
      keyField={'name'}
      isLoading={isPending}
      columns={columns}
      data={sourceData}
      onSelectRow={onSelectRow}
      rowIsSelected={rowIsSelected}
      rowsPerPageOptions={[5, 10]}
    />
  );
}

const columns: TableColumn<Source>[] = [
  {
    name: 'Name',
    sortable: true,
    selector: (row: Source) => row.document_name,
    cell: (row: Source) => (
      <span className="flex items-center gap-2  font-bold">
        <RagDocIcon className="min-w-4 max-w-4" />{' '}
        <span className="max-w-36 truncate">{row.document_name}</span>
      </span>
    ),
  },
  {
    name: 'Created By',
    sortable: true,
    selector: (row: Source) => row.created_by,
  },
  {
    name: 'Last Update',
    sortable: true,
    sortFunction: (a, b) => compareUtcDates(a.last_update, b.last_update),
    cell: (row: Source) => <span>{formatDate(row.last_update)}</span>,
  },
];
