import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import * as Dialog from '@radix-ui/react-dialog';
import DialogImage from './DialogImage';
import { cn } from '~/utils';
import { axios } from '~/services/api/setup';
import { useToastContext } from '~/Providers';
import { NotificationSeverity } from '~/common';
import { getErrorMessage } from '~/utils/error';

const Image = ({
  imagePath,
  altText,
  height,
  width,
  isSecure,
}: // n,
// i,
{
  imagePath: string;
  altText: string;
  height: number;
  width: number;

  isSecure?: boolean;
  // n: number;
  // i: number;
}) => {
  const { showToast } = useToastContext();
  const [imageUrl, setImageUrl] = useState(imagePath);
  const [isLoaded, setIsLoaded] = useState(false);
  const handleImageLoad = () => setIsLoaded(true);
  const [minDisplayTimeElapsed, setMinDisplayTimeElapsed] = useState(false);

  useEffect(() => {
    if (isSecure) {
      // Fetch the pre-signed URL
      axios
        .get(imagePath, { responseType: 'arraybuffer' })
        .then(async (response) => {
          // TODO: will need type at some point
          const blob = new Blob([response.data], {
            type: response.headers['content-type'] ?? 'image/png',
          });
          const preview = URL.createObjectURL(blob);
          setImageUrl(preview);
        })
        .catch((error) => {
          showToast({
            message: `Failed to fetch an image, error: ${getErrorMessage(error)}`,
            severity: NotificationSeverity.ERROR,
            showIcon: true,
          });
        });
    }
  }, []);
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isLoaded) {
      timer = setTimeout(() => setMinDisplayTimeElapsed(true), 150);
    }
    return () => clearTimeout(timer);
  }, [isLoaded]);
  // const makeSquare = n >= 3 && i < 2;

  let placeholderHeight = '288px';
  if (height > width) {
    placeholderHeight = '900px';
  } else if (height === width) {
    placeholderHeight = width + 'px';
  }

  return (
    <Dialog.Root>
      <div className="">
        <div className="relative mt-1 flex h-auto w-full max-w-lg items-center justify-center overflow-hidden bg-[#E9EAF6] text-gray-500 dark:bg-vnavy-dark-card dark:text-gray-400">
          <Dialog.Trigger asChild>
            <button type="button" aria-haspopup="dialog" aria-expanded="false">
              <LazyLoadImage
                // loading="lazy"
                alt={altText}
                onLoad={handleImageLoad}
                className={cn(
                  'max-h-[900px] max-w-full opacity-100 transition-opacity duration-300',
                  // n >= 3 && i < 2 ? 'aspect-square object-cover' : '',
                  isLoaded && minDisplayTimeElapsed ? 'opacity-100' : 'opacity-0',
                )}
                src={imageUrl}
                style={{
                  height: isLoaded && minDisplayTimeElapsed ? 'auto' : placeholderHeight,
                  width,
                  color: 'transparent',
                }}
                placeholder={
                  <div
                    style={{
                      height: isLoaded && minDisplayTimeElapsed ? 'auto' : placeholderHeight,
                      width,
                    }}
                  />
                }
              />
            </button>
          </Dialog.Trigger>
        </div>
      </div>
      <DialogImage src={imageUrl} height={height} width={width} />
    </Dialog.Root>
  );
};

export default Image;
