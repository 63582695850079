import { Fragment, Suspense } from 'react';
import type { TResPlugin } from 'librechat-data-provider';
import type { TMessageContent, TText, TDisplayProps } from '~/common';
import Plugin from '~/components/Messages/Content/Plugin';
import Error from '~/components/Messages/Content/Error';
import { DelayedRender } from '~/components/ui';
import EditMessage from './EditMessage';
import Container from './Container';
import { cn } from '~/utils';
import Image from './Image';
import UserMessage from './UserMessage';
import ModelMessage from './ModelMessage';

const ErrorMessage = ({ text, isWarning = false, isLast = false }) => {
  return (
    <Container>
      <div
        className={cn(
          'mb-2 rounded px-3 py-2 dark:text-gray-100',
          isWarning ? 'bg-yellow-500/10' : 'bg-red-500/10',
        )}
      >
        <em>
          <Error text={text} />
        </em>
      </div>
    </Container>
  );
};

// Display Message Component
const DisplayMessage = ({ text, isCreatedByUser, message, showCursor }: TDisplayProps) => {
  const imageFiles = message && message.files ? Object.entries(message?.files) : [];
  // ? message.files.filter((file) => file.type && file.type.startsWith('image/'))
  // : null;

  return (
    <Container>
      {imageFiles &&
        imageFiles.map(([id, url]) => (
          <Image
            key={id}
            imagePath={(url as string) ?? ''}
            height={920}
            width={1080}
            altText={'Uploaded Image'}
            isSecure
            // n={imageFiles.length}
            // i={i}
          />
        ))}
      <div
        className={cn(
          'markdown prose dark:prose-invert light w-full break-words',
          isCreatedByUser
            ? 'flex items-center whitespace-pre-wrap dark:text-gray-20'
            : 'dark:text-gray-70',
        )}
      >
        {!isCreatedByUser ? (
          <ModelMessage content={text} message={message} />
        ) : (
          <UserMessage message={message} />
        )}
      </div>
    </Container>
  );
};

// Unfinished Message Component
const UnfinishedMessage = () => (
  <ErrorMessage text="The response is incomplete; it's either still processing, was cancelled, or censored. Refresh or try a different prompt." />
);

// Content Component
const MessageContent = ({
  text,
  edit,
  error,
  unfinished,
  isSubmitting,
  isLast,
  ...props
}: TMessageContent) => {
  const { message } = props;
  if (error || message.isWarning) {
    return <ErrorMessage text={text} isWarning={message.isWarning} isLast={isLast} />;
  } else if (edit) {
    return <EditMessage text={text} isSubmitting={isSubmitting} {...props} />;
  } else {
    const marker = ':::plugin:::\n';
    const splitText = text.split(marker);
    const { plugins, messageId } = message;
    const displayedIndices = new Set<number>();
    // Function to get the next non-empty text index
    const getNextNonEmptyTextIndex = (currentIndex: number) => {
      for (let i = currentIndex + 1; i < splitText.length; i++) {
        // Allow the last index to be last in case it has text
        // this may need to change if I add back streaming
        if (i === splitText.length - 1) {
          return currentIndex;
        }

        if (splitText[i].trim() !== '' && !displayedIndices.has(i)) {
          return i;
        }
      }
      return currentIndex; // If no non-empty text is found, return the current index
    };

    return splitText.map((text, idx) => {
      let currentText = text.trim();
      let plugin: TResPlugin | null = null;

      if (plugins) {
        plugin = plugins[idx];
      }

      // If the current text is empty, get the next non-empty text index
      const displayTextIndex = currentText === '' ? getNextNonEmptyTextIndex(idx) : idx;
      currentText = splitText[displayTextIndex];
      const isLastIndex = displayTextIndex === splitText.length - 1;
      const isEmpty = currentText.trim() === '';
      const showText =
        (currentText && !isEmpty && !displayedIndices.has(displayTextIndex)) ||
        (isEmpty && isLastIndex);
      displayedIndices.add(displayTextIndex);

      return (
        <Fragment key={idx}>
          {plugin && <Plugin key={`plugin-${messageId}-${idx}`} plugin={plugin} />}
          {showText ? (
            <DisplayMessage
              key={`display-${messageId}-${idx}`}
              showCursor={false} //isLastIndex && isLast
              text={currentText}
              {...props}
            />
          ) : null}
          {!isSubmitting && unfinished && (
            <Suspense>
              <DelayedRender delay={250}>
                <UnfinishedMessage key={`unfinished-${messageId}-${idx}`} />
              </DelayedRender>
            </Suspense>
          )}
        </Fragment>
      );
    });
  }
};

export default MessageContent;
