import { useQuery } from '@tanstack/react-query';
import { getRagDocuments } from '../api/sources';
import { useAuth } from '~/Providers/useAuth';

export function useRagDocuments() {
  const { user } = useAuth();

  return useQuery({
    queryKey: ['ragDocuments'],
    queryFn: () => getRagDocuments(),
    enabled: !!user?.user_id,
    //   refetchOnWindowFocus: false,
    //   refetchOnReconnect: false,
    //   refetchOnMount: false,
  });
}
