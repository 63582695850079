import { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Box,
  Button,
  Heading,
  Text,
  Flex,
  Image,
  InputGroup,
  InputRightElement,
  IconButton,
  background,
  useToast,
} from '@chakra-ui/react';
import PasswordRequirements from './PasswordRequirements';
import { RegisterOrgForm } from '~/types/sandbox';
import {
  HARD_GRAY,
  VERA_LIME,
  VERA_NAVY,
  VERA_PINK,
  VERA_TEAL,
  VERA_WHITE,
  VERA_YELLOW,
} from '~/utils/constants';
import { useRegisterOrg } from '~/services/mutations/sandbox';
import { getErrorMessage } from '~/utils/error';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import TOScomponent from '../Chat/Input/Sandbox/TOScomponent';
import VeraFullLogo from '../svg/VeraFullLogo';
import { useAuth } from '~/Providers/useAuth';

export default function PasswordForm({
  onFormSubmitted,
  userEmail,
  title,
  subtitle,
  isRegister,
}: {
  onFormSubmitted?: Function;
  userEmail?: string;
  title: React.ReactElement | string;
  subtitle?: React.ReactElement | string;
  isRegister?: boolean;
}) {
  const { loginWithRedirect } = useAuth();
  //   const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [formData, setFormData] = useState<RegisterOrgForm>(
    isRegister
      ? { email: userEmail ?? '', password: '', org_name: '', sku: 'paid' }
      : {
          email: userEmail ?? '',
          password: '',
          org_name: '',
          sku: 'sandbox',
        },
  );
  const [showPassword, setShowPassword] = useState(false);
  const toast = useToast();

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const createOrgMutation = useRegisterOrg();

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();
      const response = await createOrgMutation.mutateAsync(formData);
      onFormSubmitted && onFormSubmitted({ formData, response });
    } catch (e) {
      toast({
        position: 'top',
        title: 'An error has occurred.',
        description: getErrorMessage(e),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      maxW="md"
      mx="auto"
      my={8}
      p={10}
      // borderWidth="1px"
      borderRadius="lg"
      background={'#2C2552'}
      color={VERA_WHITE}
    >
      {isRegister && (
        <Flex justify={'center'} mb={8}>
          <Box width="auto" height={10} zIndex={1}>
            <VeraFullLogo color={VERA_YELLOW} />
          </Box>
          {/* <Image src={'assets/vera-logo-color.svg'}  /> */}
        </Flex>
      )}
      <Box mb={6}>
        <Heading size="lg" textAlign={'center'}>
          {title}
        </Heading>
        {subtitle && (
          <Text color="#E9EAF6" mt={4}>
            {subtitle}
          </Text>
        )}
      </Box>
      <form onSubmit={handleSubmit}>
        <FormControl id="email" mb={8} isRequired>
          <FormLabel fontWeight={'bolder'}>Email</FormLabel>
          <Input
            border="unset"
            placeholder="email@example.com"
            focusBorderColor={VERA_PINK}
            background={'#3B3465'}
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
        </FormControl>
        {/* {isRegister && ( */}
        <FormControl id="org_name" mb={8} isRequired>
          <FormLabel fontWeight={'bolder'}>Organization Name</FormLabel>
          <Input
            placeholder="Enter your org name"
            focusBorderColor={VERA_PINK}
            background={'#3B3465'}
            border="unset"
            type="org_name"
            name="org_name"
            value={formData.org_name}
            onChange={handleInputChange}
          />
        </FormControl>
        {/* )} */}
        <FormControl id="password" mb={6} isRequired>
          <FormLabel fontWeight={'bolder'}>Password</FormLabel>
          <InputGroup>
            <Input
              placeholder="Create a password"
              border="unset"
              focusBorderColor={VERA_PINK}
              background={'#3B3465'}
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              isInvalid={getErrorMessage(createOrgMutation.error)?.includes('Password')}
            />
            <InputRightElement>
              <IconButton
                aria-label={showPassword ? 'Hide password' : 'Show password'}
                icon={
                  showPassword ? <ViewOffIcon color={'#E9EAF6'} /> : <ViewIcon color={'#E9EAF6'} />
                }
                onClick={togglePasswordVisibility}
                variant="ghost"
                _hover={{ background: '#3B3465' }}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <Flex mb={8}>
          <PasswordRequirements
            password={formData.password}
            isSubmitError={getErrorMessage(createOrgMutation.error)?.includes('Password')}
          />
        </Flex>
        {/* <Box mb={6}>
          <ReCAPTCHA
            sitekey={import.meta.env.VITE_CAPTCHA_SITE_KEY as string}
            onChange={handleCaptchaChange}
          />
        </Box> */}
        <Box>
          <Button
            w="full"
            type="submit"
            color={VERA_NAVY}
            background={VERA_YELLOW}
            _hover={{ background: VERA_YELLOW, opacity: 0.4 }}
            borderRadius={'0.4rem'}
            cursor={'pointer'}
            isLoading={createOrgMutation.isLoading}
            isDisabled={
              // !isCaptchaVerified ||
              !formData.password || !formData.email
            }
          >
            Submit
          </Button>
          <Box mt={2}>
            <TOScomponent />
          </Box>
        </Box>
      </form>
      {isRegister && (
        <Box mt={8} textAlign={'center'}>
          <Text fontSize={'sm'} color={VERA_WHITE}>
            Are you already registered?{' '}
            <Box as="span" cursor={'pointer'} color={VERA_PINK} onClick={() => loginWithRedirect()}>
              Log in
            </Box>
          </Text>
        </Box>
      )}
      {/* {createOrgMutation.isError && (
        <p className="mt-4 text-center text-sm text-vred">
          {getErrorMessage(createOrgMutation?.error)}
        </p>
      )} */}
    </Box>
  );
}
