import type { TDialogProps } from '~/common';
import { useMediaQuery } from '~/hooks';
import { cn } from '~/utils';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '~/components/ui';
import VeraColorLogo from '~/components/svg/VeraColorLogo';
import { AlertCircleIcon } from 'lucide-react';

export default function NoModelsInfo({ open, onOpenChange }: TDialogProps) {
  const isSmallScreen = useMediaQuery('(max-width: 767px)');

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        onOpenChange(open);
      }}
    >
      <DialogContent
        className={cn(
          'shadow-2xl dark:bg-vnavy-dark-card dark:text-white md:min-h-[250px] md:w-[680px]',
        )}
        style={{
          borderRadius: '12px',
          position: 'fixed',
          margin: 'auto',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div className="p-6 ">
          <DialogTitle className="mb-6 flex items-center text-lg font-medium leading-6 text-gray-900 dark:text-gray-200">
            <div className="mr-1 flex h-8 w-8 items-center">
              <AlertCircleIcon className="stroke-yellow-600" />
            </div>
            Model Configuration Required
          </DialogTitle>
          <p className="mb-4">
            It looks like no model has been configured for your account. To use the platform, you
            need to have at least one model set up.
          </p>

          <p className="mb-4">
            Please contact your administrator to configure a model for your account.
          </p>

          {/* <div className="mt-8 flex h-10 w-10 w-full justify-center">
            {' '}
            <VeraColorLogo />
          </div> */}

          {/* <div className="flex justify-end gap-2">
            <SecondaryButton
              className="w-28"
              onClick={() => {
                onOpenChange(false);
              }}
            >
              Cancel{' '}
            </SecondaryButton>
            <PrimaryButton className="w-28" onClick={handleSubmission}>
              {' '}
              Confirm
            </PrimaryButton>
          </div> */}
        </div>
      </DialogContent>
    </Dialog>
  );
}
