import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updatePolicy } from '../api/policies';
import { CreatePolicyForm } from '~/types/policy';
import { NotificationSeverity } from '~/common';
import { useToastContext } from '~/Providers/ToastContext';

export function useUpdateSandboxPolicy() {
  const queryClient = useQueryClient();
  const { showToast } = useToastContext();

  return useMutation({
    mutationFn: ({
      policyId,
      data,
      groupId,
    }: {
      policyId: string;
      groupId: string;
      data: CreatePolicyForm;
    }) => updatePolicy(policyId, data),
    onMutate: async ({ policyId, data, groupId }) => {
      const qk = ['groupPolicies', { groupId }];
      await queryClient.cancelQueries({ queryKey: qk });
      const previousSettings = queryClient.getQueryData(qk)?.[0];
      if (previousSettings) {
        const newSettings = { ...previousSettings, ...data };

        queryClient.setQueryData(qk, [newSettings]);
      }
      return { previousSettings };
    },
    onError: (err, data, context) => {
      showToast({
        message: 'There was an error while updating your policy. Reverted to the previous state.',
        severity: NotificationSeverity.ERROR,
      });
      queryClient.setQueryData(['chatSettings'], context?.previousSettings);
    },
    onSettled: async (data, err, { policyId }) => {
      queryClient.invalidateQueries({
        queryKey: ['policy', { policyId }],
      });
    },
  });
}
