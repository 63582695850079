import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import type { ConversationListResponse } from 'librechat-data-provider';
import { useMediaQuery, useConversation, useLocalStorage, useNavScrolling } from '~/hooks';
import { useSearchInfiniteQuery, useConversationsInfiniteQuery } from '~/data-provider';
import { TooltipProvider, Tooltip } from '~/components/ui';
import { Conversations } from '~/components/Conversations';
import { Spinner } from '~/components/svg';
import SearchBar from './SearchBar';
import NavToggle from './NavToggle';
import NavLinks from './NavLinks';
import NewChat from './NewChat';
import { cn } from '~/utils';
import store from '~/store';
//import { useAuthStore } from '~/zustand';
import { useConversations } from '~/services/queries/conversations';
import { SKU, VERA_NAVY, VERA_WHITE, VERA_YELLOW } from '~/utils/constants';
import VeraColorLogo from '../svg/VeraColorLogo';
import StripeModal from '../Chat/Input/Sandbox/StripeModal';
import { useOrg } from '~/services/queries/orgs';
import VeraFullLogo from '../svg/VeraFullLogo';

export default function Nav({ navVisible, setNavVisible }) {
  const [navWidth, setNavWidth] = useState('249px');
  const [isHovering, setIsHovering] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [newUser, setNewUser] = useLocalStorage('newUser', true);
  const [isToggleHovering, setIsToggleHovering] = useState(false);

  useEffect(() => {
    if (isSmallScreen) {
      setNavWidth('320px');
    } else {
      setNavWidth('249px');
    }
  }, [isSmallScreen]);

  const [showStripeModal, setShowStripeModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [showLoading, setShowLoading] = useState(false);

  const orgQuery = useOrg();
  const searchQuery = useRecoilValue(store.searchQuery);
  const isSearchEnabled = useRecoilValue(store.isSearchEnabled);
  const { newConversation, searchPlaceholderConversation } = useConversation();

  //const { refreshConversations } = useConversations();
  const setSearchResultMessages = useSetRecoilState(store.searchResultMessages);

  // const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useConversationsInfiniteQuery(
  //   { pageNumber: pageNumber.toString() },
  //   { enabled: isAuthenticated() },
  // );

  // const searchQueryRes = useSearchInfiniteQuery(
  //   { pageNumber: pageNumber.toString(), searchQuery: searchQuery },
  //   { enabled: isAuthenticated() && !!searchQuery.length },
  // );

  // const { containerRef, moveToTop } = useNavScrolling({
  //   setShowLoading,
  //   hasNextPage: searchQuery ? searchQueryRes.hasNextPage : hasNextPage,
  //   fetchNextPage: searchQuery ? searchQueryRes.fetchNextPage : fetchNextPage,
  //   isFetchingNextPage: searchQuery ? searchQueryRes.isFetchingNextPage : isFetchingNextPage,
  // });

  // const conversations = useMemo(
  //   () =>
  //     (searchQuery ? searchQueryRes?.data : data)?.pages.flatMap((page) => page.conversations) ||
  //     [],
  //   [data, searchQuery, searchQueryRes?.data],
  // );

  const onSearchSuccess = useCallback(({ data }: { data: ConversationListResponse }) => {
    const res = data;
    searchPlaceholderConversation();
    setSearchResultMessages(res.messages);
    /* disabled due recoil methods not recognized as state setters */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array

  // useEffect(() => {
  //   //we use isInitialLoading here instead of isLoading because query is disabled by default
  //   if (searchQueryRes.data) {
  //     onSearchSuccess({ data: searchQueryRes.data.pages[0] });
  //   }
  // }, [searchQueryRes.data, searchQueryRes.isInitialLoading, onSearchSuccess]);

  const conversationsQuery = useConversations();
  const conversations = conversationsQuery.data ?? [];

  // const clearSearch = () => {
  //   setPageNumber(1);
  //   refreshConversations();
  //   if (conversationId == 'search') {
  //     newConversation();
  //   }
  // };

  const toggleNavVisible = () => {
    setNavVisible((prev: boolean) => !prev);
    if (newUser) {
      setNewUser(false);
    }
  };

  const itemToggleNav = () => {
    if (isSmallScreen) {
      toggleNavVisible();
    }
  };

  const onUnlockFullAccess = () => setShowStripeModal(true);

  const isAtLockedAccountRoute = window.location.pathname.includes('locked-account');
  const isSandboxMode = orgQuery?.data?.sku === SKU.SANDBOX;

  return (
    <>
      <TooltipProvider delayDuration={150}>
        <Tooltip>
          <div
            className={
              'nav active dark max-w-[320px] flex-shrink-0 overflow-x-hidden bg-vnavy md:max-w-[249px]'
            }
            style={{
              width: navVisible ? navWidth : '0px',
              visibility: navVisible ? 'visible' : 'hidden',
              transition: 'width 0.2s, visibility 0.2s',
            }}
          >
            <div className="h-full w-[320px] md:w-[249px]">
              <div className="flex h-full min-h-0 flex-col">
                <div
                  className={cn(
                    'flex h-full min-h-0 flex-col transition-opacity',
                    isToggleHovering && !isSmallScreen ? 'opacity-50' : 'opacity-100',
                  )}
                >
                  <div
                    className={cn(
                      'scrollbar-trigger relative h-full w-full flex-1 items-start border-white/20',
                    )}
                  >
                    <nav className="flex h-full w-full flex-col px-3 pb-3.5">
                      <div className="sticky left-0 right-0 top-0 z-20 mb-4 mt-6 h-8 w-auto bg-vnavy">
                        <VeraFullLogo color={VERA_YELLOW} />
                      </div>
                      <div
                        className={cn(
                          `-mr-2 flex-1 flex-col ${
                            isSandboxMode ? 'overflow-y-clip' : 'overflow-y-auto'
                          } pr-2 transition-opacity duration-500`,
                          isHovering ? '' : 'scrollbar-transparent',
                        )}
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                        //ref={containerRef}
                      >
                        {!isAtLockedAccountRoute && !isSandboxMode && (
                          <NewChat
                            toggleNav={itemToggleNav}
                            // subHeaders={isSearchEnabled && <SearchBar clearSearch={clearSearch} />}
                          />
                        )}
                        {/* TODO: FETCH CONVERSATIONS! */}
                        <Conversations
                          conversations={conversations}
                          //moveToTop={moveToTop}
                          toggleNav={itemToggleNav}
                        />
                        {isSandboxMode && (
                          <div
                            className="flex h-full w-full cursor-pointer flex-col items-center justify-center pb-32"
                            onClick={onUnlockFullAccess}
                          >
                            {/* <div className=" mb-6 h-12 w-auto">
                              <VeraFullLogo color={VERA_WHITE} />
                            </div> */}
                            <label
                              onClick={onUnlockFullAccess}
                              className="align-self-center cursor-pointer text-center text-2xl font-semibold text-white"
                            >
                              Vera is Your Gateway to Generative AI
                            </label>
                            <button
                              onClick={onUnlockFullAccess}
                              className="mt-10 w-full rounded-lg bg-gradient-to-r from-blue-500 to-teal-400 px-10 py-2  font-medium text-white shadow-lg transition duration-300 ease-in-out hover:opacity-80 hover:shadow-xl"
                              style={{ background: VERA_YELLOW, color: VERA_NAVY }}
                            >
                              Unlock Full Access
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                window.open('https://www.askvera.io/contact-us', '_blank');
                              }}
                              className="mt-4 w-full rounded-lg border border-vyellow px-4 py-2 text-vyellow hover:opacity-80"
                            >
                              Contact Us
                            </button>
                          </div>
                        )}
                        <Spinner
                          className={cn(
                            'm-1 mx-auto mb-4 h-4 w-4',
                            /*isFetchingNextPage ||*/ showLoading ? 'opacity-1' : 'opacity-0',
                          )}
                        />
                      </div>
                      <NavLinks />
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NavToggle
            isHovering={isToggleHovering}
            setIsHovering={setIsToggleHovering}
            onToggle={toggleNavVisible}
            navVisible={navVisible}
          />
          <div className={`nav-mask${navVisible ? ' active' : ''}`} onClick={toggleNavVisible} />
        </Tooltip>
      </TooltipProvider>
      {isSandboxMode && showStripeModal && (
        <StripeModal onOpenChange={setShowStripeModal} open={showStripeModal} />
      )}
    </>
  );
}
