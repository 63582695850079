// Japanese phrases
// file deepcode ignore NoHardcodedPasswords: No hardcoded values present in this file
// file deepcode ignore HardcodedNonCryptoSecret: No hardcoded secrets present in this file

export default {
  com_ui_examples: '例',
  com_ui_new_chat: '新規チャット',
  com_ui_example_quantum_computing: '量子コンピュータを簡潔に説明してください',
  com_ui_example_10_year_old_b_day: '10歳の誕生日で行うクリエイティブなアイデアはありますか？',
  com_ui_example_http_in_js: 'JavascriptでHTTPリクエストを作成するにはどうすればよいですか？',
  com_ui_capabilities: '能力(機能)',
  com_ui_capability_remember: 'ユーザと話した以前の会話の内容を参照します',
  com_ui_capability_correction: 'ユーザーの追加の質問を許可する',
  com_ui_capability_decline_requests: '不適切な要求を拒否するように学習されています',
  com_ui_limitations: '制限事項',
  com_ui_limitation_incorrect_info: '誤った情報を生成することがあります',
  com_ui_limitation_harmful_biased: '有害な指示や偏った内容を生成する可能性があります',
  com_ui_limitation_limited_2021: '2021年以降の出来事に関しては知識に制限があります',
  com_ui_experimental: 'Experimental',
  com_ui_input: '入力',
  com_ui_close: '閉じる',
  com_ui_model: 'モデル',
  com_ui_select_model: 'モデル選択',
  com_ui_use_prompt: 'プロンプトの利用',
  com_ui_prev: '前',
  com_ui_next: '次',
  com_ui_stop: '止める',
  com_ui_prompt_templates: 'プロンプトテンプレート',
  com_ui_hide_prompt_templates: 'プロンプトテンプレートを非表示',
  com_ui_showing: '表示',
  com_ui_of: 'of',
  com_ui_entries: 'エントリー',
  com_ui_pay_per_call: 'すべてのAIモデルを1つの場所で。支払いは使った分だけ',
  com_ui_new_footer: 'すべてのAIモデルを1つの場所で。',
  com_ui_enter: 'Enter',
  com_ui_submit: '送信する',
  com_ui_upload_success: 'アップロード成功',
  com_ui_upload_error: 'ファイルのアップロード中にエラーが発生しました。',
  com_ui_upload_invalid: '不正なファイルです',
  com_ui_cancel: 'キャンセル',
  com_ui_save: '保存',
  com_ui_save_submit: '保存 & 送信',
  com_ui_copy_to_clipboard: 'クリップボードへコピー',
  com_ui_copied_to_clipboard: 'コピーしました',
  com_ui_regenerate: '再度 生成する',
  com_ui_continue: '続きを生成する',
  com_ui_edit: '編集',
  com_ui_success: '成功',
  com_ui_all: 'すべて',
  com_ui_clear: '削除する',
  com_ui_revoke: '無効にする',
  com_ui_revoke_info: 'ユーザへ発行した認証情報をすべて無効にする。',
  com_ui_confirm_action: '実行する',
  com_ui_chats: 'チャット',
  com_ui_delete: '削除',
  com_ui_delete_conversation: 'チャットを削除しますか？',
  com_ui_delete_conversation_confirm: 'このチャットは削除されます。',
  com_ui_preview: 'プレビュー',
  com_ui_upload: 'アップロード',
  com_ui_connect: '接続',
  com_auth_error_login:
    '入力された情報ではログインできませんでした。認証情報を確認した上で再度お試しください。',
  com_auth_error_login_rl:
    'お使いのIPアドレスから短時間に多数のログイン試行がありました。しばらくしてから再度お試しください。',
  com_auth_error_login_ban: '本サービスの利用規約違反のため、一時的にアカウントを停止しました。',
  com_auth_error_login_server:
    'サーバーエラーが発生しています。。しばらくしてから再度お試しください。',
  com_auth_no_account: 'アカウントをお持ちでない場合はこちら',
  com_auth_sign_up: '新規登録',
  com_auth_sign_in: 'ログイン',
  com_auth_google_login: 'Googleでログイン',
  com_auth_facebook_login: 'Facebookでログイン',
  com_auth_github_login: 'Githubでログイン',
  com_auth_discord_login: 'Discordでログイン',
  com_auth_email: 'メール',
  com_auth_email_required: 'メールアドレスは必須です',
  com_auth_email_min_length: 'メールアドレスは最低6文字で入力してください',
  com_auth_email_max_length: 'メールアドレスは最大120文字で入力してください',
  com_auth_email_pattern: '有効なメールアドレスを入力してください',
  com_auth_email_address: 'メールアドレス',
  com_auth_password: 'パスワード',
  com_auth_password_required: 'パスワードは必須です',
  com_auth_password_min_length: 'パスワードは最低8文字で入力してください',
  com_auth_password_max_length: 'パスワードは最大128文字で入力してください',
  com_auth_password_forgot: 'パスワードを忘れた場合はこちら',
  com_auth_password_confirm: '確認用パスワード',
  com_auth_password_not_match: 'パスワードが一致しません',
  com_auth_continue: '続ける',
  com_auth_create_account: 'アカウント登録',
  com_auth_error_create: 'アカウント登録に失敗しました。もう一度お試しください。',
  com_auth_full_name: 'フルネーム',
  com_auth_name_required: 'フルネームは必須です',
  com_auth_name_min_length: 'フルネームは最低3文字で入力してください',
  com_auth_name_max_length: 'フルネームは最大80文字で入力してください',
  com_auth_username: 'ユーザ名 (オプション)',
  com_auth_username_required: 'ユーザー名は必須です',
  com_auth_username_min_length: 'ユーザ名は最低2文字で入力してください',
  com_auth_username_max_length: 'ユーザ名は最大20文字で入力してください',
  com_auth_already_have_account: '既にアカウントがある場合はこちら',
  com_auth_login: 'ログイン',
  com_auth_reset_password: 'パスワードをリセット',
  com_auth_click: 'クリック',
  com_auth_here: 'こちら',
  com_auth_to_reset_your_password: 'to reset your password.',
  com_auth_reset_password_link_sent: 'メールを送信',
  com_auth_reset_password_email_sent: 'パスワードのリセット方法を記載したメールを送信しました。',
  com_auth_error_reset_password:
    'パスワードのリセット中に問題が発生しました。指定されたメールアドレスのユーザは存在しません。別のメールアドレスでもう一度お試しください。',
  com_auth_reset_password_success: 'パスワードのリセットに成功しました',
  com_auth_login_with_new_password: '新しいパスワードでログインをお試しください。',
  com_auth_error_invalid_reset_token: '無効なパスワードリセットトークンです。',
  com_auth_click_here: 'ここをクリック',
  com_auth_to_try_again: 'to try again.',
  com_auth_submit_registration: '登録をする',
  com_auth_welcome_back: 'おかえりなさい',
  com_endpoint_open_menu: 'メニューを開く',
  com_endpoint_bing_enable_sydney: 'Sydney有効化',
  com_endpoint_bing_to_enable_sydney: '(Sydneyを利用する)',
  com_endpoint_bing_jailbreak: 'ジェイルブレイク',
  com_endpoint_bing_context_placeholder:
    'Bingは最大7kトークンの「コンテキスト」を参照できます。具体的な上限は不明ですが、7kトークンを超えるとエラーになる可能性があります。',
  com_endpoint_bing_system_message_placeholder:
    '警告: この機能を悪用するとBingの利用を「制限」される可能性があります。すべての内容を表示するには「System Message」をクリックしてください。省略された場合は、安全と考えられる「Sydney」プリセットが使われます',
  com_endpoint_system_message: 'システムメッセージ',
  com_endpoint_message: 'メッセージ',
  com_endpoint_message_not_appendable: 'メッセージを編集、再入力してください。',
  com_endpoint_default_blank: 'デフォルト: 空',
  com_endpoint_default_false: 'デフォルト: false',
  com_endpoint_default_creative: 'デフォルト: 創造的',
  com_endpoint_default_empty: 'デフォルト: 空',
  com_endpoint_default_with_num: 'デフォルト: {0}',
  com_endpoint_context: 'コンテキスト',
  com_endpoint_tone_style: 'トーンスタイル',
  com_endpoint_token_count: 'トークン数',
  com_endpoint_output: '出力',
  com_endpoint_google_temp:
    '大きい値 = ランダム性が増します。低い値 = より決定論的になります。この値を変更するか、Top P の変更をおすすめしますが、両方を変更はおすすめしません。',
  com_endpoint_google_topp:
    'Top-p はモデルがトークンをどのように選択して出力するかを変更します。K(topKを参照)の確率の合計がtop-pの確率と等しくなるまでのトークンが選択されます。',
  com_endpoint_google_topk:
    'Top-k はモデルがトークンをどのように選択して出力するかを変更します。top-kが1の場合はモデルの語彙に含まれるすべてのトークンの中で最も確率が高い1つが選択されます(greedy decodingと呼ばれている)。top-kが3の場合は上位3つのトークンの中から選択されます。(temperatureを使用)',
  com_endpoint_google_maxoutputtokens:
    ' 	生成されるレスポンスの最大トークン数。短いレスポンスには低い値を、長いレスポンスには高い値を指定します。',
  com_endpoint_google_custom_name_placeholder: 'Googleのカスタム名を設定する',
  com_endpoint_prompt_prefix_placeholder:
    'custom instructions か context を設定する。空の場合は無視されます。',
  com_endpoint_custom_name: 'プリセット名',
  com_endpoint_prompt_prefix: 'プロンプトの先頭',
  com_endpoint_temperature: 'Temperature',
  com_endpoint_default: 'デフォルト',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top K',
  com_endpoint_max_output_tokens: '最大出力トークン数',
  com_endpoint_openai_temp:
    '大きい値 = ランダム性が増します。低い値 = より決定論的になります。この値を変更するか、Top P の変更をおすすめしますが、両方を変更はおすすめしません。',
  com_endpoint_openai_max:
    '生成されるトークンの最大値。入力トークンと出力トークンの長さの合計は、モデルのコンテキスト長によって制限されます。',
  com_endpoint_openai_topp:
    'nucleus sampling と呼ばれるtemperatureを使用したサンプリングの代わりに、top_p確率質量のトークンの結果を考慮します。つまり、0.1とすると確率質量の上位10%を構成するトークンのみが考慮されます。この値かtemperatureの変更をおすすめしますが、両方を変更はおすすめしません。',
  com_endpoint_openai_freq:
    '-2.0から2.0の値。正の値を入力すると、テキストの繰り返し頻度に基づいたペナルティを課し、文字通り「同じ文言」を繰り返す可能性を減少させる。',
  com_endpoint_openai_pres:
    '-2.0から2.0の値。正の値は入力すると、新規トークンの出現に基づいたペナルティを課し、新しいトピックについて話す可能性を高める。',
  com_endpoint_openai_resend:
    'これまでに添付した画像を全て再送信します。注意：トークン数が大幅に増加したり、多くの画像を添付するとエラーが発生する可能性があります。',
  com_endpoint_openai_detail:
    'Visionリクエストの解像度を選択します。"Low"はコストが安くて低解像度、"Highは"コストが高くて高解像度"、"Auto"は画像の解像度に基づいて自動的に選択します。',
  com_endpoint_openai_custom_name_placeholder: 'ChatGPTのカスタム名を設定する',
  com_endpoint_openai_prompt_prefix_placeholder:
    'システムメッセージに含める Custom Instructions。デフォルト: none',
  com_endpoint_anthropic_temp:
    '0から1の値。分析的・多岐の選択になる課題には0に近い値を入力する。創造的・生成的な課題には1に近い値を入力する。この値か Top P の変更をおすすめしますが、両方の変更はおすすめしません。',
  com_endpoint_anthropic_topp:
    'Top-p はモデルがトークンをどのように選択して出力するかを変更する。K(topKを参照)の確率の合計がtop-pの確率と等しくなるまでのトークンが選択される。',
  com_endpoint_anthropic_topk:
    'Top-k はモデルがトークンをどのように選択して出力するかを変更する。top-kが1の場合はモデルの語彙に含まれるすべてのトークンの中で最も確率が高い1つが選択される(greedy decodingと呼ばれている)。top-kが3の場合は上位3つのトークンの中から選択される。(temperatureを使用)',
  com_endpoint_anthropic_maxoutputtokens:
    '生成されるレスポンスの最大トークン数。短いレスポンスには低い値を、長いレスポンスには高い値を指定する。',
  com_endpoint_anthropic_custom_name_placeholder: 'Anthropicのカスタム名を設定する',
  com_endpoint_frequency_penalty: '頻度によるペナルティ',
  com_endpoint_presence_penalty: '既存性によるペナルティ',
  com_endpoint_plug_use_functions: 'Functionsを使用',
  com_endpoint_plug_resend_images: '画像の再送信',
  com_endpoint_plug_image_detail: '画像の詳細',
  com_endpoint_plug_skip_completion: 'Skip Completion',
  com_endpoint_disabled_with_tools: 'disabled with tools',
  com_endpoint_disabled_with_tools_placeholder: 'Disabled with Tools Selected',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    'システムメッセージに含める Custom Instructions。デフォルト: none',
  com_endpoint_import: 'インポート',
  com_endpoint_set_custom_name: 'このプリセットを見つけやすいように名前を設定する。',
  com_endpoint_preset_delete_confirm: '本当にこのプリセットを削除しますか？',
  com_endpoint_preset_clear_all_confirm: '本当にすべてのプリセットを削除しますか？',
  com_endpoint_preset_import: 'プリセットのインポートが完了しました',
  com_endpoint_preset_import_error: 'プリセットのインポートに失敗しました。もう一度お試し下さい。',
  com_endpoint_preset_save_error: 'プリセットの保存に失敗しました。もう一度お試し下さい。',
  com_endpoint_preset_delete_error: 'プリセットの削除に失敗しました。もう一度お試し下さい。',
  com_endpoint_preset_default_removed: 'が無効化されました。',
  com_endpoint_preset_default_item: 'デフォルト:',
  com_endpoint_preset_default_none: '現在有効なプリセットはありません。',
  com_endpoint_preset_title: 'プリセット',
  com_endpoint_preset_saved: '保存しました!',
  com_endpoint_preset_default: 'が有効化されました。',
  com_endpoint_preset: 'プリセット',
  com_endpoint_presets: 'プリセット',
  com_endpoint_preset_selected: 'プリセットが有効化されました!',
  com_endpoint_preset_selected_title: '有効化',
  com_endpoint_preset_name: 'プリセット名',
  com_endpoint_new_topic: '新規トピック',
  com_endpoint: 'エンドポイント',
  com_endpoint_hide: '非表示',
  com_endpoint_show: '表示',
  com_endpoint_examples: ' プリセット名',
  com_endpoint_completion: 'コンプリーション',
  com_endpoint_agent: 'エージェント',
  com_endpoint_show_what_settings: '設定 {0} を表示する',
  com_endpoint_save: '保存',
  com_endpoint_export: 'エクスポート',
  com_endpoint_save_as_preset: 'プリセットとして保存する',
  com_endpoint_presets_clear_warning:
    '本当にすべてのプリセットを削除しますか？ この操作は元に戻せません。',
  com_endpoint_not_implemented: 'まだ実装されていません',
  com_endpoint_no_presets: 'プリセットがありません',
  com_endpoint_not_available: 'エンドポイントは利用できません',
  com_endpoint_view_options: 'オプションを見る',
  com_endpoint_save_convo_as_preset: '会話をプリセットとして保存する',
  com_endpoint_my_preset: 'Myプリセット',
  com_endpoint_agent_model: 'エージェントモデル (推奨: GPT-3.5)',
  com_endpoint_completion_model: 'コンプリーションモデル (推奨: GPT-4)',
  com_endpoint_func_hover: 'プラグインをOpenAIの関数として使えるようにする',
  com_endpoint_skip_hover:
    'コンプリーションのステップをスキップする。(最終的な回答と生成されたステップをレビューする機能)',
  com_endpoint_config_key: 'API Keyを設定',
  com_endpoint_config_placeholder: 'ヘッダーメニューからAPI Keyを設定してください。',
  com_endpoint_config_key_for: 'API Key の設定: ',
  com_endpoint_config_key_name: 'Key',
  com_endpoint_config_value: '値を入力してください',
  com_endpoint_config_key_name_placeholder: 'API key を入力してください',
  com_endpoint_config_key_encryption: '鍵は暗号化されます。削除予定日:',
  com_endpoint_config_key_expiry: 'すでに有効期限切れです',
  com_endpoint_config_click_here: 'ここをクリック',
  com_endpoint_config_google_service_key: 'Google Service Account Key',
  com_endpoint_config_google_cloud_platform: '(from Google Cloud Platform)',
  com_endpoint_config_google_api_key: 'Google API Key',
  com_endpoint_config_google_gemini_api: '(Gemini API)',
  com_endpoint_config_google_api_info: 'Gemeni用のGenerative Language API keyを取得するには',
  com_endpoint_config_key_import_json_key: 'Service Account JSON Key をインポートする。',
  com_endpoint_config_key_import_json_key_success:
    'Service Account JSON Keyのインポートに成功しました。',
  com_endpoint_config_key_import_json_key_invalid:
    '無効なService Account JSON Keyです。正しいファイルかどうか確認してください。',
  com_endpoint_config_key_get_edge_key:
    'Bing用のアクセストークンを取得するためにログインをしてください: ',
  com_endpoint_config_key_get_edge_key_dev_tool:
    'サイトにログインした状態で、開発ツールまたは拡張機能を使用して、_U クッキーの内容をコピーします。もし失敗する場合は次の手順に従ってください。',
  com_endpoint_config_key_edge_instructions: '手順',
  com_endpoint_config_key_edge_full_key_string: 'to provide the full cookie strings.',
  com_endpoint_config_key_chatgpt:
    'ChatGPTの「無料版」のアクセストークンを入手するためにへログインをしてください:',
  com_endpoint_config_key_chatgpt_then_visit: 'つぎに、ここへアクセスしてください:',
  com_endpoint_config_key_chatgpt_copy_token: 'トークンをコピーしてください。',
  com_endpoint_config_key_google_need_to: 'こちらを有効化する必要があります:',
  com_endpoint_config_key_google_vertex_ai: 'Vertex AI を有効化',
  com_endpoint_config_key_google_vertex_api: 'API (Google Cloud) 次に、',
  com_endpoint_config_key_google_service_account: 'サービスアカウントを作成する',
  com_endpoint_config_key_google_vertex_api_role:
    '必ず「作成して続行」をクリックして、少なくとも「Vertex AI ユーザー」権限を与えてください。最後にここにインポートするJSONキーを作成してください。',
  com_nav_welcome_message: 'How can I help you today?',
  com_nav_auto_scroll: 'チャットを開いたときに最新まで自動でスクロール',
  com_nav_modular_chat: '会話の途中でのエンドポイント切替を有効化',
  com_nav_latex_parsing:
    'メッセージ内の LaTeX の構文解析 (パフォーマンスに影響する可能性があります。)',
  com_nav_profile_picture: 'プロフィール画像',
  com_nav_change_picture: '画像を変更',
  com_nav_plugin_store: 'プラグインストア',
  com_nav_plugin_search: 'プラグイン検索',
  com_show_agent_settings: 'エージェント設定を表示',
  com_show_completion_settings: 'コンプリーション設定を表示',
  com_hide_examples: '例を非表示',
  com_show_examples: '例を表示',
  com_nav_plugin_auth_error:
    'このプラグインの認証中にエラーが発生しました。もう一度お試しください。',
  com_nav_export_filename: 'ファイル名',
  com_nav_export_filename_placeholder: 'ファイル名を入力してください',
  com_nav_export_type: '形式',
  com_nav_export_include_endpoint_options: 'エンドポイントのオプションを含める',
  com_nav_enabled: '有効化',
  com_nav_not_supported: 'サポートされていません',
  com_nav_export_all_message_branches: 'すべての子メッセージを含める',
  com_nav_export_recursive_or_sequential: '再帰的? or 順次的?',
  com_nav_export_recursive: '再帰的',
  com_nav_export_conversation: '会話をエクスポートする',
  com_nav_theme: 'テーマ',
  com_nav_theme_system: 'システム',
  com_nav_theme_dark: 'ダーク',
  com_nav_theme_light: 'ライト',
  com_nav_clear_all_chats: 'すべてのチャットを削除する',
  com_nav_confirm_clear: '削除を確定',
  com_nav_close_sidebar: 'サイドバーを閉じる',
  com_nav_open_sidebar: 'サイドバーを開く',
  com_nav_send_message: 'メッセージを送信する',
  com_nav_log_out: 'ログアウト',
  com_nav_user: 'ユーザー',
  com_nav_clear_conversation: '会話を削除する',
  com_nav_clear_conversation_confirm_message:
    '本当にすべての会話を削除しますか？ この操作は取り消せません。',
  com_nav_help_faq: 'ヘルプ & FAQ',
  com_nav_settings: '設定',
  com_nav_search_placeholder: 'メッセージ検索',
  com_nav_setting_general: '一般',
  com_nav_setting_beta: 'ベータ版の機能',
  com_nav_setting_data: 'データ管理',
  com_nav_setting_account: 'アカウント',
};
