import { VERA_PURPLE } from '~/utils/constants';

function ModelsIcon({ color = VERA_PURPLE, className = '' }) {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99927 9.00036H9.0076M11.9455 11.9466C8.04031 15.8519 3.55539 17.6986 1.9282 16.0714C0.301017 14.4442 2.14775 9.95933 6.05299 6.05408C9.95823 2.14884 14.4432 0.302111 16.0703 1.9293C17.6975 3.55648 15.8508 8.0414 11.9455 11.9466ZM11.9455 6.05393C15.8508 9.95918 17.6975 14.4441 16.0703 16.0713C14.4431 17.6985 9.9582 15.8517 6.05296 11.9465C2.14771 8.04125 0.300983 3.55633 1.92817 1.92914C3.55535 0.30196 8.04027 2.14869 11.9455 6.05393ZM9.41594 9.00036C9.41594 9.23048 9.22939 9.41703 8.99927 9.41703C8.76915 9.41703 8.5826 9.23048 8.5826 9.00036C8.5826 8.77025 8.76915 8.5837 8.99927 8.5837C9.22939 8.5837 9.41594 8.77025 9.41594 9.00036Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ModelsIcon;
