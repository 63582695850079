/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import type { ContextType } from '~/common';
import { Nav, MobileNav } from '~/components/Nav';
//import { useAuthStore } from '~/zustand';
import { SKU } from '~/utils/constants';
import { useOrg } from '~/services/queries/orgs';
import { useAuth } from '~/Providers/useAuth';

export default function Root() {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const orgQuery = useOrg();
  const [navVisible, setNavVisible] = useState(() => {
    const savedNavVisible = localStorage.getItem('navVisible');
    return savedNavVisible !== null ? JSON.parse(savedNavVisible) : false;
  });
  const isAtLockedAccountRoute = window.location.pathname.includes('locked-account');

  useEffect(() => {
    if (orgQuery?.data && orgQuery?.isSuccess) {
      const isSandboxMode = orgQuery.data?.sku === SKU.SANDBOX;
      if (!isSandboxMode) setNavVisible(true);
    }
  }, [orgQuery?.isSuccess, orgQuery?.data]);

  if (!isAuthenticated) {
    navigate('/login', { replace: true });
    return null;
  }

  if (orgQuery.isLoading && !isAtLockedAccountRoute) {
    return null;
  }

  return (
    <>
      <div className="flex h-dvh">
        <div className="relative z-0 flex h-full w-full overflow-hidden">
          <Nav navVisible={navVisible} setNavVisible={setNavVisible} />
          <div className="relative flex h-full max-w-full flex-1 flex-col overflow-hidden">
            <MobileNav setNavVisible={setNavVisible} />
            <Outlet context={{ navVisible, setNavVisible } satisfies ContextType} />
          </div>
        </div>
      </div>
    </>
  );
}
