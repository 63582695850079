import type { FC } from 'react';
import { BookCopy } from 'lucide-react';
import { Content, Portal, Root, Trigger } from '@radix-ui/react-popover';
import { useChatContext } from '~/Providers';
import { cn } from '~/utils';
import TitleButton from './UI/TitleButton';
import React from 'react';
import RagDocModal from './RagDocModal';
import PrimaryButton from '~/components/Buttons/PrimaryButton';
import RagDocIcon from '~/components/svg/RagDocIcon';
import { Checkbox } from '~/components/ui/Checkbox';
import ThreeDotsMenuIcon from '~/components/svg/ThreeDotsMenuIcon';
import { useRagDocuments } from '~/services/queries/sources';
import { useUpdateSelectedRagDocuments } from '~/services/mutations/sources';
import ThreeDotsMenu from './UI/ThreeDotsMenu';
import { exportDocumentFromStore } from '~/services/api/exports';

const RagMenu: FC = () => {
  const [docModalOpen, setDocModalOpen] = React.useState(false);
  const { isSubmitting } = useChatContext();
  const ragDocumentsQuery = useRagDocuments();
  const updateSelectedRagDocumentsMutation = useUpdateSelectedRagDocuments();

  const onUnselectAll = async () => {
    await updateSelectedRagDocumentsMutation.mutateAsync([]);
  };

  // const handleSubmission = async () => {
  //   await updateSelectedRagDocumentsMutation.mutateAsync(selectedDocs);
  //   onOpenChange(false);
  //   //showToast({ message: 'Selected documents updated successfully' });
  // };

  return (
    <>
      <Root>
        <TitleButton
          primaryText={'Artifacts'}
          isDisabled={isSubmitting}
          secondaryText={
            ragDocumentsQuery.data?.selected.length ? (
              <span className="ml-2 flex h-5 w-5 items-center justify-center rounded-full bg-vteal text-xs text-white">
                {ragDocumentsQuery.data?.selected.length}
              </span>
            ) : (
              <></>
            )
          }
        />
        <Portal>
          <div
            style={{
              position: 'fixed',
              left: '0px',
              top: '0px',
              transform: 'translate3d(268px, 50px, 0px)',
              minWidth: 'max-content',
              zIndex: 'auto',
            }}
          >
            <Content
              side="bottom"
              align="center"
              className="mt-2 max-h-[495px] overflow-x-hidden rounded-lg bg-vwhite shadow-lg dark:border-gray-700 dark:bg-vnavy-dark-card dark:text-white md:min-w-[320px]"
            >
              <div className="flex h-full grow flex-col items-center justify-center gap-2 p-4">
                {ragDocumentsQuery.data?.selected.length ? (
                  <>
                    <label className="w-full rounded bg-transparent py-1  text-xs font-medium font-normal text-gray-400 transition-colors dark:bg-transparent dark:text-gray-300 sm:w-72">
                      Enrich conversations with insights from the selected documents.
                    </label>
                    {ragDocumentsQuery.data.documents
                      .filter((doc) => doc.active)
                      .map((doc) => (
                        <RagDocItem
                          key={doc.document_name}
                          name={doc.document_name}
                          createdBy={doc.created_by}
                        />
                      ))}

                    <div className="mb-2 w-full border border-gray-100" />
                    <div className="flex w-full items-center justify-between">
                      <button
                        className="text-sm font-medium text-gray-600 dark:text-gray-300"
                        onClick={onUnselectAll}
                      >
                        Unselect all
                      </button>
                      <button
                        className="text-sm font-medium text-vteal"
                        onClick={() => setDocModalOpen(true)}
                      >
                        Select more
                      </button>
                    </div>
                  </>
                ) : (
                  <NoItemsSelected isSubmitting={isSubmitting} setDocModalOpen={setDocModalOpen} />
                )}
              </div>
            </Content>
          </div>
        </Portal>
        {/* {preset && <EditPresetDialog submitPreset={submitPreset} exportPreset={exportPreset} />} */}
      </Root>
      <RagDocModal open={docModalOpen} onOpenChange={setDocModalOpen} />
    </>
  );
};

const NoItemsSelected = ({ isSubmitting, setDocModalOpen }) => {
  return (
    <>
      <span
        className={`mr-2 ms-3 text-sm font-medium ${
          isSubmitting ? 'text-gray-400' : 'text-gray-900'
        }  dark:text-white`}
      >
        No documents selected
      </span>
      <label
        htmlFor="default-preset"
        className="w-40 rounded bg-transparent px-2 py-1 text-center text-xs font-medium font-normal text-gray-400 transition-colors dark:bg-transparent dark:text-gray-300 sm:w-72"
      >
        Enrich conversations with insights from the selected documents.
      </label>

      <PrimaryButton
        onClick={() => {
          setDocModalOpen(true);
        }}
      >
        Select Documents
      </PrimaryButton>
    </>
  );
};

const RagDocItem = ({ name, createdBy }) => {
  return (
    <div className="flex w-full items-center justify-between py-2 ">
      <div className="flex items-center">
        {/* <Checkbox
          className="mr-4 focus:ring-opacity-20 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-50 dark:focus:ring-gray-600 dark:focus:ring-opacity-50 dark:focus:ring-offset-0"
          // checked={rowIsSelected(row)}
          // onChange={(e) => onSelectRow(row, e.target.checked)}
        /> */}
        <div className="flex items-center gap-3">
          <RagDocIcon />{' '}
          <div>
            <p className="text-md max-w-[190px] truncate font-medium">{name}</p>{' '}
            <div className="text-sm font-light text-gray-600 dark:text-gray-500">{createdBy}</div>
          </div>
        </div>
      </div>
      <ThreeDotsMenu
        options={[
          {
            label: 'Download',
            onSelect: async () => {
              if (name) {
                await exportDocumentFromStore(name);
              }
            },
          },
          // { label: 'Preview in Tab', onSelect: () => console.log('selected') },
        ]}
      />
    </div>
  );
};

export default RagMenu;
