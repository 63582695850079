import type { FC } from 'react';
import { Close } from '@radix-ui/react-popover';
import { EModelEndpoint } from 'librechat-data-provider';
import MenuSeparator from '../UI/MenuSeparator';
import MenuItem from './MenuItem';

const ModelItems: FC<{
  userSupportsImageUpload: boolean;
  models: EModelEndpoint[];
  selected: EModelEndpoint | '';
  isSandbox: boolean;
}> = ({ models, selected, isSandbox, userSupportsImageUpload }) => {
  return (
    <>
      {models &&
        models.map((model, i) => {
          if (!model) {
            return null;
          }
          return (
            <Close asChild key={`model-${model.id_}`}>
              <div key={`model-${model.id_}`}>
                <MenuItem
                  key={`model-item-${model.id_}`}
                  title={model.id_}
                  value={model.id_}
                  selected={selected === model.id_}
                  data-testid={`model-item-${model.id_}`}
                  supportsMedia={
                    !isSandbox && model.capabilities?.image_input && userSupportsImageUpload
                  }
                  // description="With DALL·E, browsing and analysis"
                />
                {i !== models.length - 1 && <MenuSeparator />}
              </div>
            </Close>
          );
        })}
    </>
  );
};

export default ModelItems;
