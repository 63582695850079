import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToastContext } from '~/Providers/ToastContext';
import { getErrorMessage } from '~/utils/error';
import { uploadSingleFile } from '../api/chat';
import { PresignedResponse } from '~/types/chat';
import { ExtendedFile, NotificationSeverity } from '~/common';

export function useUploadFiles() {
  const { showToast } = useToastContext();

  return useMutation<PresignedResponse[], Error, ExtendedFile[]>({
    mutationFn: async (files: ExtendedFile[]) => {
      // Process each file individually in parallel
      const uploadResults = await Promise.all(files.map((file) => uploadSingleFile(file.file)));
      return uploadResults;
    },
    onSettled(data, error) {
      if (error) {
        showToast({
          message: `${getErrorMessage(error)}`,
          duration: 5000,
          severity: NotificationSeverity.ERROR,
        });
      } else {
        return data;
      }
    },
  });
}
