import { Box } from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { BASE_API_URL } from '~/services/api/setup';
import { VERA_HEADER } from '~/utils/constants';
import { Spinner } from '../svg';

function StripeCheckout({ orgId, accessToken }: { orgId: string; accessToken: string }) {
  const [customerSecret, setCustomerSecret] = useState('');

  useEffect(() => {
    const fetchCustomerSecret = async () => {
      try {
        if (orgId && accessToken) {
          const response = await axios.post(
            `${BASE_API_URL}/stripe-customer`,
            {
              org_id: orgId,
            },
            {
              headers: {
                [VERA_HEADER]: accessToken,
              },
            },
          );

          if (response.data?.stripe_customer_secret) {
            setCustomerSecret(response.data.stripe_customer_secret);
          } else {
            console.error('No customerSecret found in the response');
          }
        }
      } catch (error) {
        console.error('Error fetching customer secret:', error);
      }
    };

    fetchCustomerSecret();
  }, [orgId, accessToken]);

  return (
    <Box borderWidth="1px" w="fit-content" my={6}>
      {!orgId || !accessToken ? (
        <Spinner color="black" />
      ) : (
        <stripe-pricing-table
          pricing-table-id={import.meta.env.VITE_STRIPE_TABLE_ID}
          publishable-key={import.meta.env.VITE_STRIPE_PK}
          customer-session-client-secret={customerSecret}
          allow_promotion_codes={true}
        ></stripe-pricing-table>
      )}
    </Box>
  );
}

export default StripeCheckout;
