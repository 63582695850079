import { AxiosError } from 'axios';

export function getErrorMessage(error: AxiosError | Error | null | unknown | string) {
  // debugger;
  if (error instanceof AxiosError) {
    return error.response ? error.response.data.error : error.message;
  } else if (error instanceof Error) {
    return error.message;
  } else if (!error) {
    return 'An unknown error has occurred.';
  }
  return JSON.stringify(error);
}
