import React from 'react';
import cn from '~/utils/cn';

function SecondaryButton({ children, className = '', onClick }) {
  return (
    <button
      className={cn(
        'rounded-md bg-gray-50 p-3 font-medium hover:bg-gray-200 dark:bg-vwhite/5 dark:hover:bg-vnavy-dark-card',
        className,
      )}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {children}
    </button>
  );
}

export default SecondaryButton;
