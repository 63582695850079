import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//import { useAuthStore } from '~/zustand';
import { useAuth } from '~/Providers/useAuth';
import { useOrg } from '~/services/queries/orgs';

export default function useAuthRedirect() {
  const { isAuthenticated } = useAuth();
  const orgQuery = useOrg();
  const navigate = useNavigate();
  const isAtTermsRoute =
    window.location.pathname.includes('terms-of-use') ||
    window.location.pathname.includes('privacy-policy');
  const isAtRegistrationRoute =
    window.location.pathname.includes('try-vera') || window.location.pathname.includes('register');
  const isAtUpgradeRoute = window.location.pathname.includes('upgrade');

  useEffect(() => {
    if (isAtTermsRoute) {
      return;
    } else if (!isAuthenticated && !isAtRegistrationRoute) {
      navigate('/login', { replace: true });
    } else if (isAuthenticated && isAtRegistrationRoute) {
      navigate('/c/new', { replace: true });
    } else if (
      isAuthenticated &&
      isAtUpgradeRoute &&
      orgQuery.isFetched &&
      orgQuery.data?.sku !== 'sandbox'
    ) {
      navigate('/c/new', { replace: true });
    }
  }, [isAuthenticated, navigate, orgQuery.isFetched]);

  return {
    isAuthenticated,
  };
}
