import { useEffect } from 'react';
import { FileSources } from 'librechat-data-provider';
import type { ExtendedFile } from '~/common';
import { useDragHelpers, useSetFilesToDelete } from '~/hooks';
import DragDropOverlay from './Input/Files/DragDropOverlay';
import { useDeleteFilesMutation } from '~/data-provider';
import { useSandboxStore } from '~/zustand/sandbox';
import { useChatSettings, useUserModels } from '~/services/queries/models';
import { useUser } from '~/services/queries/user';
import { useAuth } from '~/Providers/useAuth';
import { Model } from '~/types/models';

export default function Presentation({ children }: { children: React.ReactNode }) {
  const { isOver, canDrop, drop } = useDragHelpers();
  const { isSandbox } = useSandboxStore();
  const chatSettingsQuery = useChatSettings();
  const routingIsActive = chatSettingsQuery.data?.routing_enabled ?? false;
  const { user } = useAuth();
  const userQuery = useUser(user?.user_id!);
  const modelsQuery = useUserModels();
  const models = modelsQuery.data ?? [];
  const hasNoModels = !modelsQuery.isLoading && models.length === 0;
  const atLeastOneModelHasMedia = models.some((model) => model.capabilities?.image_input);
  const selectedModel: Model | '' =
    models.find((model) => model.id_ === chatSettingsQuery.data?.default_model) ?? '';
  const setFilesToDelete = useSetFilesToDelete();
  const { mutateAsync } = useDeleteFilesMutation({
    onSuccess: () => {
      console.log('Temporary Files deleted');
      setFilesToDelete({});
    },
    onError: (error) => {
      console.log('Error deleting temporary files:', error);
    },
  });

  useEffect(() => {
    const filesToDelete = localStorage.getItem('filesToDelete');
    const map = JSON.parse(filesToDelete ?? '{}') as Record<string, ExtendedFile>;
    const files = Object.values(map)
      .filter((file) => file.filepath)
      .map((file) => ({
        file_id: file.file_id,
        filepath: file.filepath as string,
        source: file.source as FileSources,
      }));

    if (files.length === 0) {
      return;
    }
    mutateAsync({ files });
  }, [mutateAsync]);

  const supportsImageUpload =
    userQuery?.data?.images_allowed &&
    userQuery?.data?.upload_allowed &&
    !isSandbox &&
    !hasNoModels &&
    (routingIsActive
      ? atLeastOneModelHasMedia
      : selectedModel
      ? selectedModel.capabilities.image_input
      : false);

  const isActive = canDrop && isOver && supportsImageUpload;
  return (
    <div
      ref={drop}
      className="relative flex w-full grow overflow-hidden bg-vwhite dark:bg-vnavy-dark-mode"
    >
      <div className="transition-width relative flex h-full w-full flex-1 flex-col items-stretch overflow-hidden bg-vwhite pt-0 dark:bg-vnavy-dark-mode">
        <div className="flex h-full flex-col" role="presentation" tabIndex={0}>
          {children}
          {isActive && <DragDropOverlay />}
        </div>
      </div>
    </div>
  );
}
