import React from 'react';
import cn from '~/utils/cn';

function PrimaryButton({ children, className = '', onClick }) {
  return (
    <button
      className={cn(
        'transform rounded-md bg-vyellow px-4 py-3 font-medium tracking-wide text-vnavy  transition-all duration-300 hover:opacity-75 focus:outline-none dark:bg-vyellow  dark:text-vnavy',
        className,
      )}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {children}
    </button>
  );
}

export default PrimaryButton;
