import { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import { DndProvider } from 'react-dnd';
import { RouterProvider } from 'react-router-dom';
import * as RadixToast from '@radix-ui/react-toast';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider, QueryCache } from '@tanstack/react-query';
import { ScreenshotProvider, ThemeProvider, useApiErrorBoundary } from './hooks';
import { ToastProvider, AssistantsProvider } from './Providers';
import Toast from './components/ui/Toast';
import { router } from './routes';
import {
  addAccessTokenInterceptor,
  addRedirectToLoginErrorInterceptor,
} from './services/api/setup';
import { AuthProvider, useAuth } from './Providers/useAuth.tsx';

const App = () => {
  const { setError } = useApiErrorBoundary();

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error) => {
        if (error?.response?.status === 401) {
          setError(error);
        }
      },
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppInterior />
      </AuthProvider>
    </QueryClientProvider>
  );
};

const AppInterior = () => {
  const { isLoading, getAccessToken, loginWithRedirect, setCrossSubdomainCookies } = useAuth();

  useEffect(() => {
    if (loginWithRedirect && getAccessToken) {
      addAccessTokenInterceptor(getAccessToken, setCrossSubdomainCookies);
      addRedirectToLoginErrorInterceptor(loginWithRedirect);
    }
  }, [getAccessToken, loginWithRedirect]);

  if (isLoading) return <></>;

  return (
    <RecoilRoot>
      <ThemeProvider>
        <RadixToast.Provider>
          <ToastProvider>
            <AssistantsProvider>
              <DndProvider backend={HTML5Backend}>
                <RouterProvider router={router} />
                {import.meta.env.MODE === 'development' && (
                  <ReactQueryDevtools initialIsOpen={false} position="top-right" />
                )}
                <Toast />
                <RadixToast.Viewport className="pointer-events-none fixed inset-0 z-[1000] mx-auto my-2 flex max-w-[560px] flex-col items-stretch justify-start md:pb-5" />
              </DndProvider>
            </AssistantsProvider>
          </ToastProvider>
        </RadixToast.Provider>
      </ThemeProvider>
    </RecoilRoot>
  );
};

export default () => (
  <ScreenshotProvider>
    <App />
  </ScreenshotProvider>
);
