import axios_ from 'axios';
import { VERA_HEADER } from '~/utils/constants';
import { Auth } from '~/types/auth';
import secureLocalStorage from 'react-secure-storage';

export const BASE_API_URL = import.meta.env.VITE_VERA_API;
export const axios = axios_.create({
  baseURL: BASE_API_URL,
});

// ! -- DEPRECATED --
export const appLocalStorage =
  process.env.NODE_ENV === 'development' ? localStorage : secureLocalStorage;
// Set the AUTH token for any request
axios.interceptors.request.use(function (config) {
  const auth: Auth | null = JSON.parse((appLocalStorage.getItem('auth') as string) ?? 'null');
  if (auth) config.headers[VERA_HEADER] = auth.access_token;
  return config;
});
// -------------------

// Sets access tokens in all api requests
// this interceptor is only added when the auth0 instance
// is ready and exports the getAccessTokenSilently method
export const addAccessTokenInterceptor = (
  getAccessToken: () => Promise<string | undefined>,
  setCrossSubdomainCookies: (accessToken: string) => void,
) => {
  axios.interceptors.request.use(async (config) => {
    const accessToken = await getAccessToken();
    if (accessToken) {
      config.headers[VERA_HEADER] = accessToken;
      setCrossSubdomainCookies(accessToken);
    }
    return config;
  });
};

export const addRedirectToLoginErrorInterceptor = (loginWithRedirect: Function) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // if no response or status => network error => return original error
      if (!error.response || !error.response.status) {
        return Promise.reject(error);
      }
      const { status, config } = error.response;

      // Check if user is already at locked-account route
      const isAtLockedAccountRoute = window.location.pathname.includes('locked-account');
      if (status === 423 && !isAtLockedAccountRoute) {
        window.location.href = '/locked-account';
      }

      // Check if the request is for the login endpoint
      const isLoginRequest = config.url === 'login' && config.method === 'post';
      // if not auth related, return original error
      if (status !== 401 || isLoginRequest) {
        return Promise.reject(error);
      }

      // -- DEPRECATED --
      // On auth errors, wipe session and go to login
      //  appLocalStorage.removeItem('auth');
      // window.location.href = '/login';
      // ----------------

      // On auth errors, go to login
      loginWithRedirect();
      return Promise.reject(error);
    },
  );
};

export const forceLogout = () => {
  appLocalStorage.removeItem('auth');
  window.location.href = '/login';
};
