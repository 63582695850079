// German phrases

export default {
  com_ui_examples: 'Beispiele',
  com_ui_new_chat: 'Neuer Chat',
  com_ui_example_quantum_computing: 'Erkläre Quantencomputing in einfachen Worten',
  com_ui_example_10_year_old_b_day: 'Hast du eine kreative Idee für den Geburtstag eines 10-Jährigen?',
  com_ui_example_http_in_js: 'Wie stelle ich eine HTTP-Anfrage in Javascript?',
  com_ui_capabilities: 'Funktionen',
  com_ui_capability_remember: 'Erinnert sich daran, was der Benutzer zu einem früheren Zeitpunkt in der Konversation gesagt hat',
  com_ui_capability_correction: 'Ermöglicht es dem Benutzer, nachträgliche Korrekturen vorzunehmen',
  com_ui_capability_decline_requests: 'Ermöglicht es, unangemessene Anfragen abzulehnen',
  com_ui_limitations: 'Beschränkungen',
  com_ui_limitation_incorrect_info: 'Kann gelegentlich falsche Informationen erzeugen',
  com_ui_limitation_harmful_biased:
    'Kann gelegentlich schädliche Anweisungen oder verzerrte Inhalte erzeugen',
  com_ui_limitation_limited_2021: 'Begrenztes Wissen über die Welt und Ereignisse nach 2021',
  com_ui_experimental: 'Experimentelle Funktionen',
  com_ui_input: 'Eingabe',
  com_ui_close: 'Schließen',
  com_ui_model: 'KI-Sprachmodell',
  com_ui_select_model: 'Wähle ein KI-modell',
  com_ui_use_prompt: 'Eingabeaufforderung verwenden',
  com_ui_prev: 'Vorherig',
  com_ui_next: 'Nächster',
  com_ui_stop: 'Anhalten',
  com_ui_prompt_templates: 'Prompt-Vorlagen',
  com_ui_hide_prompt_templates: 'Prompt-Vorlagen ausblenden',
  com_ui_showing: 'Zeigen',
  com_ui_of: 'von',
  com_ui_entries: 'Einträge',
  com_ui_pay_per_call: 'Alle KI-Chats an einem Ort. Bezahle pro API-Abruf nutzungsbasiert und nicht pro Monat',
  com_ui_new_footer: 'Alle KI-Chats an einem Ort.',
  com_ui_enter: 'Eingabe',
  com_ui_submit: 'Abschicken',
  com_ui_upload_success: 'Erfolgreich hochgeladene Datei',
  com_ui_upload_error: 'Beim Hochladen deiner Datei ist ein Fehler aufgetreten',
  com_ui_upload_invalid: 'Ungültige Datei zum Hochladen. Muss ein Bild sein, das nicht größer als 2 MB ist',
  com_ui_cancel: 'Abbrechen',
  com_ui_save: 'Speichern',
  com_ui_save_submit: 'Speichern & Absenden',
  com_user_message: 'Du',
  com_ui_copy_to_clipboard: 'In die Zwischenablage kopieren',
  com_ui_copied_to_clipboard: 'In die Zwischenablage kopiert',
  com_ui_regenerate: 'Neu generieren',
  com_ui_continue: 'Fortsetzen',
  com_ui_edit: 'Bearbeiten',
  com_ui_success: 'Erfolg',
  com_ui_all: 'Alle',
  com_ui_clear: 'Entferne',
  com_ui_revoke: 'Widerrufen',
  com_ui_revoke_info: 'Widerrufe alle vom Benutzer angegebenen Anmeldeinformationen',
  com_ui_confirm_action: 'Bestätige Aktion',
  com_ui_chats: 'Chats',
  com_ui_delete: 'Lösche',
  com_ui_delete_conversation: 'Chat löschen?',
  com_ui_delete_conversation_confirm: 'Damit wird gelöscht',
  com_ui_preview: 'Vorschau',
  com_ui_upload: 'Hochladen',
  com_ui_connect: 'Verbinden',
  com_auth_error_login:
    'Die Anmeldung mit den angegebenen Daten war nicht möglich. Bitte überprüfe deine Anmeldedaten und versuche es erneut.',
  com_auth_error_login_rl:
    'Zu viele Anmeldeversuche in einer kurzen Zeitspanne. Bitte versuche es später noch einmal.',
  com_auth_error_login_ban:
    'Dein Konto wurde aufgrund von Verstößen gegen unseren Service vorübergehend gesperrt.',
  com_auth_error_login_server:
    'Es ist ein interner Serverfehler aufgetreten. Bitte warte ein paar Augenblicke und versuche es erneut.',
  com_auth_no_account: 'Du hast kein Konto?',
  com_auth_sign_up: 'Melde dich an',
  com_auth_sign_in: 'Melde dich an',
  com_auth_google_login: 'Mit Google anmelden',
  com_auth_facebook_login: 'Mit Facebook anmelden',
  com_auth_github_login: 'Mit Github anmelden',
  com_auth_discord_login: 'Mit Discord anmelden',
  com_auth_email: 'E-Mail',
  com_auth_email_required: 'E-Mail ist erforderlich',
  com_auth_email_min_length: 'E-Mail muss mindestens 6 Zeichen lang sein',
  com_auth_email_max_length: 'Die E-Mail sollte nicht länger als 120 Zeichen sein',
  com_auth_email_pattern: 'Du musst eine gültige E-Mail-Adresse eingeben',
  com_auth_email_address: 'E-Mail-Adresse',
  com_auth_password: 'Passwort',
  com_auth_password_required: 'Passwort ist erforderlich',
  com_auth_password_min_length: 'Das Passwort muss mindestens 8 Zeichen lang sein',
  com_auth_password_max_length: 'Das Passwort muss weniger als 128 Zeichen lang sein',
  com_auth_password_forgot: 'Passwort vergessen?',
  com_auth_password_confirm: 'Passwort bestätigen',
  com_auth_password_not_match: 'Passwörter stimmen nicht überein',
  com_auth_continue: 'Fortfahren',
  com_auth_create_account: 'Erstelle dein Konto',
  com_auth_error_create:
    'Beim Versuch, dein Konto zu registrieren, ist ein Fehler aufgetreten. Bitte versuche es erneut.',
  com_auth_full_name: 'Vollständiger Name',
  com_auth_name_required: 'Name ist erforderlich',
  com_auth_name_min_length: 'Der Name muss mindestens 3 Zeichen lang sein',
  com_auth_name_max_length: 'Der Name muss weniger als 80 Zeichen lang sein',
  com_auth_username: 'Benutzername (optional)',
  com_auth_username_required: 'Benutzername ist erforderlich',
  com_auth_username_min_length: 'Der Benutzername muss mindestens 2 Zeichen lang sein',
  com_auth_username_max_length: 'Der Benutzername muss weniger als 20 Zeichen lang sein',
  com_auth_already_have_account: 'Hast du schon ein Konto?',
  com_auth_login: 'Anmelden',
  com_auth_reset_password: 'Setze dein Passwort zurück',
  com_auth_click: 'Klick',
  com_auth_here: 'HIER',
  com_auth_to_reset_your_password: 'um dein Passwort zurückzusetzen.',
  com_auth_reset_password_link_sent: 'E-Mail gesendet',
  com_auth_reset_password_email_sent:
    'Es wurde eine E-Mail mit weiteren Anweisungen zum Zurücksetzen deines Passworts an dich gesendet.',
  com_auth_error_reset_password:
    'Es gab ein Problem beim Zurücksetzen deines Passworts. Es wurde kein Benutzer mit der angegebenen E-Mail Adresse gefunden. Bitte versuche es erneut.',
  com_auth_reset_password_success: 'Passwort zurücksetzen erfolgreich',
  com_auth_login_with_new_password: 'Du kannst dich jetzt mit deinem neuen Passwort anmelden.',
  com_auth_error_invalid_reset_token: 'Dieses Passwort-Reset-Token ist nicht mehr gültig.',
  com_auth_click_here: 'Klicke hier',
  com_auth_to_try_again: 'um es erneut zu versuchen.',
  com_auth_submit_registration: 'Anmeldung einreichen',
  com_auth_welcome_back: 'Willkommen zurück',
  com_endpoint_open_menu: 'Menü öffnen',
  com_endpoint_bing_enable_sydney: 'Sydney aktivieren',
  com_endpoint_bing_to_enable_sydney: 'Um Sydney zu aktivieren',
  com_endpoint_bing_jailbreak: 'Jailbreak',
  com_endpoint_bing_context_placeholder:
  'Bing kann bis zu 7k Token für \'context\' verwenden, auf die es in der Konversation Bezug nehmen kann. Der genaue Grenzwert ist nicht bekannt, aber mehr als 7k Token können zu Fehlern führen.',
com_endpoint_bing_system_message_placeholder:
  'WARNUNG: Der Missbrauch dieser Funktion kann dazu führen, dass Ihnen die Nutzung von Bing untersagt wird! Klicken Sie auf \'Systemnachricht\', um vollständige Anweisungen und die Standardnachricht zu erhalten, d.h. die als sicher geltende Voreinstellung \'Sydney\'.',
com_endpoint_system_message: 'System Nachricht',
  com_endpoint_message: 'Nachricht an',
  com_endpoint_message_not_appendable: 'Bearbeite deine Nachricht oder generiere sie neu.',
  com_endpoint_default_blank: 'Standard: leer',
  com_endpoint_default_false: 'Standard: falsch',
  com_endpoint_default_creative: 'Standard: kreativ',
  com_endpoint_default_empty: 'Standard: leer',
  com_endpoint_default_with_num: 'Standard: {0}',
  com_endpoint_context: 'Kontext',
  com_endpoint_tone_style: 'Farb-Stil',
  com_endpoint_token_count: 'Token-Anzahl',
  com_endpoint_output: 'Ausgabe',
  com_endpoint_google_temp:
    'Höhere Werte = zufälliger, während niedrigere Werte = gezielter und deterministischer sind. Wir empfehlen, dies oder Top P zu ändern, aber nicht beides.',
  com_endpoint_google_topp:
    'Top-p ändert, wie das Modell die Token für die Ausgabe auswählt. Die Token werden von der höchsten K-Wahrscheinlichkeit (siehe topK-Parameter) zur niedrigsten ausgewählt, bis die Summe ihrer Wahrscheinlichkeiten gleich dem top-p-Wert ist.',
  com_endpoint_google_topk:
    'Top-k ändert, wie das Modell die Token für die Ausgabe auswählt. Ein top-k von 1 bedeutet, dass das ausgewählte Token das wahrscheinlichste unter allen Token im Vokabular des Modells ist (auch gierige Dekodierung genannt), während ein top-k von 3 bedeutet, dass das nächste Token aus den 3 wahrscheinlichsten Token ausgewählt wird (unter Verwendung der Temperatur).',
  com_endpoint_google_maxoutputtokens:
    ' Maximale Anzahl von Token, die in der Antwort erzeugt werden können. Gib einen niedrigeren Wert für kürzere Antworten und einen höheren Wert für längere Antworten an.',
  com_endpoint_google_custom_name_placeholder: 'Setze einen benutzerdefinierten Namen für Google',
  com_endpoint_prompt_prefix_placeholder: 'Setze benutzerdefinierte Anweisungen oder Kontext. Ignoriert, wenn leer.',
  com_endpoint_custom_name: 'Benutzerdefinierter Name',
  com_endpoint_prompt_prefix: 'Benutzerdefinierte Anweisungen',
  com_endpoint_temperature: 'Temperatur (Kreativität)',
  com_endpoint_default: 'Standard',
  com_endpoint_top_p: 'Top-P',
  com_endpoint_top_k: 'Top-K',
  com_endpoint_max_output_tokens: 'Maximale Ausgabe Tokens',
  com_endpoint_openai_temp:
    'Höhere Werte = zufälliger und kreativer, während niedrigere Werte = gezielter und deterministischer sind. Werte über 1.0 sind nicht empfohlen. Wir empfehlen, dies oder Top P zu ändern, aber nicht beides.',
  com_endpoint_openai_max:
    'Die maximale Anzahl der zu erzeugenden Token. Die Gesamtlänge der eingegebenen und generierten Token wird durch die Kontextlänge des Modells begrenzt.',
  com_endpoint_openai_topp:
    'Eine Alternative zum Sampling mit Temperatur, genannt Nukleus-Sampling, bei dem das Modell die Ergebnisse der Token mit der Wahrscheinlichkeitsmasse top_p berücksichtigt. 0,1 bedeutet also, dass nur die Token mit den obersten 10% Wahrscheinlichkeitsmasse berücksichtigt werden. Wir empfehlen, dies oder die Temperatur zu ändern, aber nicht beides.',
  com_endpoint_openai_freq:
    'Zahl zwischen -2,0 und 2,0. Standard: 0. Positive Werte bestrafen neue Token auf der Grundlage ihrer bisherigen Häufigkeit im Text und verringern so die Wahrscheinlichkeit, dass das Model dieselbe Zeile wortwörtlich wiederholt.',
  com_endpoint_openai_pres:
    'Zahl zwischen -2,0 und 2,0. Standard: 0. Positive Werte bestrafen neue Token, je nachdem, ob sie bereits im Text vorkommen, und erhöhen so die Wahrscheinlichkeit, dass das Model über neue Themen spricht.',
  com_endpoint_openai_resend:
    'Sende alle zuvor angehängten Bilder erneut. Hinweis: Dies kann die Token-Kosten erheblich erhöhen und bei vielen Bildanhängen können Fehler auftreten.',
  com_endpoint_openai_detail:
    'Die Auflösung für Vision-Anfragen. "Niedrig" ist billiger und schneller, "Hoch" ist detaillierter und teurer und "Auto" wählt automatisch zwischen den beiden, je nach Bildauflösung.',
  com_endpoint_openai_custom_name_placeholder: 'Setzt einen benutzerdefinierten Namen für ChatGPT',
  com_endpoint_openai_prompt_prefix_placeholder:
    'Lege benutzerdefinierte Anweisungen fest, die in die System Nachricht aufgenommen werden sollen. Standardmäßig: keine',
  com_endpoint_anthropic_temp:
    'Der Bereich reicht von 0 bis 1. Verwende temp näher an 0 für analytische / Multiple Choice Aufgaben und näher an 1 für kreative und generative Aufgaben. Wir empfehlen, dies oder Top P zu ändern, aber nicht beides.',
  com_endpoint_anthropic_topp:
    'Top-p ändert, wie das Modell die Token für die Ausgabe auswählt. Die Token werden von der höchsten K-Wahrscheinlichkeit (siehe top-k-Parameter) zur niedrigsten ausgewählt, bis die Summe ihrer Wahrscheinlichkeiten gleich dem top-p-Wert ist.',
  com_endpoint_anthropic_topk:
    'Top-k ändert, wie das Modell Token für die Ausgabe auswählt. Ein top-k von 1 bedeutet, dass das ausgewählte Token das wahrscheinlichste unter allen Token im Vokabular des Modells ist, während ein top-k von 3 bedeutet, dass das nächste Token aus den 3 wahrscheinlichsten Token ausgewählt wird (unter Verwendung der Temperatur).',
  com_endpoint_anthropic_maxoutputtokens:
    'Maximale Anzahl von Token, die in der Antwort erzeugt werden können. Gib einen niedrigeren Wert für kürzere Antworten und einen höheren Wert für längere Antworten an.',
  com_endpoint_anthropic_custom_name_placeholder: 'Lege einen eigenen Namen für Anthropic fest',
  com_endpoint_frequency_penalty: 'Frequency Penalty',
  com_endpoint_presence_penalty: 'Presence Penalty',
  com_endpoint_plug_use_functions: 'Funktionen verwenden',
  com_endpoint_plug_resend_images: 'Bilder erneut senden',
  com_endpoint_plug_image_detail: 'Bild-Detail',
  com_endpoint_plug_skip_completion: 'Fertigstellung überspringen',
  com_endpoint_disabled_with_tools: 'Wegen Plug-ins deaktiviert',
  com_endpoint_disabled_with_tools_placeholder: 'Deaktiviert mit ausgewählten Plug-ins',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    'Setzt benutzerdefinierte Anweisungen, die in die System Nachricht aufgenommen werden. Standardmäßig: keine',
  com_endpoint_import: 'Importieren',
  com_endpoint_set_custom_name: 'Lege einen benutzerdefinierten Namen fest, für den Fall, dass du diese Vorgabe finden kannst',
  com_endpoint_preset_delete_confirm: 'Bist du sicher, dass du diese Vorgabe löschen willst?',
  com_endpoint_preset_clear_all_confirm: "Bist du sicher, dass du alle deine Voreinstellungen löschen willst?",
  com_endpoint_preset_import: 'Voreinstellung importiert!',
  com_endpoint_preset_import_error: 'Beim Import deiner Voreinstellung ist ein Fehler aufgetreten. Bitte versuche es erneut.',
  com_endpoint_preset_save_error: 'Es ist ein Fehler beim Speichern deiner Voreinstellung aufgetreten. Bitte versuche es noch einmal.',
  com_endpoint_preset_delete_error: 'Beim Löschen deiner Voreinstellung ist ein Fehler aufgetreten. Bitte versuche es noch einmal.',
  com_endpoint_preset_default_removed: 'ist nicht mehr die Standardeinstellung.',
  com_endpoint_preset_default_item: 'Standard:',
  com_endpoint_preset_default_none: 'Keine Standardeinstellung aktiv.',
  com_endpoint_preset_title: 'Voreinstellung',
  com_endpoint_preset_saved: 'Gespeichert!',
  com_endpoint_preset_default: 'ist jetzt die Standardeinstellung.',
  com_endpoint_preset: 'Voreinstellung',
  com_endpoint_presets: 'Voreinstellungen',
  com_endpoint_preset_selected: 'Voreinstellung aktiv!',
  com_endpoint_preset_selected_title: 'Aktiv!',
  com_endpoint_preset_name: 'Voreinstellungsname',
  com_endpoint_new_topic: 'Neues Thema',
  com_endpoint: 'Endpunkt',
  com_endpoint_hide: 'Ausblenden',
  com_endpoint_show: 'Anzeigen',
  com_endpoint_examples: 'Voreinstellungen',
  com_endpoint_completion: 'Fertigstellung',
  com_endpoint_agent: 'Agent',
  com_endpoint_show_what_settings: 'Zeige {0} Einstellungen',
  com_endpoint_save: 'Speichern',
  com_endpoint_export: 'Exportieren',
  com_endpoint_save_as_preset: 'Als Voreinstellung speichern',
  com_endpoint_presets_clear_warning:
    'Bist du sicher, dass du alle Voreinstellungen löschen willst? Dies ist nicht umkehrbar.',
  com_endpoint_not_implemented: 'Nicht implementiert',
  com_endpoint_no_presets: 'Es gibt noch keine Voreinstellungen, verwende die Schaltfläche "Einstellungen", um eine Vorlage zu erstellen.',
  com_endpoint_not_available: 'Kein Endpunkt verfügbar',
  com_endpoint_view_options: 'Ansichtsoptionen',
  com_endpoint_save_convo_as_preset: 'Konversation als Voreinstellung speichern',
  com_endpoint_my_preset: 'Meine Voreinstellung',
  com_endpoint_agent_model: 'Agentenmodell (empfohlen: GPT-3.5)',
  com_endpoint_completion_model: 'KI-Sprachmodell (empfohlen: GPT-4-Turbo)',
  com_endpoint_func_hover: 'Aktiviere die Verwendung von Plug-ins als OpenAI-Funktionen',
  com_endpoint_skip_hover:
    'Aktiviere das Überspringen des Abschlussschritts, der die endgültige Antwort und die generierten Schritte überprüft',
  com_endpoint_config_key: 'API-Schlüssel festlegen',
  com_endpoint_config_placeholder: 'Setze deinen Schlüssel im Header-Menü zum Chat',
  com_endpoint_config_key_for: 'API-Schlüssel setzen für',
  com_endpoint_config_key_name: 'Schlüssel',
  com_endpoint_config_value: 'Wert eingeben für',
  com_endpoint_config_key_name_placeholder: 'Setze API-Schlüssel zuerst',
  com_endpoint_config_key_encryption: 'Dein Schlüssel wird verschlüsselt und gelöscht bei',
  com_endpoint_config_key_expiry: 'der Ablauf der Gültigkeit des API-Schlüssels',
  com_endpoint_config_click_here: 'Hier klicken',
  com_endpoint_config_google_service_key: 'Google Service Account Key',
  com_endpoint_config_google_cloud_platform: '(von Google Cloud Platform)',
  com_endpoint_config_google_api_key: 'Google API Key',
  com_endpoint_config_google_gemini_api: "(Gemini API)",
  com_endpoint_config_google_api_info: 'Um deinen Generative Language API-Schlüssel (für Gemini) zu erhalten,',
  com_endpoint_config_key_import_json_key: 'Importiere Service Account JSON Key.',
  com_endpoint_config_key_import_json_key_success: 'Erfolgreich importierter Service Account JSON Key',
  com_endpoint_config_key_import_json_key_invalid:
    'Ungültiger Service Account JSON Key, Hast du die richtige Datei importiert?',
  com_endpoint_config_key_get_edge_key: 'Um dein Access Token für Bing zu erhalten, melde dich an bei',
  com_endpoint_config_key_get_edge_key_dev_tool:
    'Verwende Dev-Tools oder eine Erweiterung, während du auf der Website angemeldet bist, um den Inhalt des _U-Cookies zu kopieren. Wenn dies fehlschlägt, befolge die folgenden Anweisungen',
  com_endpoint_config_key_edge_instructions: 'Anweisungen',
  com_endpoint_config_key_edge_full_key_string: 'um die vollständigen Cookie-Strings zu übermitteln.',
  com_endpoint_config_key_chatgpt: 'Um dein Zugangstoken für ChatGPT \'Gratis Version\' zu erhalten, melde dich bei',
  com_endpoint_config_key_chatgpt_then_visit: 'danach besuche',
  com_endpoint_config_key_chatgpt_copy_token: 'Kopiere das Zugangstoken.',
  com_endpoint_config_key_google_need_to: 'Du musst',
  com_endpoint_config_key_google_vertex_ai: 'Vertex AI aktivieren',
  com_endpoint_config_key_google_vertex_api: 'API auf Google Cloud, dann',
  com_endpoint_config_key_google_service_account: 'Ein Service-Konto erstellen',
  com_endpoint_config_key_google_vertex_api_role:
    'Stelle sicher, dass du auf \'Erstellen und Fortfahren\' klickst, um mindestens die \'Vertex AI User\'-Rolle zu vergeben. Erstelle schließlich einen JSON-Schlüssel, den du hier importieren kannst.',
  com_nav_welcome_message: 'Wie kann ich dir heute helfen?',
  com_nav_auto_scroll: 'Beim Öffnen automatisch zum Neuesten scrollen',
  com_nav_modular_chat: 'Aktiviere das Umschalten von Endpunkten während eines Gesprächs',
  com_nav_latex_parsing: 'Parsen von LaTeX in Nachrichten (kann die Leistung beeinträchtigen)',
  com_nav_profile_picture: 'Profilbild',
  com_nav_change_picture: 'Bild ändern',
  com_nav_plugin_store: 'Plugin-Store',
  com_show_agent_settings: 'Agenteneinstellungen anzeigen',
  com_show_completion_settings: 'Fertigstellungseinstellungen anzeigen',
  com_hide_examples: 'Beispiele ausblenden',
  com_show_examples: 'Beispiele anzeigen',
  com_nav_plugin_search: 'Plug-ins suchen',
  com_nav_plugin_auth_error:
    'Beim Versuch, dieses Plugin zu authentifizieren, ist ein Fehler aufgetreten. Bitte versuche es erneut.',
  com_nav_export_filename: 'Dateiname',
  com_nav_export_filename_placeholder: 'Setze den Dateinamen',
  com_nav_export_type: 'Typ',
  com_nav_export_include_endpoint_options: 'Endpunktoptionen einbeziehen',
  com_nav_enabled: 'Aktiviert',
  com_nav_not_supported: 'Nicht unterstützt',
  com_nav_export_all_message_branches: 'Alle Nachrichtenzweige exportieren',
  com_nav_export_recursive_or_sequential: 'Rekursiv oder sequentiell?',
  com_nav_export_recursive: 'Rekursiv',
  com_nav_export_conversation: 'Konversation exportieren',
  com_nav_theme: 'Thema',
  com_nav_theme_system: 'System',
  com_nav_theme_dark: 'Dunkel',
  com_nav_theme_light: 'Hell',
  com_nav_user_name_display: 'Benutzernamen in Nachrichten anzeigen',
  com_nav_clear_all_chats: 'Alle Chats löschen',
  com_nav_confirm_clear: 'Bestätige Löschen',
  com_nav_close_sidebar: 'Sidebar schließen',
  com_nav_open_sidebar: 'Seitenleiste öffnen',
  com_nav_send_message: 'Nachricht senden',
  com_nav_log_out: 'Abmelden',
  com_nav_user: 'NUTZER',
  com_nav_clear_conversation: 'Unterhaltungen löschen',
  com_nav_clear_conversation_confirm_message:
    'Bist du sicher, dass du alle Unterhaltungen löschen willst? Dies ist unumkehrbar.',
  com_nav_help_faq: 'Hilfe & FAQ',
  com_nav_settings: 'Einstellungen',
  com_nav_search_placeholder: 'Nachrichten suchen',
  com_nav_setting_general: 'Allgemein',
  com_nav_setting_beta: 'Beta-Funktionen',
  com_nav_setting_data: 'Datenkontrollen',
  com_nav_setting_account: 'Konto',
  com_nav_language: 'Sprache',
  com_nav_lang_auto: 'Automatische Erkennung',
};