import { ChatSettings, Model } from '~/types/models';
import { axios } from './setup';

export const getUserModels = async (userId: string) => {
  return (await axios.get<{ models: Model[] }>(`users/${userId}/models`)).data.models;
};

export const updateChatSettings = async (userId: string, data: ChatSettings) => {
  return (await axios.put(`users/${userId}/chat-settings`, data)).data;
};

export const getChatSettings = async (userId: string) => {
  return (await axios.get<ChatSettings>(`users/${userId}/chat-settings`)).data;
};
