import reactStringReplace from 'react-string-replace';
import BlockedIcon from '~/components/svg/BlockedIcon';
import LockIcon from '~/components/svg/LockIcon';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui';
import { useContext } from 'react';
import { ThemeContext } from '~/hooks';

export const REDACT_REGEX = /<([^<>]+)>/g;

export const formatRedactedString = (content: string) => {
  const redactFilter = content.replaceAll('<REDACTED>', 'REDACTED');
  return redactFilter;
};

interface RedactReplaceProps {
  content: string | React.ReactNode[];
  policyMessage: string;
}

export const RedactReplace = ({ content, policyMessage }: RedactReplaceProps) => {
  const { theme } = useContext(ThemeContext);

  let isDark = theme === 'dark';

  if (theme === 'system') {
    isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  return (
    <>
      {reactStringReplace(content, REDACT_REGEX, (match, i) => {
        return (
          <TooltipProvider delayDuration={50} key={i + match + content}>
            <Tooltip>
              <TooltipTrigger>
                <span className="mx-1 inline-flex w-max items-center gap-0.5 rounded-full bg-gray-50 px-2 py-0.5 font-sans text-sm font-medium uppercase text-vnavy dark:bg-gray-100/10 dark:text-gray-70">
                  <LockIcon className="mr-1" /> {match}
                </span>
              </TooltipTrigger>
              {policyMessage && (
                <TooltipContent className="bg-[#4A417A]" side="top" sideOffset={-5}>
                  {policyMessage}
                </TooltipContent>
              )}
            </Tooltip>
          </TooltipProvider>
        );
      })}
    </>
  );
};

interface BlockReplaceProps {
  content: string | React.ReactNode[];
  policyMessage: string;
}

export const BlockReplace = ({ content, policyMessage }: BlockReplaceProps) => {
  return (
    <>
      {reactStringReplace(content, 'CONTENT BLOCKED', (match, i) => {
        return (
          <TooltipProvider delayDuration={50} key={i + match + content}>
            <Tooltip>
              <TooltipTrigger>
                <span
                  className="mx-1 inline-flex w-max items-center gap-0.5 rounded-full px-2 py-0.5 font-sans text-sm font-medium text-vred"
                  style={{
                    background: '#EE422B2A',
                  }}
                >
                  <BlockedIcon /> CONTENT BLOCKED
                </span>
              </TooltipTrigger>
              {policyMessage && (
                <TooltipContent className="bg-[#4A417A]" side="top" sideOffset={-5}>
                  {policyMessage}
                </TooltipContent>
              )}
            </Tooltip>
          </TooltipProvider>
        );
      })}
    </>
  );
};
