import { Content, Portal, Root } from '@radix-ui/react-popover';
import { FC } from 'react';
import ModelItems from './Models/MenuItems';
import { useChatContext } from '~/Providers';
import TitleButton from './UI/TitleButton';

const ModelMenu = ({
  models,
  routingIsActive,
  selected,
  isSandbox,
  userSupportsImageUpload,
}: {
  models: any[];
  isSandbox;
  routingIsActive: boolean;
  selected: string;
  userSupportsImageUpload: boolean;
}) => {
  const { isSubmitting } = useChatContext();

  return (
    <Root>
      <div className="flex items-center">
        <TitleButton
          primaryText={(selected ?? '') + ' '}
          isDisabled={routingIsActive || isSubmitting}
        />
      </div>

      <Portal>
        <div
          style={{
            position: 'fixed',
            left: '0px',
            top: '0px',
            transform: 'translate3d(268px, 50px, 0px)',
            minWidth: 'max-content',
            zIndex: 'auto',
          }}
        >
          <Content
            side="bottom"
            align="start"
            className="mr-4 mt-2 max-h-[65vh] min-w-[325px] overflow-y-auto rounded-lg bg-vwhite shadow-lg dark:bg-vnavy-dark-card dark:text-white lg:max-h-[75vh]"
          >
            <ModelItems
              userSupportsImageUpload={userSupportsImageUpload}
              models={models}
              selected={selected}
              isSandbox={isSandbox}
            />
          </Content>
        </div>
      </Portal>
    </Root>
  );
};

export default ModelMenu;
