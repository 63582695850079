import { useQuery } from '@tanstack/react-query';
import { getUser, getUserGroups } from '../api/user';

export function useUser(userId: string | null) {
  return useQuery({
    queryKey: ['users', { userId }],
    queryFn: () => getUser(userId!),
    enabled: !!userId,
  });
}

export function useUserGroups(userId: string) {
  return useQuery({
    queryKey: ['userGroups', { userId }],
    queryFn: () => getUserGroups(userId!),
    enabled: !!userId,
  });
}

// todo: change to mutation to take advantage of settled
// export function useUserMutation() {
//   const { setDemoComplete, setIsSandbox } = useSandboxStore();

//   return useMutation({
//     mutationFn: () => getUser(user?.user_metadata?.user_id!),
//     onSettled(data, error, variables, context) {
//       if (error) {
//         console.log(error);
//       } else {
//         if (data?.account_state === ACCOUNT_STATE.SANDBOX) {
//           setIsSandbox(true);
//           if (data?.curr_requests >= data?.max_requests) setDemoComplete();
//         }
//       }
//       return data;
//     },
//   });
// }
