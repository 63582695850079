import { createRoot } from 'react-dom/client';
import App from './App';
import './style.css';
import './mobile.css';
import { ApiErrorBoundaryProvider } from './hooks/ApiErrorBoundaryContext';
import { Auth0Provider } from '@auth0/auth0-react';
import posthog from 'posthog-js';

const container = document.getElementById('root');
const root = createRoot(container);

if (import.meta.env.VITE_MODE === 'PROD') {
  posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
    api_host: 'https://us.posthog.com',
    name: 'Vera Chat App',
  });
}

root.render(
  <ApiErrorBoundaryProvider>
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: import.meta.env.VITE_AUTH0_LOGIN_CALLBACK_URL,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      }}
    >
      <App />
    </Auth0Provider>
  </ApiErrorBoundaryProvider>,
);
