import { defineStyle, defineStyleConfig, extendTheme } from '@chakra-ui/react';
import {
  HARD_GRAY,
  SOFT_GRAY,
  VERA_BLUE,
  VERA_LIME,
  VERA_MINT,
  VERA_NAVY,
  VERA_PINK,
  VERA_RED,
  VERA_TEAL,
  VERA_YELLOW,
} from '~/utils/constants';
import '@fontsource/barlow';

// ---- THEME ----
const veraLime = defineStyle({
  background: `${VERA_LIME}1A`,
  color: 'green',
  // let's also provide dark mode alternatives
  _dark: {
    background: 'orange.300',
    color: 'orange.800',
  },
});

export const badgeTheme = defineStyleConfig({
  variants: { veraLime },
});

const colors = {
  vera: {
    yellow: VERA_YELLOW,
    blue: VERA_BLUE,
    navy: VERA_NAVY,
    mint: VERA_MINT,
    teal: VERA_TEAL,
    red: VERA_RED,
    soft_gray: SOFT_GRAY,
    hard_gray: HARD_GRAY,
  },
  // generated with https://themera.vercel.app/
  purple: {
    '50': '#EBE5FF',
    '100': '#C6B8FF',
    '200': '#A18AFF',
    '300': '#7C5CFF',
    '400': '#572EFF',
    '500': '#3300FF',
    '600': '#2800CC',
    '700': '#1E0099',
    '800': '#140066',
    '900': '#0A0033',
  },
  pink: {
    '50': '#FFE5F8',
    '100': '#FFB8EC',
    '200': '#FF8AE0',
    '300': '#FF5CD3',
    '400': '#FF2EC7',
    '500': '#FF00BB',
    '600': '#CC0095',
    '700': '#990070',
    '800': '#66004B',
    '900': '#330025',
  },
  green: {
    '50': '#E6FEEF',
    '100': '#B9FDD1',
    '200': '#8CFCB4',
    '300': '#60FB96',
    '400': '#33FA79',
    '500': '#06F95B',
    '600': '#05C749',
    '700': '#049537',
    '800': '#026424',
    '900': '#013212',
  },
  blue: {
    '50': '#EEEBF9',
    '100': '#CEC8EF',
    '200': '#AFA4E4',
    '300': '#9081DA',
    '400': '#705DCF',
    '500': '#513AC5',
    '600': '#412E9E',
    '700': '#312376',
    '800': '#20174F',
    '900': '#100C27',
  },
  red: {
    '50': '#FEEBE7',
    '100': '#FBC7BC',
    '200': '#F9A390',
    '300': '#F67F65',
    '400': '#F35B39',
    '500': '#F1370E',
    '600': '#C12C0B',
    '700': '#912108',
    '800': '#601606',
    '900': '#300B03',
  },
  yellow: {
    '50': '#FFFCE6',
    '100': '#FEF7B9',
    '200': '#FDF28B',
    '300': '#FDED5E',
    '400': '#FCE831',
    '500': '#FCE403',
    '600': '#C9B603',
    '700': '#978902',
    '800': '#655B01',
    '900': '#322E01',
  },
};

export const theme = extendTheme({
  colors,
  components: { Badge: badgeTheme },
  fonts: {
    heading: `'Barlow', sans-serif`,
    body: `'Barlow', sans-serif`,
  },

  shadows: { outline: `0 0 0 2px ${VERA_PINK}` },
});
// ---- THEME ----
