// French phrases
// file deepcode ignore NoHardcodedPasswords: No hardcoded values present in this file
// file deepcode ignore HardcodedNonCryptoSecret: No hardcoded secrets present in this file

export default {
  com_ui_examples: 'Exemples',
  com_ui_new_chat: 'Nouvelle conversation',
  com_ui_example_quantum_computing: 'Expliquer l\'informatique quantique en termes simples',
  com_ui_example_10_year_old_b_day:
    'Tu as des idées créatives pour l\'anniversaire d\'un enfant de 10 ans ?',
  com_ui_example_http_in_js: 'Comment faire une requête HTTP en Javascript ?',
  com_ui_capabilities: 'Capacités',
  com_ui_capability_remember:
    'Se souvient de ce que l\'utilisateur a dit plus tôt dans la conversation',
  com_ui_capability_correction: 'Permet à l\'utilisateur de fournir des corrections de suivi',
  com_ui_capability_decline_requests: 'Formé à refuser les demandes inappropriées',
  com_ui_limitations: 'Limitations',
  com_ui_limitation_incorrect_info: 'Peut occasionnellement générer des informations incorrectes',
  com_ui_limitation_harmful_biased:
    'Peut occasionnellement produire des instructions nuisibles ou du contenu biaisé',
  com_ui_limitation_limited_2021: 'Connaissance limitée du monde et des événements après 2021',
  com_ui_experimental: 'Fonctionnalités expérimentales',
  com_ui_input: 'Entrée',
  com_ui_close: 'Fermer',
  com_ui_model: 'Modèle',
  com_ui_select_model: 'Sélectionner un modèle',
  com_ui_use_prompt: 'Utiliser le prompt',
  com_ui_prev: 'Précédent',
  com_ui_next: 'Suivant',
  com_ui_stop: 'Arrêt ',
  com_ui_prompt_templates: 'Modèles de prompt',
  com_ui_hide_prompt_templates: 'Masquer les modèles de prompt',
  com_ui_showing: 'Affichage',
  com_ui_of: 'des',
  com_ui_entries: 'entrées',
  com_ui_pay_per_call:
    'Toutes les conversations IA au même endroit. Payez à la demande et non par mois',
  com_ui_new_footer: 'Toutes les conversations IA au même endroit.',
  com_ui_enter: 'Entrer',
  com_ui_submit: 'Soumettre',
  com_ui_upload_success: 'Fichier téléversé avec succès',
  com_ui_upload_error: 'Une erreur s\'est produite lors du téléversement de votre fichier',
  com_ui_upload_invalid:
    'Fichier invalide pour le téléversement. Doit être une image ne dépassant pas 2 Mo',
  com_ui_cancel: 'Annuler',
  com_ui_save: 'Sauvegarder',
  com_ui_save_submit: 'Enregistrer et Soumettre',
  com_user_message: 'Vous',
  com_ui_copy_to_clipboard: 'Copier dans le presse-papier',
  com_ui_copied_to_clipboard: 'Copié dans le presse-papier',
  com_ui_regenerate: 'Régénérer',
  com_ui_continue: 'Continuer',
  com_ui_edit: 'Modifier',
  com_ui_success: 'Succès',
  com_ui_all: 'tout',
  com_ui_clear: 'Effacer',
  com_ui_revoke: 'Révoquer',
  com_ui_revoke_info:
    'Révoquer toutes les informations d\'identification fournies par l\'utilisateur',
  com_ui_confirm_action: 'Confirmer l\'action',
  com_ui_chats: 'discussions',
  com_ui_delete: 'Supprimer',
  com_ui_delete_conversation: 'Supprimer la discussions?',
  com_ui_delete_conversation_confirm: 'Cela supprimera',
  com_ui_preview: 'Aperçu',
  com_ui_upload: 'Téléverser',
  com_ui_connect: 'Connecter',
  com_auth_error_login:
    'Impossible de se connecter avec les informations fournies. Veuillez vérifier vos identifiants et réessayer.',
  com_auth_error_login_rl:
    'Trop de tentatives de connexion en peu de temps. Veuillez réessayer plus tard.',
  com_auth_error_login_ban:
    'Votre compte a été temporairement banni en raison de violations de notre service.',
  com_auth_error_login_server:
    'Une erreur interne du serveur s\'est produite. Veuillez patienter quelques instants et réessayer.',
  com_auth_no_account: 'Vous n\'avez pas de compte?',
  com_auth_sign_up: 'S\'inscrire',
  com_auth_sign_in: 'Se connecter',
  com_auth_google_login: 'Se connecter avec Google',
  com_auth_facebook_login: 'Se connecter avec Facebook',
  com_auth_github_login: 'Se connecter avec Github',
  com_auth_discord_login: 'Se connecter avec Discord',
  com_auth_email: 'Courriel',
  com_auth_email_required: 'Le courriel est obligatoire',
  com_auth_email_min_length: 'Le courriel doit comporter au moins 6 caractères',
  com_auth_email_max_length: 'Le courriel ne doit pas dépasser 120 caractères',
  com_auth_email_pattern: 'Vous devez entrer une adresse courriel valide',
  com_auth_email_address: 'Adresse courriel',
  com_auth_password: 'Mot de passe',
  com_auth_password_required: 'Le mot de passe est obligatoire',
  com_auth_password_min_length: 'Le mot de passe doit comporter au moins 8 caractères',
  com_auth_password_max_length: 'Le mot de passe doit être inférieur à 128 caractères',
  com_auth_password_forgot: 'Mot de passe oublié?',
  com_auth_password_confirm: 'Confirmer le mot de passe',
  com_auth_password_not_match: 'Les mots de passe ne correspondent pas',
  com_auth_continue: 'Continuer',
  com_auth_create_account: 'Créez votre compte',
  com_auth_error_create:
    'Il y a eu une erreur lors de la tentative d\'enregistrement de votre compte. Veuillez réessayer.',
  com_auth_full_name: 'Nom complet',
  com_auth_name_required: 'Le nom est obligatoire',
  com_auth_name_min_length: 'Le nom doit comporter au moins 3 caractères',
  com_auth_name_max_length: 'Le nom doit être inférieur à 80 caractères',
  com_auth_username: 'Nom d\'utilisateur',
  com_auth_username_required: 'Le nom d\'utilisateur est obligatoire',
  com_auth_username_min_length: 'Le nom d\'utilisateur doit comporter au moins 3 caractères',
  com_auth_username_max_length: 'Le nom d\'utilisateur doit être inférieur à 20 caractères',
  com_auth_already_have_account: 'Vous avez déjà un compte ?',
  com_auth_login: 'Connexion',
  com_auth_reset_password: 'Réinitialiser votre mot de passe',
  com_auth_click: 'Cliquez',
  com_auth_here: 'ICI',
  com_auth_to_reset_your_password: 'pour réinitialiser votre mot de passe.',
  com_auth_reset_password_link_sent: 'Courriel envoyé',
  com_auth_reset_password_email_sent:
    'Un courriel vous a été envoyé avec des instructions supplémentaires pour réinitialiser votre mot de passe.',
  com_auth_error_reset_password:
    'Il y a eu un problème pour réinitialiser votre mot de passe. Aucun utilisateur n\'a été trouvé avec l\'adresse courriel fournie. Veuillez réessayer.',
  com_auth_reset_password_success: 'Réinitialisation du mot de passe réussie',
  com_auth_login_with_new_password:
    'Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.',
  com_auth_error_invalid_reset_token:
    'Ce jeton de réinitialisation de mot de passe n\'est plus valide.',
  com_auth_click_here: 'Cliquez ici',
  com_auth_to_try_again: 'pour réessayer.',
  com_auth_submit_registration: 'Soumettre l\'inscription',
  com_auth_welcome_back: 'Bienvenue à nouveau',
  com_endpoint_open_menu: 'Ouvrir le menu',
  com_endpoint_bing_enable_sydney: 'Activer Sydney',
  com_endpoint_bing_to_enable_sydney: 'Pour activer Sydney',
  com_endpoint_bing_jailbreak: 'Jailbreak',
  com_endpoint_bing_context_placeholder:
    'Bing peut utiliser jusqu\'à 7k jetons pour le "contexte", qu\'il peut référencer pour la conversation. La limite spécifique n\'est pas connue mais peut entraîner des erreurs dépassant les 7k jetons',
  com_endpoint_bing_system_message_placeholder:
    'AVERTISSEMENT : L\'abus de cette fonctionnalité peut vous faire BANNIR de l\'utilisation de Bing! Cliquez sur "Message système" pour obtenir les instructions complètes et le message par défaut si omis, qui est le préréglage "Sydney" qui est considéré comme sûr.',
  com_endpoint_system_message: 'Message système',
  com_endpoint_message: 'Message',
  com_endpoint_message_not_appendable: 'Editer votre message ou regénerer.',
  com_endpoint_default_blank: 'par défaut : vide',
  com_endpoint_default_false: 'par défaut : faux',
  com_endpoint_default_creative: 'par défaut : créatif',
  com_endpoint_default_empty: 'par défaut : vide',
  com_endpoint_default_with_num: 'par défaut : {0}',
  com_endpoint_context: 'Contexte',
  com_endpoint_tone_style: 'Style de ton',
  com_endpoint_token_count: 'Nombre de jetons',
  com_endpoint_output: 'Sortie',
  com_endpoint_google_temp:
    'Des valeurs plus élevées = plus aléatoires, tandis que des valeurs plus faibles = plus concentrées et déterministes. Nous vous recommandons de modifier ceci ou Top P mais pas les deux.',
  com_endpoint_google_topp:
    'Top-p change la façon dont le modèle sélectionne les jetons pour la sortie. Les jetons sont sélectionnés du plus K (voir le paramètre topK) probable au moins jusqu\'à ce que la somme de leurs probabilités égale la valeur top-p.',
  com_endpoint_google_topk:
    'Top-k change la façon dont le modèle sélectionne les jetons pour la sortie. Un top-k de 1 signifie que le jeton sélectionné est le plus probable parmi tous les jetons du vocabulaire du modèle (également appelé décodage glouton), tandis qu\'un top-k de 3 signifie que le jeton suivant est sélectionné parmi les 3 jetons les plus probables (en utilisant la température).',
  com_endpoint_google_maxoutputtokens:
    'Nombre maximum de jetons qui peuvent être générés dans la réponse. Spécifiez une valeur plus faible pour des réponses plus courtes et une valeur plus élevée pour des réponses plus longues.',
  com_endpoint_google_custom_name_placeholder: 'Définir un nom personnalisé pour Google',
  com_endpoint_google_prompt_prefix_placeholder:
    'Définir des instructions ou un contexte personnalisés. Ignoré si vide.',
  com_endpoint_custom_name: 'Nom personnalisé',
  com_endpoint_prompt_prefix: 'Préfixe du prompt',
  com_endpoint_temperature: 'Température',
  com_endpoint_default: 'par défaut',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top K',
  com_endpoint_max_output_tokens: 'Nombre maximum de jetons en sortie',
  com_endpoint_openai_temp:
    'Des valeurs plus élevées = plus aléatoires, tandis que des valeurs plus faibles = plus concentrées et déterministes. Nous vous recommandons de modifier ceci ou Top P mais pas les deux.',
  com_endpoint_openai_max:
    'Le nombre maximum de jetons à générer. La longueur totale des jetons d\'entrée et des jetons générés est limitée par la longueur du contexte du modèle.',
  com_endpoint_openai_topp:
    'Une alternative à l\'échantillonnage avec température, appelée échantillonnage du noyau, où le modèle considère les résultats des jetons avec une masse de probabilité top_p. Ainsi, 0,1 signifie que seuls les jetons représentant les 10 % de masse de probabilité les plus élevés sont pris en compte. Nous vous recommandons de modifier ceci ou la température mais pas les deux.',
  com_endpoint_openai_freq:
    'Nombre compris entre -2,0 et 2,0. Les valeurs positives pénalisent les nouveaux jetons en fonction de leur fréquence existante dans le texte jusqu\'à présent, diminuant ainsi la probabilité que le modèle répète la même ligne mot pour mot.',
  com_endpoint_openai_pres:
    'Nombre compris entre -2,0 et 2,0. Les valeurs positives pénalisent les nouveaux jetons en fonction du fait qu\'ils apparaissent ou non dans le texte jusqu\'à présent, augmentant ainsi la probabilité que le modèle parle de nouveaux sujets.',
  com_endpoint_openai_resend:
    'Renvoyer toutes les images précédemment jointes. Remarque : cela peut augmenter considérablement le coût en jetons et vous pouvez rencontrer des erreurs avec de nombreuses pièces jointes d\'images.',
  com_endpoint_openai_detail:
    'La résolution pour les requêtes Vision. "Low" est moins cher et plus rapide, "High" est plus détaillé et plus cher, et "Auto" choisira automatiquement entre les deux en fonction de la résolution de l\'image.',
  com_endpoint_openai_custom_name_placeholder: 'Définir un nom personnalisé pour ChatGPT',
  com_endpoint_openai_prompt_prefix_placeholder:
    'Définir des instructions personnalisées à inclure dans le message système. Par défaut : aucun',
  com_endpoint_anthropic_temp:
    'Varie de 0 à 1. Utilisez une température proche de 0 pour l\'analyse / le choix multiple, et proche de 1 pour les tâches créatives et génératives. Nous vous recommandons de modifier ceci ou Top P mais pas les deux.',
  com_endpoint_anthropic_topp:
    'Top-p change la façon dont le modèle sélectionne les jetons pour la sortie. Les jetons sont sélectionnés du plus K (voir le paramètre topK) probable au moins jusqu\'à ce que la somme de leurs probabilités égale la valeur top-p.',
  com_endpoint_anthropic_topk:
    'Top-k change la façon dont le modèle sélectionne les jetons pour la sortie. Un top-k de 1 signifie que le jeton sélectionné est le plus probable parmi tous les jetons du vocabulaire du modèle (également appelé décodage glouton), tandis qu\'un top-k de 3 signifie que le jeton suivant est sélectionné parmi les 3 jetons les plus probables (en utilisant la température).',
  com_endpoint_anthropic_maxoutputtokens:
    'Nombre maximum de jetons qui peuvent être générés dans la réponse. Spécifiez une valeur plus faible pour des réponses plus courtes et une valeur plus élevée pour des réponses plus longues.',
  com_endpoint_anthropic_custom_name_placeholder: 'Définir un nom personnalisé pour Anthropic',
  com_endpoint_frequency_penalty: 'Pénalité de fréquence',
  com_endpoint_presence_penalty: 'Pénalité de présence',
  com_endpoint_plug_use_functions: 'Utiliser les fonctions',
  com_endpoint_plug_resend_images: 'Renvoyer des images',
  com_endpoint_plug_skip_completion: 'Sauter la complétion',
  com_endpoint_disabled_with_tools: 'désactivé avec les outils',
  com_endpoint_disabled_with_tools_placeholder: 'Désactivé avec les outils sélectionnés',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    'Définir des instructions personnalisées à inclure dans le message système. Par défaut : aucun',
  com_endpoint_import: 'Importer',
  com_endpoint_set_custom_name:
    'Définir un nom personnalisé, au cas où vous trouveriez ce préréglage',
  com_endpoint_preset_delete_confirm: 'Êtes-vous sûr de vouloir supprimer ce préréglage?',
  com_endpoint_preset_clear_all_confirm: 'Êtes-vous sûr de vouloir supprimer tous vos préréglages?',
  com_endpoint_preset_import: 'Préréglage importé!',
  com_endpoint_preset_import_error:
    'Il y a eu une erreur lors de l\'importation de votre préréglage. Veuillez réessayer.',
  com_endpoint_preset_save_error:
    'Il y a eu une erreur lors de la sauvegarde de votre préréglage. Veuillez réessayer.',
  com_endpoint_preset_delete_error:
    'Il y a eu une erreur lors de la suppression de votre préréglage. Veuillez réessayer.',
  com_endpoint_preset_default_removed: 'n\'est plus le préréglage par défaut.',
  com_endpoint_preset_default_item: 'Par défaut :',
  com_endpoint_preset_default_none: 'Aucun préréglage par défaut actif.',
  com_endpoint_preset_title: 'Préréglage',
  com_endpoint_preset_saved: 'Enregistré!',
  com_endpoint_preset_default: 'est maintenant le préréglage par défaut.',
  com_endpoint_preset: 'préréglage',
  com_endpoint_presets: 'préréglages',
  com_endpoint_preset_selected: 'Préréglage actif!',
  com_endpoint_preset_selected_title: 'Actif!',
  com_endpoint_preset_name: 'Nom du préréglage',
  com_endpoint_new_topic: 'Nouveau sujet',
  com_endpoint: 'Endpoint',
  com_endpoint_hide: 'Masquer',
  com_endpoint_show: 'Afficher',
  com_endpoint_examples: ' Exemples',
  com_endpoint_completion: 'Complétion',
  com_endpoint_agent: 'Agent',
  com_endpoint_show_what_settings: 'Afficher les paramètres {0}',
  com_endpoint_save: 'Enregistrer',
  com_endpoint_export: 'Exporter',
  com_endpoint_save_as_preset: 'Enregistrer comme préréglage',
  com_endpoint_presets_clear_warning:
    'Etes-vous sûr de vouloir effacer tous les préréglages? Cette action est irréversible.',
  com_endpoint_not_implemented: 'Non implémenté',
  com_endpoint_no_presets:
    'Aucun préréglage pour l\'instant, utilisez le bouton paramètres pour en créer un',
  com_endpoint_not_available: 'Aucun endpoint disponible',
  com_endpoint_view_options: 'Voir les options',
  com_endpoint_save_convo_as_preset: 'Enregistrer la conversation comme préréglage',
  com_endpoint_my_preset: 'Mon préréglage',
  com_endpoint_agent_model: 'Modèle d\'agent (recommandé : GPT-3.5)',
  com_endpoint_completion_model: 'Modèle de complétion (recommandé : GPT-4)',
  com_endpoint_func_hover: 'Activer l\'utilisation des plugins comme fonctions OpenAI',
  com_endpoint_skip_hover:
    'Activer le saut de l\'étape de complétion, qui examine la réponse finale et les étapes générées',
  com_endpoint_config_key: 'Définir la clé API',
  com_endpoint_config_placeholder: 'Définissez votre clé dans le menu En-tête pour discuter.',
  com_endpoint_config_key_for: 'Définir la clé API pour',
  com_endpoint_config_key_name: 'Clé',
  com_endpoint_config_value: 'Entrez la valeur pour',
  com_endpoint_config_key_name_placeholder: 'Définir d\'abord la clé API',
  com_endpoint_config_key_encryption: 'Votre clé sera cryptée et supprimée à',
  com_endpoint_config_key_expiry: 'le délai d\'expiration',
  com_endpoint_config_click_here: 'Cliquez ici',
  com_endpoint_config_google_service_key: 'Clé de compte de service Google',
  com_endpoint_config_google_cloud_platform: '(de Google Cloud Platform)',
  com_endpoint_config_google_api_key: 'Clé API Google',
  com_endpoint_config_google_gemini_api: '(API Gemini)',
  com_endpoint_config_google_api_info:
    'Pour obtenir votre clé API de langage génératif (pour Gemini),',
  com_endpoint_config_key_import_json_key: 'Importez la clé JSON du compte de service.',
  com_endpoint_config_key_import_json_key_success:
    'Clé JSON du compte de service importé avec succès',
  com_endpoint_config_key_import_json_key_invalid:
    'Clé JSON du compte de service invalide. Avez-vous importé le bon fichier ?',
  com_endpoint_config_key_get_edge_key:
    'Pour obtenir votre jeton d\'accès pour Bing, connectez-vous à',
  com_endpoint_config_key_get_edge_key_dev_tool:
    'Utilisez dev tools ou une extension lorsque vous êtes connecté au site pour copier le contenu du cookie _U. Si cela échoue, suivez ces',
  com_endpoint_config_key_edge_instructions: 'instructions',
  com_endpoint_config_key_edge_full_key_string: 'pour fournir les chaînes complètes des cookies.',
  com_endpoint_config_key_chatgpt:
    'Pour obtenir votre jeton d\'accès pour ChatGPT \'Version gratuite\', se connecter à',
  com_endpoint_config_key_chatgpt_then_visit: 'puis visitez',
  com_endpoint_config_key_chatgpt_copy_token: 'Copiez le jeton d\'accès.',
  com_endpoint_config_key_google_need_to: 'Vous devez',
  com_endpoint_config_key_google_vertex_ai: 'Activer Vertex AI',
  com_endpoint_config_key_google_vertex_api: 'API sur Google Cloud, puis',
  com_endpoint_config_key_google_service_account: 'Créer un compte de service',
  com_endpoint_config_key_google_vertex_api_role:
    'Assurez-vous de cliquer \'Créer et continuer\' pour donner au moins le role \'Utilisateur de Vertex AI\'. Finalement, créez une clé JSON à importer ici.',
  com_nav_welcome_message: 'Comment puis-je vous aider aujourd\'hui?',
  com_nav_auto_scroll: 'Défilement automatique jusqu\'au plus récent à l\'ouverture',
  com_nav_modular_chat: 'Activer le changement de points de terminaison en cours de conversation',
  com_nav_latex_parsing: 'Analyse LaTeX dans les messages (peut affecter les performances)',
  com_nav_profile_picture: 'Photo de profil',
  com_nav_change_picture: 'Changer de photo',
  com_nav_plugin_store: 'Boutique de plugins',
  com_show_agent_settings: 'Afficher les paramètres de l\'agent',
  com_show_completion_settings: 'Afficher les paramètres de complétion',
  com_hide_examples: 'Masquer les exemples',
  com_show_examples: 'Afficher les exemples',
  com_nav_plugin_search: 'Rechercher des plugins',
  com_nav_plugin_auth_error:
    'Une erreur s\'est produite lors de la tentative d\'authentification de ce plugin. Veuillez réessayer.',
  com_nav_export_filename: 'Nom du fichier',
  com_nav_export_filename_placeholder: 'Définir le nom du fichier',
  com_nav_export_type: 'Type',
  com_nav_export_include_endpoint_options: 'Inclure les options d\'extrémité',
  com_nav_enabled: 'Activé',
  com_nav_not_supported: 'Non pris en charge',
  com_nav_export_all_message_branches: 'Exporter toutes les branches de messages',
  com_nav_export_recursive_or_sequential: 'Récursif ou séquentiel ?',
  com_nav_export_recursive: 'Récursif',
  com_nav_export_conversation: 'Exporter la conversation',
  com_nav_theme: 'Thème',
  com_nav_theme_system: 'Système',
  com_nav_theme_dark: 'Sombre',
  com_nav_theme_light: 'Clair',
  com_nav_user_name_display: 'Afficher le nom d\'utilisateur dans les messages',
  com_nav_clear_all_chats: 'Effacer toutes les conversations',
  com_nav_confirm_clear: 'Confirmer l\'effacement',
  com_nav_close_sidebar: 'Fermer la barre latérale',
  com_nav_open_sidebar: 'Ouvrir la barre latérale',
  com_nav_send_message: 'Envoyer un message',
  com_nav_log_out: 'Se déconnecter',
  com_nav_user: 'UTILISATEUR',
  com_nav_clear_conversation: 'Effacer les conversations',
  com_nav_clear_conversation_confirm_message:
    'Êtes-vous sûr de vouloir effacer toutes les conversations ? Ceci est irréversible.',
  com_nav_help_faq: 'Aide & FAQ',
  com_nav_settings: 'Paramètres',
  com_nav_search_placeholder: 'Rechercher des messages',
  com_nav_setting_general: 'Général',
  com_nav_setting_beta: 'Fonctionnalités bêta',
  com_nav_setting_data: 'Contrôles des données',
  com_nav_setting_account: 'Compte',
};
