import { v4 } from 'uuid';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'librechat-data-provider';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import type { TMessage } from 'librechat-data-provider';
import { NotificationSeverity, type TAskFunction } from '~/common';
import useNewConvo from './useNewConvo';
import store from '~/store';
// import { useAuthStore } from '~/zustand';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { VERA_HEADER } from '~/utils/constants';
import { EVENT_TYPES } from '~/types/events';
import { BASE_API_URL, forceLogout } from '~/services/api/setup';
import { useNavigate, useParams } from 'react-router-dom';
import { buildMessagesFromEvents } from '~/utils/buildTree';
import { getErrorMessage } from '~/utils/error';
import { useToastContext } from '~/Providers';
import { useSandboxStore } from '~/zustand/sandbox';
import { useUploadFiles } from '~/services/mutations/chat';
import { PresignedResponse } from '~/types/chat';
import { useChatSettings } from '~/services/queries/models';
import { useAuth } from '~/Providers/useAuth';

// this to be set somewhere else
export default function useVeraChat(index = '', paramId: string | undefined) {
  const navigate = useNavigate();
  const params = useParams();
  const queryClient = useQueryClient();
  const { isSandbox } = useSandboxStore();
  const { user, getAccessToken, loginWithRedirect } = useAuth();
  const [abortController, setAbortController] = useState(new AbortController());
  const [showStopButton, setShowStopButton] = useState(false);
  const chatSettingsQuery = useChatSettings();
  const routingEnabled = chatSettingsQuery.data?.routing_enabled ?? false;

  const [currEvent, setCurrEvent] = useRecoilState(store.eventMessageByIndex(index));
  const [isSubmitting, setIsSubmitting] = useRecoilState(store.isSubmittingFamily(index));
  const [error, setError] = useRecoilState(store.errorMessageByIndex(index));
  const [latestMessage, setLatestMessage] = useRecoilState(store.latestMessageFamily(index));
  const [modelUpdatedMessage, setModelUpdatedMessage] = useState<null | any>(null);

  const [files, setFiles] = useRecoilState(store.filesByIndex(index));
  const [filesLoading, setFilesLoading] = useState(false);

  const uploadFilesMutation = useUploadFiles();
  const { newConversation } = useNewConvo(index);
  const { showToast } = useToastContext();
  const { useCreateConversationAtom } = store;
  const { conversation, setConversation } = useCreateConversationAtom(index);
  const { conversationId } = conversation ?? {};

  useEffect(() => {
    if (modelUpdatedMessage?.conversationId !== params.conversationId) {
      setModelUpdatedMessage(null);
    }
  }, [params.conversationId]);

  const queryParam = paramId === 'new' ? paramId : conversationId ?? paramId ?? '';

  const resetLatestMessage = useResetRecoilState(store.latestMessageFamily(index));
  const setSiblingIdx = useSetRecoilState(
    store.messagesSiblingIdxFamily(latestMessage?.parentMessageId ?? null),
  );

  const setMessages = useCallback(
    (messages: TMessage[], customId?: string) => {
      queryClient.setQueryData<TMessage[]>([QueryKeys.messages, customId ?? queryParam], messages);
    },
    [queryParam, queryClient],
  );

  const getMessages = useCallback(
    (customId?: string) => {
      return queryClient.getQueryData<TMessage[]>([QueryKeys.messages, customId ?? queryParam]);
    },
    [queryParam, queryClient],
  );

  const getEvents = useCallback(
    (customId?: string) => {
      return queryClient.getQueryData<TMessage[]>(['events', customId ?? queryParam]);
    },
    [queryParam, queryClient],
  );

  const setEvents = useCallback(
    (events: TMessage[], customId?: string) => {
      queryClient.setQueryData<TMessage[]>(['events', customId ?? queryParam], events);
    },
    [queryParam, queryClient],
  );

  const setSubmission = useSetRecoilState(store.submissionByIndex(index));

  const handleFiles = async () => {
    if (files.size) {
      try {
        setCurrEvent(`Processing Image${files.size === 1 ? '' : 's'}`);
        const data = await uploadFilesMutation.mutateAsync(Array.from(files.values()));
        return data;
      } catch (e) {
        return null;
      }
    }
    return [];
  };

  const ask: TAskFunction = async (
    {
      text,
      parentMessageId = null,
      conversationId = null,
      messageId = null,
      interactionId = null,
      supportsImageUpload = false,
    },
    {
      editedText = null,
      editedMessageId = null,
      isRegenerate = false,
      isContinued = false,
      isEdited = false,
    } = {},
  ) => {
    // console.log('[ASK] interactionId: ', interactionId);
    setModelUpdatedMessage(null);
    setError('');
    const messages = getMessages() ?? [];
    const lastMessage = messages.length > 0 ? messages[messages.length - 1] : null;
    setShowStopButton(true);
    setIsSubmitting(true);

    let uploadedFiles: PresignedResponse[] | null = null;
    if (supportsImageUpload) {
      uploadedFiles = await handleFiles();

      if (!uploadedFiles) {
        setCurrEvent('');
        setShowStopButton(false);
        setIsSubmitting(false);
        return;
      }
    }

    setCurrEvent('Analyzing');

    const convoId =
      conversationId ?? conversation?.conversation_id ?? lastMessage?.conversationId ?? null;

    if (!isRegenerate) {
      const tempMessage = {
        text: '...',
        sender: user?.email,
        isCreatedByUser: true,
        parentMessageId: parentMessageId ?? lastMessage?.messageId ?? null,
        conversationId: convoId,
        messageId: 'tempMessage',
        isError: false,
      };
      setMessages([...messages, tempMessage]);
    }

    let token = '';
    try {
      token = (await getAccessToken())!;
    } catch (e) {
      loginWithRedirect();
    }
    const apiUrl = `${BASE_API_URL}/chat${isRegenerate ? '/regenerate' : ''}`;
    const payload = isRegenerate
      ? { interaction_id: interactionId }
      : {
          prompt_text: text.trim(),
          conversation_id: convoId,
          image_ids:
            supportsImageUpload && uploadedFiles
              ? uploadedFiles.map((image) => image.image_id)
              : [],
        };
    const headers = {
      'Content-Type': 'application/json',
      [VERA_HEADER]: token,
    };

    fetchEventSource(apiUrl, {
      method: 'POST',
      headers,
      signal: abortController.signal,
      body: JSON.stringify(payload),
      openWhenHidden: true,
      async onopen(response) {
        // console.log('[PROTO] OPENED CONNECTION:', response);
        if (response.ok) {
          return; // everything's good
        } else if (response.status === 401) {
          // auth issue -> logout
          loginWithRedirect();

          //if user is locked
        } else if (response.status === 403) {
          // abortController.abort();
          // setAbortController(new AbortController());
          setCurrEvent('');
          setShowStopButton(false);
          setIsSubmitting(false);
          const revertMessages = !convoId
            ? []
            : messages.filter((m) => m.messageId !== 'tempMessage');
          setMessages(revertMessages);

          if (!convoId) {
            showToast({
              message:
                "Conversation failed to be created: Your current Subscription Plan 'Self Serve' does not allow this action. Please upgrade your plan for access.",
              severity: NotificationSeverity.ERROR,
              showIcon: true,
              duration: 6000,
            });
          } else {
            if (!isSandbox)
              setError(
                "Prompt failed to send: Your current Subscription Plan 'Self Serve' does not allow this action. Please upgrade your plan for access.",
              );
          }
        } else if (response.status === 429) {
          // abortController.abort();
          // setAbortController(new AbortController());
          setCurrEvent('');
          setShowStopButton(false);
          setIsSubmitting(false);
          const revertMessages = !convoId
            ? []
            : messages.filter((m) => m.messageId !== 'tempMessage');
          setMessages(revertMessages);
          if (!isSandbox) {
            setError(
              'Prompt failed to send: API request limit reached. Please contact your administrator to adjust the limit set for your account.',
            );
          }

          if (!convoId)
            showToast({
              message:
                'Conversation failed to be created: API request limit reached. Please contact your administrator to adjust the limit set for your account.',
              severity: NotificationSeverity.ERROR,
              showIcon: true,
              duration: 6000,
            });
        } else if (response.status === 423) {
          window.location.href = '/locked-account';
        } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
          // client-side errors are usually non-retriable:
          // debugger;
          throw response;
        } else {
          throw response;
        }
      },
      onmessage(msg) {
        if (msg.data) {
          const data = JSON.parse(msg.data);
          processEvent(data);
        }
        if (msg.event === 'FatalError') throw new Error(msg.data);
      },
      onerror(e) {
        abortController.abort();
        if (e && e.response && e.response.status === 423) {
          window.location.href = '/locked-account';
        }
        // const revertMessages = !convoId
        //   ? []
        //   : messages.filter((m) => m.messageId !== 'tempMessage');
        // setMessages(revertMessages);
        setAbortController(new AbortController());
        setCurrEvent('');
        setShowStopButton(false);
        setIsSubmitting(false);
        setError(getErrorMessage(e));
      },
      onclose() {
        const messages = getMessages() ?? [];
        // console.log('[ONCLOSE] messages: ', messages);
        const lastMessage = messages.length > 0 ? messages[messages.length - 1] : null;
        const convoId =
          conversationId ?? conversation?.conversation_id ?? lastMessage?.conversationId ?? null;
        // '[ONCLOSE] convoId', convoId);
        if (convoId && paramId === 'new') {
          queryClient.invalidateQueries({ queryKey: ['conversations'] });
          const events = getEvents() ?? [];
          setEvents(events, convoId);
          const newConvoPathName = `/c/${convoId}`;
          navigate(newConvoPathName, { state: { shallow: true, messages, events } });
        }
        setCurrEvent('');
        setShowStopButton(false);
        setIsSubmitting(false);
        queryClient.invalidateQueries({ queryKey: ['users', { userId: user?.user_id }] });
      },
    });
  };

  const processEvent = (data) => {
    const events = getEvents() ?? [];
    // console.log('[processEvent] events: ', events);
    switch (data.event_type) {
      case EVENT_TYPES.STATUS:
        setCurrEvent(data.event.message);
        break;
      case EVENT_TYPES.MESSAGE:
        events.push(data);
        setEvents([...events]);
        const messages = buildMessagesFromEvents({ events, user }) ?? [];
        const latestMessage_ = messages[messages.length - 1];
        setMessages(messages);
        setLatestMessage(latestMessage_);
        if (
          latestMessage &&
          !latestMessage.isCreatedByUser &&
          !!latestMessage.modelId &&
          !!latestMessage_.modelId &&
          latestMessage.modelId !== latestMessage_.modelId &&
          routingEnabled
        ) {
          setModelUpdatedMessage(latestMessage_);
        }

        break;
      default:
        setEvents([...events, data]);
    }
  };

  const regenerate = ({ parentMessageId }) => {
    const messages = getMessages();
    const parentMessage = messages?.find((element) => element.messageId == parentMessageId);

    if (parentMessage && parentMessage.isCreatedByUser) {
      ask({ ...parentMessage }, { isRegenerate: true });
    } else {
      console.error(
        'Failed to regenerate the message: parentMessage not found or not created by user.',
      );
    }
  };

  const continueGeneration = () => {
    if (!latestMessage) {
      console.error('Failed to regenerate the message: latestMessage not found.');
      return;
    }

    const messages = getMessages();

    const parentMessage = messages?.find(
      (element) => element.messageId == latestMessage.parentMessageId,
    );

    if (parentMessage && parentMessage.isCreatedByUser) {
      ask({ ...parentMessage }, { isContinued: true, isRegenerate: true, isEdited: true });
    } else {
      console.error(
        'Failed to regenerate the message: parentMessage not found, or not created by user.',
      );
    }
  };

  const stopGenerating = () => {
    abortController.abort();
    const messages = getMessages() ?? [];
    const events = getEvents() ?? [];
    if (messages.length > 0 && messages[messages.length - 1].isCreatedByUser) {
      const messagesWithLastUserMessageRemoved = [...messages];
      messagesWithLastUserMessageRemoved.pop();

      let amountOfEventsToBeRemoved = 0;
      let lastInteractionId = events[events.length - 1].interaction_id;
      for (let i = events.length - 1; i > 0; i--) {
        const evt = events[i];
        if (evt.interaction_id === lastInteractionId) {
          amountOfEventsToBeRemoved++;
        } else {
          break;
        }
      }
      const eventsWithLastEventsRemoved = [...events];
      eventsWithLastEventsRemoved.splice(
        eventsWithLastEventsRemoved.length - amountOfEventsToBeRemoved,
        amountOfEventsToBeRemoved,
      );

      setEvents(eventsWithLastEventsRemoved);
      setMessages(messagesWithLastUserMessageRemoved);
    }

    setCurrEvent('');
    setAbortController(new AbortController());
    setSubmission(null);
    setShowStopButton(false);
    setIsSubmitting(false);
  };

  const handleStopGenerating = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    stopGenerating();
  };

  const handleRegenerate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const parentMessageId = latestMessage?.parentMessageId;
    if (!parentMessageId) {
      console.error('Failed to regenerate the message: parentMessageId not found.');
      return;
    }
    regenerate({ parentMessageId });
  };

  const handleContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    continueGeneration();
    setSiblingIdx(0);
  };

  const [showBingToneSetting, setShowBingToneSetting] = useRecoilState(
    store.showBingToneSettingFamily(index),
  );
  const [showPopover, setShowPopover] = useRecoilState(store.showPopoverFamily(index));
  const [abortScroll, setAbortScroll] = useRecoilState(store.abortScrollFamily(index));
  const [preset, setPreset] = useRecoilState(store.presetByIndex(index));
  const [optionSettings, setOptionSettings] = useRecoilState(store.optionSettingsFamily(index));
  const [showAgentSettings, setShowAgentSettings] = useRecoilState(
    store.showAgentSettingsFamily(index),
  );

  const getUserTempMessageIsPresent = () => {
    const messages = getMessages() ?? [];

    return messages?.some((message) => message.messageId === 'tempMessage');
  };

  return {
    newConversation,
    conversation,
    setConversation,
    // getConvos,
    // setConvos,
    modelUpdatedMessage,
    setModelUpdatedMessage,
    getUserTempMessageIsPresent,
    isSubmitting,
    setIsSubmitting,
    getMessages,
    setMessages,
    setSiblingIdx,
    latestMessage,
    setLatestMessage,
    resetLatestMessage,
    index,
    regenerate,
    stopGenerating,
    handleStopGenerating,
    handleRegenerate,
    handleContinue,
    showPopover,
    setShowPopover,
    abortScroll,
    setAbortScroll,
    showBingToneSetting,
    setShowBingToneSetting,
    preset,
    setPreset,
    optionSettings,
    setOptionSettings,
    showAgentSettings,
    setShowAgentSettings,
    files,
    setFiles,
    filesLoading,
    setFilesLoading,

    error,
    currEvent,
    setCurrEvent,
    ask,
    showStopButton,
    setShowStopButton,
  };
}
