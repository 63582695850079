import { useRecoilValue } from 'recoil';
import { TypeAnimation } from 'react-type-animation';
import { useMessageHelpers, useLocalize } from '~/hooks';
import type { TMessageProps } from '~/common';
import { Plugin } from '~/components/Messages/Content';
import MessageContent from './Content/MessageContent';
import SiblingSwitch from './SiblingSwitch';
// eslint-disable-next-line import/no-cycle
import MultiMessage from './MultiMessage';
import HoverButtons from './HoverButtons';
import SubRow from './SubRow';
import { cn } from '~/utils';
import store from '~/store';
//import { useAuthStore } from '~/zustand';
import MessageInfoChip from './MessageInfoChip';
import { SOFT_GRAY, VERA_BLUE, VERA_PURPLE, VERA_RED, VERA_TEAL } from '~/utils/constants';
import ErrorAlertIcon from '~/components/svg/ErrorAlertIcon';
import ModelsIcon from '~/components/svg/ModelsIcon';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/Tooltip';
import { useAuth } from '~/Providers/useAuth';

export default function Message(props: TMessageProps) {
  const UsernameDisplay = useRecoilValue<boolean>(store.UsernameDisplay);

  const { user } = useAuth();
  const localize = useLocalize();

  const {
    ask,
    icon,
    edit,
    isLast,
    enterEdit,
    handleScroll,
    conversation,
    isSubmitting,
    latestMessage,
    handleContinue,
    copyToClipboard,
    regenerateMessage,
    modelUpdatedMessage,
  } = useMessageHelpers(props);

  const { message, siblingIdx, siblingCount, setSiblingIdx, currentEditId, setCurrentEditId } =
    props;

  if (!message) {
    return null;
  }

  const showModelUpdated = modelUpdatedMessage?.messageId === message.messageId;

  const {
    text,
    children,
    messageId = null,
    isCreatedByUser,
    isError,
    unfinished,
    parentMessageId,
    isWarning,
  } = message ?? {};

  let messageLabel = '';
  if (isCreatedByUser) {
    messageLabel = UsernameDisplay ? user?.email : localize('com_user_message');
  } else {
    messageLabel = message.sender;
  }

  return (
    <>
      <div
        className="text-token-text-primary w-full border-0 bg-transparent dark:border-0 dark:bg-transparent"
        onWheel={handleScroll}
        onTouchMove={handleScroll}
      >
        <div className="m-auto justify-center p-4 py-2 text-base md:gap-6 ">
          <div className="} group mx-auto flex flex-1 gap-3 text-base md:max-w-3xl md:px-5 lg:max-w-[40rem] lg:px-1 xl:max-w-[48rem] xl:px-5">
            <div className="relative flex flex-shrink-0 flex-col items-end">
              <div>
                <div className="pt-0.5">
                  <div
                    className="gizmo-shadow-stroke flex h-6 w-6 items-center justify-center overflow-hidden rounded-full"
                    style={{ height: 24, width: 24 }}
                  >
                    {typeof icon === 'string' && /[^\\x00-\\x7F]+/.test(icon as string) ? (
                      <span className=" direction-rtl w-40 overflow-x-scroll">{icon}</span>
                    ) : (
                      icon
                    )}
                  </div>
                  {(isError || isWarning) && (
                    <ErrorAlertIcon
                      className="absolute left-3 top-3 w-4"
                      color={isWarning ? '#FDD835' : VERA_RED}
                    />
                  )}
                  {/* <p className="text-sm">messageId: {messageId}</p>
                  <p className="text-sm">parentMessageId: {parentMessageId}</p> */}
                </div>
              </div>
            </div>
            <div
              className={cn('relative flex w-full flex-col', isCreatedByUser ? '' : 'agent-turn')}
            >
              <div className="select-none font-semibold">{messageLabel}</div>

              <div className="flex-col gap-1 md:gap-3">
                {showModelUpdated && (
                  <label style={{ color: VERA_PURPLE }} className="my-2 flex text-sm">
                    <TooltipProvider delayDuration={50}>
                      <Tooltip defaultOpen={false}>
                        <TooltipTrigger>
                          <ModelsIcon className="mr-2" />{' '}
                        </TooltipTrigger>

                        <TooltipContent className="bg-[#4A417A]" side="top" sideOffset={-5}>
                          Vera has identified a better model for this prompt.
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                    <TypeAnimation
                      sequence={[100, 'Model has been updated']}
                      cursor={false}
                      repeat={0}
                    />
                  </label>
                )}
                <div className="flex max-w-full flex-grow flex-col gap-0">
                  {/* Legacy Plugins */}
                  {message?.plugin && <Plugin plugin={message?.plugin} />}
                  <MessageContent
                    ask={ask}
                    edit={edit}
                    isLast={isLast}
                    text={text ?? ''}
                    message={message}
                    enterEdit={enterEdit}
                    error={isError}
                    isSubmitting={isSubmitting}
                    unfinished={unfinished ?? false}
                    isCreatedByUser={isCreatedByUser ?? true}
                    siblingIdx={siblingIdx ?? 0}
                    setSiblingIdx={
                      setSiblingIdx ??
                      (() => {
                        return;
                      })
                    }
                  />
                </div>
              </div>
              {/* {isLast && isSubmitting ? null : ( */}

              <SubRow
                classes={`text-xs items-center  ${
                  isLast && isSubmitting ? 'hidden' : 'block'
                } h-max-content`}
              >
                <SiblingSwitch
                  siblingIdx={siblingIdx}
                  siblingCount={siblingCount}
                  setSiblingIdx={setSiblingIdx}
                />

                <div className="flex flex-col-reverse gap-1 lg:flex-row lg:items-center lg:gap-2 ">
                  {!isError && !isWarning && (
                    <HoverButtons
                      isRegenaratable={
                        message.isCacheResult && latestMessage?.messageId === message.messageId
                      }
                      isEditing={edit}
                      message={message}
                      enterEdit={enterEdit}
                      isSubmitting={isSubmitting}
                      conversation={conversation ?? null}
                      regenerate={() => regenerateMessage(message.interactionId)}
                      copyToClipboard={copyToClipboard}
                      handleContinue={handleContinue}
                      latestMessage={latestMessage}
                    />
                  )}

                  {!isCreatedByUser && (
                    <>
                      {(!!message.modelId ||
                        !!message.systemMessage ||
                        !!message.isCacheResult) && (
                        <span
                          className="hidden lg:block"
                          style={{ background: SOFT_GRAY, height: '16px', width: 1 }}
                        />
                      )}
                      <div className="mt-1 flex items-center gap-2 lg:mt-0">
                        {message.modelId && (
                          <MessageInfoChip text={message.modelId} color={'#fff'} bg={VERA_PURPLE}>
                            {message.modelReason}
                          </MessageInfoChip>
                        )}

                        {message.systemMessage && !message.isCacheResult && (
                          <MessageInfoChip text={'System info'} color="#fff" bg={VERA_PURPLE}>
                            {message.systemMessage}
                          </MessageInfoChip>
                        )}

                        {message.isCacheResult && (
                          <MessageInfoChip text={'Cached response'} bg={VERA_PURPLE} color={'#FFF'}>
                            {message.systemMessage}
                          </MessageInfoChip>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </SubRow>

              {/* )} */}
            </div>
          </div>
        </div>
      </div>
      <MultiMessage
        key={messageId}
        messageId={messageId}
        conversation={conversation}
        messagesTree={children ?? []}
        currentEditId={currentEditId}
        setCurrentEditId={setCurrentEditId}
      />
    </>
  );
}
