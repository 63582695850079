import { Source } from '~/types/source';
import { axios } from './setup';

export const getRagDocuments = async () => {
  const { data } = await axios.get<{ documents: Source[]; count: number }>(`rag/document_links`);
  const selected = data.documents.filter((doc) => doc.active).map((doc) => doc.document_id);
  return { ...data, selected };
};

export const updateSelectedRagDocuments = async (data: string[]) => {
  return (await axios.put(`rag/document_links`, { documents: data })).data;
};
