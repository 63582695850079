import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SOFT_GRAY } from '~/utils/constants';

function TOScomponent() {
  const navigate = useNavigate();
  const tosModal = useDisclosure();
  const privacyModal = useDisclosure();

  return (
    <>
      <Text fontSize={'xs'} color={SOFT_GRAY}>
        By creating an account, you agree to our{' '}
        <Box
          as={Link}
          fontWeight={'semibold'}
          cursor={'pointer'}
          to="/terms-of-use"
          target="_blank"
        >
          Terms of Use
        </Box>{' '}
        and{' '}
        <Box
          as={Link}
          fontWeight={'semibold'}
          cursor="pointer"
          to="/privacy-policy"
          target="_blank"
        >
          Privacy Policy
        </Box>
        .
      </Text>
      {/* <Modal isOpen={tosModal.isOpen} onClose={tosModal.onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Terms of Service</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              overflowY="auto"
              height="300px"
              p={2}
              mb={4}
              sx={{
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-thumb': {
                  bg: 'gray.300',
                  borderRadius: '5px',
                  _hover: {
                    bg: 'gray.500',
                  },
                },
                '&::-webkit-scrollbar-track': {
                  bg: 'transparent',
                  _hover: {
                    bg: 'gray.200',
                  },
                },
              }}
            >
              <Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit amet lacinia ex.
                Pellentesque ultricies tincidunt viverra. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Nullam sed accumsan quam, vel pharetra lacus. Duis ac orci vitae
                eros tincidunt tempor. Praesent suscipit, sapien sit amet congue fringilla, magna
                urna pellentesque odio, eu auctor ligula enim ut nulla. Nulla varius risus eget
                lacus cursus, ac malesuada lectus bibendum. Aliquam erat volutpat. Duis eu viverra
                enim. Ut scelerisque velit erat, nec scelerisque metus sodales ac. Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Donec sit amet lacinia ex. Pellentesque
                ultricies tincidunt viverra. Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Nullam sed accumsan quam, vel pharetra lacus. Duis ac orci vitae eros
                tincidunt tempor. Praesent suscipit, sapien sit amet congue fringilla, magna urna
                pellentesque odio, eu auctor ligula enim ut nulla. Nulla varius risus eget lacus
                cursus, ac malesuada lectus bibendum. Aliquam erat volutpat. Duis eu viverra enim.
                Ut scelerisque velit erat, nec scelerisque metus sodales ac. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. Donec sit amet lacinia ex. Pellentesque ultricies
                tincidunt viverra. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                sed accumsan quam, vel pharetra lacus. Duis ac orci vitae eros tincidunt tempor.
                Praesent suscipit, sapien sit amet congue fringilla, magna urna pellentesque odio,
                eu auctor ligula enim ut nulla. Nulla varius risus eget lacus cursus, ac malesuada
                lectus bibendum. Aliquam erat volutpat. Duis eu viverra enim. Ut scelerisque velit
                erat, nec scelerisque metus sodales ac. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Donec sit amet lacinia ex. Pellentesque ultricies tincidunt
                viverra. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed
                accumsan quam, vel pharetra lacus. Duis ac orci vitae eros tincidunt tempor.
                Praesent suscipit, sapien sit amet congue fringilla, magna urna pellentesque odio,
                eu auctor ligula enim ut nulla. Nulla varius risus eget lacus cursus, ac malesuada
                lectus bibendum. Aliquam erat volutpat. Duis eu viverra enim. Ut scelerisque velit
                erat, nec scelerisque metus sodales ac.
              </Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={privacyModal.isOpen} onClose={privacyModal.onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Privacy & Cookie Statement</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box overflowY="auto" height="300px" p={2} mb={4}>
              <Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit amet lacinia ex.
                Pellentesque ultricies tincidunt viverra. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Nullam sed accumsan quam, vel pharetra lacus. Duis ac orci vitae
                eros tincidunt tempor. Praesent suscipit, sapien sit amet congue fringilla, magna
                urna pellentesque odio, eu auctor ligula enim ut nulla. Nulla varius risus eget
                lacus cursus, ac malesuada lectus bibendum. Aliquam erat volutpat. Duis eu viverra
                enim. Ut scelerisque velit erat, nec scelerisque metus sodales ac.
              </Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal> */}
    </>
  );
}

export default TOScomponent;
