import DataTable, { TableColumn, createTheme } from 'react-data-table-component';
import { Checkbox } from '~/components/ui';
import { ExpandableRowsComponent } from 'react-data-table-component/dist/DataTable/types';
import './styles.css';
import { VERA_NAVY, VERA_TEAL } from '~/utils/constants';
import { useContext } from 'react';
import { ThemeContext } from '~/hooks/ThemeContext';
import ThreeDotsMenu, { ActionOption } from '../Chat/Menus/UI/ThreeDotsMenu';

createTheme('vera-table', {
  striped: {
    default: '#E9EAF6',
  },
  text: {
    primary: VERA_NAVY,
    secondary: VERA_NAVY,
  },
  background: {
    default: '#21213',
  },

  divider: {
    default: '#fff',
  },
});

createTheme(
  'dark-vera-table',
  {
    striped: {
      default: VERA_NAVY,
    },
    text: {
      primary: 'rgba(197, 197, 210)',
      secondary: 'rgba(197, 197, 210)',
    },
    background: {
      default: '#21213',
    },
    context: {
      background: '#718096',
      text: 'rgba(197, 197, 210)',
    },
    divider: {
      default: '#4A5568',
    },
    button: {
      default: '#CBD5DC',
      hover: 'rgba(255, 255, 255, 0.08)',
      focus: 'rgba(255, 255, 255, 0.12)',
      disabled: 'rgba(255, 255, 255, 0.34)',
    },
    sortFocus: {
      default: '#CBD5DC',
    },
  },
  'dark',
);

const tableStyles = {
  headCells: {
    style: {
      fontSize: 14,
      fontWeight: 700,
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
};

interface DefaultDataTableProps {
  columns: TableColumn<any>[];
  data: any[];
  rowsPerPageOptions?: number[];
  isLoading?: boolean;
  onClickRow?: (row: any) => void;
  keyField?: 'id' | string;
  rowIsSelected?: (row: any) => boolean;
  onSelectRow?: (row: any, checked: boolean) => void;
  omitColumns?: string[];
  actions?: ActionOption[];
  expandableRowsComponent?: ExpandableRowsComponent<any>;

  serverPagination?: {
    onChangePage: (page: number) => void;
    totalCount: number;
    limit?: number;
    onChangeRowsPerPage: (currRows: number, currPage: number) => void;
    isFetching?: boolean;
  };
}

export function DefaultDataTable({
  columns,
  data,
  isLoading,
  onClickRow,
  onSelectRow,
  rowIsSelected,
  omitColumns,
  expandableRowsComponent,
  actions,
  serverPagination,
  rowsPerPageOptions,
}: DefaultDataTableProps) {
  const { theme } = useContext(ThemeContext);
  let isDark = theme === 'dark';
  if (theme === 'system') {
    isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  // handles getting the table columns
  // and will filter unwanted columns if provided
  const getColumns = () => {
    let tableColumns = columns;
    if (omitColumns) {
      tableColumns = columns.filter(
        (column) => !omitColumns.includes((column as { name: string }).name),
      );
    }

    // handles adding the check cell when rows are selectable
    if (!!onSelectRow && !!rowIsSelected) {
      const selectCell = {
        sortable: false,
        width: '50px',
        cell: (row: any) => (
          //   <Center w="100%">
          <Checkbox
            className="focus:ring-opacity-20 dark:border-gray-500 dark:bg-[#2C2552] dark:text-gray-50 dark:focus:ring-gray-600 dark:focus:ring-opacity-50 dark:focus:ring-offset-0"
            checked={rowIsSelected(row)}
            onCheckedChange={(checked) => {
              onSelectRow(row, checked);
            }}
          />
          //   </Center>
        ),
        grow: 0,
      };
      tableColumns = [selectCell, ...tableColumns];
    }

    // handles adding the three dots menu when there are actions
    if (actions && actions.length) {
      const actionCell = {
        sortable: false,
        width: '50px',
        cell: (row: any) => <ThreeDotsMenu options={actions} row={row} />,
        grow: 0,
      };

      tableColumns = [...tableColumns, actionCell];
    }

    return tableColumns;
  };

  // Handles adding the server pagination logic
  let serverPaginationProps = {};
  if (!!serverPagination) {
    serverPaginationProps = {
      paginationServer: true,
      paginationPerPage: serverPagination.limit,
      paginationTotalRows: serverPagination.totalCount,
      onChangePage: serverPagination.onChangePage,
      onChangeRowsPerPage: serverPagination.onChangeRowsPerPage,
    };
  }

  return (
    <DataTable
      className="rdt_Vera_Table"
      paginationRowsPerPageOptions={rowsPerPageOptions ?? [5, 10, 15, 20]}
      theme={isDark ? 'dark-vera-table' : 'vera-table'}
      highlightOnHover
      fixedHeader
      pointerOnHover
      customStyles={tableStyles}
      striped
      noDataComponent={
        <>No records to display</>
        // <Center h={'100px'}>
        //   <EmptyText>No records to display</EmptyText>
        // </Center>
      }
      progressComponent={<>Loading...</>}
      progressPending={isLoading}
      columns={getColumns()}
      data={data}
      pagination
      onRowClicked={(row) => onClickRow && onClickRow(row)}
      selectableRowsNoSelectAll
      expandableRows={!!expandableRowsComponent}
      expandOnRowClicked={!!expandableRowsComponent}
      expandableRowsComponent={expandableRowsComponent}
      {...serverPaginationProps}
    />
  );
}
