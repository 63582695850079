import React, { useState, useEffect } from 'react';
import VeraSpinner from '~/components/svg/VeraSpinner';
import VeraMessage from './VeraMessage';
import { VERA_PURPLE } from '~/utils/constants';

interface ProcessingSpinnerProps {
  event: string | null;
  isUser?: boolean;
}

const ProcessingSpinner: React.FC<ProcessingSpinnerProps> = ({ event, isUser = false }) => {
  const [displayedEvent, setDisplayedEvent] = useState<string | null>(
    event?.endsWith('...') ? event.slice(0, -3) : event,
  );

  useEffect(() => {
    const waitingTimer1 = setTimeout(() => {
      setDisplayedEvent(`${displayedEvent}...`);
    }, 4500); // 4.5 seconds

    const waitingTimer2 = setTimeout(() => {
      setDisplayedEvent(`Still ${displayedEvent?.toLowerCase()}...`);
    }, 12000); // 12 seconds

    const waitingTimer3 = setTimeout(() => {
      setDisplayedEvent(`${event?.split(' ')[0]} is taking longer than usual...`);
    }, 30000); // 30 seconds

    const waitingTimer4 = setTimeout(() => {
      setDisplayedEvent(`System experiencing longer wait times than normal...`);
    }, 50000); // 50 seconds

    return () => {
      clearTimeout(waitingTimer1);
      clearTimeout(waitingTimer2);
      clearTimeout(waitingTimer3);
      clearTimeout(waitingTimer4);
    };
  }, [event]);

  return (
    <VeraMessage isUser={isUser}>
      <div
        className="flex max-w-max items-center justify-center rounded-full py-2 pl-4 pr-5"
        style={{
          background: `${VERA_PURPLE}1A`,
        }}
      >
        <VeraSpinner className="mr-1" /> {displayedEvent}
      </div>
    </VeraMessage>
  );
};

export default ProcessingSpinner;
