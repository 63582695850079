import React, { useEffect, useState } from 'react';
import Background from './Background';
import { useAuth } from '~/Providers/useAuth';
import { Box, Heading, VStack, Text, Link } from '@chakra-ui/react';
import StripeCheckout from '../Registration/StripeCheckout';
import { HARD_GRAY, VERA_PINK, VERA_WHITE, VERA_YELLOW } from '~/utils/constants';
import { ArrowRight } from 'lucide-react';
import { ArrowRightIcon } from '@chakra-ui/icons';

function UpgradePage() {
  const { getAccessToken, user, isLoading } = useAuth();
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const token = await getAccessToken();
        setAccessToken(token!);
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };

    if (!!getAccessToken) {
      fetchAccessToken();
    }
  }, [getAccessToken, isLoading]);

  return (
    <Background>
      <Box
        maxW="md"
        mx="auto"
        my={8}
        p={8}
        background={'#2C2552'}
        color={VERA_WHITE}
        borderRadius={'lg'}
      >
        <VStack spacing={4} textAlign={'center'}>
          <Heading as="h2" size="lg" w={'80%'}>
            Your account has been successfully created 🎉
          </Heading>

          <Text fontSize="lg" mt={-2}>
            You're just one step away from unlocking everything Vera has to offer!
          </Text>

          <StripeCheckout orgId={user?.org_id!} accessToken={accessToken!} />

          <Link
            onClick={() => (window.location.href = import.meta.env.VITE_AUTH0_LOGOUT_CALLBACK_URL)}
            fontSize={'smaller'}
            color={VERA_YELLOW}
          >
            Or continue to your sandbox here
          </Link>
        </VStack>
      </Box>
    </Background>
  );
}

export default UpgradePage;
