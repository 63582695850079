import FileSaver from 'file-saver';
import { axios } from './setup';

export const exportDocumentFromStore = async (filename: string) => {
  const { data } = await axios.get<Blob>(`/rag/${filename}/download`, {
    responseType: 'blob',
  });
  const blob = new Blob([data]);
  FileSaver.saveAs(blob, filename);
};
