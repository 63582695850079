// Korean phrases

export default {
  com_ui_examples: '예시',
  com_ui_new_chat: '새 채팅',
  com_ui_example_quantum_computing: '양자 컴퓨팅을 간단하게 설명해줄래?',
  com_ui_example_10_year_old_b_day: '10살 아이 생일 파티를 위한 참신한 아이디어 있을까?',
  com_ui_example_http_in_js: '자바스크립트에서 HTTP 요청을 어떻게 만드나요?',
  com_ui_capabilities: '기능',
  com_ui_capability_remember: '대화 중 사용자가 이전에 말한 것을 기억해요.',
  com_ui_capability_correction: '사용자가 수정 사항을 제공할 수 있어요.',
  com_ui_capability_decline_requests: '부적절한 요청을 거부하도록 훈련되었어요.',
  com_ui_limitations: '제한사항',
  com_ui_limitation_incorrect_info: '간혹 잘못된 정보를 생성할 수 있습니다.',
  com_ui_limitation_harmful_biased: '간혹 해로운 지시나 편향된 내용을 제공할 수 있습니다.',
  com_ui_limitation_limited_2021: '2021년 이후의 세계 및 이벤트에 대한 지식이 제한적입니다.',
  com_ui_input: '입력',
  com_ui_close: '닫기',
  com_ui_model: '모델',
  com_ui_select_model: '모델 선택',
  com_ui_use_prompt: '프롬프트 사용',
  com_ui_prev: '이전',
  com_ui_next: '다음',
  com_ui_prompt_templates: '프롬프트 템플릿',
  com_ui_hide_prompt_templates: '프롬프트 템플릿 숨기기',
  com_ui_showing: '표시 중',
  com_ui_of: '/',
  com_ui_entries: '개',
  com_ui_pay_per_call: '모든 AI 대화를 한 곳에서. 월별이 아닌 호출 당 지불',
  com_ui_enter: 'Enter',
  com_ui_submit: '제출',
  com_ui_upload_success: '파일 업로드 성공',
  com_ui_upload_invalid: '잘못된 파일입니다',
  com_ui_cancel: '취소',
  com_ui_save: '저장',
  com_ui_copy_to_clipboard: '클립보드에 복사',
  com_ui_copied_to_clipboard: '클립보드에 복사되었습니다',
  com_ui_regenerate: '재생성',
  com_ui_continue: '계속',
  com_ui_edit: '편집',
  com_ui_success: '성공',
  com_ui_all: '전체',
  com_ui_clear: '지우기',
  com_ui_revoke: '취소',
  com_ui_revoke_info: '사용자가 제공한 자격 증명을 모두 취소합니다.',
  com_ui_confirm_action: '작업 확인',
  com_ui_chats: '채팅',
  com_ui_delete: '삭제',
  com_ui_delete_conversation: '채팅을 삭제하시겠습니까?',
  com_ui_delete_conversation_confirm: '이 채팅이 삭제됩니다',
  com_auth_error_login: '제공된 정보로 로그인할 수 없습니다. 자격 증명을 확인하고 다시 시도하세요.',
  com_auth_no_account: '계정이 없으신가요?',
  com_auth_sign_up: '가입하기',
  com_auth_sign_in: '로그인',
  com_auth_google_login: 'Google로 로그인',
  com_auth_facebook_login: 'Facebook으로 로그인',
  com_auth_github_login: 'Github으로 로그인',
  com_auth_discord_login: 'Discord로 로그인',
  com_auth_email: '이메일',
  com_auth_email_required: '이메일은 필수입니다',
  com_auth_email_min_length: '이메일은 최소 6자 이상이어야 합니다',
  com_auth_email_max_length: '이메일은 120자를 넘을 수 없습니다',
  com_auth_email_pattern: '유효한 이메일 주소를 입력하세요',
  com_auth_email_address: '이메일 주소',
  com_auth_password: '비밀번호',
  com_auth_password_required: '비밀번호는 필수입니다',
  com_auth_password_min_length: '비밀번호는 최소 8자 이상이어야 합니다',
  com_auth_password_max_length: '비밀번호는 128자를 넘을 수 없습니다',
  com_auth_password_forgot: '비밀번호를 잊으셨나요?',
  com_auth_password_confirm: '비밀번호 확인',
  com_auth_password_not_match: '비밀번호가 일치하지 않습니다',
  com_auth_continue: '계속',
  com_auth_create_account: '계정 만들기',
  com_auth_error_create: '계정을 등록하는 중에 오류가 발생했습니다. 다시 시도하세요.',
  com_auth_full_name: '이름',
  com_auth_name_required: '이름은 필수입니다',
  com_auth_name_min_length: '이름은 최소 3자 이상이어야 합니다',
  com_auth_name_max_length: '이름은 80자를 초과할 수 없습니다',
  com_auth_username: '사용자명',
  com_auth_username_required: '사용자명이 필요합니다',
  com_auth_username_min_length: '사용자명은 최소 3자 이상이어야 합니다',
  com_auth_username_max_length: '사용자명은 20자를 초과할 수 없습니다',
  com_auth_already_have_account: '이미 계정이 있으신가요?',
  com_auth_login: '로그인',
  com_auth_reset_password: '비밀번호 재설정',
  com_auth_click: '클릭',
  com_auth_here: '여기',
  com_auth_to_reset_your_password: '비밀번호를 재설정하려면',
  com_auth_reset_password_link_sent: '이메일 전송',
  com_auth_reset_password_email_sent:
    '비밀번호 재설정에 대한 지침이 포함된 이메일이 전송되었습니다.',
  com_auth_error_reset_password:
    '비밀번호 재설정 중에 문제가 발생했습니다. 제공된 이메일 주소로 사용자를 찾을 수 없습니다. 다시 시도하세요.',
  com_auth_reset_password_success: '비밀번호 재설정 성공',
  com_auth_login_with_new_password: '새로운 비밀번호로 로그인할 수 있습니다.',
  com_auth_error_invalid_reset_token: '이 비밀번호 재설정 토큰은 더 이상 유효하지 않습니다.',
  com_auth_click_here: '여기를 클릭하세요',
  com_auth_to_try_again: '다시 시도하세요.',
  com_auth_submit_registration: '등록하기',
  com_auth_welcome_back: '다시 오신 것을 환영합니다',
  com_endpoint_open_menu: '메뉴 열기',
  com_endpoint_bing_enable_sydney: '시드니 활성화',
  com_endpoint_bing_to_enable_sydney: '시드니를 활성화하려면',
  com_endpoint_bing_jailbreak: 'Jailbreak',
  com_endpoint_bing_context_placeholder:
    'Bing은 \'컨텍스트\'로 최대 7,000개의 토큰을 사용할 수 있으며, 대화에서 참조할 수 있습니다. 구체적인 제한은 알려져 있지 않지만, 7,000개의 토큰을 초과하면 오류가 발생할 수 있습니다.',
  com_endpoint_bing_system_message_placeholder:
    '경고: 이 기능의 오용으로 인해 Bing의 사용이 \'금지\'될 수 있습니다. 모든 내용을 보려면 \'시스템 메시지\'를 클릭하세요. 생략된 경우 \'시드니\' 프리셋이 사용됩니다.',
  com_endpoint_system_message: '시스템 메시지',
  com_endpoint_default_blank: '기본값: 공백',
  com_endpoint_default_false: '기본값: false',
  com_endpoint_default_creative: '기본값: 창의적',
  com_endpoint_default_empty: '기본값: 비어 있음',
  com_endpoint_default_with_num: '기본값: {0}',
  com_endpoint_context: '컨텍스트',
  com_endpoint_tone_style: '톤 스타일',
  com_endpoint_token_count: '토큰 수',
  com_endpoint_output: '출력',
  com_endpoint_google_temp:
    '높은 값 = 더 무작위, 낮은 값 = 더 집중적이고 결정적입니다. 이 값을 변경하거나 Top P 중 하나만 변경하는 것을 권장합니다.',
  com_endpoint_google_topp:
    'Top-p는 모델이 출력에 사용할 토큰을 선택하는 방식을 변경합니다. 토큰은 가장 높은 확률부터 가장 낮은 확률까지 선택됩니다. 선택된 토큰의 확률의 합이 top-p 값과 같아질 때까지 선택됩니다.',
  com_endpoint_google_topk:
    'Top-k는 모델이 출력에 사용할 토큰을 선택하는 방식을 변경합니다. top-k가 1인 경우 모델의 어휘 중 가장 확률이 높은 토큰이 선택됩니다(greedy decoding). top-k가 3인 경우 다음 토큰은 가장 확률이 높은 3개의 토큰 중에서 선택됩니다(temperature 사용).',
  com_endpoint_google_maxoutputtokens:
    '응답에서 생성할 수 있는 최대 토큰 수입니다. 짧은 응답에는 낮은 값을, 긴 응답에는 높은 값을 지정하세요.',
  com_endpoint_google_custom_name_placeholder: 'Google에 대한 사용자 정의 이름 설정',
  com_endpoint_prompt_prefix_placeholder:
    '사용자 정의 지시사항 또는 컨텍스트를 설정하세요. 비어 있으면 무시됩니다.',
  com_endpoint_custom_name: '사용자 정의 이름',
  com_endpoint_prompt_prefix: '프롬프트 접두사',
  com_endpoint_temperature: '온도',
  com_endpoint_default: '기본값',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top K',
  com_endpoint_max_output_tokens: '최대 출력 토큰 수',
  com_endpoint_openai_temp:
    '높은 값 = 더 무작위, 낮은 값 = 더 집중적이고 결정적입니다. 이 값을 변경하거나 Top P 중 하나만 변경하는 것을 권장합니다.',
  com_endpoint_openai_max:
    '생성할 최대 토큰 수입니다. 입력 토큰과 생성된 토큰의 총 길이는 모델의 컨텍스트 길이로 제한됩니다.',
  com_endpoint_openai_topp:
    '온도를 사용한 샘플링 대신, top_p 확률 질량을 고려하는 nucleus 샘플링입니다. 따라서 0.1은 상위 10% 확률 질량을 구성하는 토큰만 고려합니다. 이 값을 변경하거나 온도를 변경하는 것을 권장하지만, 둘 다 변경하지는 마세요.',
  com_endpoint_openai_freq:
    '텍스트에서 토큰의 빈도수에 따라 새로운 토큰에 패널티를 부여합니다. 이전에 나온 텍스트의 빈도수에 따라 새로운 토큰의 확률이 감소하여 동일한 문장을 반복할 가능성을 줄입니다.',
  com_endpoint_openai_pres:
    '텍스트에서 토큰이 나타나는지 여부에 따라 새로운 토큰에 패널티를 부여합니다. 이전에 나온 텍스트에 나타나는 토큰에 대한 패널티를 증가시켜 새로운 주제에 대해 이야기할 가능성을 높입니다.',
  com_endpoint_openai_custom_name_placeholder: 'ChatGPT에 대한 사용자 정의 이름을 설정하세요.',
  com_endpoint_openai_prompt_prefix_placeholder:
    '시스템 메시지에 포함할 사용자 정의 지시사항을 설정하세요. 기본값: 없음',
  com_endpoint_anthropic_temp:
    '0에서 1 사이의 값으로, 분석/다중 선택에는 0에 가까운 값을 사용하고, 창의적이고 생성적인 작업에는 1에 가까운 값을 사용하세요. 이 값을 변경하거나 Top P 중 하나만 변경하는 것을 권장합니다.',
  com_endpoint_anthropic_topp:
    'Top-p는 모델이 출력에 사용할 토큰을 선택하는 방식을 변경합니다. 토큰은 가장 높은 확률부터 가장 낮은 확률까지 선택됩니다. 선택된 토큰의 확률의 합이 top-p 값과 같아질 때까지 선택됩니다.',
  com_endpoint_anthropic_topk:
    'Top-k는 모델이 출력에 사용할 토큰을 선택하는 방식을 변경합니다. top-k가 1인 경우 모델의 어휘 중 가장 확률이 높은 토큰이 선택됩니다(greedy decoding). top-k가 3인 경우 다음 토큰은 가장 확률이 높은 3개의 토큰 중에서 선택됩니다(temperature 사용).',
  com_endpoint_anthropic_maxoutputtokens:
    '응답에서 생성할 수 있는 최대 토큰 수입니다. 짧은 응답에는 낮은 값을, 긴 응답에는 높은 값을 지정하세요.',
  com_endpoint_anthropic_custom_name_placeholder: 'Anthropic에 대한 사용자 정의 이름 설정',
  com_endpoint_frequency_penalty: '빈도 패널티',
  com_endpoint_presence_penalty: '존재 패널티',
  com_endpoint_plug_use_functions: '함수 사용',
  com_endpoint_plug_skip_completion: '완료 단계 건너뛰기',
  com_endpoint_disabled_with_tools: '도구 사용 불가',
  com_endpoint_disabled_with_tools_placeholder: '도구 선택 시 사용 불가',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    '시스템 메시지에 포함할 사용자 정의 지시사항을 설정하세요. 기본값: 없음',
  com_endpoint_import: '가져오기',
  com_endpoint_set_custom_name: '프리셋을 쉽게 찾을 수 있도록 사용자 정의 이름을 설정하세요',
  com_endpoint_preset: '프리셋',
  com_endpoint_presets: '프리셋',
  com_endpoint_preset_name: '프리셋 이름',
  com_endpoint_new_topic: '새로운 주제',
  com_endpoint: '엔드포인트',
  com_endpoint_hide: '숨기기',
  com_endpoint_show: '표시',
  com_endpoint_examples: ' 프리셋',
  com_endpoint_completion: '완료',
  com_endpoint_agent: '에이전트',
  com_endpoint_show_what_settings: '{0} 설정 표시',
  com_endpoint_save: '저장',
  com_endpoint_export: '내보내기',
  com_endpoint_save_as_preset: '프리셋으로 저장',
  com_endpoint_presets_clear_warning:
    '모든 프리셋을 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.',
  com_endpoint_not_implemented: '구현되지 않았습니다',
  com_endpoint_no_presets: '아직 프리셋이 없습니다',
  com_endpoint_not_available: '사용할 수 없는 엔드포인트입니다',
  com_endpoint_view_options: '옵션 보기',
  com_endpoint_save_convo_as_preset: '대화를 프리셋으로 저장',
  com_endpoint_my_preset: '내 프리셋',
  com_endpoint_agent_model: '에이전트 모델 (권장: GPT-3.5)',
  com_endpoint_completion_model: '완료 모델 (권장: GPT-4)',
  com_endpoint_func_hover: '플러그인을 OpenAI 함수로 사용할 수 있도록 합니다.',
  com_endpoint_skip_hover:
    '완료 단계를 건너뛰도록 합니다. 최종 답변과 생성된 단계를 검토하는 단계입니다.',
  com_endpoint_config_key: 'API 키 설정',
  com_endpoint_config_key_for: 'API 키 설정: ',
  com_endpoint_config_key_name: '키',
  com_endpoint_config_value: '값 입력',
  com_endpoint_config_key_name_placeholder: '먼저 API 키를 설정하세요',
  com_endpoint_config_key_encryption: '키는 암호화되어 저장되며, 만료 시간에 삭제됩니다',
  com_endpoint_config_key_expiry: '만료 시간',
  com_endpoint_config_key_import_json_key: '서비스 계정 JSON 키 가져오기',
  com_endpoint_config_key_import_json_key_success: '서비스 계정 JSON 키 가져오기 성공',
  com_endpoint_config_key_import_json_key_invalid:
    '유효하지 않은 서비스 계정 JSON 키입니다. 올바른 파일을 가져왔는지 확인하세요',
  com_endpoint_config_key_get_edge_key: 'Bing 액세스 토큰을 얻으려면 다음 사이트에 로그인하세요',
  com_endpoint_config_key_get_edge_key_dev_tool:
    '로그인한 상태에서 개발 도구 또는 확장 프로그램을 사용하여 _U 쿠키의 내용을 복사합니다. 실패하는 경우 다음',
  com_endpoint_config_key_edge_instructions: '지침',
  com_endpoint_config_key_edge_full_key_string: '전체 쿠키 문자열을 제공하세요',
  com_nav_plugin_store: '플러그인 스토어',
  com_nav_plugin_search: '플러그인 검색',
  com_nav_plugin_auth_error:
    '이 플러그인을 인증하려는 중에 오류가 발생했습니다. 다시 시도해주세요.',
  com_nav_export_filename: '파일 이름',
  com_nav_export_filename_placeholder: '파일 이름을 설정하세요',
  com_nav_export_type: '유형',
  com_nav_export_include_endpoint_options: '엔드포인트 옵션 포함',
  com_nav_enabled: '활성화됨',
  com_nav_not_supported: '지원되지 않음',
  com_nav_export_all_message_branches: '모든 메시지 브랜치 내보내기',
  com_nav_export_recursive_or_sequential: '재귀적 또는 순차적?',
  com_nav_export_recursive: '재귀적',
  com_nav_export_conversation: '대화 내보내기',
  com_nav_theme: '테마',
  com_nav_theme_system: '시스템',
  com_nav_theme_dark: '다크',
  com_nav_theme_light: '라이트',
  com_nav_clear_all_chats: '모든 채팅 지우기',
  com_nav_confirm_clear: '지우기 확인',
  com_nav_close_sidebar: '사이드바 닫기',
  com_nav_open_sidebar: '사이드바 열기',
  com_nav_send_message: '메시지 보내기',
  com_nav_log_out: '로그아웃',
  com_nav_user: '사용자',
  com_nav_clear_conversation: '대화 지우기',
  com_nav_clear_conversation_confirm_message:
    '모든 대화를 지우시겠습니까? 이 작업은 되돌릴 수 없습니다.',
  com_nav_help_faq: '도움말 및 FAQ',
  com_nav_settings: '설정',
  com_nav_search_placeholder: '메시지 검색',
  com_nav_setting_general: '일반',
  com_nav_setting_data: '데이터 제어',
};
