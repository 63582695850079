// Spanish phrases
// file deepcode ignore NoHardcodedPasswords: No hardcoded values present in this file
// file deepcode ignore HardcodedNonCryptoSecret: No hardcoded secrets present in this file

export default {
  com_ui_examples: 'Ejemplos',
  com_ui_new_chat: 'Nuevo Chat',
  com_ui_example_quantum_computing: 'Explicar la computación cuántica en términos simples',
  com_ui_example_10_year_old_b_day:
    '¿Tienes alguna idea creativa para el cumpleaños de un niño de 10 años?',
  com_ui_example_http_in_js: '¿Cómo hago una solicitud HTTP en Javascript?',
  com_ui_capabilities: 'Capacidades',
  com_ui_capability_remember: 'Recuerda lo que el usuario dijo anteriormente en la conversación',
  com_ui_capability_correction: 'Permite que el usuario proporcione correcciones de seguimiento',
  com_ui_capability_decline_requests: 'Entrenado para rechazar solicitudes inapropiadas',
  com_ui_limitations: 'Limitaciones',
  com_ui_limitation_incorrect_info: 'Puede generar ocasionalmente información incorrecta',
  com_ui_limitation_harmful_biased:
    'Puede ocasionalmente producir instrucciones perjudiciales o contenido sesgado',
  com_ui_limitation_limited_2021: 'Conocimiento limitado del mundo y eventos después de 2021',
  com_ui_experimental: 'Experimental',
  com_ui_input: 'Entrada',
  com_ui_close: 'Cerrar',
  com_ui_model: 'Modelo',
  com_ui_select_model: 'Selecciona un modelo',
  com_ui_use_prompt: 'Usar el prompt',
  com_ui_prev: 'Anterior',
  com_ui_next: 'Siguiente',
  com_ui_stop: 'Detener',
  com_ui_prompt_templates: 'Plantillas de Prompt',
  com_ui_hide_prompt_templates: 'Ocultar Plantillas de Prompt',
  com_ui_showing: 'Mostrando',
  com_ui_of: 'de',
  com_ui_entries: 'Entradas',
  com_ui_pay_per_call:
    'Todas las conversaciones de IA en un solo lugar. Paga por llamada y no por mes',
  com_ui_new_footer: 'Todas las conversaciones de IA en un solo lugar.',
  com_ui_enter: 'Entrar',
  com_ui_submit: 'Enviar',
  com_ui_upload_success: 'Archivo cargado exitosamente',
  com_ui_upload_error: 'Hubo un error al cargar tu archivo',
  com_ui_upload_invalid:
    'Archivo no válido para cargar. Debe ser una imagen que no exceda los 2 MB',
  com_ui_cancel: 'Cancelar',
  com_ui_save: 'Guardar',
  com_ui_save_submit: 'Guardar y Enviar',
  com_user_message: 'Tú',
  com_ui_copy_to_clipboard: 'Copiar al portapapeles',
  com_ui_copied_to_clipboard: 'Copiado al portapapeles',
  com_ui_regenerate: 'Regenerar',
  com_ui_continue: 'Continuar',
  com_ui_edit: 'Editar',
  com_ui_success: 'Éxito',
  com_ui_all: 'todos',
  com_ui_clear: 'Limpiar',
  com_ui_revoke: 'Revocar',
  com_ui_revoke_info: 'Revocar todas las credenciales proporcionadas por el usuario',
  com_ui_confirm_action: 'Confirmar Acción',
  com_ui_chats: 'chats',
  com_ui_delete: 'Eliminar',
  com_ui_delete_conversation: '¿Eliminar chat?',
  com_ui_delete_conversation_confirm: 'Esto eliminará',
  com_ui_preview: 'Vista previa',
  com_ui_upload: 'Cargar',
  com_ui_connect: 'Conectar',
  com_auth_error_login:
    'No se pudo iniciar sesión con la información proporcionada. Verifica tus credenciales e inténtalo de nuevo.',
  com_auth_error_login_rl:
    'Demasiados intentos de inicio de sesión en un corto período de tiempo. Por favor, inténtalo nuevamente más tarde.',
  com_auth_error_login_ban:
    'Tu cuenta ha sido temporalmente suspendida debido a violaciones de nuestro servicio.',
  com_auth_error_login_server:
    'Hubo un error interno del servidor. Por favor, espera unos momentos e inténtalo de nuevo.',
  com_auth_no_account: '¿No tienes una cuenta?',
  com_auth_sign_up: 'Registrarse',
  com_auth_sign_in: 'Iniciar sesión',
  com_auth_google_login: 'Iniciar sesión con Google',
  com_auth_facebook_login: 'Iniciar sesión con Facebook',
  com_auth_github_login: 'Iniciar sesión con Github',
  com_auth_discord_login: 'Iniciar sesión con Discord',
  com_auth_email: 'Correo electrónico',
  com_auth_email_required: 'El correo electrónico es obligatorio',
  com_auth_email_min_length: 'El correo electrónico debe tener al menos 6 caracteres',
  com_auth_email_max_length: 'El correo electrónico no debe tener más de 120 caracteres',
  com_auth_email_pattern: 'Debes ingresar una dirección de correo electrónico válida',
  com_auth_email_address: 'Dirección de correo electrónico',
  com_auth_password: 'Contraseña',
  com_auth_password_required: 'La contraseña es obligatoria',
  com_auth_password_min_length: 'La contraseña debe tener al menos 8 caracteres',
  com_auth_password_max_length: 'La contraseña debe tener menos de 128 caracteres',
  com_auth_password_forgot: '¿Olvidaste la contraseña?',
  com_auth_password_confirm: 'Confirmar contraseña',
  com_auth_password_not_match: 'Las contraseñas no coinciden',
  com_auth_continue: 'Continuar',
  com_auth_create_account: 'Crear tu cuenta',
  com_auth_error_create:
    'Hubo un error al intentar registrar tu cuenta. Por favor, inténtalo nuevamente.',
  com_auth_full_name: 'Nombre completo',
  com_auth_name_required: 'El nombre es obligatorio',
  com_auth_name_min_length: 'El nombre debe tener al menos 3 caracteres',
  com_auth_name_max_length: 'El nombre debe tener menos de 80 caracteres',
  com_auth_username: 'Nombre de usuario (opcional)',
  com_auth_username_required: 'El nombre de usuario es obligatorio',
  com_auth_username_min_length: 'El nombre de usuario debe tener al menos 2 caracteres',
  com_auth_username_max_length: 'El nombre de usuario debe tener menos de 20 caracteres',
  com_auth_already_have_account: '¿Ya tienes una cuenta?',
  com_auth_login: 'Iniciar sesión',
  com_auth_reset_password: 'Restablecer tu contraseña',
  com_auth_click: 'Haz clic',
  com_auth_here: 'AQUÍ',
  com_auth_to_reset_your_password: 'para restablecer tu contraseña.',
  com_auth_reset_password_link_sent: 'Correo electrónico enviado',
  com_auth_reset_password_email_sent:
    'Se ha enviado un correo electrónico con más instrucciones para restablecer tu contraseña.',
  com_auth_error_reset_password:
    'Hubo un problema al restablecer tu contraseña. No se encontró ningún usuario con la dirección de correo electrónico proporcionada. Por favor, inténtalo de nuevo.',
  com_auth_reset_password_success: 'Restablecimiento de contraseña exitoso',
  com_auth_login_with_new_password: 'Ahora puedes iniciar sesión con tu nueva contraseña.',
  com_auth_error_invalid_reset_token:
    'Este token de restablecimiento de contraseña ya no es válido.',
  com_auth_click_here: 'Haz clic aquí',
  com_auth_to_try_again: 'para intentarlo de nuevo.',
  com_auth_submit_registration: 'Enviar registro',
  com_auth_welcome_back: 'Bienvenido de nuevo',
  com_endpoint_open_menu: 'Abrir menú',
  com_endpoint_bing_enable_sydney: 'Habilitar Sydney',
  com_endpoint_bing_to_enable_sydney: 'Para habilitar Sydney',
  com_endpoint_bing_jailbreak: 'Jailbreak',
  com_endpoint_bing_context_placeholder:
    'Bing puede usar hasta 7k tokens para "contexto", que puede referenciar en la conversación. El límite específico no se conoce, pero puede haber errores al superar los 7k tokens',
  com_endpoint_bing_system_message_placeholder:
    'ADVERTENCIA: El uso indebido de esta función puede resultar en una PROHIBICIÓN del uso de Bing. Haga clic en \'System Message\' para obtener instrucciones completas y el mensaje predeterminado si se omite, que es el ajuste preestablecido \'Sydney\', considerado seguro.',
  com_endpoint_system_message: 'Mensaje del Sistema',
  com_endpoint_message: 'Conversar con',
  com_endpoint_message_not_appendable: 'Edita tu mensaje o Regenera.',
  com_endpoint_default_blank: 'predeterminado: en blanco',
  com_endpoint_default_false: 'predeterminado: falso',
  com_endpoint_default_creative: 'predeterminado: creativo',
  com_endpoint_default_empty: 'predeterminado: vacío',
  com_endpoint_default_with_num: 'predeterminado: {0}',
  com_endpoint_context: 'Contexto',
  com_endpoint_tone_style: 'Estilo de Tono',
  com_endpoint_token_count: 'Conteo de Tokens',
  com_endpoint_output: 'Salida',
  com_endpoint_google_temp:
    'Valores más altos = más aleatorio, mientras que valores más bajos = más enfocado y determinista. Recomendamos cambiar esto o Top P, pero no ambos.',
  com_endpoint_google_topp:
    'Top-p cambia cómo el modelo selecciona tokens para la salida. Los tokens se seleccionan desde el más K (ver el parámetro topK) probable hasta el menos, hasta que la suma de sus probabilidades sea igual al valor de top-p.',
  com_endpoint_google_topk:
    'Top-k cambia cómo el modelo selecciona tokens para la salida. Un top-k de 1 significa que el token seleccionado es el más probable entre todos los tokens en el vocabulario del modelo (también llamado decodificación codiciosa), mientras que un top-k de 3 significa que el próximo token se selecciona entre los 3 tokens más probables (usando temperatura).',
  com_endpoint_google_maxoutputtokens:
    'Número máximo de tokens que se pueden generar en la respuesta. Especifica un valor menor para respuestas más cortas y un valor mayor para respuestas más largas.',
  com_endpoint_google_custom_name_placeholder: 'Establece un nombre personalizado para Google',
  com_endpoint_prompt_prefix_placeholder:
    'Establece instrucciones personalizadas o contexto. Ignorado si está vacío.',
  com_endpoint_custom_name: 'Nombre Personalizado',
  com_endpoint_prompt_prefix: 'Prefijo del Prompt',
  com_endpoint_temperature: 'Temperatura',
  com_endpoint_default: 'predeterminado',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top K',
  com_endpoint_max_output_tokens: 'Máximo de Tokens de Salida',
  com_endpoint_openai_temp:
    'Valores más altos = más aleatorio, mientras que valores más bajos = más enfocado y determinista. Recomendamos cambiar esto o Top P, pero no ambos.',
  com_endpoint_openai_max:
    'Tokens máximos para generar. La longitud total de los tokens de entrada y los tokens generados está limitada por la longitud del contexto del modelo.',
  com_endpoint_openai_topp:
    'Una alternativa para el muestreo con temperatura, llamada muestreo de núcleo, donde el modelo considera los resultados de los tokens con masa de probabilidad top_p. Entonces, 0.1 significa que solo se consideran los tokens que comprenden la masa de probabilidad del 10% superior. Recomendamos cambiar esto o la temperatura, pero no ambos.',
  com_endpoint_openai_freq:
    'Número entre -2.0 y 2.0. Los valores positivos penalizan nuevos tokens en función de su frecuencia existente en el texto hasta ahora, disminuyendo la probabilidad de que el modelo repita la misma línea literalmente.',
  com_endpoint_openai_pres:
    'Número entre -2.0 y 2.0. Los valores positivos penalizan nuevos tokens en función de si aparecen en el texto hasta ahora, aumentando la probabilidad de que el modelo hable sobre nuevos temas.',
  com_endpoint_openai_custom_name_placeholder: 'Establece un nombre personalizado para ChatGPT',
  com_endpoint_openai_prompt_prefix_placeholder:
    'Establece instrucciones personalizadas para incluir en el Mensaje del Sistema. Predeterminado: ninguno',
  com_endpoint_anthropic_temp:
    'Varía de 0 a 1. Usa temp más cercano a 0 para analítico / opción múltiple, y más cercano a 1 para tareas creativas y generativas. Recomendamos cambiar esto o Top P, pero no ambos.',
  com_endpoint_anthropic_topp:
    'Top-p cambia cómo el modelo selecciona tokens para la salida. Los tokens se seleccionan desde el más K (ver el parámetro topK) probable hasta el menos, hasta que la suma de sus probabilidades sea igual al valor de top-p.',
  com_endpoint_anthropic_topk:
    'Top-k cambia cómo el modelo selecciona tokens para la salida. Un top-k de 1 significa que el token seleccionado es el más probable entre todos los tokens en el vocabulario del modelo (también llamado decodificación codiciosa), mientras que un top-k de 3 significa que el próximo token se selecciona entre los 3 tokens más probables (usando temperatura).',
  com_endpoint_anthropic_maxoutputtokens:
    'Número máximo de tokens que se pueden generar en la respuesta. Especifica un valor menor para respuestas más cortas y un valor mayor para respuestas más largas.',
  com_endpoint_anthropic_custom_name_placeholder:
    'Establece un nombre personalizado para Anthropic',
  com_endpoint_frequency_penalty: 'Penalización de Frecuencia',
  com_endpoint_plug_use_functions: 'Usar Funciones',
  com_endpoint_plug_skip_completion: 'Omitir Completado',
  com_endpoint_disabled_with_tools: 'desactivado con herramientas',
  com_endpoint_disabled_with_tools_placeholder: 'Desactivado con Herramientas Seleccionadas',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    'Establecer instrucciones personalizadas para incluir en el Mensaje del Sistema. Predeterminado: ninguno',
  com_endpoint_import: 'Importar',
  com_endpoint_set_custom_name:
    'Establecer un nombre personalizado, en caso de que quieras encontrar este preset',
  com_endpoint_preset_delete_confirm: '¿Estás seguro de que quieres eliminar este preset?',
  com_endpoint_preset_clear_all_confirm: '¿Estás seguro de que quieres eliminar todos tus presets?',
  com_endpoint_preset_import: 'Preset Importado!',
  com_endpoint_preset_import_error:
    'Hubo un error al importar tu preset. Por favor, inténtalo de nuevo.',
  com_endpoint_preset_save_error:
    'Hubo un error al guardar tu preset. Por favor, inténtalo de nuevo.',
  com_endpoint_preset_delete_error:
    'Hubo un error al eliminar tu preset. Por favor, inténtalo de nuevo.',
  com_endpoint_preset_default_removed: 'ya no es el preset predeterminado.',
  com_endpoint_preset_default_item: 'Predeterminado:',
  com_endpoint_preset_default_none: 'Ningún preset predeterminado activo.',
  com_endpoint_preset_title: 'Preset',
  com_endpoint_preset_saved: 'Guardado!',
  com_endpoint_preset_default: 'es ahora el preset predeterminado.',
  com_endpoint_preset: 'preset',
  com_endpoint_presets: 'presets',
  com_endpoint_preset_selected: 'Preset Activo!',
  com_endpoint_preset_selected_title: 'Activo!',
  com_endpoint_preset_name: 'Nombre del Preset',
  com_endpoint_new_topic: 'Nuevo Tema',
  com_endpoint: 'Endpoint',
  com_endpoint_hide: 'Ocultar',
  com_endpoint_show: 'Mostrar',
  com_endpoint_examples: ' Presets',
  com_endpoint_completion: 'Completado',
  com_endpoint_agent: 'Agente',
  com_endpoint_show_what_settings: 'Mostrar Configuraciones de {0}',
  com_endpoint_save: 'Guardar',
  com_endpoint_export: 'Exportar',
  com_endpoint_save_as_preset: 'Guardar como Preset',
  com_endpoint_presets_clear_warning:
    '¿Estás seguro de que quieres borrar todos los presets? Esto es irreversible.',
  com_endpoint_not_implemented: 'No implementado',
  com_endpoint_no_presets: 'Aún no hay presets, utiliza el botón de configuración para crear uno',
  com_endpoint_not_available: 'Ningún endpoint disponible',
  com_endpoint_view_options: 'Ver Opciones',
  com_endpoint_save_convo_as_preset: 'Guardar Conversación como Preset',
  com_endpoint_my_preset: 'Mi Preset',
  com_endpoint_agent_model: 'Modelo del Agente (Recomendado: GPT-3.5)',
  com_endpoint_completion_model: 'Modelo de Completado (Recomendado: GPT-4)',
  com_endpoint_func_hover: 'Habilitar uso de Plugins como Funciones OpenAI',
  com_endpoint_skip_hover:
    'Habilitar la etapa de salto de completado, que revisa la respuesta final y las etapas generadas',
  com_endpoint_config_key: 'Establecer Clave API',
  com_endpoint_config_placeholder: 'Establece tu Clave en el menú Cabecera para conversar.',
  com_endpoint_config_key_for: 'Establecer Clave API para',
  com_endpoint_config_key_name: 'Clave',
  com_endpoint_config_value: 'Insertar valor para',
  com_endpoint_config_key_name_placeholder: 'Establece la clave API primero',
  com_endpoint_config_key_encryption: 'Tu clave será encriptada y eliminada en',
  com_endpoint_config_key_expiry: 'el tiempo de expiración',
  com_endpoint_config_click_here: 'Haz clic Aquí',
  com_endpoint_config_google_service_key: 'Clave de la Cuenta de Servicio de Google',
  com_endpoint_config_google_cloud_platform: '(de Google Cloud Platform)',
  com_endpoint_config_google_api_key: 'Clave API de Google',
  com_endpoint_config_google_gemini_api: '(API Gemini)',
  com_endpoint_config_google_api_info:
    'Para obtener tu clave API de Lenguaje Generativo (para Gemini),',
  com_endpoint_config_key_import_json_key: 'Importar Clave JSON de la Cuenta de Servicio.',
  com_endpoint_config_key_import_json_key_success:
    'Clave JSON de la Cuenta de Servicio importada con éxito',
  com_endpoint_config_key_import_json_key_invalid:
    'Clave JSON de la Cuenta de Servicio inválida, ¿importaste el archivo correcto?',
  com_endpoint_config_key_get_edge_key:
    'Para obtener tu token de acceso para Bing, inicia sesión en',
  com_endpoint_config_key_get_edge_key_dev_tool:
    'Utiliza las herramientas de desarrollo o una extensión mientras estás conectado al sitio para copiar el contenido de la cookie _U. Si esto falla, sigue estas',
  com_endpoint_config_key_edge_instructions: 'instrucciones',
  com_endpoint_config_key_edge_full_key_string:
    'para proporcionar las cadenas completas de la cookie.',
  com_endpoint_config_key_chatgpt:
    'Para obtener tu token de acceso para ChatGPT \'Versión Gratuita\', inicia sesión en',
  com_endpoint_config_key_chatgpt_then_visit: 'luego visita',
  com_endpoint_config_key_chatgpt_copy_token: 'Copia el token de acceso.',
  com_endpoint_config_key_google_need_to: 'Necesitas',
  com_endpoint_config_key_google_vertex_ai: 'Habilitar Vertex AI',
  com_endpoint_config_key_google_vertex_api: 'API en Google Cloud, luego',
  com_endpoint_config_key_google_service_account: 'Crea una Cuenta de Servicio',
  com_endpoint_config_key_google_vertex_api_role:
    'Asegúrate de hacer clic en \'Crear y Continuar\' para dar al menos el rol \'Usuario de Vertex AI\'. Finalmente, crea una clave JSON para importar aquí.',
  com_nav_welcome_message: '¿Cómo puedo ayudarte hoy?',
  com_nav_auto_scroll: 'Desplazamiento Automático al Más Nuevo al Abrir',
  com_nav_modular_chat: 'Activar el cambio de Endpoints en medio de la conversación',
  com_nav_profile_picture: 'Foto de Perfil',
  com_nav_change_picture: 'Cambiar foto',
  com_nav_plugin_store: 'Tienda de plugins',
  com_show_agent_settings: 'Mostrar Configuraciones del Agente',
  com_show_completion_settings: 'Mostrar Configuraciones de Completado',
  com_hide_examples: 'Ocultar Ejemplos',
  com_show_examples: 'Mostrar Ejemplos',
  com_nav_plugin_search: 'Buscar plugins',
  com_nav_plugin_auth_error:
    'Hubo un error al intentar autenticar este plugin. Por favor, inténtalo de nuevo.',
  com_nav_export_filename: 'Nombre del archivo',
  com_nav_export_filename_placeholder: 'Establecer el nombre del archivo',
  com_nav_export_type: 'Tipo',
  com_nav_export_include_endpoint_options: 'Incluir opciones de endpoint',
  com_nav_enabled: 'Habilitado',
  com_nav_not_supported: 'No soportado',
  com_nav_export_all_message_branches: 'Exportar todas las ramas de mensajes',
  com_nav_export_recursive_or_sequential: '¿Recursivo o secuencial?',
  com_nav_export_recursive: 'Recursivo',
  com_nav_export_conversation: 'Exportar conversación',
  com_nav_theme: 'Tema',
  com_nav_theme_system: 'Sistema',
  com_nav_theme_dark: 'Oscuro',
  com_nav_theme_light: 'Claro',
  com_nav_user_name_display: 'Mostrar nombre de usuario en los mensajes',
  com_nav_clear_all_chats: 'Limpiar todos los chats',
  com_nav_confirm_clear: 'Confirmar Limpieza',
  com_nav_close_sidebar: 'Cerrar barra lateral',
  com_nav_open_sidebar: 'Abrir barra lateral',
  com_nav_send_message: 'Enviar mensaje',
  com_nav_log_out: 'Cerrar sesión',
  com_nav_user: 'USUARIO',
  com_nav_clear_conversation: 'Limpiar conversaciones',
  com_nav_clear_conversation_confirm_message:
    '¿Estás seguro de que quieres limpiar todas las conversaciones? Esto es irreversible.',
  com_nav_help_faq: 'Ayuda y Preguntas Frecuentes',
  com_nav_settings: 'Configuraciones',
  com_nav_search_placeholder: 'Buscar mensajes',
  com_nav_setting_general: 'General',
  com_nav_setting_data: 'Controles de datos',
  com_nav_setting_account: 'Cuenta',
  com_nav_language: 'Idioma',
  com_nav_lang_auto: 'Detección automática',
  com_nav_lang_spanish: 'Español',
};
