import { useEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import type { TMessage } from 'librechat-data-provider';
import ScrollToBottom from '~/components/Messages/ScrollToBottom';
import { useScreenshot, useMessageScrolling, useMessageHandler, useVeraChat } from '~/hooks';
import { CSSTransition } from 'react-transition-group';
import MultiMessage from './MultiMessage';
import ProcessingSpinner from './ProcessingSpinner';
import VeraErrorMessage from './VeraErrorMessage';
import { useParams } from 'react-router-dom';
import autoAnimate from '@formkit/auto-animate';

export default function MessagesView({
  messagesTree: _messagesTree,
  Header,
}: {
  messagesTree?: TMessage[] | null;
  Header?: ReactNode;
}) {
  const { conversationId } = useParams();
  const { screenshotTargetRef } = useScreenshot();
  const [currentEditId, setCurrentEditId] = useState<number | string | null>(-1);
  const { isSubmitting, currEvent, error, getUserTempMessageIsPresent } = useVeraChat(
    conversationId,
    conversationId,
  );
  const {
    scrollableRef,
    messagesEndRef,
    showScrollButton,
    handleSmoothToRef,
    debouncedHandleScroll,
  } = useMessageScrolling(_messagesTree);

  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  return (
    <div className="flex-1 overflow-hidden overflow-y-auto">
      <div className="dark:gpt-dark-gray relative h-full">
        <div
          onScroll={debouncedHandleScroll}
          ref={scrollableRef}
          style={{
            height: '100%',
            overflowY: 'auto',
            width: '100%',
          }}
        >
          <div className="flex flex-col pb-14 text-sm dark:bg-transparent">
            {(_messagesTree && _messagesTree?.length == 0) || _messagesTree === null ? (
              <div className="flex w-full items-center justify-center gap-1 bg-gray-50 p-3 text-sm text-gray-500 dark:border-gray-900/50 dark:bg-vnavy-dark-mode dark:text-gray-300">
                Nothing found
              </div>
            ) : (
              <>
                {Header && Header}
                <div ref={screenshotTargetRef}>
                  <div ref={parent}>
                    <MultiMessage
                      key={conversationId} // avoid internal state mixture
                      messagesTree={_messagesTree}
                      messageId={conversationId ?? null}
                      setCurrentEditId={setCurrentEditId}
                      currentEditId={currentEditId ?? null}
                    />

                    {!!error && <VeraErrorMessage error={error} />}
                  </div>

                  {isSubmitting && conversationId && (
                    <ProcessingSpinner
                      key={currEvent}
                      event={currEvent}
                      isUser={
                        getUserTempMessageIsPresent() || currEvent?.includes('Processing Image')
                      }
                    />
                  )}
                </div>
              </>
            )}
            <div
              className="dark:gpt-dark-gray group h-0 w-full flex-shrink-0 dark:border-gray-900/50"
              ref={messagesEndRef}
            />
          </div>
        </div>
        <CSSTransition
          in={showScrollButton}
          timeout={400}
          classNames="scroll-down"
          unmountOnExit={false}
          // appear
        >
          {() => showScrollButton && <ScrollToBottom scrollHandler={handleSmoothToRef} />}
        </CSSTransition>
      </div>
    </div>
  );
}
