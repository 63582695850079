// VERA AUTH HEADER
export const VERA_HEADER = 'x-vera-access-token';

// VERA COLORS
export const VERA_YELLOW = '#FCE61A';
export const VERA_LIME = '#32FA78';
export const VERA_PINK = '#FF32C8';
export const VERA_PURPLE = '#967CFF';
export const VERA_WHITE = '#F6F7FF';
export const VERA_NAVY = '#1C1444';
export const VERA_RED = '#F35431';
// GRAYS
export const MED_GRAY = '#6C7080';
export const SOFT_GRAY = '#DADCE5';
export const HARD_GRAY = '#4A5568';
export const ALTERNATIVE_GRAY = '#A6A9B2';

// APP LANDING PATH
export const LANDING_PATH = '/c/new';

// ACCOUNT STATE
export const ACCOUNT_STATE = {
  NORMAL: 'normal',
  LOCKED: 'locked',
};

// SKU
export const SKU = {
  SANDBOX: 'sandbox',
  PAID: 'paid',
  FULL_ACCESS: 'full-access',
};

// DEPRECATED VERA COLORS
export const VERA_BLUE = '#3F5AFF';
export const VERA_MINT = '#19D8CA';
export const VERA_TEAL = '#30A9E5';
// export const VERA_RED = '#EE422B';
//export const VERA_PEACH = "#FF9688";
// export const VERA_NAVY = "#192C6B";
// export const BACKGROUND_BASE = "#F2F5F9"
