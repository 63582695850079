import { useContext, type ReactNode } from 'react';
//import { useGetEndpointsQuery } from 'librechat-data-provider/react-query';
import { EModelEndpoint } from 'librechat-data-provider';
import { icons } from './Menus/Models/Icons';
import { useChatContext } from '~/Providers';
import { ThemeContext, useLocalize } from '~/hooks';
import VeraColorIcon from '../svg/VeraColorLogo';
import { useUserModels } from '~/services/queries/models';
import { useSandboxStore } from '~/zustand/sandbox';
import VeraFullLogo from '../svg/VeraFullLogo';
import { VERA_NAVY, VERA_WHITE } from '~/utils/constants';

export default function Landing({ Header }: { Header?: ReactNode }) {
  // const { conversation } = useChatContext();
  const { theme } = useContext(ThemeContext);

  let isDark = theme === 'dark';
  const { isDemoComplete, isSandbox } = useSandboxStore();

  const localize = useLocalize();
  const modelsQuery = useUserModels();
  const models = modelsQuery.data ?? [];
  const chatIsUnavailable = !modelsQuery.isLoading && models.length === 0;
  // let { endpoint } = conversation ?? {};
  // if (
  //   endpoint === EModelEndpoint.assistant ||
  //   endpoint === EModelEndpoint.chatGPTBrowser ||
  //   endpoint === EModelEndpoint.azureOpenAI ||
  //   endpoint === EModelEndpoint.gptPlugins
  // ) {
  //   endpoint = EModelEndpoint.openAI;
  // }

  const getLandingMessage = () => {
    if (isDemoComplete && isSandbox) {
      return 'Unlock Full Access to Vera Today!';
    } else if (chatIsUnavailable) {
      return 'Chat is currently unavailable';
    }

    return localize('com_nav_welcome_message');
  };

  return (
    <div className="relative h-full">
      <div className="absolute left-0 right-0">{Header && Header}</div>
      <div className="flex h-full flex-col items-center justify-center">
        <div className="mb-10 h-16 w-auto">
          <div className="gizmo-shadow-stroke relative flex h-full items-center justify-center rounded-full text-black">
            <VeraFullLogo color={isDark ? VERA_WHITE : VERA_NAVY} />
          </div>
        </div>
        <div className="mb-5 text-2xl font-medium dark:text-vwhite">{getLandingMessage()}</div>
      </div>
    </div>
  );
}
